import React from 'react';
import { Mutation } from '@apollo/react-components';
import { destroyImageMutation } from 'lib/graphql/mutations';
import { CloseIconWrapper, CloseIcon } from './styled';

export default ({ id, removeImageFromStateById, setIsPersisting }) => {
  return (
    <Mutation mutation={destroyImageMutation}>
      {(mutation) => (
        <CloseIconWrapper
          onClick={(event) => {
            if (!window.confirm('Are you sure you want to delete this image?')) {
              return event.preventDefault();
            }
            setIsPersisting(true);
            mutation({
              variables: { id },
              update: (proxy, { data }) => {
                removeImageFromStateById(data.destroyImage.id);
                setIsPersisting(false);
              },
            });
          }}
        >
          <CloseIcon width={13} height={13} title="Close" />
        </CloseIconWrapper>
      )}
    </Mutation>
  );
};
