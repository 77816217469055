import styled from 'styled-components/macro';
import { deprecatedRebassProps } from './deprecated';

const Text = styled.p`
  font-size: 1em;
  margin: 0px;
  color: ${(p) => p.color || 'inherit'};
`;

Text.propTypes = {
  ...deprecatedRebassProps,
};

export default Text;
