import update from 'immutability-helper';
import set from 'lodash/set';
import { withProps } from 'recompose';

function getNewImagePosition(state) {
  const positions = state.result.images.map((imageId) => state.entities.images[imageId].position);
  return positions.length > 0 ? Math.max(...positions) + 1 : 0;
}

function generateId() {
  return `new-${Math.random().toString(36).substring(7)}`;
}

const withImagesStateHandlers = withProps(
  ({ setFormState, handleCardFormSubmit, setLoadingImageUrls, ...rest }) => ({
    removeImageFromStateById: (deletedImageId) => {
      setFormState((state) => {
        const deletedImageIndex = state.result.images.indexOf(deletedImageId);
        return update(state, {
          entities: { images: { $unset: [deletedImageId] } },
          result: { images: { $splice: [[deletedImageIndex, 1]] } },
        });
      });
    },

    resetImageState(image) {
      // No need to do state.result.ctas as the CTA should still exist,
      // we're just resetting its values.
      setFormState((state) => set({ ...state }, `entities.images.${image.id}`, image));
    },

    // Remove the title, description and url from the image form's state
    // We'll need a save to clear it out
    clearImageState(image) {
      setFormState((state) =>
        update(state, {
          entities: {
            images: {
              [`${image.id}`]: {
                $set: { ...image, title: '', description: '', url: '' },
              },
            },
          },
        }),
      );
    },

    addImageToStateById: (imageUrls, defaultDeck) => {
      setFormState((state) => {
        const newImagePosition = getNewImagePosition(state);
        const newImageIds = [];
        const newImages = {};
        let newState;

        // Build the state for the newly uploaded images
        imageUrls.forEach((imageUrl, index) => {
          const id = generateId();
          newImageIds.push(id);
          // Ensure each images uses a higher position
          const position = newImagePosition + index;
          newImages[id] = { id, position, imageUrl };
        });

        if (state.entities.images) {
          // On subsequent uploads we just need to update the images array
          newState = update(state, { entities: { images: { $merge: newImages } } });
        } else {
          // On initial upload there will be no state.entities.images object defined
          // so we need to add it to state.entities.images. Note that state.entities.ctas may
          // exist and we don't want to overwrite it!
          newState = update(state, { entities: { $merge: { images: newImages } } });
        }

        // Always update state.result.images
        newState = update(newState, { result: { images: { $push: newImageIds } } });

        // Persist the new image
        handleCardFormSubmit(newState, defaultDeck.id);

        // Clear out state for uploading preview images
        setLoadingImageUrls([]);

        return newState;
      });
    },
  }),
);

export default withImagesStateHandlers;
