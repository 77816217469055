import React from 'react';

function Palette(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 42 42" {...props}>
      <path
        fill="#ffffff"
        fillRule="evenodd"
        d="M34.003 22.002c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3m-8-11c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3m-6 24c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5m-9-22c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3m9-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3m-1.105-5C6.008.002.003 7.986.003 13.383c0 4.178 3.23 5.778 5.823 7.065 2.264 1.122 3.651 1.89 3.651 3.458 0 1.988-.896 2.686-2.134 3.654-1.394 1.088-3.129 2.444-3.129 5.871 0 5.047 6.038 8.571 14.684 8.571 9.288 0 23.105-5.339 23.105-20.048C42.003 8.208 33.365.002 18.898.002"
      />
    </svg>
  );
}

export default Palette;
