import React from 'react';
import { compose, withProps, omitProps } from 'lib/recompose';
import { css } from 'styled-components';
import { Text, Input as BaseInput } from 'components/web-ui';
import FormBasePopover from 'components/cards/forms/popovers/base';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import { unsetSelectedCallToAction } from 'store/modules/card-call-to-action';
import { Button as BaseButton } from 'components/web-ui';
import styled from 'styled-components/macro';
import AssistedUrlInputAdapter from '../assisted-url-input-adapter';

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const Input = styled(BaseInput)`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  margin-bottom: 7px;
  ${fieldBorderCSS};
`.withComponent(omitProps('hasErrors')(BaseInput));

const Field = styled.div`
  position: relative;
`;

const Hint = styled(Text)`
  color: ${({ theme }) => theme.colors.genericForm.hint};
  font-size: 13px;
  margin-top: -3px;
  margin-bottom: 15px;
`;

const SubmitButton = styled(BaseButton)`
  font-size: 18px;
  border-radius: 2em;
  height: 50px;
  margin-top: 12px;
  background-color: ${(p) => p.theme.colors.primaryPurple};
  color: white;

  background-color: ${(p) => p.disabled && '#f5f5f5'};
  color: ${(p) => p.disabled && '#c1c1c1'};

  &:hover {
    cursor: ${(p) => (p.disabled ? 'initial' : 'pointer')};
    box-shadow: ${(p) => p.disabled && 'none'};
  }
`;

const FormPanel = (props) => {
  const {
    card,
    data: { defaultDeck },
    formState,
    handleCardFormSubmit,
    isFormValid,
    isPersisting,
    renderField,
    selectedCallToAction,
    setFormState,
    unsetPopover,
  } = props;

  return (
    <FormBasePopover
      card={card}
      cta={get(formState, `entities.ctas.${selectedCallToAction}`)}
      formState={formState}
      setFormState={setFormState}
      title={`${card.ctas.length ? 'Edit' : 'Add'} link`}
    >
      {renderField({
        name: `entities.ctas.${selectedCallToAction}.title`,
        placeholder: 'Tap here',
        hint: 'Button text — call to action',
        selectAllOnFocus: true,
        styledHint: Hint,
        styledInput: Input,
        styledField: Field,
      })}
      {renderField({
        autoCapitalize: 'off',
        autoCorrect: 'off',
        type: 'text',
        name: `entities.ctas.${selectedCallToAction}.url`,
        placeholder: 'Website, Phone Number, or Email',
        styledInput: AssistedUrlInputAdapter,
        styledField: Field,
      })}

      <SubmitButton
        children={isPersisting ? 'Saving...' : 'Save'}
        disabled={!isFormValid}
        onClick={async (event) => {
          event.preventDefault();

          const saveWasSuccessful = await handleCardFormSubmit(formState, defaultDeck.id);
          if (saveWasSuccessful) {
            unsetPopover(null);
            unsetSelectedCallToAction(null);
          } else {
            console.error(formState);
            console.error(saveWasSuccessful);
          }
        }}
      />
    </FormBasePopover>
  );
};

export default compose(
  connect(
    (state, ownProps) => ({
      isShowingAccountSwitcher: state.accountSwitcher ? state.accountSwitcher.isShowing : false,
      selectedCallToAction: state.cardCallToAction.id,
    }),
    (dispatch) => ({
      unsetPopover: () => dispatch(unsetPopover()),
      unsetSelectedCallToAction: () => dispatch(unsetSelectedCallToAction()),
    }),
  ),

  withProps(({ formState, selectedCallToAction, ...props }) => {
    const cta = get(formState, `entities.ctas.${selectedCallToAction}`);

    if (!cta) return false;

    return { isFormValid: !!cta.url && !isEmpty(cta.title) };
  }),
)(FormPanel);
