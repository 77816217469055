import React from 'react';
import { compose, withProps, defaultProps } from 'recompose';
import styled from 'styled-components/macro';
import MailChimpSVG from './logo-mailchimp';
import ConstantContactSVG from './logo-constantcontact';
import ZapierSVG from './logo-zapier';

export const ZapierLogo = compose(
  defaultProps({
    width: 80,
    height: 80,
  }),
  withProps((props) => ({
    children: <ZapierSVG {...props} />,
  })),
)(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width + 40}px;
  height: ${(props) => props.height + 40}px;
  border-radius: 50%;
`);

export const MailChimpLogo = compose(
  defaultProps({
    width: 80,
    height: 80,
  }),
  withProps((props) => ({
    children: <MailChimpSVG {...props} />,
  })),
)(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width + 40}px;
  height: ${(props) => props.height + 40}px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
`);

export const ConstantContactLogo = compose(
  defaultProps({
    width: 80,
    height: 80,
  }),
  withProps((props) => ({
    children: <ConstantContactSVG {...props} />,
  })),
)(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width + 40}px;
  height: ${(props) => props.height + 40}px;
`);
