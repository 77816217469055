import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components/macro';

const BaseBlocker = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  pointer-events: auto;
`;

const OverlayBlocker = styled(({ opacity, ...rest }) => <BaseBlocker {...rest} />)`
  background-color: rgba(0, 0, 0, ${(props) => props.opacity});
  z-index: ${(props) => props.zIndex || 10};
`;

const _Blocker = ({ handleClick, opacity, zIndex }) => (
  <OverlayBlocker opacity={opacity} onClick={handleClick} zIndex={zIndex} />
);

const handlers = withHandlers({
  handleClick:
    ({ dispatch, onClick }) =>
    (event) => {
      onClick && onClick(event);
    },
});

// prettier-ignore
export default compose(
  connect(),
  handlers
)(_Blocker);
