import React from 'react';
import Blocker from './blocker';
import Transition from 'react-transition-group/Transition';
import styled from 'styled-components/macro';

const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px 14px;
  border-radius: 8px;
  position: absolute;
  font-size: 14px;
  /*
    The blocker is set to z-index: 10, so any value above that should work.
    However, I needed to set this to at least 15 to get it to work. ¯\_(ツ)_/¯
  */
  z-index: 15;

  @keyframes pop {
    50% {
      transform: scale(1.2);
    }
  }

  ${({ state }) =>
    state === 'entered' &&
    `
      animation: pop 0.15s ease-in-out;
      animation-fill-mode: forwards;
    `};

  left: ${({ left }) => `calc(${left})`};
  top: ${({ top }) => `calc(${top})`};

  :after,
  :before {
    bottom: 100%;
    left: 19px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :after {
    border-color: rgba(119, 100, 213, 0);
    border-bottom-color: white;
    border-width: 6px;
    margin-left: -6px;
  }
  :before {
    border-color: rgba(119, 100, 213, 0);
    border-bottom-color: white;
    border-width: 6px;
    margin-left: -6px;
  }
`.withComponent(({ state, left, top, ...rest }) => <div {...rest} />);

function Tooltip(props) {
  const { children, left, top, onClickBlocker, blockerPosition } = props;
  return (
    <React.Fragment>
      <Blocker blockerPosition={blockerPosition} onClick={onClickBlocker} zIndex={10} />
      <Transition appear={true} in={true} timeout={500}>
        {(state) => <TooltipContainer children={children} state={state} left={left} top={top} />}
      </Transition>
    </React.Fragment>
  );
}

export default Tooltip;
