import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableImage from './sortable-image';
import { DraggableWrapper } from './styled';

class SortableImageGrid extends React.Component {
  render() {
    const { handleShowImageForm, loadingImageUrls, orderedImages, removeImageFromStateById } =
      this.props;

    //  This DragabbleWrapper div is required (so don't remove it)
    //  https://github.com/clauderic/react-sortable-hoc/issues/367
    return (
      <DraggableWrapper>
        {orderedImages.map((image, index) => (
          <SortableImage
            disabled={false}
            handleShowImageForm={handleShowImageForm}
            image={image}
            index={index}
            key={index}
            removeImageFromStateById={removeImageFromStateById}
          />
        ))}
        {loadingImageUrls.map((loadingImage, index) => (
          <SortableImage
            disabled
            image={{ imageUrl: loadingImage.src }}
            index={orderedImages.length + index}
            isLoading
            key={index}
          />
        ))}
      </DraggableWrapper>
    );
  }
}

export default SortableContainer(SortableImageGrid, { withRef: true });
