import React from 'react';
import * as Recompose from 'recompose';
import { toggleMoreMenu } from 'store/modules/more-menu';
import * as Redux from 'react-redux';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import * as Styled from './styled';
import Avatar from 'components/avatar';

const activeState = css`
  color: ${(props) => props.theme.colors.alphaBlack40};
  background-color: ${(props) => props.theme.colors.alphaWhite40};
`;

const ShowAccountMenuButton = styled(Styled.HeaderButtonCircle)`
  position: absolute;
  right: 14px;
  top: calc(${({ theme }) => theme.cardForm.headerTopOffset} + 16px);
  cursor: pointer;
  width: 38px;
  height: 38px;
  transform: translate3d(0, 0, 0);
  /* Set to work with the onboarding blockers */
  z-index: 5;

  ${(p) => p.isActive && activeState}

  & > div {
    background-color: transparent;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export default Recompose.compose(
  Redux.connect(
    (state, ownProps) => ({
      isShowingMoreMenu: state.moreMenu.isShowing,
    }),
    (dispatch, ownProps) => ({
      toggleMoreMenu: () => dispatch(toggleMoreMenu()),
    }),
  ),

  Recompose.withHandlers({
    onClick: (props) => props.toggleMoreMenu,
  }),

  Recompose.withProps((props) => {
    const { username, profile } = props.currentAccount;
    const isActive = props.isShowingMoreMenu;
    const children = <Avatar image={profile.pictureUrl} username={username} />;
    const title = 'Account Settings';

    return { isActive, children, title };
  }),
)(ShowAccountMenuButton);
