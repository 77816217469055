import config from 'config';
import routes from 'config/routes';
import { withHandlers } from 'lib/recompose';

const toQuerystring = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

export default withHandlers({
  handleInstagramLogin: (props) => async (evt) => {
    evt && evt.preventDefault();

    // We used to have code here that attempted to log the user out of instagram
    // However, this appears to be blocked (at least in Chrome) due to CORB:
    // https://www.chromestatus.com/feature/5629709824032768

    localStorage.setItem(
      config.IG_AUTH_LS_KEY,
      JSON.stringify({
        redirectTo: props.location.pathname,
        currentAccount: props.currentAccount,
      }),
    );

    const query = {
      client_id: config.instagram.appId,
      redirect_uri: `${window.location.origin}${routes.oauthIGCallbackPath}`,
      response_type: 'code',
      scope: 'business_basic',
    };

    window.location.assign(`https://www.instagram.com/oauth/authorize?${toQuerystring(query)}`);
  },
});
