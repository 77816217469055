import ViewHeader from '@tap-bio/ui/patterns/view-header';
import { Layout as BaseLayout, Main as BaseMain } from 'components/cards/forms/shared-ui';
import { YoutubeIconWithWordmark } from 'components/icons';
import { Input as BaseInput, Text } from 'components/web-ui';
import { compose, omitProps, withHandlers } from 'lib/recompose';
import React from 'react';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const Layout = styled(BaseLayout)`
  padding: 0;
`;

const Main = styled(BaseMain)`
  padding: ${10 / 16}rem 1rem;
`;

const FormContainer = styled.div`
  padding: 10px 0 0 0;
`;

const Hint = styled(Text)`
  font-size: 13px;
  color: ${(p) => p.theme.colors.lightGrey};
  margin-top: 5px;
  line-height: 1.35;

  strong {
    color: black;
  }
`;

const Input = styled.input`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  ${fieldBorderCSS};
`.withComponent(omitProps('hasErrors')(BaseInput));

const Field = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  flex-direction: column;
  align-items: stretch;
`;

const PlaylistField = styled(Field)`
  margin-top: 30px;
`;

const YoutubeIconContainer = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Example = styled.span`
  background-color: ${(props) => props.theme.colors.lightYellow};
  border-radius: 6px;
  margin-top: 10px;
  padding: 8px 10px;
  display: block;
`;

function FormPanel(props) {
  const { formState, isPersisting, renderField, handleUnset } = props;

  return (
    <Layout>
      <ViewHeader>
        <ViewHeader.LeftButton onClick={handleUnset} />
        <ViewHeader.Title children="YouTube Card" />
        <ViewHeader.RightButton type="Submit" children={isPersisting ? 'Saving...' : 'Save'} />
      </ViewHeader>
      <Main>
        <YoutubeIconContainer>
          <YoutubeIconWithWordmark height="30px" />
        </YoutubeIconContainer>
        <FormContainer>
          {renderField({
            name: `entities.youtubes.${formState.result.youtube}.username`,
            hint: (
              <React.Fragment>
                <span>
                  A <strong>username</strong>, <strong>channel ID</strong> or{' '}
                  <strong>video ID</strong>. We'll display the latest videos from a channel, or the
                  single video.
                </span>
                <Example>
                  www.youtube.com/<strong>username</strong>
                  <br />
                  www.youtube.com/user/<strong>username</strong>
                  <br />
                  www.youtube.com/channel/<strong>channel_id</strong>
                  <br />
                  www.youtube.com/watch?v=<strong>video_id</strong>
                </Example>
              </React.Fragment>
            ),
            placeholder: 'username / channel ID / video ID',
            selectAllOnFocus: true,
            styledInput: Input,
            styledHint: Hint,
            styledField: Field,
          })}
          {renderField({
            hint: (
              <React.Fragment>
                <span>
                  Any YouTube public <strong>playlist ID</strong>. We'll display the videos from
                  this playlist. (optional)
                </span>
                <Example>
                  www.youtube.com/playlist?list=<strong>playlist_id</strong>
                </Example>
              </React.Fragment>
            ),
            name: `entities.youtubes.${formState.result.youtube}.alternatePlaylistId`,
            placeholder: 'Tap to add an optional playlist ID',
            selectAllOnFocus: true,
            styledInput: Input,
            styledHint: Hint,
            styledField: PlaylistField,
          })}
        </FormContainer>
      </Main>
    </Layout>
  );
}

export default compose(
  connect(null, (dispatch) => ({
    unsetPopover: (event) => dispatch(unsetPopover()),
  })),
  withHandlers({
    handleUnset: (props) => (event) => {
      event.preventDefault();
      props.unsetPopover();
    },
  }),
)(FormPanel);
