import React from 'react';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import { withRouter } from 'react-router';
import { compose, withState, withHandlers } from 'lib/recompose';
import { Link } from 'components/web-ui';
import { Message, RouterLink } from 'components/ui';
import routes from 'config/routes';
import * as Styled from './styled';
import IdentitiesList from './identities-list';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

function AccountAccessScene(props) {
  const {
    currentAccount: { username },
    currentUser: { features },
  } = props;
  return (
    <Styled.Layout>
      <ViewHeader>
        <ViewHeader.LeftButton children="Back" onClick={props.onClickDone} />
        <ViewHeader.Title children="Analytics Updates" />
        <ViewHeader.RightButton children="" />
      </ViewHeader>
      <Styled.Main>
        <Message
          level="notice"
          text={
            <React.Fragment>
              Please{' '}
              <RouterLink to={routes.billing.changeProduct(username)}>upgrade your plan</RouterLink>{' '}
              to receive periodic analytics reports
            </React.Fragment>
          }
          isVisible={!features.hasStats}
        />
        <p>
          We'll send you periodic updates with how many impressions, views, and clicks your account
          receives!
        </p>
        <IdentitiesList disabled={!features.hasStats} />
        <br />
        <div>
          <small>
            Want to send notifications to additional email addresses? Visit your
            <Link onClick={props.onClickAccountAccessLink}> account access page</Link> to add more.
          </small>
        </div>
      </Styled.Main>
    </Styled.Layout>
  );
}

export default compose(
  withRouter,
  withIdentityAccounts,
  withState('refresh', 'setRefresh'),

  withHandlers({
    onClickDone: (props) => props.history.goBack,
    onClickAccountAccessLink: (props) => () => {
      const { history, currentAccount } = props;
      return history.push(routes.accountAccess(currentAccount.username));
    },
  }),
)(AccountAccessScene);
