import { Text } from 'components/web-ui';
import React from 'react';
import { branch, compose, pure, renderComponent } from 'recompose';
import styled from 'styled-components/macro';

const ErrorContainer = styled.div`
  color: ${(p) => p.theme.colors.errorRed};
  margin-bottom: 10px;
`;

function NonFatalErrorMessage(props) {
  const { message } = props;
  return (
    <ErrorContainer>
      <Text center width="85%" mx="auto">
        {message}
      </Text>
    </ErrorContainer>
  );
}

NonFatalErrorMessage.defaultProps = { message: 'Something went wrong loading this page. :-(' };

export default compose(
  //
  branch(
    ({ message }) => !message,
    renderComponent((props) => null),
  ),
  pure,
)(NonFatalErrorMessage);
