import BlockingSpinner from 'components/ui/spinner-blocking';
import { ButtonCircle, Text } from 'components/web-ui';
import routes from 'config/routes';
import capitalizeFirstLetter from 'lib/capitalize-first-letter';
import React from 'react';
import * as Recompose from 'recompose';
import styled from 'styled-components/macro';
import { withPriceFormatting } from './hocs';
import withChangePlan from './hocs/with-change-plan';
import { augmentPlanWithDiscount, findDiscount } from './lib';
import SelectPlanConfirmModal from './select-plan-confirm-modal';

const Container = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.billing.divider};
  border-bottom: 1px solid ${({ theme }) => theme.colors.billing.divider};
  padding: 15px;
`;

const Selection = styled.div`
  display: flex;
  font-size: 17px;
  color: black;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fonts.weights.medium};
`;

const Plan = styled(Text)``;

const Renewal = styled(Text)`
  font-size: 15px;
`;

const Price = styled(Text)``;

const PaymentMethods = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
`;

const CheckoutButton = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  color: white;
  width: 100%;
  font-size: 1rem;
  height: 4rem;
`;

const Checkout = ({
  activeFeatures,
  currentUser,
  handleClickCheckoutButton,
  handleHideConfirmationModal,
  handlePlanChange,
  history,
  isPersisting,
  plan,
  plan: { interval, amount, discountedAmount },
  product,
  products,
  setShowConfirmChangePlanModal,
  showConfirmChangePlanModal,
  userShouldCheckOut,
  toTwoDecimalPlaces,
}) => (
  <Container>
    <BlockingSpinner
      active={isPersisting}
      children="Please wait a moment while we change your plan."
    />

    <Selection>
      <Plan children={`${product.name} - ${capitalizeFirstLetter(interval)}ly`} />
      <Price
        children={`$${toTwoDecimalPlaces(
          (discountedAmount >= 0 ? discountedAmount : amount) / 100,
        )}`}
      />
    </Selection>
    <Renewal children="automatically renewed - cancel anytime" />
    <PaymentMethods>
      <CheckoutButton
        children={userShouldCheckOut ? 'Pay with card' : 'Change plan'}
        onClick={handleClickCheckoutButton}
      />
    </PaymentMethods>

    {/* The confirm modal displays on clicking the 'Change to X Plan button' */}
    <SelectPlanConfirmModal
      activeFeatures={activeFeatures}
      currentUser={currentUser}
      history={history}
      isActive={showConfirmChangePlanModal}
      newPlan={plan}
      newProduct={product}
      onClickClose={handleHideConfirmationModal}
      onClickConfirm={handlePlanChange}
      products={products}
    />
  </Container>
);

export default Recompose.compose(
  withPriceFormatting,
  Recompose.branch(({ plan, isVisible }) => !plan || !isVisible, Recompose.renderNothing),
  Recompose.withProps(({ plan: { amount }, currentUser }) => ({
    // If they're checking out and the plan's discountedAmount is zero, we'll still require them
    // to add a credit card.
    userShouldCheckOut: amount !== '0' && !currentUser.stripeSource,
  })),
  Recompose.withState('errors', 'setErrors', null),
  Recompose.withState('isPersisting', 'setIsPersisting', false),
  Recompose.withState('showConfirmChangePlanModal', 'setShowConfirmChangePlanModal', false),
  withChangePlan,
  Recompose.withHandlers({
    // Set state to hide the modal
    handleHideConfirmationModal:
      ({ setShowConfirmChangePlanModal }) =>
      (event) => {
        setShowConfirmChangePlanModal(false);
      },

    //
    // Handler for the 'confirm plan change' modal's CONFIRM button
    //   1. Hides the modal
    //   2. Executes a mutation that changes the plan

    handlePlanChange: (props) => async (event) => {
      const {
        currentUser,
        handleChangePlan,
        onSuccessfulPlanChange,
        plan,
        setShowConfirmChangePlanModal,
      } = props;
      event.preventDefault();
      setShowConfirmChangePlanModal(false);
      const success = await handleChangePlan(plan.stripeId, currentUser.id);

      if (!success) return;

      onSuccessfulPlanChange(); // Display the success modal
    },

    // Handler for clicking the checkout button.
    //   - If the user has a valid credit card then display a plan change confirmation modal.
    //   - Otherwise head to a new screen to add a credit card

    handleClickCheckoutButton:
      ({
        currentAccount,
        handleChangePlan,
        history,
        onSuccessfulPlanChange,
        plan,
        setErrors,
        setShowConfirmChangePlanModal,
        userShouldCheckOut,
      }) =>
      async (event) => {
        event.preventDefault();
        if (userShouldCheckOut) {
          // Head on over to add a card
          history.push(routes.billing.addCreditCard(currentAccount.username, plan.stripeId));
        } else {
          // TODO: Detect downgrade & should info in modal
          setShowConfirmChangePlanModal(true);
        }
      },
  }),
  Recompose.withProps(({ currentUser: { discounts }, plan: { stripeId } }) =>
    findDiscount(discounts, stripeId),
  ),
  Recompose.withProps(({ discount, plan }) => augmentPlanWithDiscount(discount, plan)),
  Recompose.pure,
)(Checkout);
