import Clipboard from 'clipboard';
import config from 'config';
import routes from 'config/routes';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import * as Recompose from 'lib/recompose';
import * as Redux from 'react-redux';
import { withRouter } from 'react-router';
import { hideMoreMenu } from 'store/modules/more-menu';
import { setToast } from 'store/modules/toast';

export default Recompose.compose(
  Redux.connect(
    (state, ownProps) => ({
      isShowing: state.moreMenu.isShowing,
    }),
    (dispatch, ownProps) => ({
      hideMoreMenu: () => dispatch(hideMoreMenu()),
      setToast: (message) => dispatch(setToast(message)),
    }),
  ),
  withRouter,
  withIdentityAccounts,
  Recompose.lifecycle({
    componentDidMount() {
      const props = this.props;
      this.linkClipboard = new Clipboard('#copy-link');
      this.linkClipboard.on('success', function (e) {
        props.setToast('Profile URL is copied to the clipboard');
        e.clearSelection();
      });
    },
    componentWillUnmount() {
      this.linkClipboard.destroy();
    },
  }),
  Recompose.withProps(({ currentAccount: { username } }) => ({
    landingPageUrl: routes.landingPage(config.landingHost, username),
  })),
  Recompose.withHandlers({
    navigateTo: (props) => (route) => (event) => {
      event.preventDefault();
      props.history.push(route);
    },
  }),
);
