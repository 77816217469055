import { withApollo } from '@apollo/react-hoc';
import { UploadRequest } from '@navjobs/upload';
import SelectBackgroundPhoto from 'components/cards/preview/profile/select-background-photo';
import ConstantContactIcon from 'components/icons/constantcontact';
import InstagramIcon from 'components/icons/instagram';
import MailChimpIcon from 'components/icons/mailchimp';
import Spinner from 'components/ui/spinner';
import { Text } from 'components/web-ui';
import withAvailableAvatars from 'hocs/with-available-avatars';
import withUploadImage from 'hocs/with-upload-image';
import { resizeImage } from 'lib/image-utils';
import React from 'react';
import { compose, defaultProps, pure, withHandlers, withProps, withState } from 'recompose';
import styled from 'styled-components/macro';
import Field from './ui/field';
import BaseLabel from './ui/label';

const AvatarOptions = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  grid-template-columns: repeat(5, 1fr);

  @media (min-width: 321px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const AvatarItem = styled.div`
  position: relative;
  display: flex;
  width: 52px;
  height: 52px;

  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.selected ? props.theme.colors.primaryPurple : props.theme.colors.white};

  background-color: ${(props) =>
    props.purple ? props.theme.colors.primaryPurple : props.theme.colors.avatarGrey};
  background-image: ${(props) => props.image && `url(${props.image})`};
  background-size: cover;
  background-position: center;

  opacity: ${(props) => (props.disabled ? 0.7 : 1.0)};

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};

  &:after {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    content: '';
    border-radius: 50%;
    border-width: 2px;
    border-style: ${(props) => (props.withBorder ? 'solid' : 'none')};
    border-color: ${(props) =>
      props.selected ? props.theme.colors.white : props.theme.colors.avatarGrey};
  }
`;

const Center = styled.div`
  flex: 1;
  display: flex;

  justify-content: center;
  align-items: center;

  font-size: 22px;
  font-weight: bold;
  color: white;

  text-transform: uppercase;
`;

const AvatarType = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.primaryPurple : props.theme.colors.avatarGrey};

  z-index: 1;

  pointer-events: none;

  & > svg {
    width: 12px;
    fill: ${(props) => props.theme.colors.white};
  }
`;

function TextAvatar(props) {
  const { name, selected, ...rest } = props;
  return (
    <AvatarItem selected={selected} data-image="" withBorder {...rest}>
      <Center children={name} />
    </AvatarItem>
  );
}

function ImageAvatar(props) {
  const { image, provider, selected, ...rest } = props;
  return (
    <AvatarItem
      selected={selected}
      image={image}
      data-image={image}
      withBorder={selected}
      {...rest}
    >
      {provider && (
        <AvatarType selected={selected}>
          {provider === 'instagram' && <InstagramIcon />}
          {provider === 'mailchimp' && <MailChimpIcon />}
          {provider === 'constantcontact' && <ConstantContactIcon />}
        </AvatarType>
      )}
    </AvatarItem>
  );
}

function UploadAvatar(props) {
  const { image, uploading, onImageSelected, ...rest } = props;
  return (
    <AvatarItem purple {...rest}>
      <Center>
        {uploading ? (
          <Spinner fill="white" />
        ) : (
          <svg width="30" height="30" viewBox="0 0 100 100">
            <svg fill="#FFF" transform="matrix(.69 0 0 .69 15.5 15.5)" viewBox="0 0 48 48">
              <path d="M36.1 18A11.55 11.55 0 0 0 15 13.8a6.77 6.77 0 0 0-7.08 7.85A9.47 9.47 0 0 0 5 28.86a8.58 8.58 0 0 0 .1.87A8.88 8.88 0 0 0 14 37h3a1.37 1.37 0 0 0 0-2.73h-3a6.13 6.13 0 0 1-6.19-4.97c0-.2 0-.4-.07-.6a7 7 0 0 1 2.52-5.5 1.33 1.33 0 0 0 .47-1.43 4.18 4.18 0 0 1 1.13-4.19 4.38 4.38 0 0 1 3.61-1 1.35 1.35 0 0 0 1.44-.67 9.06 9.06 0 0 1 9-4.62 9 9 0 0 1 7.66 8 1.37 1.37 0 0 0 1.06 1.17 7 7 0 0 1 1.71 13.2 5.75 5.75 0 0 1-2.65.58h-2.97a1.37 1.37 0 0 0 0 2.73h2.94a8.49 8.49 0 0 0 3.88-.88A9.73 9.73 0 0 0 36.1 18z" />
              <path d="M30.5 25.48L25 20a1.42 1.42 0 0 0-.43-.29 1.4 1.4 0 0 0-.53-.11 1.31 1.31 0 0 0-.52.11A1.35 1.35 0 0 0 23 20l-5.5 5.48a1.36 1.36 0 0 0 1.93 1.93l3.21-3.21v12.54a1.36 1.36 0 1 0 2.72 0V24.21l3.21 3.2a1.34 1.34 0 0 0 1 .4 1.37 1.37 0 0 0 1-2.33z" />
            </svg>
          </svg>
        )}
      </Center>
      <SelectBackgroundPhoto onImageSelected={onImageSelected} />
    </AvatarItem>
  );
}

function CustomizeAvatarField(props) {
  const {
    hint,
    label,
    value,
    hidden,
    styledLabel: Label,
    textAvatarName,
    allowTextAvatar,
    loadingPictures,
    profilePictures,
    onAvatarSelected,
    onAvatarImageSelected,
    isUploading,
    uploadedPicture,
  } = props;

  return (
    <Field hidden={hidden}>
      <Label children={label} />
      <AvatarOptions>
        {/* upload avatar button */}
        <UploadAvatar uploading={isUploading} onImageSelected={onAvatarImageSelected} />

        {/* allow selection of default single letter text avatar */}
        {allowTextAvatar && (
          <TextAvatar name={textAvatarName} selected={!value} onClick={onAvatarSelected} />
        )}

        {/* display original uploaded profile picture */}
        {uploadedPicture && (
          <ImageAvatar
            image={uploadedPicture}
            selected={value === uploadedPicture}
            onClick={onAvatarSelected}
          />
        )}

        {/* loading progress bar for available avatars from identities */}
        {loadingPictures && <Spinner fill="rgba(0, 0, 0, 0.5)" />}

        {/* listing of avatars */}
        {profilePictures.map(({ pictureUrl, identity }, i) => (
          <ImageAvatar
            key={i}
            provider={identity && identity.provider}
            image={pictureUrl}
            selected={value === pictureUrl}
            onClick={onAvatarSelected}
          />
        ))}
      </AvatarOptions>
      {hint && <Text children={hint} />}
    </Field>
  );
}

export default compose(
  defaultProps({
    hidden: false,
    styledLabel: BaseLabel,
    label: 'Avatar',
    value: '',
    allowTextAvatar: true,
    onChange: (name, value) => null,
    onUploadStarted: () => null,
    onUploadCompleted: () => null,
  }),
  withProps(({ username }) => ({
    textAvatarName: username[0],
  })),
  withState('isUploading', 'setUploading', false),
  withState('uploadedPicture', 'setUploadedPicture', (props) => {
    if (props.value && props.value.indexOf('imgix.net') > -1) {
      return props.value;
    }
    return null;
  }),
  withApollo,
  withAvailableAvatars,
  // file uploaded callback
  withHandlers({
    onCompletedUpload:
      ({ setUploading, setUploadedPicture, onChange, onUploadCompleted }) =>
      (name, urls) => {
        const url = urls[0];
        setUploading(false);
        setUploadedPicture(url);
        onChange('pictureUrl', url);
        onUploadCompleted();
      },
  }),
  withUploadImage('cards/avatars'),
  // handlers for image selection
  withHandlers({
    onAvatarSelected:
      ({ onChange }) =>
      (evt) => {
        onChange('pictureUrl', evt.target.dataset.image);
      },
    onAvatarImageSelected:
      ({ setUploading, handleBeforeRequest, handleRequest, handleAfterRequest, onUploadStarted }) =>
      async (_, input) => {
        setUploading(true);
        onUploadStarted();

        const file = await resizeImage(input);
        const files = [file];
        const before = await handleBeforeRequest({ files });
        const { error, aborted, status } = await UploadRequest({
          files,
          request: handleRequest({ before, files }),
          progress: () => null,
        });
        if (error || aborted) return;
        await handleAfterRequest({ before, files, status });
      },
  }),
  pure,
)(CustomizeAvatarField);
