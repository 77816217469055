import React from 'react';
import { Crop, Frame } from './styles';
import MediaType from 'legacy/media-type';

function Medium(props) {
  const { photo, view } = props;

  return (
    <Frame view={view}>
      <a
        href={photo.href}
        data-photo-id={photo.id}
        data-photo-permalink={photo.permalink}
        data-photo-media-url={photo.src}
      >
        <Crop style={{ backgroundImage: `url(${photo.src})` }} data-src={photo.src}>
          <MediaType type={photo.mediaType} />
        </Crop>
      </a>
    </Frame>
  );
}

export default Medium;
