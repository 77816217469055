import AssistedUrlInput from '@tap-bio/ui/patterns/assisted-url-input';
import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const StyledInput = styled(AssistedUrlInput)`
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  margin-bottom: 7px;
  ${fieldBorderCSS};

  .helpers {
    border-color: ${(props) => props.theme.colors.profileForm.inputBorder};
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: -10px;
  }
`;

function AssistedUrlInputAdapter(props) {
  const { value: defaultValue, placeholder, onChange } = props;

  return <StyledInput defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} />;
}

export default React.memo(
  AssistedUrlInputAdapter,
  function renderOnlyOnReset(prevProps, nextProps) {
    const SKIP_RENDER = true;
    const RENDER = false;

    const valueChanged = prevProps.value !== nextProps.value;
    const toOrFromBlank = !nextProps.value || !prevProps.value;

    if (valueChanged && toOrFromBlank) return RENDER;

    return SKIP_RENDER;
  },
);
