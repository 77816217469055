import React from 'react';
import styled from 'styled-components/macro';
import CallToActionButton from 'components/cards/forms/call-to-action-button';

const CallToActionItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  background-color: ${(p) => p.brandColor};
  border-radius: 2em;

  & > * {
    margin-bottom: 0;
  }
`;

const PositionControls = styled.div`
  color: white;
  font-size: 13px;
  margin: 0 10px;
  position: absolute;
  right: 20px;

  & > a {
    color: rgba(0, 0, 0, 0.4);
    background-color: transparent;
    border-radius: 50% 50% 4px 4px;
    margin-bottom: 2px;
  }

  & > :last-child {
    transform: rotateZ(180deg);
  }
`;

const Button = styled.a`
  display: block;
  line-height: 1.2;

  background-color: rgba(0, 0, 0, 0.4);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  & > svg {
    fill: white;
    transform: rotateZ(45deg);
  }
`;

const Ctas = styled.div``;

const SortableCallToActionButtons = ({
  ctas,
  onClick,
  brandColor,
  isPreview,
  onSortUpClick,
  onSortDownClick,
  onDeleteClick,
  customSort,
}) => (
  <Ctas>
    {ctas
      .filter((cta) => cta.id)
      .map((cta, index) => {
        return (
          <CallToActionItem key={cta.id} brandColor={brandColor}>
            <CallToActionButton
              brandColor="transparent"
              cta={cta}
              onClick={onClick && onClick.bind(this, cta.id)}
              isPreview={isPreview}
              handleDeleteCta={onDeleteClick}
            />
            <PositionControls>
              <Button onClick={onSortUpClick.bind(null, cta.id)}>▲</Button>
              <Button onClick={onSortDownClick.bind(null, cta.id)}>▲</Button>
            </PositionControls>
          </CallToActionItem>
        );
      })}
  </Ctas>
);

CallToActionButton.defaultProps = {
  isPreview: false,
};

export default SortableCallToActionButtons;
