import Clipboard from 'clipboard';
import { ButtonRightArrowIcon as ArrowIconBase } from 'components/icons';
import TextInput from 'components/ui/base/text-input';
import { Image as BaseImage, Text as BaseText, ButtonCircle, Heading } from 'components/web-ui';
import config from 'config';
import routes from 'config/routes';
import addLink1 from 'images/onboarding-add-link-1.svg';
import addLink2 from 'images/onboarding-add-link-2.svg';
import React from 'react';
import { compose, lifecycle, pure, withHandlers, withProps, withState } from 'recompose';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding-top: 0.75rem;
`;

const PrimaryHeading = styled(Heading)`
  font-size: 20px;
  text-align: center;
  margin-top: 13px;
`;

const SecondaryHeading = styled(Heading)`
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 25px;
`;

const SlidingBoxLayout = styled.div`
  display: flex;
  width: calc(100% + 28px);
  margin-left: -14px;
  overflow: hidden;
`;

const SlidingBox = styled.div`
  min-width: 100%;
  position: relative;
`;

const SlidingBoxes = styled.div`
  display: flex;

  @keyframes slide-in {
    100% {
      transform: translateX(-100%);
    }
  }
  width: 200%;
  ${(props) => props.activePanel === 1 && 'animation: slide-in 0.2s forwards'};
`.withComponent(({ activePanel, ...rest }) => <div {...rest} />);

const Image = styled(BaseImage)`
  width: 50%;
  max-width: 75%;
  margin: 0 auto;
  min-height: 30%;
`;

const Text = styled(BaseText)`
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
`;

const TextGrey = styled(Text)`
  color: #888888;
`;

const CopyButton = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 4rem;
  font-size: 1rem;
  width: 100%;
`;

const ArrowIcon = styled(ArrowIconBase)`
  @keyframes bounce {
    0%,
    20%,
    60%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateX(-10px);
    }

    80% {
      transform: translateX(-5px);
    }
  }

  animation: bounce 1s infinite;
  position: absolute;
  top: 151px;
  left: 60px;

  path {
    fill: black;
  }
`;

const AddLinkToInstagramProfileModal = ({
  activePanel,
  handleClickCopyButton,
  landingPageUrl,
  showTooltip,
  username,
}) => (
  <Layout>
    <PrimaryHeading children="Add Your Tap Bio Address" />

    <SlidingBoxLayout>
      <SlidingBoxes activePanel={activePanel}>
        <SlidingBox>
          <SecondaryHeading children="Paste in the website field on your Instagram profile" />
          <Image src={addLink1} alt="Instagram Profile Screen" />
        </SlidingBox>
        <SlidingBox>
          <SecondaryHeading children="To manage your Tap Bio, just tap your profile link in Instagram" />
          <Image src={addLink2} alt="Focus on where to paste the link" />
          <ArrowIcon />
        </SlidingBox>
      </SlidingBoxes>
    </SlidingBoxLayout>

    <TextGrey>
      Return to your instagram profile. Tap “Edit Profile” and paste this URL into the website
      field.
    </TextGrey>

    <div>
      <TextInput readOnly value={landingPageUrl} />
      <CopyButton
        data-clipboard-text={landingPageUrl}
        id="copy-link"
        onClick={handleClickCopyButton}
      >
        {activePanel === 0 ? 'Tap to Copy' : 'Copied!'}
      </CopyButton>
    </div>
  </Layout>
);

const withLifecycle = lifecycle({
  componentDidMount() {
    const props = this.props;
    const that = this;
    this.linkClipboard = new Clipboard('#copy-link');
    this.linkClipboard.on('success', function (e) {
      props.setShowTooltip(true);
      that.interval = setTimeout(() => props.setShowTooltip(false), 2500);
      e.clearSelection();
    });
  },
  componentWillUnmount() {
    clearInterval(this.interval);
    this.linkClipboard.destroy();
  },
});

export default compose(
  withState('showTooltip', 'setShowTooltip', false),
  withState('activePanel', 'setActivePanel', 0),
  withProps(({ username }) => ({
    landingPageUrl: routes.landingPage(config.landingHost, username),
  })),

  withHandlers({
    handleClickCopyButton:
      ({ setActivePanel }) =>
      (event) => {
        event.preventDefault();
        setActivePanel(1);
      },
  }),

  withLifecycle,
  pure,
)(AddLinkToInstagramProfileModal);
