import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
`;

export const Description = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
`;
