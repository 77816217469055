import querystring from 'querystring';
import { withProps } from 'recompose';

export default withProps(({ backgroundMediaUrl, uploadedPhotoUrl, defaultBackgroundMediaUrl }) => {
  if (!backgroundMediaUrl && !uploadedPhotoUrl && !defaultBackgroundMediaUrl) {
    return { hasBlendMode: false };
  }

  const photoUrl = uploadedPhotoUrl || backgroundMediaUrl || defaultBackgroundMediaUrl;
  const params = photoUrl.split('?')[1];
  const qs = querystring.parse(params);
  const hasBlendMode = qs.blend && qs.bm !== 'normal' ? true : false;
  return { hasBlendMode };
});
