import { compose, branch, withProps, renderComponent } from 'lib/recompose';
import Authorize from './authorize';
import Manage from './manage';

const name = 'constantcontact';

const withConstantContactIdentities = withProps(({ currentAccount }) => {
  const identities = currentAccount.identities.filter((id) => id.provider === name);
  return { identities };
});

const shouldRenderSetup = ({ identities }) => identities.length > 0;

export function isConstantContactProvider(props) {
  return props.provider === name;
}

export default compose(
  withConstantContactIdentities,
  branch(shouldRenderSetup, renderComponent(Manage)),
)(Authorize);
