import React from 'react';
import BrandingTag from './branding-tag';
import Router from 'routes/router';
import SwiperMouseNav from './swiper-mouse-nav';

function Aside(props) {
  return (
    <Router>
      <BrandingTag />
      <SwiperMouseNav />
    </Router>
  );
}

export default Aside;
