import { Heading, Text, Link } from 'components/web-ui';
import verticalSmoothScrollingCSS from 'components/cards/shared/styles/vertical-smooth-scrolling-css';
import styled from 'styled-components/macro';

const Content = styled.div`
  color: white;
  padding: 70px 8% 120px 8%;

  ${(p) => (p.scrollable ? verticalSmoothScrollingCSS : 'overflow: hidden;')};
  opacity: ${(p) => (p.scrollable ? 1 : 0.5)};

  /*
    Make some blank space above the content
  */
  &::before {
    content: '';
    display: block;
    padding-bottom: 80%;
  }
`;

const Name = styled(Heading)`
  flex-shrink: 0;
  font-weight: bold;
  font-size: 30px;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.64);
  text-align: center;
  margin: 0;
`;

const Title = styled(Text)`
  flex-shrink: 0;
  margin: 8px 0;
  font-size: 16px;
  text-align: center;
`;

const Bio = styled(Text)`
  font-size: 19.5px;
  line-height: 1.4;
  font-weight: ${(props) => props.theme.fonts.weights.normal};
  margin-top: 22px;
  white-space: pre-line;
`;

const UploadContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  padding-top: 50px;
  z-index: 3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(p) => p.hidden && 'display: none;'};
`;

const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  flex-wrap: wrap;

  & > * {
    margin: 0 8px 10px 8px;
  }
`;

const WebsiteLink = styled(Link)`
  color: #fff;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  margin-bottom: 22px;

  :active {
    opacity: 0.75;
  }
`;

const SkipButton = styled.button`
  width: 140px;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  z-index: 10;
  font-size: 1em;
  background-color: ${(props) => props.theme.colors.alphaBlack40};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.white};
  -webkit-tap-highlight-color: ${(props) => props.theme.colors.alphaBlack50};
  border: none;
  outline: none;
  ${(props) => props.hidden && 'display: none;'};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.alphaBlack60};
  }
`;

const OnboardingLink = styled.div`
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${(props) => props.theme.colors.alphaWhite70};
  cursor: text;

  // Name
  h2 {
    padding: 5px 0 8px 0;
  }

  // Description
  p {
    padding: 5px 0 6px 0;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

export {
  Content,
  Name,
  Title,
  Bio,
  UploadContainer,
  SocialIconsContainer,
  WebsiteLink,
  SkipButton,
  OnboardingLink,
};
