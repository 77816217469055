import React from 'react';
import styled from 'styled-components/macro';
import { ButtonCircle } from 'components/web-ui';

const Button = styled(ButtonCircle)`
  font-size: 17px;
  width: 100%;
`;

const FormButton = ({ hidden, ...props }) => !hidden && <Button {...props} />;

FormButton.defaultProps = {
  bg: 'veryDarkPurple',
  color: 'white',
  py: 4,
  my: 4,
};

export default FormButton;
