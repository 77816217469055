import React from 'react';
import pick from 'lodash/pick';
import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

// This is how the blocker divs are laid out. The area in the
// center is the click zone.
//
//    ---------------
//         Top
//    ---------------
//    |   |     |   |
//    | L |     | R |
//    |   |     |   |
//    ---------------
//        Bottom
//    ---------------

const BlockerBaseCSS = css`
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${(props) => props.zIndex};
  position: absolute;
`;

const BlockerTop = styled.div`
  ${BlockerBaseCSS}
  top: 0;
  left: 0;
  right: 0;
  height: ${(props) => `calc(${props.blockerPosition.top} - ${props.blockerPosition.topOffset});`};
`;

const BlockerRight = styled.div`
  ${BlockerBaseCSS}
  top: ${(props) => `calc(${props.blockerPosition.top} - ${props.blockerPosition.topOffset});`};
  left: ${(props) => `calc(${props.blockerPosition.left} + ${props.blockerPosition.width});`};
  height: ${(props) => `calc(${props.blockerPosition.bottom} - ${props.blockerPosition.top});`};
  right: 0;
`;

const BlockerBottom = styled.div`
  ${BlockerBaseCSS}
  bottom: 0;
  left: 0;
  right: 0;
  top: ${(props) => `calc(${props.blockerPosition.bottom} - ${props.blockerPosition.topOffset});`};
`;

const BlockerLeft = styled.div`
  ${BlockerBaseCSS}
  top: ${(props) => `calc(${props.blockerPosition.top} - ${props.blockerPosition.topOffset});`};
  left: 0;
  height: ${(props) => `calc(${props.blockerPosition.bottom} - ${props.blockerPosition.top});`};
  width: ${(props) => `calc(${props.blockerPosition.left})`};
`;

class Blocker extends React.Component {
  constructor(props) {
    super(props);
    this.blockerTopRef = React.createRef();
    this.state = {};
  }

  // React guarantees that refs are set before componentDidMount or componentDidUpdate hooks. But
  // only for children that actually got rendered.
  // In this component the ref is _not_ set on initial render so we have to set it into state
  // in componentDidMount and use it in the re-render. Note that we *must* render the component
  // for the ref to be set.
  // https://stackoverflow.com/a/50019873/453405
  componentDidMount() {
    this.setState({ blockerRect: this.blockerTopRef.current.getBoundingClientRect() });
  }

  render() {
    const blockerProps = pick(this.props, ['zIndex', 'blockerPosition']);

    // Determine the position (offset) of the top blocker. This is our
    // positional reference for displaying the blocker window.
    if (this.state.blockerRect) {
      blockerProps['blockerPosition']['topOffset'] = `${this.state.blockerRect.top}px`;
    }

    return (
      <Container ref={this.blockerTopRef} onClick={this.props.onClick}>
        <BlockerTop {...blockerProps} />
        <BlockerRight {...blockerProps} />
        <BlockerBottom {...blockerProps} />
        <BlockerLeft {...blockerProps} />
      </Container>
    );
  }
}

export default Blocker;
