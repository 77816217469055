import { withProps } from 'recompose';

/**
 * @summary A HOC that determines whether to show a background overlay or not.
 *          The overlay is applied to all cards that have a background photo.
 *
 * @returns {Function} - a boolean indicating whether to display the overlay
 */
export default withProps(({ backgroundMediaUrl, formState, card }) => {
  if (formState) return { hasBackgroundOverlay: formState.result.hasBackgroundGradient };

  return { hasBackgroundOverlay: card.hasBackgroundGradient };
});
