import { graphql } from '@apollo/react-hoc';
import routes from 'config/routes';
import {
  withDetectProvider,
  withValidateEmail,
  withValidateTel,
} from 'hocs/with-validation-handlers';
import Authentication from 'lib/authentication';
import { usernameSearchQuery } from 'lib/graphql/queries';
import debounce from 'lodash/debounce';
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import {
  branch,
  compose,
  pure,
  renderComponent,
  withHandlers,
  withProps,
  withState,
} from 'recompose';

export default compose(
  withRouter,
  withState('formData', 'setFormData', {}),
  withState('queryParam', 'setQueryParam', ''),
  withState('responseData', 'setResponseData', {}),
  withState('throttledSetQueryParam', '', (props) =>
    debounce((value) => props.setQueryParam(value), 500),
  ),
  withState('formIsPersisting', 'setformIsPersisting', false),

  branch(
    (props) => {
      const params = new URLSearchParams(props.location.search);
      if (params.has('token')) {
        Authentication.authenticate(params.get('token'));
        return true;
      }
      return false;
    },
    renderComponent(() => <Redirect to={routes.accounts} />),
  ),

  branch(
    (props) => Authentication.isAuthenticated(),
    renderComponent(() => <Redirect to={routes.accounts} />),
  ),

  branch(
    (props) => props.responseData.identity,
    renderComponent(({ responseData, formData }) => (
      <Redirect
        push
        to={{
          pathname: `${routes.loginPath}/verify/${responseData.identity.uid}`,
          search: window.location.search,
          state: {
            ...responseData.identity,
            action: 'login',
          },
        }}
      />
    )),
  ),

  withDetectProvider,
  withValidateEmail,
  withValidateTel,

  graphql(usernameSearchQuery, {
    options: (props) => ({
      variables: { username: props.queryParam || '' },
    }),
    props: ({ data }) => ({
      data: data,
      accountNameTaken: !!data.publicAccount,
    }),
  }),

  withProps((props) => {
    const username = props.formData.username;
    const provider = props.detectProvider(username);
    const errors = {
      username:
        provider === 'email' ? !props.validateEmail(username) : !props.validateTel(username),
    };

    const any = !!errors.username || !username;
    return { formErrors: { ...errors, any } };
  }),

  withProps(({ formData, formErrors, ...props }) => {
    const { username: usernameString, provider } = formData;
    const username = provider === 'sms' ? usernameString.replace(/\D/g, '') : usernameString;

    return {
      formHints: {
        username:
          username && !formErrors.username ? (
            <span>
              We'll send a code to <b>{username}</b> that you'll use to sign in on the next screen.
            </span>
          ) : (
            `We'll send a code that you'll use to sign in on the next screen.`
          ),
      },
    };
  }),

  withHandlers({
    onFieldChange:
      ({ setFormData, formData, detectProvider }) =>
      (name, value) => {
        const provider = detectProvider(formData.username);
        setFormData({ ...formData, [name]: value, provider });
      },
    onSubmit: (props) => (event, mutation) => {
      event.preventDefault();
      props.setformIsPersisting(true);
      mutation({
        variables: {
          username: props.formData.username,
          provider: props.detectProvider(props.formData.username),
          action: 'login',
        },
      });
    },
    onRedirectToRegister: (props) => () => {
      props.history.push({
        pathname: routes.registrationPath,
        search: window.location.search,
      });
    },
  }),

  // If the 'Enter' key is pressed when there's a valid username, submit the form.
  withHandlers({
    handleOnKeyUp: (props) => (event, mutation) => {
      if (props.formErrors.any) return;
      if (event.key === 'Enter') props.onSubmit(event, mutation);
    },
  }),

  pure,
);
