import React from 'react';
import { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import Output from './output';
import Error from './error';

const InputLabel = styled.label`
  display: block;
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  opacity: 0;
  margin: 0;
  border: none;
  padding: 0;
  height: 1px;
  width: 100%;
  overflow: hidden;
  outline: none;

  &::focus,
  &::active {
    outline: none;
  }
`;

function CodeInput(props) {
  const { light, onFieldChange, formData, ...rest } = props;
  const { code = '' } = formData;
  const inputEl = useRef(null);
  const [hasFocus, setHasFocus] = useState(true);

  return (
    <InputLabel>
      <Output code={code} onClick={() => inputEl.current.focus()} hasFocus={hasFocus} />
      <Error {...rest} light={light} />
      <Input
        type="tel"
        ref={inputEl}
        onChange={(event) => {
          const { value } = event.target;
          if (value.length > 6) return;
          if (isNaN(parseFloat(value)) && !isFinite(value)) return;
          onFieldChange('code', value);
        }}
        autoFocus="on"
        autoComplete="one-time-code"
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        value={code}
      />
    </InputLabel>
  );
}

export default CodeInput;
