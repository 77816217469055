import * as Recompose from 'lib/recompose';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import verticalSmoothScrollingCSS from 'components/cards/shared/styles/vertical-smooth-scrolling-css';

const Layout = styled.div`
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
`.withComponent(Recompose.omitProps('isShowingMoreMenu')('div'));

const Header = styled.div`
  flex-shrink: 0;
  z-index: 1;
`;

const Content = styled.div`
  ${verticalSmoothScrollingCSS};
  overflow-y: auto;
`;

const MenuLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const NavItem = styled.div`
  display: flex;
  font-size: 15px;
  line-height: 46px;
  padding-left: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.navigationDividerGrey};

  :last-child {
    border: none;
  }

  a {
    color: ${(props) => props.theme.colors.black};
  }
`;

const RouterLink = styled(Link)`
  width: 100%;
`;

const AnchorLink = styled.a`
  width: 100%;
`;

AnchorLink.defaultProps = {
  target: '_blank',
};

export { Layout, MenuLayout, NavItem, RouterLink, AnchorLink, Header, Content };
