// A module…
//
// 1. MUST export default a function called reducer()
// 2. MUST export its action creators as functions
// 3. MUST have action types in the form npm-module-or-app/reducer/ACTION_TYPE
// 4. MAY export its action types as UPPER_SNAKE_CASE, if an external reducer needs to listen
//    for them, or if it is a published reusable library

const UPDATE = 'tb.deck-viewstate.update';

const initialState = { swiperReady: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setDeckViewstate(payload) {
  return { type: UPDATE, payload };
}
