import { withApollo } from '@apollo/react-hoc';
import withCallToActionStateHandlers, {
  handleDeleteCta,
  handleShowCtaForm,
} from 'hocs/with-call-to-action-state-handlers';
import withDestroyCallToActionMutation from 'hocs/with-destroy-call-to-action-mutation';
import withImagesStateHandlers from 'hocs/with-images-state-handlers';
import withUploadImage from 'hocs/with-upload-image';
import { compose, withHandlers, withProps, withState } from 'lib/recompose';
import { connect } from 'react-redux';
import { setSelectedCallToAction } from 'store/modules/card-call-to-action';
import { setSelectedPopover } from 'store/modules/card-popovers';

export default compose(
  withState('loadingImageUrls', 'setLoadingImageUrls', []),
  withImagesStateHandlers,
  withProps(({ data: { defaultDeck }, addImageToStateById }) => ({
    onCompletedUpload: async (name, imageUrls) => addImageToStateById(imageUrls, defaultDeck),
  })),
  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setSelectedCallToAction: (id) => dispatch(setSelectedCallToAction(id)),
  })),
  withCallToActionStateHandlers,
  withDestroyCallToActionMutation,
  withProps(({ card }) => ({ hasCtas: card.ctas.length, buttonColor: card.buttonColor })),
  withHandlers({ handleShowCtaForm, handleDeleteCta }),
  withApollo,
  withUploadImage('cards/backgrounds'),
);
