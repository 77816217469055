import React from 'react';
import styled from 'styled-components/macro';
import NumberFormatter from 'components/cards/stats/elements/number-formatter';
import BarChart from 'components/cards/stats/elements/bar-chart/index';
import TwoLevelMetadata from 'components/cards/stats/elements/two-level-metadata';
import enhance from 'components/cards/stats/cards/shared/enhancer';
import DeletedTrendItem from 'components/cards/stats/elements/deleted-trend-item';
import {
  TrendsItemWrapper,
  TrendsItemContainer,
  TrendsItemHeader,
  TrendsBarChartContainer,
  TrendsSummary,
} from 'components/cards/stats/elements/shared';

const ImageThumbnail = styled.a`
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: block;
  background-image: url(${(props) => props.src});
  background-color: #e6e3ea;
  background-size: cover;
  border-radius: 5px;
  margin-right: 10px;
`;

const Photo = ({ photo, summary, graph, unit, total }) => (
  <TrendsItemWrapper>
    {photo ? (
      <TrendsItemContainer>
        <TrendsItemHeader>
          <ImageThumbnail src={photo.imageUrl} href={photo.url} target="_blank" />
          <TwoLevelMetadata title={photo.title} description={photo.url} href={photo.url} />
        </TrendsItemHeader>
      </TrendsItemContainer>
    ) : (
      <DeletedTrendItem />
    )}
    {!summary && (
      <TrendsBarChartContainer>
        <BarChart data={graph} unit={unit} />
      </TrendsBarChartContainer>
    )}
    <TrendsSummary>
      <NumberFormatter value={total} /> Clicks
    </TrendsSummary>
  </TrendsItemWrapper>
);

export default enhance(Photo);
