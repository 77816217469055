import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.primaryPurple};
  color: ${(p) => p.theme.colors.white};
  padding-bottom: 65px;
`;

const Content = styled.main`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
`;

const Message = styled.p`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: ${(p) => p.theme.colors.white};
`;

export { Layout, Content, Message };
