import React from 'react';
import { compose, defaultProps } from 'recompose';
import { BarChart, Bar, Rectangle, XAxis } from 'recharts';
import styled from 'styled-components/macro';
import sizeMe from 'react-sizeme';
import BarChartTick from 'components/cards/stats/elements/bar-chart/tick';

const Layout = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  height: 150px;
  min-height: 150px;
`;

function CustomBar(props) {
  const width = props.width > 10 ? 10 : props.width;
  const height = props.height > 0 ? props.height : 1.0;
  const offset = (props.width - width) / 2;
  const fill = props.height === 0.0 ? '#c2bec8' : props.fill;
  const newProps = { ...props, width, height, fill, x: props.x + offset };
  return <Rectangle {...newProps} radius={[20, 20, 0, 0]} />;
}

function CustomBarLabel(props) {
  const { x, y, value } = props;
  if (!value) return null;
  const width = props.width > 10 ? 10 : props.width;
  const offset = (props.width - width) / 2;
  return (
    <text dy={-4} fill="#8f7f9e" fontSize={10} textAnchor="middle" x={x + 5 + offset} y={y}>
      {value}
    </text>
  );
}

function Chart(props) {
  const { data, unit, size } = props;
  return (
    <Layout>
      <BarChart
        width={size.width}
        height={size.height}
        data={data}
        barGap={6}
        barCategoryGap={'20%'}
        baseLine={8}
        margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          axisLine={{ stroke: '#e6e3ea' }}
          dataKey="datetime"
          interval={0}
          minTickGap={1}
          tick={<BarChartTick unit={unit} />}
          tickLine={{ stroke: '#e6e3ea' }}
          tickSize={7}
        />
        <Bar
          label={CustomBarLabel}
          shape={CustomBar}
          dataKey="total"
          type="basis"
          stroke="none"
          fill="url(#analytics-uv)"
          fillOpacity={1}
          isAnimationActive={false}
        />
      </BarChart>
    </Layout>
  );
}

export default compose(
  defaultProps({
    data: [],
    unit: 'DAILY',
  }),
)(
  sizeMe({
    monitorWidth: true,
    monitorHeight: true,
  })(Chart),
);
