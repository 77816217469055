import React from 'react';
import { compose, omitProps } from 'lib/recompose';
import { css } from 'styled-components';
import { Text, Input as BaseInput } from 'components/web-ui';
import FormBasePopover from 'components/cards/forms/popovers/base';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import { unsetSelectedCallToAction } from 'store/modules/card-call-to-action';
import { Button as BaseButton } from 'components/web-ui';
import styled from 'styled-components/macro';

// --- Styled Components ---

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const Input = styled(BaseInput)`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  margin-bottom: 10px;
  ${fieldBorderCSS};
`.withComponent(omitProps('hasErrors')(BaseInput));

const Hint = styled(Text)`
  color: ${({ theme }) => theme.colors.genericForm.hint};
  font-size: 14px;
  margin-top: -3px;
  margin-bottom: 15px;
`;

const SubmitButton = styled(BaseButton)`
  font-size: 18px;
  border-radius: 2em;
  height: 50px;
  margin-top: 15px;
  background-color: ${(p) => p.theme.colors.primaryPurple};
  color: white;

  :disabled {
    opacity: 0.25;
  }
`;

const FormPanel = (props) => {
  const {
    card,
    data: { defaultDeck },
    formState,
    handleCardFormSubmit,
    isPersisting,
    renderField,
    selectedCallToAction,
    unsetPopover,
    setFormState,
  } = props;

  // Disable the submit button if we don't have data for both fields
  const ctx = get(formState, `entities.ctas.${selectedCallToAction}`);
  const isFormValid = !isEmpty(ctx.message) && !isEmpty(ctx.title);

  return (
    <FormBasePopover
      card={card}
      cta={get(formState, `entities.ctas.${selectedCallToAction}`)}
      formState={formState}
      setFormState={setFormState}
      title={`${card.ctas.length ? 'Edit' : 'Add'} button`}
    >
      {renderField({
        name: `entities.ctas.${selectedCallToAction}.title`,
        placeholder: 'Tap here',
        hint: 'Button text — call to action',
        selectAllOnFocus: true,
        styledHint: Hint,
        styledInput: Input,
        styledField: 'label',
      })}
      {renderField({
        name: `entities.ctas.${selectedCallToAction}.message`,
        placeholder: 'Tap here',
        hint: 'Confirmation message',
        selectAllOnFocus: true,
        styledHint: Hint,
        styledInput: Input,
        styledField: 'label',
      })}

      <SubmitButton
        children={isPersisting ? 'Saving...' : 'Done'}
        disabled={!isFormValid}
        onClick={async (event) => {
          event.preventDefault();
          const saveWasSuccessful = await handleCardFormSubmit(formState, defaultDeck.id);
          if (saveWasSuccessful) {
            unsetPopover(null);
            unsetSelectedCallToAction(null);
          }
        }}
      />
    </FormBasePopover>
  );
};

export default compose(
  connect(
    (state, ownProps) => ({
      isShowingAccountSwitcher: state.accountSwitcher ? state.accountSwitcher.isShowing : false,
      selectedCallToAction: state.cardCallToAction.id,
    }),
    (dispatch) => ({
      unsetPopover: () => dispatch(unsetPopover()),
      unsetSelectedCallToAction: () => dispatch(unsetSelectedCallToAction()),
    }),
  ),
)(FormPanel);
