import React from 'react';
import update from 'immutability-helper';
import set from 'lodash/set';
import { compose } from 'recompose';
import config from 'config';
import ColorSwatchPickerField from 'components/accounts/onboarding/color-swatch-picker-field';
import FormBasePopover from 'components/cards/forms/popovers/base';
import Checkbox from 'components/ui/form-checkbox';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import { setButtonColor, unsetButtonColor } from 'store/modules/card-update-button-color';
import { Button as BaseButton, Label as BaseLabel } from 'components/web-ui';
import styled from 'styled-components/macro';

const Label = styled(BaseLabel)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.veryDarkPurple};
  margin-bottom: 10px;
  display: block;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  padding: 0 14px 15px 14px;
  border-bottom: 1px solid #eeeeee;
  margin: 0 -14px;
`;

const ColorPickerContainer = styled.div`
  border-bottom: 1px solid #eeeeee;
  margin: 0 -14px;
  padding: 15px 14px 0 14px;
`;

const ResetButton = styled.button`
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.appleBlue};
  margin-top: 15px;
  margin-left: 10px;
`;

const SubmitButton = styled(BaseButton)`
  flex-grow: 10;
  font-size: 18px;
  border-radius: 2em;
  height: 50px;
  margin-top: 15px;
  background-color: ${(p) => p.theme.colors.primaryPurple};
  color: white;

  &:hover {
    background-color: ${(p) => p.theme.colors.darkPurple};
  }
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const ButtonColorPanel = ({
  data: { defaultDeck },
  currentAccount: {
    profile: { brandColor },
  },
  isPersisting,
  formState,
  handleCardFormSubmit,
  unsetPopover,
  selectedButtonColor,
  setButtonColor,
  setFormState,
}) => (
  <FormBasePopover title="Styles">
    <CheckboxContainer>
      <Checkbox
        description="Display background overlay"
        checked={formState.result.hasBackgroundGradient}
        onClick={(value) =>
          setFormState((state) =>
            update(state, { result: { hasBackgroundGradient: { $set: value } } }),
          )
        }
      />
    </CheckboxContainer>

    <ColorPickerContainer>
      <Label>Button Color</Label>

      <ColorSwatchPickerField
        name="buttonColor"
        placeholder="#HEX color value"
        swatches={config.profileColorChoices}
        value={selectedButtonColor || brandColor}
        onChange={(name, value) => setButtonColor(value)}
      />
    </ColorPickerContainer>

    <ActionsContainer>
      <SubmitButton
        children={isPersisting ? 'Saving...' : 'Done'}
        onClick={async (event) => {
          event.preventDefault();
          const newCard = { ...formState };
          set(newCard, 'result.buttonColor', selectedButtonColor);
          const saveWasSuccessful = await handleCardFormSubmit(newCard, defaultDeck.id);
          if (saveWasSuccessful) {
            unsetPopover();
            unsetButtonColor();
          }
        }}
      />
      <ResetButton
        children="Reset"
        onClick={async (event) => {
          event.preventDefault();
          setButtonColor(null);
        }}
      />
    </ActionsContainer>
  </FormBasePopover>
);

export default compose(
  connect(
    (state, ownProps) => ({ selectedButtonColor: state.cardButtonColor.color }),
    (dispatch) => ({
      unsetPopover: () => dispatch(unsetPopover()),
      unsetButtonColor: () => dispatch(unsetButtonColor()),
      setButtonColor: (color) => dispatch(setButtonColor(color)),
    }),
  ),
)(ButtonColorPanel);
