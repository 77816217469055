import buttonClickPsuedoSelectors from 'components/styles/button-click-psuedo-selectors';
import { Button, Text } from 'components/web-ui';
import React from 'react';
import { compose, defaultProps, withHandlers, withProps } from 'recompose';
import styled from 'styled-components/macro';

const headerHeight = '60px';

const HeaderBackgroundCropperLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${headerHeight};
  padding: 0;
  margin-top: calc(0px - ${headerHeight} - 1px);
  background-color: white;
`;

const CancelButton = styled(Button)`
  margin-right: auto;
  font-size: 17px;
  font-weight: normal;
  padding: 0 0 0 20px;
  min-height: ${headerHeight};
  ${buttonClickPsuedoSelectors};
  background-color: white;
  color: black;
`;

const DoneButton = styled(Button)`
  background-color: inherit;
  font-size: 17px;
  font-weight: normal;
  margin-left: auto;
  min-height: ${headerHeight};
  outline: 0;
  padding: 0 20px 0 0;
  color: black;
  ${buttonClickPsuedoSelectors};
`;

const Title = styled(Text)`
  color: black;
  font-weight: ${(props) => props.theme.fonts.weights.bold};
`;

// --- The Component ---

function HeaderBackgroundCropper(props) {
  const { isPersisting, title, onClickDone, onClickCancel } = props;
  return (
    <HeaderBackgroundCropperLayout>
      <CancelButton onClick={onClickCancel} children="Cancel" />
      <Title children={title} />
      <DoneButton
        children={isPersisting ? 'Saving...' : 'Save'}
        disabled={isPersisting}
        onClick={onClickDone}
        type="submit"
      />
    </HeaderBackgroundCropperLayout>
  );
}

export default compose(
  defaultProps({
    allowResizing: false,
  }),
  withProps(({ allowResizing }) => {
    const title = allowResizing ? 'Crop your new photo' : 'Change photo';
    return { title };
  }),
  withHandlers({
    onClickCancel:
      ({ onCancel }) =>
      (evt) => {
        evt.preventDefault();
        onCancel();
      },
    onClickDone:
      ({ onDone, startUpload }) =>
      (evt) => {
        evt.preventDefault();
        onDone(startUpload);
      },
  }),
)(HeaderBackgroundCropper);
