"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.StyledWrapper = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var StyledWrapper = _styledComponents["default"].div.withConfig({
  displayName: "styled__StyledWrapper",
  componentId: "ufl61u-0"
})(["display:flex;align-items:center;padding:0.75rem 0.5em;border:1px solid currentColor;border-radius:5px;position:relative;z-index:1;input,select{font-size:inherit;background-color:transparent;border:none;}input:active,input:focus,select:active,select:focus{outline:none;}.helper-field{border-right:1px solid currentColor;padding-right:0.5em;margin-right:0.5em;}.helper-field.-none,.helper-field.-email{display:none;}.input-field{flex:1;display:flex;}.input-field > *{flex:1;}"]);

exports.StyledWrapper = StyledWrapper;
var _default = StyledWrapper;
exports["default"] = _default;