import React from 'react';
import * as Sentry from '@sentry/browser';
import config from 'config';
import { graphql } from '@apollo/react-hoc';
import { compose, withState, branch, renderComponent, withProps, lifecycle } from 'recompose';
import withQueryParams from 'hocs/with-query-params';
import { addConstantContactIdentityMutation } from 'lib/graphql/mutations';
import Spinner from 'components/ui/spinner';
import { Redirect } from 'react-router-dom';

function SuccessRedirect(props) {
  const to = {
    pathname: props.redirectTo,
    state: {
      provider: props.provider,
    },
  };
  return <Redirect to={to} />;
}

function FailureRedirect(props) {
  const to = {
    pathname: props.redirectTo,
    state: {
      error: props.error,
    },
  };
  return <Redirect to={to} />;
}

const withConstantContactProps = withProps((props) => {
  const { accountId, provider, username, redirectTo } = JSON.parse(
    localStorage.getItem(config.CONSTANTCONTACT_AUTH_LS_KEY),
  );
  return { accountId, provider, username, redirectTo };
});

const withMutation = graphql(addConstantContactIdentityMutation, {
  props: ({ mutate, ownProps }) => ({
    addConstantContactIdentity: ({ accountId, username, code }) => {
      return mutate({
        variables: { accountId, username, code },
        update: () => ownProps.setSuccess(true),
      }).catch((res) => {
        const errors = res.graphQLErrors;
        if (Array.isArray(errors) && errors.length > 0) {
          Sentry.captureException('Unable to add ConstantContact identity', {
            extra: { errors },
          });
          ownProps.setError(errors[0].message);
          ownProps.setSuccess(false);
        }
      });
    },
  }),
});

const withAddConstantContactIdentity = lifecycle({
  componentDidMount() {
    const { accountId, username, code, addConstantContactIdentity } = this.props;
    addConstantContactIdentity({
      accountId,
      username,
      code,
    });
  },
});

export default compose(
  withState('success', 'setSuccess'),
  withState('error', 'setError'),
  withQueryParams,
  withConstantContactProps,
  withMutation,

  branch((props) => props.loading, renderComponent(Spinner)),
  branch((props) => props.success === true, renderComponent(SuccessRedirect)),
  branch((props) => props.success === false, renderComponent(FailureRedirect)),

  withAddConstantContactIdentity,
)((props) => null);
