import React from 'react';
import { compose, pure, withProps } from 'recompose';
import { ButtonCircle } from 'components/web-ui';
import styled from 'styled-components/macro';

const Button = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  height: 4rem;
  font-size: 1rem;
  color: white;
  width: 100%;

  &:disabled {
    background-color: #eceff1;
    color: #606d7e;
    cursor: default;
  }

  &:disabled:hover {
    background-color: #eceff1;
    color: #606d7e;
    cursor: default;
    box-shadow: none;
  }
`;

function SelectPlanButton(props) {
  const { onClick, disabled, text } = props;
  return <Button onClick={onClick} disabled={disabled} children={text} />;
}

export default compose(
  withProps((props) => {
    const { stripeId } = props.selectedProduct;

    const isPlatinum = stripeId === 'platinum-product';
    const text = isPlatinum ? 'Contact support for Platinum pricing' : 'Continue';
    const disabled = !stripeId;

    return {
      text,
      disabled,
    };
  }),
  pure,
)(SelectPlanButton);
