import React from 'react';
import { compose } from 'recompose';
import Video from 'components/cards/stats/cards/youtube/video';
import withLoader from 'components/cards/stats/elements/loading-trends';

function sortVideos(nodes) {
  return nodes.sort((a, b) => {
    if (a.total !== b.total) {
      return a.total > b.total ? -1 : 1;
    }
    if (a.video && b.video) {
      const p1 = a.video.publishedAt;
      const p2 = b.video.publishedAt;
      return p1 > p2 ? -1 : 1;
    }
    return 0;
  });
}

function Trends(props) {
  if (!props.data) return null;

  const { summary, unit, data, timeline } = props;
  const { edges } = data;
  const nodes = edges.map((edge) => edge.node);
  const sortedNodes = sortVideos(nodes);

  return sortedNodes.map((node, i) => (
    <Video key={i} unit={unit} summary={summary} timeline={timeline} {...node} />
  ));
}

export default compose(withLoader)(Trends);
