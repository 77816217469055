import React from 'react';
import { PropTypes } from 'prop-types';
import { darken, contrastAdjust } from 'components/web-ui/color-utils';
import { compose, mapProps } from 'lib/recompose';
import styled from 'styled-components/macro';

const Link = styled.a`
  margin: 0px;
  font-size: 1em;
  color: ${(p) => p.color || 'blue'};
  background-color: ${(p) => p.bg};
  cursor: pointer;

  &:hover {
    outline: none;
    background-color: ${(p) => p.bg && darken(p.bg)};
  }

  &:active {
    outline: none;
    background-color: ${(p) => p.bg && darken(p.bg)};
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${(p) => p.bg && contrastAdjust(p.bg)};
  }
`;

function LinkComponent(props) {
  return <Link {...props} />;
}

LinkComponent.propTypes = {
  color: PropTypes.string,
  to: PropTypes.string,
};

export default compose(
  mapProps((props) => {
    const rest = { ...props };

    delete rest.to;

    return {
      href: props.to,
      ...rest,
    };
  }),
)(LinkComponent);
