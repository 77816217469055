import AreaChart from 'components/cards/stats/elements/area-chart';
import Counter from 'components/cards/stats/elements/counter';
import Segment from 'components/cards/stats/elements/segment';
import { Blur, NumbersContainer, Panel } from 'components/cards/stats/elements/shared';
import { withFrequency } from 'components/cards/stats/enhancer';
import { ButtonCircle } from 'components/web-ui';
import routes from 'config/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose, withState } from 'recompose';
import styled from 'styled-components/macro';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 302px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  justify-content: flex-end;
`;

const Message = styled.div`
  font-size: 15px;
  text-align: center;
  margin: 1.5rem;
`;

const UpgradeButton = styled(ButtonCircle)`
  font-size: 1rem;
  color: white;
  height: 4rem;
  background-color: ${(p) => p.theme.colors.primaryPurple};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

function Component(props) {
  const { frequency, chart, currentAccount } = props;
  const { username } = currentAccount;
  return (
    <Panel>
      {/* blur container */}
      <Blur>
        {/* date range selector */}
        <Segment selected={frequency} />

        {/* show stats numbers */}
        <NumbersContainer>
          <Counter total={0} loading={true} active={true} name="Impressions" />
        </NumbersContainer>

        {/* show stats on chart */}
        <AreaChart range={frequency} data={chart} />
      </Blur>
      <Overlay>
        <Message>Please upgrade your plan to see your stats</Message>
        <UpgradeButton
          to={routes.billing.changeProduct(username)}
          as={Link}
          children="Upgrade your plan"
        />
      </Overlay>
    </Panel>
  );
}

const withRandomChart = withState('chart', 'setChart', () => {
  const data = [];
  const today = new Date();
  const currentHour = today.getHours();
  for (let i = 0; i < 12; i++) {
    const date = new Date();
    date.setHours(currentHour - i);
    data.push({
      total: Math.floor(70 + Math.random() * 131),
      when: date.toString(),
    });
  }
  return data;
});

export default compose(withFrequency, withRandomChart)(Component);
