import { DownTriangleIcon } from 'components/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setPropTypes, withHandlers, withProps, withState } from 'recompose';
import Select from '../forms/select';
import { postJSON } from '../lib/fetch_json';
import './photo-change-view-status.css';

const propTypes = {
  instagramPhoto: PropTypes.object.isRequired,
  lipPhoto: PropTypes.object,
  updatePhotoState: PropTypes.func.isRequired,
};

const HIDDEN = 'hidden';
const VISIBLE = 'visible';
const OPTIONS = [
  { value: HIDDEN, label: 'Hidden' },
  { value: VISIBLE, label: 'Visible' },
];

function PhotoChangeViewStatus(props) {
  const { handleChange, lipPhoto } = props;
  return (
    <div className="photo-change-view-status">
      <Select
        name="viewStatus"
        onChange={handleChange}
        options={OPTIONS}
        value={(lipPhoto || {}).view_status || 'visible'}
      />
      <DownTriangleIcon size={23} />
    </div>
  );
}

const props = withProps(({ defaultLink, lipPhoto, instagramPhoto }) => ({
  photoParams(viewStatus) {
    const params = {
      photo: {
        view_status: viewStatus,
      },
    };
    // Pinning an existing LIP photo so just pass that photo's ID.
    if (lipPhoto) {
      params.photo.id = lipPhoto.id;
    } else {
      // We're working with an Instagram photo.
      // We'll need sufficient data to create the LIP photo too.
      params.photo.url = instagramPhoto.url;
      params.photo.instagram_photo_id = instagramPhoto.id;
    }

    // If we don't have a URL AND there's a default URL, use that.
    if (!params.photo.url && defaultLink) {
      params.photo.url = defaultLink.url;
      params.photo.title = defaultLink.title;
      params.photo.description = defaultLink.description;
    }

    return params;
  },
}));

const handlers = withHandlers({
  handleChange:
    ({ photoParams, setIsPersisting, updatePhotoState, user }) =>
    async (event, viewStatus) => {
      // Make the request to update the pinned photo
      // const viewStatus = event.target.value;
      let response = null;
      let body = null;

      try {
        setIsPersisting(true);
        response = await postJSON(
          window.PATHS.changeStatusApiV1PhotosPath(user.username),
          photoParams(viewStatus),
        );
        body = await response.json();
      } catch (error) {
        alert('Sorry, there was an error updating this photo');
      } finally {
        setIsPersisting(false);
      }

      if (response && response.ok) {
        // Update the data / UI
        updatePhotoState(body.photo, { action: viewStatus });
      } else {
        // let errorMessage = null;
        // Move this to a prop
        // Object.keys(body.errors).forEach((key, index) => {
        //   if (index === 0) { errorMessage = `${key} ${body.errors[key][0]}`; }
        // });
        // alert(errorMessage || 'Sorry, there was an error pinning this photo');
      }
    },
});

export default compose(
  setPropTypes(propTypes),
  withState('isPersisting', 'setIsPersisting', false),
  props,
  handlers,
)(PhotoChangeViewStatus);
