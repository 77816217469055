import objectToArray from 'lib/object-to-array';
import {
  branch,
  lifecycle,
  renderComponent,
  withHandlers,
  withProps,
  withState,
} from 'lib/recompose';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { mailingListNotReady } from './validator';

const defaultProviderState = {
  kind: 'zapier',
  url: '',
  listId: '',
  doubleOptIn: false,
};

const defaultCtaState = {
  title: 'Sign Up',
  message: 'Thanks for signing up',
  url: window.location.origin,
};

export const withPreviousFormState = withState(
  'previousFormState',
  'setPreviousFromState',
  ({ formState }) => cloneDeep(formState),
);
export const withFormReadyState = withState('wasNotReady', 'setWasNotReady', mailingListNotReady);
export const withFormSettingsState = withState('settings', 'setSettings', (props) => {
  return props.location && props.location.state && props.location.state.provider ? true : false;
});

export const withFormHandlers = withHandlers({
  initializeProvider:
    ({ card, data, location, formState, setFormState, handleCardFormSubmit }) =>
    async () => {
      if (!formState.result.mailingList) {
        const id = `new-${Math.random().toString(36).substring(7)}`;
        setFormState((state) => {
          let updated = state;
          updated = set({ ...updated }, 'entities.mailingLists', {
            [id]: { ...defaultProviderState },
          });
          updated = set({ ...updated }, 'result.mailingList', id);
          return updated;
        });
        await handleCardFormSubmit(formState, data.defaultDeck.id);
        return;
      }
      // temporary switch provider to target after oauth authentication
      if (location.state && typeof location.state.provider === 'string') {
        const id = formState.result.mailingList;
        setFormState((state) =>
          set({ ...state }, `entities.mailingLists.${id}.kind`, location.state.provider),
        );
      }
    },
  initializeDefaultCta:
    ({ card, data, initializeNewCallToAction, setFormState, handleCardFormSubmit }) =>
    async () => {
      const ctas = card.ctas;
      if (Array.isArray(ctas) && ctas.length > 0) return;
      const id = initializeNewCallToAction();
      const state = setFormState((state) =>
        set({ ...state }, `entities.ctas.${id}`, { id, ...defaultCtaState }),
      );
      await handleCardFormSubmit(state, data.defaultDeck.id);
    },
  showButtonCustomizationOptions:
    ({ card, setSelectedPopover, setSelectedCallToAction }) =>
    (event) => {
      event.preventDefault();
      const ctas = card.ctas;
      if (!(Array.isArray(ctas) && ctas.length > 0)) return;

      setSelectedPopover('mailing-list-button');
      setSelectedCallToAction(card.ctas[0].id);
    },
});

export const withFormProps = withProps(({ location, card, currentAccount, formState }) => {
  const { profile } = currentAccount;
  const { brandColor } = profile;
  const { buttonColor = brandColor } = card;
  const ctas = objectToArray(formState.entities.ctas);
  const mailingLists = objectToArray(formState.entities.mailingLists);
  const buttonText = Array.isArray(ctas) & (ctas.length > 0) ? ctas[0].title : 'Sign up';
  return {
    ctas,
    mailingLists,
    buttonColor: buttonColor || brandColor,
    buttonText,
    error: location.state && location.state.error,
  };
});

export const withLifeCycle = lifecycle({
  componentDidMount() {
    this.props.initializeProvider();
    this.props.initializeDefaultCta();
  },
  componentDidUpdate({ receiveSettingsPopoverEvent: prev }) {
    const { setSettings, unsetPopover, receiveSettingsPopoverEvent: next } = this.props;
    if (prev !== next && next) {
      unsetPopover();
      setSettings(true);
    }
  },
});

export const renderSettings = (component) =>
  branch(({ settings }) => !!settings, renderComponent(component));
