import React from 'react';
import * as Recompose from 'recompose';
import * as Redux from 'react-redux';
import { setSelectedPopover } from 'store/modules/card-popovers';
import { setButtonColor } from 'store/modules/card-update-button-color';
import { PaletteIcon } from 'components/icons';
import * as Styled from './styled';

PaletteIcon.defaultProps = {
  width: 38,
  height: 18,
};

export default Recompose.compose(
  Redux.connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setButtonColor: (color) => dispatch(setButtonColor(color)),
  })),

  Recompose.withProps((props) => {
    return {
      children: React.createElement(PaletteIcon),
    };
  }),

  Recompose.withHandlers({
    onClick: (props) => (event) => {
      event.preventDefault();
      const { buttonColor } = props.card;
      const { brandColor } = props;
      props.setButtonColor(buttonColor || brandColor);
      props.setSelectedPopover('custom-styles');
    },
  }),
)(Styled.HeaderButton);
