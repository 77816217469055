import React from 'react';
import { compose, branch, withProps } from 'recompose';
import { connect } from 'react-redux';
import BaseForm from 'components/cards/forms/base';
import { Button } from 'components/web-ui';

const EventCardForm = ({ card, renderField, handleShowLinkForm }) => <div />;

const mapDispatchToProps = (dispatch, ownProps) => {
  return { dispatch };
};

export default compose(
  connect(mapDispatchToProps),
  // temporarity render a placeholder
  branch(
    (props) => true,
    (props) => (
      <p>
        <Button onClick={props.history.goBack} children="Back" />
        The event form is coming soon
      </p>
    ),
  ),
  withProps(() => ({ FormComponent: EventCardForm })),
)(BaseForm);
