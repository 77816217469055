import Header from 'components/headers/deck-header';
import ChooseBackgroundFilter from 'components/image-upload/choose-background-filter';
import BackgroudPhotoCropper from 'components/image-upload/crop-background-photo';
import Toast from 'components/toast/toast';
import Account from 'lib/current-account';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Swiper from './swiper';

function getPageTitle({ card, accountName }) {
  const parts = [accountName];
  const title = card && (card.title ? card.title : `Untitled ${card.kind} card`);
  if (title) parts.push(title);
  parts.push('Your Tap Bio dashboard');
  return parts.join(' | ');
}

function CardsComponent(props) {
  const {
    activeIndex,
    cards,
    currentAccount,
    currentUser,
    handleUpdateProfileBackgroundImage,
    handleUpdateProfileBackgroundColor,
    handleCancelPhotoCropper,
    handleImageUploaded,
    setOnboardingCard,
    setUploadedBackgroundUrl,
    uploadedBackgroundPhotoUrl,
    selectedBackgroundPhoto,
    unsetSelectedBackgroundImage,
    setDeckViewstate,
  } = props;

  const { username, profile } = props.currentAccount;

  const handleSwiperInit = useCallback(
    function (swiper) {
      const { id, kind } = cards[swiper.activeIndex];
      setOnboardingCard({ id, kind });
      Account.setCurrentAccount(username);
      setDeckViewstate({
        activeIndex: swiper.activeIndex,
        cardKind: kind,
        swiperReady: true,
      });
    },
    [cards, username, setOnboardingCard, setDeckViewstate],
  );

  const handleSlideChangeEnd = useCallback(
    function (swiper) {
      const { id, kind } = cards[swiper.activeIndex];
      setOnboardingCard({ id, kind });
      setDeckViewstate({
        activeIndex: swiper.activeIndex,
        cardKind: kind,
        swiperReady: true,
      });
    },
    [cards, setOnboardingCard, setDeckViewstate],
  );

  const pageTitle = getPageTitle({
    card: cards[activeIndex],
    accountName: currentAccount.username,
  });

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Header currentAccount={currentAccount} currentUser={currentUser} />
      <Swiper
        children={cards}
        currentAccount={currentAccount}
        currentUser={currentUser}
        activeIndex={activeIndex}
        onSwiperInit={handleSwiperInit}
        onSlideChangeEnd={handleSlideChangeEnd}
      />

      {/* Popover for editing the background photo */}
      {/* Displaying this here is a problem because the slider's navigation segments are visible over it. */}
      {uploadedBackgroundPhotoUrl && (
        <ChooseBackgroundFilter
          handleSubmit={async (url, brandColor) => {
            // Update the profile
            await handleUpdateProfileBackgroundImage(profile.id, {
              defaultBackgroundMediaUrl: url,
            });
            // Update the card image background color
            await handleUpdateProfileBackgroundColor(brandColor);
            // Hide the image upload screen
            setUploadedBackgroundUrl(null);
            unsetSelectedBackgroundImage();
          }}
          profile={profile}
        />
      )}

      {selectedBackgroundPhoto && (
        <BackgroudPhotoCropper
          onCancel={handleCancelPhotoCropper}
          onCompletedUpload={handleImageUploaded}
        />
      )}

      <Toast />
    </>
  );
}

export default CardsComponent;
