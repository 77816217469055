import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash/isEmpty';
import { graphql } from '@apollo/react-hoc';
import { billingChangePlanMutation } from 'lib/graphql/mutations';

export default graphql(billingChangePlanMutation, {
  props: ({ mutate, ownProps: { setIsPersisting, setErrors } }) => ({
    handleChangePlan: async (newPlanStripeId, userId) => {
      let success = true;
      setIsPersisting(true);
      setErrors(null);
      try {
        const response = await mutate({
          mutation: billingChangePlanMutation,
          variables: { input: { newPlanStripeId, userId } },
        });
        if (!isEmpty(response.data.changePlan.errors)) {
          success = false;
          setErrors(response.data.changePlan.errors.error_message);
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      } finally {
        setIsPersisting(false);
        return success;
      }
    },
  }),
});
