import React from 'react';
import Field from 'components/ui/field';
import Input from 'components/ui/form-text-input';
import Button from 'components/ui/form-button';
import { Layout, Main } from 'components/accounts/styled';
import styled from 'styled-components/macro';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

const Form = styled.form`
  padding: 20px;
`;

function AddAccount(props) {
  return (
    <Layout>
      <ViewHeader>
        <ViewHeader.LeftButton onClick={props.onClickBack} />
        <ViewHeader.Title children="Add Account" />
        <ViewHeader.RightButton children="" />
      </ViewHeader>
      <Main>
        <Form>
          <h1>Add a new account</h1>
          <Field
            autoCapitalize="off"
            autoCorrect="off"
            name="accountName"
            styledInput={Input}
            placeholder="New account name"
            onChange={props.onFieldChange}
            value={props.formData.accountName}
            validationError={props.formErrors.accountName}
            hint={props.formHints.accountName}
          />
          <Button
            text="Next"
            disabled={props.formErrors.any || props.data.loading}
            onClick={props.onSubmit}
          />
        </Form>
      </Main>
    </Layout>
  );
}

export default AddAccount;
