import React from 'react';
import sizeMe from 'react-sizeme';
import { compose, defaultProps } from 'recompose';
import { AreaChart, Area } from 'recharts';
import styled from 'styled-components/macro';

const units = {
  '1D': '24h ago',
  '1W': '7d ago',
  '1M': '1m ago',
  ALL: '∞ ',
};

const Layout = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 150px;
  min-height: 150px;
`;

const Label = styled.div`
  position: absolute;
  bottom: 20px;
  color: #a498b0;
  font-size: 13px;
  font-weight: 600;
`;

const DateFromLabel = styled(Label)`
  left: 20px;
`;

const DateToLabel = styled(Label)`
  right: 20px;
`;

const DetailsLabel = styled(Label)`
  left: 50%;
  transform: translateX(-50%);
  color: #b2acb9;
  cursor: pointer;
`;

function Chart(props) {
  const { data, range, size, expanded, onMoreButtonPressed } = props;
  return (
    <Layout>
      <AreaChart
        width={size.width}
        height={size.height}
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <Area
          type="basis"
          stroke="none"
          fillOpacity={1}
          fill="url(#analytics-uv)"
          dataKey="total"
          isAnimationActive={true}
        />
      </AreaChart>
      <DateFromLabel>{units[range]}</DateFromLabel>
      <DateToLabel>Today</DateToLabel>
      {typeof onMoreButtonPressed === 'function' && (
        <DetailsLabel onClick={onMoreButtonPressed}>
          {expanded ? 'Hide details' : 'Tap for more'}
        </DetailsLabel>
      )}
    </Layout>
  );
}

export default compose(
  defaultProps({
    data: [],
    expanded: false,
  }),
)(
  sizeMe({
    monitorWidth: true,
    monitorHeight: true,
  })(Chart),
);
