import React from 'react';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Link } from 'components/web-ui';
import { omitProps } from 'lib/recompose';

function LinkWithOnClick(props) {
  return <Link {...props} />;
}

const withOnClick = withHandlers({
  onClick: (props) => (event) => {
    event.preventDefault();
    props.onClick && props.onClick();
    props.replace ? props.history.replace(props.to) : props.history.push(props.to);
  },
});

export default compose(
  //
  withRouter,
  withOnClick,
  omitProps('history', 'is', 'location', 'match', 'onClick', 'staticContext', 'to'),
)(LinkWithOnClick);
