import React from 'react';
import * as Recompose from 'lib/recompose';
import { connect } from 'react-redux';
import { toggleStats } from 'store/modules/stats';
import { BarChartIcon } from 'components/icons';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import * as Styled from './styled';

const active = css`
  background-color: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.6);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 100vh rgba(255, 255, 255, 0.6);
  }
`;

const StatsButton = styled(Styled.HeaderButtonCircle)`
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  svg path {
    fill: currentColor;
  }

  ${(p) => p.isShowingStats && active};
`;

export default Recompose.compose(
  connect(
    (state) => ({ isShowingStats: state.stats.isShowing }),
    (dispatch) => ({ toggleStats: () => dispatch(toggleStats()) }),
  ),

  Recompose.withHandlers({
    onClick: (props) => (event) => {
      event.preventDefault();
      props.toggleStats();
    },
  }),

  Recompose.withProps((props) => {
    const children = React.createElement(BarChartIcon);
    return { children };
  }),

  Recompose.pure,
)(StatsButton);
