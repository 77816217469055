import React from 'react';
import { Blocker } from 'components/ui';
import Transition from 'react-transition-group/Transition';
import styled from 'styled-components/macro';

const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 14px 8px 14px;
  border-radius: 8px;
  position: absolute;
  top: 70px;
  left: 20px;
  right: 20px;
  bottom: 90px;
  font-size: 14px;
  transition: opacity 0.1s linear;
  opacity: 0;
  /* Always above the blocker (which is set to 100) */
  z-index: 101;
  pointer-events: auto;

  ${({ state }) => state === 'entered' && `opacity: 1;`};
`.withComponent(({ state, left, top, ...rest }) => <div {...rest} />);

const Close = styled.span`
  font-size: 38px;
  font-weight: 100;
  position: absolute;
  right: 15px;
  top: 0px;
`;

function Modal(props) {
  const { children, onClickClose } = props;
  return (
    <div>
      <Blocker opacity={0.5} zIndex={100} />
      <Transition appear={true} in={true} timeout={0}>
        {(state) => (
          <ModalContainer state={state}>
            <Close onClick={onClickClose} children="&times;" />
            {children}
          </ModalContainer>
        )}
      </Transition>
    </div>
  );
}

export default Modal;
