import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Button as BaseButton, Text as BaseText } from 'components/web-ui';
import { PlusCircleIcon as BasePlusCircleIcon } from 'components/icons';
import { defaultProps } from 'lib/recompose';

const commonStyles = css`
  background-color: ${(props) => props.theme.colors.alphaBlack30};
  border-radius: 2em;
  border: 2px solid ${(props) => props.theme.colors.alphaWhite50};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 100;
  font-size: 17px;
  padding: 12px;
  color: inherit;

  :active,
  :focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.alphaWhite50};
    box-shadow: none;
    background-color: ${(props) => props.theme.colors.alphaBlack50};
  }
`;

const Button = styled(BaseButton)`
  ${commonStyles};
`;

const DummyButton = styled.div`
  ${commonStyles};
  z-index: 0;
`;

const ButtonText = styled(BaseText)`
  font-weight: bold;
  color: inherit;
`;

const PlusCircleIcon = styled(BasePlusCircleIcon)`
  margin-right: 8px;
  path {
    fill: ${(props) => props.theme.colors.alphaWhite50};
  }
`;

// --- The Component ---

function AddCallToActionButton({ onClick, buttonText, isButton }) {
  const children = (
    <React.Fragment>
      <PlusCircleIcon width={30} height={30} />
      <ButtonText children={buttonText} />
    </React.Fragment>
  );
  const props = { py: 3, children };
  return isButton ? <Button {...props} onClick={onClick} /> : <DummyButton {...props} />;
}

export default defaultProps({
  isButton: true,
  buttonText: 'Add a link',
})(AddCallToActionButton);
