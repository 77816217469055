"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Spacer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _button = _interopRequireDefault(require("../../../elements/buttons/button"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Spacer = (0, _styledComponents["default"])(_button["default"]).withConfig({
  displayName: "spacer__Spacer",
  componentId: "p388l8-0"
})(["flex:1;cursor:default;&:hover,&:active,&:focus{box-shadow:none;}"]);
exports.Spacer = Spacer;
var _default = Spacer;
exports["default"] = _default;