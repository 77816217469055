import { withProps } from 'recompose';

export default withProps(
  ({
    currentUser: {
      stripeSubscription: { planStripeId, productStripeId },
    },
  }) => ({
    currentPlanStripeId: planStripeId,
    currentProductStripeId: productStripeId,
  }),
);
