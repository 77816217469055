import React from 'react';
import { Title } from 'components/cards/preview/styles';

// --- Components ---

function EventCard(props) {
  return (
    <div>
      <Title children="Event" />
    </div>
  );
}

export default EventCard;
