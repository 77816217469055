import React from 'react';
import styled from 'styled-components/macro';

const Svg = styled.svg``;

Svg.defaultProps = {
  width: '1em',
  xmlns: 'http://www.w3.org/2000/svg',
};

function ConstantContactLogo(props) {
  return (
    <Svg height={props.width} {...props} viewBox="0 0 42 29">
      <path fill="none" d="M-1-1h44v31H-1z" />
      <g>
        <g fillRule="evenodd" fill="none">
          <path
            fill={props.fill || '#FCD98D'}
            d="M19.894 14.733a49.809 49.809 0 0 1 7.398-1.322 52.83 52.83 0 0 1 1.743-5.53l-.23.04a51.854 51.854 0 0 0-6.99 1.846 51.863 51.863 0 0 0-1.92 4.966m-6.097-2.485a54.404 54.404 0 0 1 2.462-4.502 64.778 64.778 0 0 0-4.019 2.523 53.323 53.323 0 0 0-2.787 4.54 53.329 53.329 0 0 1 4.344-2.56"
          />
          <path
            fill={props.fill || '#809EBA'}
            d="M18.881 15.008a52.941 52.941 0 0 1 1.793-4.84 52.882 52.882 0 0 0-6.218 2.738 52.29 52.29 0 0 0-1.845 4.346 47.363 47.363 0 0 1 6.269-2.245"
          />
          <path
            fill={props.fill || '#2B5E8C'}
            d="M28.667 7.04c.252-.047.5-.092.745-.134a54.995 54.995 0 0 1 2.57-5.524 56.607 56.607 0 0 0-6.864 2.136 54.347 54.347 0 0 0-2.794 5.14 52.758 52.758 0 0 1 6.343-1.618M5.59 21.13c.444-1.189.943-2.39 1.491-3.574a54.088 54.088 0 0 0-2.723 2.18 51.297 51.297 0 0 0-2.194 3.963A43.915 43.915 0 0 1 5.59 21.13"
          />
          <path
            fill={props.fill || '#F9B31B'}
            d="M11.491 17.764a52.584 52.584 0 0 1 1.67-4.16 53.297 53.297 0 0 0-4.579 2.865 50.905 50.905 0 0 0-1.73 3.838 44.798 44.798 0 0 1 4.64-2.543"
          />
          <path
            fill={props.fill || '#809EBA'}
            d="M28.226 13.322c2.792-.238 5.6-.254 8.203-.046.62-2.203 1.277-4.285 2.003-6.241-.12-.002-.24-.002-.363-.002-2.616 0-5.315.226-8.021.67a51.494 51.494 0 0 0-1.822 5.619"
          />
          <path
            fill={props.fill || '#F9B31B'}
            d="M38.065 6.141c.244 0 .478.002.704.006A46.88 46.88 0 0 1 41.674 0c-1.953.076-4.95.334-8.54 1.117a54.298 54.298 0 0 0-2.701 5.62 50.385 50.385 0 0 1 7.632-.596M18.334 20.776a49.423 49.423 0 0 1 3.056-.43 53.525 53.525 0 0 1 4.699-.29c.19-1.847.522-3.771.98-5.726a49.389 49.389 0 0 0-7.493 1.409 46.902 46.902 0 0 0-1.242 5.037"
          />
          <path
            fill={props.fill || '#FCD98D'}
            d="M4.088 25.928c.276-1.097.609-2.224.99-3.357a43.644 43.644 0 0 0-3.85 3.12A43.43 43.43 0 0 0 0 28.784a35.182 35.182 0 0 1 4.088-2.856"
          />
          <path
            fill={props.fill || '#2B5E8C'}
            d="M17.407 20.945a46.96 46.96 0 0 1 1.165-4.921 46.59 46.59 0 0 0-6.377 2.396 46.106 46.106 0 0 0-1.247 4.28 44.477 44.477 0 0 1 6.46-1.755"
          />
        </g>
      </g>
    </Svg>
  );
}

export default ConstantContactLogo;
