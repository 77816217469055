import styled from 'styled-components/macro';
import { css } from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';
import { omitProps } from 'lib/recompose';

export const InputCSS = css`
  border-image-width: 0;
  background-color: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  resize: none;
  text-shadow: inherit;
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.theme.colors.alphaBlack20};
  box-sizing: border-box;

  ::placeholder {
    color: ${(props) => props.theme.colors.alphaWhite70};
  }

  :focus,
  :active {
    box-shadow: none;
    outline: none;
  }
`;

export const InputPlaceholderCSS = css`
  background-color: transparent;
  :focus,
  :active {
    ::placeholder {
      opacity: 0;
    }
  }
`;

export const BaseCSS = css`
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fonts.weights.normal};
  margin: 0;
  padding: 0;
  white-space: pre-line;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu;
`;

export const TitleCSS = css`
  font-size: 32px;
  font-weight: ${(props) => props.theme.fonts.weights.bold};
  line-height: 1.1875;
  margin: 0;
  text-align: center;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 1em;
`;

export const TitleSmallCSS = css`
  ${TitleCSS};
  font-size: 20px;
  margin: 0 0 3px 0;
`;

export const DescriptionCSS = css`
  font-size: 23px;
  line-height: 1.3;
  text-align: center;
  box-sizing: border-box;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  margin: 0 0 18px 0;
`;

export const DescriptionSmallCSS = css`
  ${DescriptionCSS};
  font-size: 13px;
`;

export const ButtonCSS = css`
  font-size: 23px;
  line-height: 1.3;
  text-align: center;
`;

export const StyledField = styled.div`
  margin: 0;
  display: block;
  line-height: 0;
`;

export const TitleTextarea = styled.textarea`
  ${InputCSS};
  ${InputPlaceholderCSS};
  ${BaseCSS};
  ${TitleCSS};
`.withComponent(omitProps('hasErrors')(TextareaAutosize));

export const DescriptionTextarea = styled.textarea`
  ${InputCSS};
  ${InputPlaceholderCSS};
  ${BaseCSS};
  ${DescriptionCSS};
`.withComponent(omitProps('hasErrors')(TextareaAutosize));

export const TitleSmallTextarea = styled.textarea`
  ${InputCSS};
  ${InputPlaceholderCSS};
  ${BaseCSS};
  ${TitleSmallCSS};
`.withComponent(omitProps('hasErrors')(TextareaAutosize));

export const DescriptionSmallTextarea = styled.textarea`
  ${InputCSS};
  ${InputPlaceholderCSS};
  ${BaseCSS};
  ${DescriptionSmallCSS};
`.withComponent(omitProps('hasErrors')(TextareaAutosize));
