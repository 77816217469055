import React from 'react';

function Chat(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 40 40" {...props}>
      <path
        fill="#929EA8"
        d="m19.8 35.1c-9 0-16.3-7-16.3-15.6s7.3-15.7 16.3-15.7 16.2 7 16.2 15.7c0 3.1-0.9 6-2.6 8.4-0.1 0.1-0.1 0.2-0.2 0.3-0.2 0.3-0.3 0.8-0.3 1.2l1.7 6.9-6.6-2.5c-0.3-0.1-0.6-0.1-0.9-0.1s-0.6 0.1-0.9 0.1c0 0 0 0.1-0.1 0.1-2 0.8-4.1 1.2-6.3 1.2z"
      />
    </svg>
  );
}

export default Chat;
