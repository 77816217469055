import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import request from '../lib/request';
import addProtocolToURL from '../lib/add_protocol_to_url';
import { deleteJSON } from '../lib/fetch_json';
import Field from '../forms/field';
import Button from '../button';

// Styles
import './photo-form.css';

const FAIL = 'fail';
const INITIAL = 'initial';
const IN_PROGRESS = 'in-progress';
const SUCCESS = 'success';
const SAVE_STATUS_DISPLAY = {
  [FAIL]: 'Save failed! Click to try again',
  [INITIAL]: 'Save',
  [IN_PROGRESS]: 'Saving...',
  [SUCCESS]: 'Save',
};

function getPhotoData(photo, instagramPhoto) {
  return {
    photo: {
      ...photo,
      instagram_photo_id: instagramPhoto.id,
      permalink: instagramPhoto.permalink || instagramPhoto.link,
    },
  };
}

export function tooltipText() {
  return window.innerWidth > 500 ? 'Fetch link preview' : undefined;
}

export function getPhotoAttrs(photo) {
  return pick(photo, ['id', 'url', 'title', 'description', 'instagram_photo_id']);
}

export function getUrl({ instagramPhoto, defaultLink }) {
  const url = instagramPhoto.url || defaultLink.url;
  return addProtocolToURL(url) || '';
}

export default class PhotoForm extends Component {
  static propTypes = {
    defaultLink: PropTypes.object,
    instagramPhoto: PropTypes.object.isRequired,
    lipPhoto: PropTypes.object,
    updatePhotoState: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    defaultLink: {},
  };

  constructor(props) {
    super(props);
    const photo = getPhotoAttrs(props.lipPhoto);
    if (!photo.url) {
      photo.url = getUrl(props);
    }

    // If we're using a default link and there's not other URL then set that content
    if (props.defaultLink && !props.instagramPhoto.url) {
      if (!photo.title) {
        photo.title = props.defaultLink.title;
        photo.description = props.defaultLink.description;
      }
    }

    this.state = {
      errors: {},
      isDirty: false,
      isEnriching: null,
      saveStatus: INITIAL,
      photo,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lipPhoto) {
      this.setState({ photo: nextProps.lipPhoto });
    }
  }

  // === Start event handlers ===

  handleInputChange = (field, value) => {
    const { photo } = this.state;
    photo[field] = value;
    this.setState({ photo, isDirty: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.savePhoto(this.state.photo);
  };

  handleClear = (event) => {
    event.preventDefault();
    const photoId = this.state.photo.id;
    if (!photoId) return;

    this.deletePhoto(photoId);
  };

  // === End event handlers ===

  getErrorMessagesFor(inputKey) {
    if (!this.state.errors[inputKey]) return null;
    return this.state.errors[inputKey][0];
  }

  clearedFormState() {
    return {
      description: '',
      title: '',
      url: getUrl(this.props),
    };
  }

  async savePhoto(photo) {
    const { instagramPhoto, user } = this.props;
    this.setState({ saveStatus: IN_PROGRESS });

    // Create
    let url = window.PATHS.apiV1PhotosPath(user.username);
    let method = 'POST';

    // Update
    if (photo.id) {
      url = window.PATHS.updatePhotoUrl(user.username, photo.id);
      method = 'PATCH';
    }

    try {
      const savedPhoto = await request(url, method, getPhotoData(photo, instagramPhoto));
      this.setState({ photo: savedPhoto, errors: {}, isDirty: false, saveStatus: SUCCESS });
    } catch (errors) {
      this.setState({ ...errors, saveStatus: FAIL });
    }
  }

  async deletePhoto(photoId) {
    const { user } = this.props;
    this.setState({ saveStatus: IN_PROGRESS });
    try {
      await deleteJSON(window.PATHS.deletePhotoUrl(user.username, photoId, 'DELETE'));
      this.setState({ saveStatus: SUCCESS, photo: this.clearedFormState() });
    } catch (error) {
      this.setState({ saveStatus: FAIL });
    }
  }

  render() {
    const { saveStatus, photo } = this.state;
    return (
      <form className="photo-form" onSubmit={this.handleSubmit}>
        <div className="fieldset">
          <div className="url-field">
            <Field
              autoCapitalize="off"
              autoCorrect="off"
              placeholder="Only posts with a link appear on your card"
              errors={this.getErrorMessagesFor('url')}
              label="Link"
              name="url"
              onChange={this.handleInputChange}
              value={photo.url}
            />
          </div>
        </div>

        <div className="actions">
          {this.state.photo.id && (
            <Button className="clear" onClick={this.handleClear} text="Clear & Save" />
          )}
          <Button
            disabled={this.state.saveStatus === IN_PROGRESS}
            className="save"
            text={SAVE_STATUS_DISPLAY[saveStatus]}
          />
        </div>
      </form>
    );
  }
}
