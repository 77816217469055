import * as Recompose from 'lib/recompose';

export default Recompose.compose(
  Recompose.mapProps((props) => {
    const {
      backgroundColor,
      backgroundColorSolid,
      backgroundMediaUrl,
      buttonColor,
      description,
      editable,
      enabled,
      hasBackgroundGradient,
      hidden,
      moveable,
      title,
    } = props.card;

    const friends = props.card.friends.map((friend) => {
      const { id, featuredText, position } = friend;
      const { profile, ...account } = friend.account;
      return {
        ...account,
        ...profile,
        id,
        featuredText,
        position,
      };
    });

    return {
      friends,
      title,
      description,
      backgroundColor,
      backgroundColorSolid,
      backgroundMediaUrl,
      buttonColor,
      hasBackgroundGradient,
      editable,
      enabled,
      hidden,
      moveable,
    };
  }),

  Recompose.mapProps((props) => {
    const friends = props.friends.sort(function (a, b) {
      return a.position - b.position;
    });

    return {
      ...props,
      friends,
    };
  }),
);
