import React from 'react';
import pick from 'lodash/pick';
import { Text, ButtonCircle } from 'components/web-ui';
import SocialAccountField from 'components/cards/forms/profile/social-account-field';
import ScrollContainer from 'components/ui/scroll-container';
import { compose, lifecycle } from 'recompose';
import withCallToActionStateHandlers from 'hocs/with-call-to-action-state-handlers';
import styled from 'styled-components/macro';

const Container = styled.div`
  padding: 20px 16px 0 16px;
`;

const AddButton = styled(ButtonCircle)`
  border: 2px solid ${({ theme }) => theme.colors.profileForm.inputBorder};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: 15px;
  width: 100%;
  padding: 1.5em 2em;

  :hover,
  :focus,
  :active {
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.white};
    -webkit-tap-highlight-color: ${({ theme }) => theme.colors.white};
  }

  :active {
    opacity: 0.5;
  }
`;

const Info = styled(Text)`
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
`;

function SocialAccountFields(props) {
  const ctas = props.formState.entities.ctas;
  return (
    <ScrollContainer>
      <Container>
        {ctas &&
          Object.keys(ctas).map((id) => (
            <SocialAccountField
              {...pick(props, [
                'currentAccount',
                'card',
                'formState',
                'setFormState',
                'renderField',
                'renderSelect',
              ])}
              key={id}
              cta={ctas[id]}
            />
          ))}

        <AddButton
          children="Add another link"
          onClick={(event) => {
            event.preventDefault();
            props.initializeNewCallToAction();
          }}
        />
        <Info>These will appear on your About Card.</Info>
      </Container>
    </ScrollContainer>
  );
}

export default compose(
  withCallToActionStateHandlers,
  lifecycle({
    /*
     * If there's no existing CTAs on load, show a blank form for the first one
     */
    componentWillMount() {
      if (this.props.formState.result.ctas.length === 0) {
        this.props.initializeNewCallToAction();
      }
    },
  }),
)(SocialAccountFields);
