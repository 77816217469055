import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import './button.css';

export default class Button extends Component {
  static propTypes = {
    // icon: PropTypes.function, // No idea what this should be to validate correctly
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconSize: PropTypes.number,
    link: PropTypes.string,
    onClick: PropTypes.func,
    openInNewPage: PropTypes.bool,
    subText: PropTypes.string,
    text: PropTypes.string,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    openInNewPage: false,
    iconSize: 20,
    disabled: false,
  };

  handleClick = () => {
    const { link, openInNewPage } = this.props;
    if (!link) return; // e.g. it's a form submit button.
    if (openInNewPage) {
      window.open(link, '_blank');
    } else {
      window.location.href = link;
    }
  };

  classes() {
    const { icon, text } = this.props;
    return classNames('button', this.props.className, {
      '-has-icon': icon,
      '-only-icon': icon && !text,
    });
  }

  render() {
    const { text, iconSize, onClick, disabled, tooltip, subText } = this.props;
    return (
      <button
        className={this.classes()}
        data-tip={tooltip}
        disabled={disabled}
        onClick={onClick || this.handleClick}
      >
        <span className="text">{text}</span>
        {subText && <p className="sub-text">{subText}</p>}
        {this.props.icon && <this.props.icon className="icon" size={iconSize} />}
        <ReactTooltip class="tooltip" delayHide={500} effect="solid" html />
      </button>
    );
  }
}
