import { accountAnalyticsVisitsQuery as query } from 'lib/graphql/queries';
import initStatType from 'components/cards/stats/dataloader';

const resolver = (props) => {
  return (
    props.publicAccount && props.publicAccount.analytics && props.publicAccount.analytics.visits
  );
};

export default initStatType({
  name: 'Page Views',
  query,
  resolver,
});
