import React from 'react';
import { css } from 'styled-components';
import { convertHexToRgba } from 'lib/color-utils';
import { Button as BaseButton, Link as BaseLink } from 'components/web-ui';
import { TrashIcon } from 'components/icons';
import styled from 'styled-components/macro';
import ctaOnClickHandler from '@tap-bio/core/event-handlers/cta-on-click-handler';

const commonStyles = css`
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1.35;
  padding: 18px;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  background-color: ${(p) => p.background};
  color: white;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Button = styled(BaseButton)`
  ${commonStyles};
  position: relative;
`;

const Link = styled(BaseLink)`
  ${commonStyles};
`;

const DeleteControl = styled.div`
  position: absolute;
  left: 3.5px;
  margin: 0 10px;
  display: block;
  line-height: 1.2;
  background-color: rgba(0, 0, 0, 0.4);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const CallToActionButton = (props) => {
  const { cta, handleDeleteCta, isPreview, onClick } = props;
  const brandColor = convertHexToRgba(props.brandColor);

  if (isPreview) {
    return (
      <Link
        children={cta.title}
        href={cta.url}
        background={brandColor}
        onClick={ctaOnClickHandler({ url: cta.url })}
      />
    );
  }

  return (
    <Button onClick={onClick} background={brandColor}>
      {typeof handleDeleteCta === 'function' && (
        <DeleteControl
          onClick={(event) => handleDeleteCta(event, cta.id)}
          children={React.createElement(TrashIcon, { height: 12 })}
        ></DeleteControl>
      )}
      {cta.title}
    </Button>
  );
};

CallToActionButton.defaultProps = {
  mt: 0,
};

export default CallToActionButton;
