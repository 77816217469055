import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

export const mutation = gql`
  mutation {
    exportStats
  }
`;

export default graphql(mutation);
