import React from 'react';

function Show(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M 12 5 C 6 5 1.386719 11.105469 1.1875 11.40625 C 0.886719 11.804688 0.886719 12.195313 1.1875 12.59375 C 1.386719 12.894531 6 19 12 19 C 18 19 22.613281 12.894531 22.8125 12.59375 C 23.113281 12.195313 23.113281 11.804688 22.8125 11.40625 C 22.613281 11.105469 18 5 12 5 Z M 12 7 C 14.800781 7 17 9.199219 17 12 C 17 14.800781 14.800781 17 12 17 C 9.199219 17 7 14.800781 7 12 C 7 9.199219 9.199219 7 12 7 Z M 12 9.5 C 10.621094 9.5 9.5 10.621094 9.5 12 C 9.5 13.378906 10.621094 14.5 12 14.5 C 13.378906 14.5 14.5 13.378906 14.5 12 C 14.5 10.621094 13.378906 9.5 12 9.5 Z " />
    </svg>
  );
}

Show.defaultProps = {
  fill: 'currentColor',
  width: 24,
  height: 24,
};

export default Show;
