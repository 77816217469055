import { Button as Base } from 'components/web-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import styled from 'styled-components/macro';

const ButtonBase = styled(Base)`
  border-radius: 100px;
  background-color: #dddddd;
  color: black;
  border: none;
  padding: 1.5em 2em;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  :active,
  :focus {
    outline: none;
    box-shadow: none;
  }
  :active {
    opacity: 0.75;
  }
  :hover {
    box-shadow: none;
  }
`;

const Button = styled.button``.withComponent(
  ({ children, handleClick, icon, iconSize, link, openInNewPage, subText, text, ...props }) => (
    <ButtonBase {...props}>
      {!!icon && icon}
      {text}
      {children}
    </ButtonBase>
  ),
);

Button.propTypes = {
  // icon: PropTypes.function, // No idea what this should be to validate correctly
  disabled: PropTypes.bool,
  iconSize: PropTypes.number,
  link: PropTypes.string,
  onClick: PropTypes.func,
  openInNewPage: PropTypes.bool,
  subText: PropTypes.string,
  text: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  iconSize: 20,
  openInNewPage: false,
  type: 'submit',
};

const handlers = withHandlers({
  handleClick:
    ({ link, openInNewPage }) =>
    (event) => {
      if (!link) return; // e.g. it's a form submit button.
      if (openInNewPage) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    },
});

export default compose(handlers)(Button);
