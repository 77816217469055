import React from 'react';
import { branch, renderComponent } from 'recompose';
import { Redirect } from 'react-router-dom';
import routes from 'config/routes';

// When attempting to access an account, check to see if it's enabled. If it isn't, then redirect to
// the first account that is enabled.

export default branch(
  //
  ({ data, currentIdentity, currentAccount, match }) => {
    if (!data) return true;

    // Fetching current account identity
    if (data.loading) return false;

    // The URL does not have the :accountUsername param
    if (!match.params.accountUsername) return false;

    // Account does not exist
    if (!currentAccount || !currentIdentity) return true;

    // Account is not enabled (i.e. plan was downgraded)
    return !currentAccount.enabled;
  },
  renderComponent(({ accounts: identityAccounts, currentUser }) => {
    const user = currentUser ? currentUser : {};

    const accounts = Array.isArray(identityAccounts) ? identityAccounts : user.accounts;

    if (!accounts || accounts.length === 0) {
      return <Redirect push to={{ pathname: routes.logoutPath }} />;
    }

    const firstEnabledAccount = accounts.find((account) => account.enabled);
    if (!firstEnabledAccount) {
      return <Redirect push to={{ pathname: routes.logoutPath }} />;
    }

    return <Redirect push to={{ pathname: routes.account(firstEnabledAccount.username) }} />;
  }),
);
