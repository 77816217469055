import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import config from 'config';
import Authentication from 'lib/authentication';

const errorLink = onError(({ graphQLErrors, response, operation }) => {
  if (!graphQLErrors) return;

  switch (operation.operationName) {
    case 'createSessionChallenge':
    case 'createSessionResponse':
      response.data.error = graphQLErrors[0];
      response.errors = null;
      return;

    default:
      return;
  }
});

const httpLink = createHttpLink({
  uri: `${config.apiHost}/graphql`,
  // credentials: 'same-origin'
});

// Configure GraphQL to read a JWT from local storage and send
// it in the Authorization header of each request
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = Authentication.getToken();
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  });
  return forward(operation);
});

export const cache = new InMemoryCache({
  addTypename: true,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'InstagramFeedItem':
        return `InstagramFeedItem:${object.permalink}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});
const link = ApolloLink.from([authMiddleware, errorLink, httpLink]);

export default new ApolloClient({ cache, link });
