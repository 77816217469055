import React from 'react';
import { compose } from 'recompose';
import CallToAction from 'components/cards/stats/cards/shared/ctas/cta';
import withLoader from 'components/cards/stats/elements/loading-trends';

function Trends(props) {
  if (!props.data) return null;

  const { summary, unit, data, timeline } = props;
  const { edges } = data;
  const nodes = edges.map((edge) => edge.node);

  return nodes.map((node, i) => (
    <CallToAction key={i} unit={unit} summary={summary} timeline={timeline} {...node} />
  ));
}

export default compose(withLoader)(Trends);
