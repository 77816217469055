import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';

const Container = styled.div`
  color: white;
  font-size: 13px;
  margin-top: 5px;
  line-height: 1.3;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
`;

const Title = styled.span`
  color: white;
  font-weight: ${({ theme }) => theme.fonts.weights.semiBold};
`;

const PublishedAt = styled.span`
  color: rgba(255, 255, 255, 0.8);
  font-weight: ${({ theme }) => theme.fonts.weights.thin};
  margin-left: 8px;
`;

function VideoMeta(props) {
  const { video } = props;
  return (
    <Container key={video.id}>
      <Title children={video.title} />
      <PublishedAt children={moment.unix(video.publishedAt).format('MMMM D, YYYY')} />
    </Container>
  );
}

export default VideoMeta;
