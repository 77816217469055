import { graphql } from '@apollo/react-hoc';
import config from 'config';
import routes from 'config/routes';
import { updateAccountProfileMutation } from 'lib/graphql/mutations';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withProps } from 'recompose';
import { setSelectedPopover } from 'store/modules/card-popovers';
import { setSelectedBackgroundImage } from 'store/modules/card-select-background';

export default compose(
  withRouter,

  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setSelectedBackgroundImage: (image, file) => dispatch(setSelectedBackgroundImage(image, file)),
  })),

  withProps(({ currentAccount: { profile } }) => ({
    hasDefaultBackgroundImage: !!profile.defaultBackgroundMediaUrl,
    profile,
  })),

  graphql(updateAccountProfileMutation, {
    props: ({ mutate, ownProps }) => ({
      updateProfileBackgroundImage: async (url) => {
        await mutate({
          variables: {
            accountId: ownProps.currentAccount.id,
            accountProfileInputArguments: {
              defaultBackgroundMediaUrl: url,
            },
          },
        });
      },
    }),
  }),

  withHandlers({
    onImageSelected: (props) => (image, file) => {
      props.setSelectedBackgroundImage(image, file);
    },

    // When skipping adding a background image we'll add a default background image.
    // This helps us hide the 'upload an image' hint because we know they've dealt with it, even
    // though it wasn't to acutally upload an image.
    handleSkip: (props) => (event) => {
      event.preventDefault();
      // If background image is missing, sets default profile background image
      if (!props.backgroundMediaUrl) {
        props.updateProfileBackgroundImage(config.DEFAULT_BACKGROUND_MEDIA_URL);
      }
    },
  }),

  withHandlers({
    handleOnboardingLinkClick: (props) => (event) => {
      // Ensure a default background image exists before heading to the profile edit form
      props.handleSkip(event);
      props.history.push(routes.accountCardEditPath(props.currentAccount.username, props.card.id));
    },
  }),
);
