import React, { Component } from 'react';
import './instagram-unavailable.css';

export default class InstagramUnavailable extends Component {
  render() {
    return (
      <div className="instagram-unavailable">
        <h1>Uh Oh.</h1>
        <p>
          We were not able to load your Instagram photos.
          <br />
          Sometimes this is due to your Instagram account becoming disconnected. Perhaps you revoked
          access from withing Instagram?
          <br />
          Please tap "Save" and then tap the Tap Bio menu in the upper right corner. Tap Account
          Access and find the Fix button next to your Instagram name.
          <br />
          Thank you!
        </p>
      </div>
    );
  }
}
