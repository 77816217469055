import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  background-color: white;
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
`;

function isASingleVideo(username) {
  if (!username) return false;
  return username.indexOf('/watch') !== -1;
}

function NotConfigured(props) {
  return (
    <Container>
      This YouTube card is not correctly configured. Tap the cog above to update your settings.
      {isASingleVideo((props.card.youtube || {}).username) && (
        <span>
          <br />
          Is your video's visibility set to Public?
        </span>
      )}
    </Container>
  );
}

export default NotConfigured;
