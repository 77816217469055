import React from 'react';
import SocialAccount from 'components/cards/forms/profile/social-account';
import styled from 'styled-components/macro';

const SocialAccountsContainer = styled.div``;

function SocialAccounts(props) {
  const { ctas } = props;
  return (
    <SocialAccountsContainer>
      {ctas.map((cta) => (
        <SocialAccount key={cta.id} cta={cta} />
      ))}
    </SocialAccountsContainer>
  );
}

export default SocialAccounts;
