import TextInput from 'components/ui/base/text-input';
import { Button, Text } from 'components/web-ui';
import {
  convert8BitAlphaToHex,
  convertHexToRgbaObject,
  findActualColor,
  transferOpacity,
} from 'lib/color-utils';
import { readableColor } from 'polished';
import React from 'react';
import { Slide, Swiper } from 'react-dynamic-swiper';
import { compose, pure, withHandlers, withState } from 'recompose';
import styled from 'styled-components/macro';
import Field from './ui/field';

const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px 20px 14px;
  margin: 0 -14px;
`;

const HexInput = styled(TextInput)`
  width: 120px;
  text-align: center;
  margin: 0 15px 0 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${(p) => (p.value ? p.value : '#FAFAFA')};
  border-color: ${(p) => p.value};
  color: ${(p) => {
    /*
      Don't attempt to convert the colour if it's not in the correct format
      e.g. a user is typing in a new colour.
    */
    if (p.value[0] === '#' && [4, 7, 9].includes(p.value.length)) {
      return readableColor(findActualColor(p.value), 'currentColor', 'white');
    }
    return 'currentColor';
  }};

  ::placeholder {
    color: ${(props) => props.theme.colors.alphaBlack40};
  }

  :focus {
    border: 1px solid ${(props) => props.theme.colors.veryDarkPurple};
    outline: none;
    box-shadow: none;
  }
`;

const SwatchList = styled.div`
  padding: 5px 14px 0 14px;
  user-select: none;
  column-count: 6;
  column-gap: 10px;
`;

const Swatch = styled(Button)`
  border-radius: 6px;
  height: 50px;
  display: block;
  width: 100%;
  padding: 0;

  /* Using a relative unit like em breaks iOS safari */
  margin-bottom: 10px;

  :nth-child(even) {
    margin-bottom: 9px;
  }

  :hover,
  :focus,
  :active {
    box-shadow: none;
    border: 0;
  }

  ${(p) => p['data-selected'] && 'box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32) !important;'};
`;

const SwiperContainer = styled.div`
  margin-left: -14px;
  margin-right: -14px;

  .swiper-color-swatch-container .swiper-pagination {
    height: 20px;
    position: static;
    margin: 0 0 7px 0;
  }

  .swiper-color-swatch-container {
    position: relative;
  }

  .swiper-color-swatch-container > .swiper-pagination-bullets {
    bottom: 20px;
  }

  .swiper-color-swatch-container > .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-color: #331851;
  }
`;

const RangeInput = styled.input`
  width: 100%;

  /* --- Generated via http://danielstern.ca/range.css/#/ --- */

  appearance: none;
  margin: 7.3px 0;
  background-color: transparent;

  :focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 11.4px;
    cursor: pointer;
    box-shadow:
      1px 1px 1px rgba(0, 0, 0, 0),
      0px 0px 1px rgba(13, 13, 13, 0);
    background: #eeeeee;
    border-radius: 1.8px;
    border: 0px solid rgba(0, 0, 0, 0);
  }
  ::-webkit-slider-thumb {
    box-shadow:
      0px 0px 0px rgba(0, 0, 0, 0),
      0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid #00001e;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #331751;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.3px;
  }
  :focus::-webkit-slider-runnable-track {
    background: #f1f1f1;
  }
  ::-moz-range-track {
    width: 100%;
    height: 11.4px;
    cursor: pointer;
    box-shadow:
      1px 1px 1px rgba(0, 0, 0, 0),
      0px 0px 1px rgba(13, 13, 13, 0);
    background: #eeeeee;
    border-radius: 1.8px;
    border: 0px solid rgba(0, 0, 0, 0);
  }
  ::-moz-range-thumb {
    box-shadow:
      0px 0px 0px rgba(0, 0, 0, 0),
      0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid #00001e;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #331751;
    cursor: pointer;
  }
  ::-ms-track {
    width: 100%;
    height: 11.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  ::-ms-fill-lower {
    background: #ebebeb;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 3.6px;
    box-shadow:
      1px 1px 1px rgba(0, 0, 0, 0),
      0px 0px 1px rgba(13, 13, 13, 0);
  }
  ::-ms-fill-upper {
    background: #eeeeee;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 3.6px;
    box-shadow:
      1px 1px 1px rgba(0, 0, 0, 0),
      0px 0px 1px rgba(13, 13, 13, 0);
  }
  ::-ms-thumb {
    box-shadow:
      0px 0px 0px rgba(0, 0, 0, 0),
      0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid #00001e;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #331751;
    cursor: pointer;
    height: 11.4px;
  }
  :focus::-ms-fill-lower {
    background: #eeeeee;
  }
  :focus::-ms-fill-upper {
    background: #f1f1f1;
  }
`;

function getInitialSlide(swatches, selectedSwatch) {
  let initialSlide = 0;
  if (swatches.tapBioAlt.includes(selectedSwatch)) {
    initialSlide = 1;
  }
  if (swatches.greyscale.includes(selectedSwatch)) {
    initialSlide = 2;
  }
  return initialSlide;
}

const SwatchCompleteField = ({
  hint,
  expanded,
  swatches,
  selectedSwatch,
  setSelectedSwatch,
  handleOnChange,
  updateInputWithSwatchValue,
  updateInputWithOpacityValue,
  hidden,
  onSubmit,
  label,
  ...props
}) => (
  <Field hidden={hidden}>
    {hint && <Text children={hint} />}
    {expanded && (
      <SwiperContainer>
        <Swiper
          containerClassName="swiper-color-swatch-container"
          swiperOptions={{
            sliderCount: 4,
            initialSlide: getInitialSlide(swatches, selectedSwatch),
          }}
          navigation={false}
          pagination
        >
          <Slide>
            <SwatchList>
              {swatches.tapBioPrimary.map((swatch) => (
                <Swatch
                  key={`swatch-${swatch}`}
                  onClick={updateInputWithSwatchValue}
                  bg={swatch}
                  value={swatch}
                  data-selected={selectedSwatch === swatch}
                />
              ))}
            </SwatchList>
          </Slide>

          <Slide>
            <SwatchList>
              {swatches.tapBioAlt.map((swatch) => (
                <Swatch
                  key={`swatch-${swatch}`}
                  onClick={updateInputWithSwatchValue}
                  bg={swatch}
                  value={swatch}
                  data-selected={selectedSwatch === swatch}
                />
              ))}
            </SwatchList>
          </Slide>

          <Slide>
            <SwatchList>
              {swatches.greyscale.map((swatch) => (
                <Swatch
                  key={`swatch-${swatch}`}
                  onClick={updateInputWithSwatchValue}
                  bg={swatch}
                  value={swatch}
                  data-selected={selectedSwatch === swatch}
                />
              ))}
            </SwatchList>
          </Slide>
        </Swiper>
      </SwiperContainer>
    )}

    <Columns>
      <HexInput {...props} onChange={handleOnChange} />
      <RangeInput
        max={255}
        min={60}
        step={15}
        onChange={updateInputWithOpacityValue}
        type="range"
        value={convertHexToRgbaObject(props.value).alpha8Bit}
      />
    </Columns>
  </Field>
);

SwatchCompleteField.defaultProps = {
  expanded: true,
};

export default compose(
  withState('selectedSwatch', 'setSelectedSwatch', ({ value }) => value),
  withHandlers({
    handleOnChange: (props) => (event) => {
      const { setSelectedSwatch, name, onChange } = props;
      const { value } = event.target;

      setSelectedSwatch(value);
      onChange(name, value);
    },

    updateInputWithSwatchValue: (props) => (event) => {
      const { name, onChange, selectedSwatch, setSelectedSwatch } = props;
      const value = transferOpacity(selectedSwatch, event.target.value);

      event.preventDefault();
      setSelectedSwatch(value);
      onChange(name, value);
    },

    updateInputWithOpacityValue: (props) => (event) => {
      event.preventDefault();

      let value = null;

      // Determine the #RRGGBBAA value by taking the 0-255 value from the slider, converting
      // it to an alpha hex value e.g. D6 and replacing the original alpha hex value.
      const { name, onChange, setSelectedSwatch } = props;
      const hexAlpha = convert8BitAlphaToHex(event.target.value);

      // If the color hex value has 7 digits, append the alpha hex value
      // Otherwise swap it
      if (props.value.length === 7) {
        value = `${props.value}${hexAlpha}`;
      } else {
        value = `${props.value.slice(0, -2)}${hexAlpha}`;
      }

      setSelectedSwatch(value);
      onChange(name, value);
    },
  }),
  pure,
)(SwatchCompleteField);
