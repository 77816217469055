import React from 'react';
import styled from 'styled-components/macro';

const Layout = styled.div`
  position: absolute;
  left: 7px;
  bottom: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 5px;
  line-height: 16px;
  border-radius: 3px;
  color: black;
  font-size: 11px;
`;

function MediaType(props) {
  if (!props.type) return null;

  const type = props.type === 'CAROUSEL_ALBUM' ? 'gallery' : props.type;
  return <Layout>{type.toLowerCase()}</Layout>;
}

export default MediaType;
