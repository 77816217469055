import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './field.css';
import './label.css';

export default class Field extends Component {
  static propTypes = {
    autoCapitalize: PropTypes.string,
    autoCorrect: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    errors: PropTypes.string,
    hint: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    autoCapitalize: 'on',
    autoCorrect: 'on',
    placeholder: '',
    type: 'text',
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = { isFocused: false };
  }

  // === Start event handlers ===

  handleBlur = () => this.setState({ isFocused: false });
  handleClick = () => this.input.focus();
  handleFocus = (e) => {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    if (onFocus) onFocus(e);
  };

  // === End event handlers ===

  render() {
    const {
      autoCapitalize,
      autoCorrect,
      children,
      className,
      errors,
      hint,
      label,
      name,
      onChange,
      placeholder,
      type,
      value,
    } = this.props;
    const { isFocused } = this.state;
    return (
      <label
        className={classNames('field', 'label', className, {
          '-has-error': errors,
          '-is-focused': isFocused,
        })}
        htmlFor={name}
        onClick={this.handleClick}
      >
        <span className="label-text">
          {label} {errors}
        </span>
        {hint && <span className="hint">{hint}</span>}
        <input
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          name={name}
          onBlur={(event) => {
            // Webview bugfix for iOS 12
            // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
            // https://openradar.appspot.com/radar?id=5018321736957952
            window.scrollTo(0, 0);
            this.handleBlur && this.handleBlur(event);
          }}
          onChange={(event) => onChange(name, event.target.value)}
          onFocus={this.handleFocus}
          placeholder={placeholder}
          ref={(elem) => {
            this.input = elem;
          }}
          type={type}
          value={value}
        />
        {children}
      </label>
    );
  }
}
