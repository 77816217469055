import React from 'react';
import styled from 'styled-components/macro';
import isEmpty from 'lodash/isEmpty';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as Recompose from 'recompose';
import { graphql } from '@apollo/react-hoc';
import { Redirect, withRouter } from 'react-router-dom';
import withQueryParams from 'hocs/with-query-params';
import { addInstagramIdentityMutation } from 'lib/graphql/mutations';
import Spinner from 'components/ui/spinner';
import config from 'config';

const Button = styled.button`
  border-radius: 2em;
  font-size: 14px;
  width: 50%;
  height: 4em;
  cursor: pointer;
  padding: 0 10px 1px 10px;
  color: white;
  background-color: ${(p) => p.theme.colors.alert};
  border: 0;
  text-align: center;
`;

const ErrorMessage = styled.div`
  padding: 2em 1em;
`;

function AccessDeniedRedirect(props) {
  return <Redirect to={props.redirectTo} />;
}

function SuccessRedirect(props) {
  return <Redirect to={props.redirectTo} />;
}

SuccessRedirect.propTypes = {
  redirectTo: PropTypes.string.isRequired,
};

function OAuthException(props) {
  const errorMessage = props.errors.map((err) => err.message).join('');
  Sentry.captureMessage('[Instagram OAuth] Error', {
    extra: {
      code: props.code,
      errorMessage: errorMessage,
      account: props.currentAccount,
      redirectTo: props.redirectTo,
    },
    level: 'info',
  });
  return (
    <div>
      <div style={{ color: 'white', margin: '60px 10px 0 10px', textAlign: 'center' }}>
        There was an error connecting your Instagram account.
        <ErrorMessage dangerouslySetInnerHTML={{ __html: errorMessage }} />
        <Button onClick={() => props.history.push(props.redirectTo)} children="Try Again" />
      </div>
    </div>
  );
}

const withAddInstagramIdentity = Recompose.lifecycle({
  componentDidMount() {
    this.props.addInstagramIdentity(this.props);
  },
});

export default Recompose.compose(
  Recompose.withState('success', 'setSuccess', false),
  Recompose.withState('errors', 'setErrors', {}),
  withRouter,
  withQueryParams,
  Recompose.withProps((props) => {
    const { currentAccount, redirectTo } = JSON.parse(localStorage.getItem(config.IG_AUTH_LS_KEY));
    return { currentAccount, redirectTo };
  }),

  // Exit early if the user cancelled their connection to Instagram
  Recompose.branch(
    ({ error }) => error === 'access_denied',
    Recompose.renderComponent(AccessDeniedRedirect),
  ),

  graphql(addInstagramIdentityMutation, {
    props: ({ mutate, ownProps }) => ({
      addInstagramIdentity: ({ currentAccount, code }) => {
        const accountId = parseInt(currentAccount.id, 10);
        const username = currentAccount.username;
        if (!code) {
          Sentry.captureMessage('[Instagram OAuth] Code is empty or undefined', {
            extra: { currentAccount, code, ownProps },
            level: 'info',
          });
        }
        if (!accountId || !username) {
          Sentry.captureMessage('[Instagram Oauth] Missing account id / username', {
            extra: { currentAccount, code, ownProps },
            level: 'info',
          });
        }
        return mutate({
          variables: { accountId, username, code },
          update: (proxy, { data: { addInstagramIdentity } }) => {
            const errors = addInstagramIdentity.errors && JSON.parse(addInstagramIdentity.errors);
            if (errors) return ownProps.setErrors(errors);

            ownProps.setSuccess(true);
          },
        }).catch((res) => {
          const errors = res.graphQLErrors;
          if (Array.isArray(errors) && errors.length > 0) {
            Sentry.captureMessage('[Instagram Oauth] Unable to add instagram identity', {
              extra: { errors },
              level: 'info',
            });
            ownProps.setErrors(errors);
            ownProps.setSuccess(false);
          }
        });
      },
    }),
  }),
  Recompose.branch((props) => props.loading, Recompose.renderComponent(Spinner)),
  Recompose.branch((props) => props.success, Recompose.renderComponent(SuccessRedirect)),
  Recompose.branch(
    ({ errors }) => errors.error_type === 'OAuthException',
    Recompose.renderComponent(OAuthException),
  ),
  Recompose.branch(({ errors }) => !isEmpty(errors), Recompose.renderComponent(OAuthException)),
  withAddInstagramIdentity,
)((props) => {
  return (
    <div>
      <p style={{ color: 'white', marginTop: '100px', textAlign: 'center' }}>
        Please wait while we connect your account.
      </p>
      <Spinner />;
    </div>
  );
});
