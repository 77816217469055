import routes from 'config/routes';
import * as Recompose from 'lib/recompose';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideStats } from 'store/modules/stats';
import * as Styled from './styled';

function Button(props) {
  const { handleUnset, ...rest } = props;
  return (
    <div onClick={handleUnset}>
      <Styled.HeaderButtonPill {...rest} />
    </div>
  );
}

export default Recompose.compose(
  Recompose.branch((props) => !props.isVisible, Recompose.renderNothing),
  // Don't render the edit button if the card is a replica. Replica's derive their data from
  // the replicated card and thus cannot be edited, only hidden / moved etc.
  Recompose.branch(({ card }) => card.isReplica && card.editable, Recompose.renderNothing),
  connect(null, (dispatch) => ({ hideStats: () => dispatch(hideStats()) })),
  Recompose.withProps((props) => {
    const { username } = props.currentAccount;
    const to = routes.accountCardEditPath(username, props.card.id);
    const onClick = props.hideStats;

    return {
      onClick,
      to,
      children: 'Edit card',
      as: Link,
    };
  }),
  Recompose.omitProps('isVisible', 'currentAccount', 'card', 'hideStats'),
  Recompose.pure,
)(Button);
