import React from 'react';
import { branch, renderComponent } from 'recompose';
import { Redirect } from 'react-router-dom';
import routes from 'config/routes';

const propCheckError = (propName) => {
  return new Error(
    `cannot check authorization without ${propName}, please include withAccounts/withIdentityAccounts before this function`,
  );
};

const checkAuthorization = ({ currentUser, currentIdentity, match, ...props }) => {
  if (!currentUser && !currentIdentity) throw propCheckError('current user');
  if (!match) throw propCheckError('routerParams');

  return !!props.currentAccount;
};

export default branch(
  (props) => {
    if (!props.currentAccount) return true;
    if (!props.currentUser && !props.currentIdentity) throw propCheckError('current user');
    if (!props.match) throw propCheckError('routerParams');

    return !checkAuthorization(props);
  },

  renderComponent(() => <Redirect push to={{ pathname: routes.loginPath }} />),
);
