import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

export const query = gql`
  query GetMailChimpLists(
    $username: String
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    mailchimpLists(
      accountUsername: $username
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        cursor
        node {
          id
          name
          url
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export default graphql(query, {
  options: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  props: ({ ownProps, data: withMailchimpLists }) => {
    return { withMailchimpLists };
  },
});
