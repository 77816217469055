import { graphql } from '@apollo/react-hoc';
import { publicInstagramFeedWithCardIdQuery } from 'lib/graphql/queries';

export const withInstagramFeed = graphql(publicInstagramFeedWithCardIdQuery, {
  skip: ({ card }) => !card,
  options: (props) => {
    const cardId = props.card.id;
    const nextMaxId = props.feed.nextMaxId;
    return {
      fetchPolicy: 'network-only',
      variables: { cardId, nextMaxId },
    };
  },
  props: ({
    data: { publicInstagramFeedWithCardId, loading, fetchMore, ...request },
    ownProps,
  }) => ({
    feed: {
      ...ownProps.feed,
      ...publicInstagramFeedWithCardId,
      errorType: request.error ? request.error.graphQLErrors[0].type : null,
    },
    loading,
    loadMoreEntries() {
      if (typeof fetchMore !== 'function') return;

      return fetchMore({
        variables: {
          nextMaxId: publicInstagramFeedWithCardId.nextMaxId,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          return {
            request,
            ...previousResult,
            ...fetchMoreResult,
            publicInstagramFeedWithCardId: {
              ...previousResult.publicInstagramFeedWithCardId,
              ...fetchMoreResult.publicInstagramFeedWithCardId,
              photos: [
                ...previousResult.publicInstagramFeedWithCardId.photos,
                ...fetchMoreResult.publicInstagramFeedWithCardId.photos,
              ],
            },
          };
        },
      }).catch(function (error) {
        // Usually we're catching Invariant Violation: Invariant Violation: 15
        // (see https://github.com/apollographql/invariant-packages) which is
        // caused by the unmounting of this component while the request is in
        // flight. Unfortunately apollo-client doesn't offer any practical
        // solutions for this so we're swallowing this particular error.
        if (error.name !== 'Invariant Violation') {
          throw error;
        }
      });
    },
    request,
  }),
});
