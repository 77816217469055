import { compose, withProps } from 'recompose';

const withGraphData = withProps(({ events, timeline }) => {
  const { edges } = events;
  const nodes = edges.map((edge) => edge.node);
  const counters = nodes.reduce(
    (o, node) => ({
      ...o,
      [node.datetime]: node.total,
    }),
    {},
  );
  const graph = timeline.map((datetime) => ({
    datetime,
    total: counters[datetime] || 0,
  }));
  return { graph };
});

const enhance = (component) => compose(withGraphData)(component);

export default enhance;
