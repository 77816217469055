import React from 'react';
import styled from 'styled-components/macro';
import { omitProps } from 'lib/recompose';
import { Heading } from 'components/web-ui';
import {
  BaseCSS,
  DescriptionCSS,
  DescriptionSmallCSS,
  TitleCSS,
  TitleSmallCSS,
} from 'components/cards/shared/styles/fields';

const BaseHeading = omitProps('isVisible')(Heading);

export const Title = styled(BaseHeading)`
  ${BaseCSS};
  ${TitleCSS};
`;

export const Description = styled(BaseHeading)`
  ${BaseCSS};
  ${DescriptionCSS};
`;

export const TitleSmall = styled(BaseHeading)`
  ${BaseCSS};
  ${TitleSmallCSS};
`;

export const DescriptionSmall = styled(BaseHeading)`
  ${BaseCSS};
  ${DescriptionSmallCSS};
`;

export const CtaButtonContainerFixed = styled.div`
  bottom: 10px;
  left: 10px;
  position: absolute;
  right: 10px;
  z-index: 100;
`;

// --- Components ---

// Sometimes we need to add extra space between the title / description and the content below it
// Whether we show this space depends on the presence / absence of the title / description
export function ContentSpacer({ title, description, children }) {
  let paddingBottom = '0';
  if (title && !description) paddingBottom = '16px';
  return <div style={{ paddingBottom }} children={children} />;
}
