import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import cardCallToAction from 'store/modules/card-call-to-action';
import cardImage from 'store/modules/card-image';
import cardPopovers from 'store/modules/card-popovers';
import cardUploadBackground from 'store/modules/card-upload-background';
import cardSelectedBackground from 'store/modules/card-select-background';
import cardButtonColor from 'store/modules/card-update-button-color';
import cardContentOverlay from 'store/modules/card-content-overlay';
import toast from 'store/modules/toast';
import moreMenu from 'store/modules/more-menu';
import onboarding from 'store/modules/onboarding';
import stats from 'store/modules/stats';
import deckViewstate from 'store/modules/deck-viewstate';
import * as actions from './actions';
import * as reducers from './reducers';

const rootReducer = combineReducers({
  ...reducers,
  cardCallToAction,
  cardPopovers,
  cardUploadBackground,
  cardSelectedBackground,
  cardImage,
  cardButtonColor,
  cardContentOverlay,
  toast,
  moreMenu,
  onboarding,
  stats,
  deckViewstate,
});

const middleware = applyMiddleware();
const store = createStore(rootReducer, composeWithDevTools(middleware));

export { actions };
export default store;
