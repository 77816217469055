import classNames from 'classnames';
import React from 'react';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import withAutoLoad from '../hocs/with-autoload';
import withHandleForceRefreshPhotos from '../hocs/with-handle-force-refresh-photos';
import withHandleLoadMorePhotos from '../hocs/with-handle-load-more-photos';
import withLoadPhotos from '../hocs/with-load-photos';
import withLoadPhotosUrl from '../hocs/with-load-photos-url';
import withPhotoCount from '../hocs/with-photo-count';
import withUpdatePhotos from '../hocs/with-update-photos';
import InstagramUnavailable from '../instagram-unavailable';
import PhotoCard from '../photo-card';
import Spinner from '../spinner';
import './photo-cards.css';

const PhotoCards = ({
  activePanels,
  cardId,
  displayCount,
  globallyActivePanel,
  handleChangeGloballyActivePanel,
  handleChangePanel,
  handleLoadMorePhotos,
  hasPhotosWithLinks,
  instagramUnavailable,
  photoCount,
  requestManager,
  requestManager: { autoloadComplete, morePhotosToLoad, isLoadingPhotos },
  roundToNearestHundred,
  shouldDisplayCard,
  updatePhotos,
  photos,
  url,
  user,
  ...props
}) => (
  <div className="photo-cards">
    <div className="header-container">
      <button
        title="This button will refresh your photos from Instagram's API. Use is sparingly as Instagram allows only 200 requests per hour. Repeated use of this button could have unexpected results."
        onClick={props.handleForceRefresh}
        className="force-refresh-button"
      >
        Force Refresh{' '}
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 32 32"
        >
          <path
            d="M4 10h20v6l8-8-8-8v6h-24v12h4zM28 22h-20v-6l-8 8 8 8v-6h24v-12h-4z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
    <div className={classNames('photo-cards-container', { '-is-loading': isLoadingPhotos })}>
      {photos
        .filter((photo, index) => shouldDisplayCard(photo, index))
        .slice(0, displayCount.count)
        .map((photo) => (
          <PhotoCard
            activePanel={activePanels[photo.instagram_photo.id] || globallyActivePanel}
            cardId={cardId}
            instagramPhoto={photo.instagram_photo}
            key={photo.instagram_photo.id}
            lipPhoto={photo.lip_photo}
            onChangePanel={handleChangePanel.bind(this, photo)}
            photoMeta={photo.meta}
            updatePhotoState={updatePhotos}
            url={url(photo)}
            user={user}
          />
        ))}

      {isLoadingPhotos && <Spinner className="-is-photo-spinner" />}
      {instagramUnavailable && <InstagramUnavailable />}
    </div>
  </div>
);

const urlProps = withProps(({ user }) => ({
  url: (photo) =>
    (photo.lip_photo || {}).url || photo.instagram_photo.url || (user.default_link || {}).url || '',
}));

const getActivePanelProps = withProps(({ activePanels }) => ({
  getActivePanel(index) {
    return activePanels[index] || 'stats';
  },
}));

const helperProps = withProps(({ activePanels, url, globallyActivePanel }) => ({
  roundToNearestHundred(value) {
    if (value < 100) return 100;
    return Math.round(value / 100) * 100;
  },
  // Always display the card if we're editing
  // Only display the card in stats view if there's a URL
  shouldDisplayCard: (photo) => {
    if (globallyActivePanel === 'edit') return true;
    const activePanel = activePanels[photo.instagram_photo.id] || 'stats';
    if (activePanel === 'edit') return true;
    return !!(activePanel === 'stats' && url(photo));
  },
}));

const hasPhotoWithLinksProps = withProps(({ photos, url }) => ({
  hasPhotosWithLinks: photos.some((photo) => url(photo)),
}));

const handlers = withHandlers({
  // Set the array element for this specific panel. If this is the first time then
  // x null elements will be created before the target element.
  handleChangePanel:
    ({ setActivePanels }) =>
    (photo, newValue) => {
      setActivePanels((activePanels) => {
        const newActivePanels = { ...activePanels };
        newActivePanels[photo.instagram_photo.id] = newValue;
        return newActivePanels;
      });
    },
});

export default compose(
  withState('activePanels', 'setActivePanels', {}),
  withState('instagramUnavailable', 'setInstagramUnavailable', false),
  withState('photos', 'setPhotos', []),
  withState('requestManager', 'setRequestManager', {
    autoloadComplete: false,
    instagramRequestCount: 0,
    isLoadingPhotos: true,
    maxRequestCount: 15,
    morePhotosToLoad: true,
    nextMaxId: null,
    photoBatch: [],
  }),
  handlers,
  withLoadPhotosUrl,
  withLoadPhotos,
  withPhotoCount,
  withUpdatePhotos,
  withHandleLoadMorePhotos, // Handler for clicking the 'Load more...' button
  withHandleForceRefreshPhotos, // Handler for clicking 'Force refresh' button
  urlProps,
  getActivePanelProps,
  helperProps,
  hasPhotoWithLinksProps,
  // Use autoload to load in 13 pages at 15 per page = 195 photos
  // This is so that the (default) stats view always looks though a reasonable number of photos.
  // e.g. if there's only 1 linked photo in 10 we should display approx 16 photos.
  withAutoLoad,
  // We could do this in withPropsOnChange (and I did originally) but if you update state you'll
  // get a warning something like:
  //
  //   Warning: setState(...): Cannot update during an existing state transition (such as within
  //   `render` or another component's constructor). Render methods should be a pure function of
  //   props and state; constructor side-effects are an anti-pattern, but can be moved to
  //   `componentWillMount`
  //
  // Whilst using lifecycle methods is considered an 'escape hatch' I'm not sure what other approach
  // we could take here. We cannot update state until after a render is complete and
  // `componentDidUpdate` is the correct lifecycle method for this.
  lifecycle({
    // Kick off the loading of photos
    async componentWillMount() {
      await this.props.loadPhotos();
    },

    async componentDidUpdate(prevProps) {
      const {
        globallyActivePanel,
        handleAddFirstLink,
        hasPhotosWithLinks,
        loadPhotos,
        photos,
        requestManager: { maxRequestCount, isLoadingPhotos },
        setActivePanels,
      } = this.props;

      // If we have a change in the number of max requests, kick off loading again but ONLY
      // if we're not currently loading.
      if (maxRequestCount !== prevProps.requestManager.maxRequestCount && !isLoadingPhotos) {
        await loadPhotos();
      }

      if (globallyActivePanel !== prevProps.globallyActivePanel) {
        // Iterate all photos and create an array of their new view statuses
        // All values will be null when queried so we'll use the default
        if (photos.length) {
          const newActivePanels = {};
          photos.forEach(({ instagram_photo }) => {
            newActivePanels[instagram_photo.id] = globallyActivePanel;
          });
          setActivePanels(newActivePanels);
          if (!hasPhotosWithLinks) handleAddFirstLink();
        } else {
          setActivePanels({});
        }
      }
    },
  }),
)(PhotoCards);
