import React from 'react';
import Field from 'components/ui/field';
import Input from 'components/ui/form-text-input';
import Button from 'components/ui/form-button';
import Hint from 'components/accounts/change-username/hint';
import { Layout, Main } from 'components/accounts/styled';
import styled from 'styled-components/macro';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

const Form = styled.form`
  padding: 20px;
`;

function UpdateAccountUsername(props) {
  return (
    <Layout>
      <ViewHeader>
        <ViewHeader.LeftButton onClick={props.onClickBack} />
        <ViewHeader.Title children="Change Username" />
        <ViewHeader.RightButton children="" />
      </ViewHeader>
      <Main>
        <Form>
          <Hint oldUsername={props.currentAccount.username} newUsername={props.username} />
          <Field
            autoCapitalize="off"
            autoCorrect="off"
            name="username"
            styledInput={Input}
            placeholder="New account name"
            onChange={props.onFieldChange}
            value={props.username}
            validationError={props.errors.username}
          />
          <Button
            text="Next"
            disabled={props.errors.any || props.loading}
            onClick={props.onSubmit}
          />
        </Form>
      </Main>
    </Layout>
  );
}

export default UpdateAccountUsername;
