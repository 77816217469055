import React from 'react';
import { omitProps } from 'lib/recompose';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Input as DefaultInput, Label, Text } from 'components/web-ui';

const selectAllOnFocusHandler = (event) => {
  // Exit early if the field type is email to avoid:
  //
  //   InvalidStateError: Failed to execute 'setSelectionRange' on 'HTMLInputElement': The input
  //   element's type ('email') does not support selection.
  if (event.target.type === 'email') return;

  // Handle if setSelectionRange doesn't exist, which seems to be the case sometimes.
  if (!event.target.setSelectionRange) return;

  event.persist();
  setTimeout(() => event.target.setSelectionRange(0, 9999), 0);
};

const DefaultStyledField = styled(Label)`
  position: relative;
  width: 100%;
  margin: 15px 0;
  flex-direction: column;
  align-items: stretch;
`;

export const HintText = styled(Text)`
  font-size: 13px;
  opacity: 0.4;
`;

const Error = styled.span`
  color: ${(props) => (props.light ? '#FFE87F' : props.theme.colors.errorRed)};
  font-size: 13px;
  line-height: 1.2;
  margin-bottom: 5px;
`.withComponent(omitProps('light')(Text));

const Field = (props) => {
  const {
    autoCapitalize,
    autoCorrect,
    autoFocus,
    checked,
    disabled,
    handleBlur,
    hint,
    label,
    light,
    name,
    onChange,
    onKeyUp,
    onFocus,
    placeholder,
    selectAllOnFocus,
    styledField: StyledField,
    styledHint: StyledHint,
    styledInput: StyledInput,
    styledLabel: StyledLabel,
    type,
    validationError,
    value,
  } = props;

  return (
    <StyledField htmlFor={name}>
      {label ? (
        <StyledLabel>
          {label}
          {validationError}
        </StyledLabel>
      ) : (
        validationError && <Error light={light}>{validationError}</Error>
      )}
      <StyledInput
        key={name}
        autoCapitalize={autoCapitalize}
        autoCorrect={autoCorrect}
        autoFocus={autoFocus}
        name={name}
        onBlur={(event) => {
          // Webview bugfix for iOS 12
          // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
          // https://openradar.appspot.com/radar?id=5018321736957952
          window.scrollTo(0, 0);
          handleBlur && handleBlur(event);
        }}
        onChange={(event) => {
          const target = event.target;
          const value = target.type === 'checkbox' ? target.checked : target.value;
          onChange(name, value);
        }}
        onKeyUp={onKeyUp}
        onFocus={selectAllOnFocus ? selectAllOnFocusHandler : onFocus}
        placeholder={placeholder}
        type={type}
        value={type === 'checkbox' ? undefined : value || ''}
        checked={type === 'checkbox' ? checked : undefined}
        hasErrors={!!validationError}
        disabled={disabled}
      />
      {hint && <StyledHint children={hint} key={`hint_${name}`} />}
    </StyledField>
  );
};

Field.propTypes = {
  autoCapitalize: PropTypes.string,
  autoCorrect: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

Field.defaultProps = {
  autoCapitalize: 'on',
  autoCorrect: 'on',
  disabled: false,
  placeholder: '',
  styledField: DefaultStyledField,
  styledHint: HintText,
  styledInput: omitProps('hasErrors')(DefaultInput),
  styledLabel: Text,
  type: 'text',
  value: '',
  onKeyUp: () => null,
};

export default Field;
