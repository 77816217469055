import React from 'react';
import styled from 'styled-components/macro';

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  font-family: 'SF Mono', monospace;
`;

const StyledBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.125rem;
  border-bottom: 2px solid currentColor;
  font-size: 60px;
`;

const Placeholder = styled.div`
  @keyframes blinkingText {
    0% {
      color: currentColor;
    }
    49% {
      color: currentColor;
    }
    60% {
      color: transparent;
    }
    99% {
      color: transparent;
    }
    100% {
      color: currentColor;
    }
  }

  &::before {
    vertical-align: middle;
    line-height: 1.5;
    font-size: ${(p) => (p.focused ? 2 : 1)}rem;
    content: '${(p) => (p.focused ? '|' : '•')}';
    opacity: ${(p) => (p.focused ? 0.75 : 0.5)};
    font-weight: ${(p) => (p.focused ? 300 : 600)};
    animation: ${(p) => (p.focused ? 'blinkingText 1.2s infinite' : 'none')};
  }
`;

function Box(props) {
  const { code, focused } = props;
  const content = code ? <div>{code}</div> : <Placeholder focused={focused} />;
  return <StyledBox>{content}</StyledBox>;
}

function Output(props) {
  const { code, hasFocus, onClick } = props;
  return (
    <Layout onClick={onClick}>
      <Box focused={hasFocus && code.length === 0} code={code[0]} />
      <Box focused={hasFocus && code.length === 1} code={code[1]} />
      <Box focused={hasFocus && code.length === 2} code={code[2]} />
      <Box focused={hasFocus && code.length === 3} code={code[3]} />
      <Box focused={hasFocus && code.length === 4} code={code[4]} />
      <Box focused={hasFocus && code.length === 5} code={code[5]} />
    </Layout>
  );
}

export default Output;
