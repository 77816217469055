import styled from 'styled-components/macro';
import SelectInput from 'components/ui/base/select-input';

const Layout = styled.div`
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;

const Header = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 20px;
  margin: -20px;
`;

const Main = styled.div`
  padding: ${20 / 16}rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Button = styled.a`
  display: block;
  text-align: right;

  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
`;

const Select = styled(SelectInput)`
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
`;

export { Layout, Header, Main, Button, Select };
