import React from 'react';
import Spinner from 'components/ui/spinner';
import styled from 'styled-components/macro';
import { branch, renderComponent } from 'recompose';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Loading = () => (
  <Container>
    <Spinner fill="rgba(0, 0, 0, 0.3)" />
  </Container>
);

export default branch((props) => props.loading, renderComponent(Loading));
