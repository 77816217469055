import styled from 'styled-components/macro';

const Description = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey};
  margin-top: 5px;
  margin-bottom: 15px;
`;

export default Description;
