import { graphql } from '@apollo/react-hoc';
import DeleteButton from 'components/accounts/access/delete-button';
import FixInstagramButton from 'components/accounts/access/fix-instagram-button';
import VerifyButton from 'components/accounts/access/verify-button';
import ConstantContactIcon from 'components/icons/constantcontact';
import EnvelopeIcon from 'components/icons/envelope';
import InstagramIcon from 'components/icons/instagram';
import MailChimpIcon from 'components/icons/mailchimp';
import MobileDeviceIcon from 'components/icons/mobile-device';
import { withList } from 'components/ui/base/list';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import { removeAccountIdentityAssociationMutation } from 'lib/graphql/mutations';
import { compose, withState } from 'lib/recompose';
import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';

const Icon = (props) =>
  ({
    instagram: <InstagramIcon {...props} />,
    mailchimp: <MailChimpIcon {...props} />,
    constantcontact: <ConstantContactIcon {...props} />,
    email: <EnvelopeIcon {...props} />,
    sms: <MobileDeviceIcon {...props} />,
  })[props.name];

const IdentityIcon = styled(Icon)`
  min-width: 1.25em;
  margin-right: 10px;
  color: black;
`;

const Layout = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
`;

const Controls = styled.div`
  flex: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 30px;
`;

const DeleteButtonContainer = styled.div`
  flex: 0 0 30px;
  margin-left: 4px;
  height: 30px;
`;

const Username = styled.span`
  word-break: break-all;
`;

function IdentityListItem(props) {
  const current = props.currentIdentity.id === props.identity.id;
  const verified = props.identity.tokenIsValid;
  const username = {
    email: props.identity.username,
    sms: '+' + props.identity.username,
    instagram: props.identity.username,
    mailchimp: props.identity.username,
    constantcontact: props.identity.username,
  }[props.identity.provider];

  return (
    <Layout>
      <Content>
        <IdentityIcon name={props.identity.provider} />
        <Username>{username}</Username>
      </Content>
      <Controls>
        <VerifyButton isVerified={verified} identity={props.identity} />
        <FixInstagramButton identity={props.identity} currentAccount={props.currentAccount} />
        <DeleteButtonContainer>
          <DeleteButton isCurrent={current} onClick={() => props.onDelete(props.identity.id)} />
        </DeleteButtonContainer>
      </Controls>
    </Layout>
  );
}

export default compose(
  withState('needsRefresh', 'setNeedsRefresh', false),
  withRouter,
  withIdentityAccounts,
  withList((props) => ({
    iterator: props.currentAccount.identities,
    propName: 'identity',
    key: 'id',
  })),

  graphql(removeAccountIdentityAssociationMutation, {
    props: ({ mutate, ownProps }) => ({
      onDelete: (identityId) => {
        const accountId = parseInt(ownProps.currentAccount.id, 10);
        return mutate({
          variables: { accountId, identityId },
          update: (proxy, { data: { removeIdentityAssociation } }) => {
            const errors = removeIdentityAssociation.errors;
            if (errors) return console.error(errors);
            ownProps.data.refetch();
          },
        });
      },
    }),
  }),
)(IdentityListItem);
