import React from 'react';
import styled from 'styled-components/macro';
import Button from 'components/ui/base/button';
import Spinner from 'components/ui/spinner';
import { InputCSS } from 'components/cards/shared/styles/fields';

const Container = styled.div`
  bottom: 0;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const EmailInput = styled.input`
  ${InputCSS};
  margin-bottom: 10px;
  width: 100%;
  padding: 17px;
  border: 1.5px solid ${(p) => (p.valid ? '#ffffff' : p.theme.colors.errorRed)};
  border-radius: 99999px;
  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.alphaWhite20} 0%,
    ${(p) => p.theme.colors.alphaWhite30} 100%
  );
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  text-shadow: none;
  outline: none;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};

  ::placeholder {
    color: ${(p) => p.theme.colors.white};
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SubmitButton = styled.button`
  background-color: ${(p) => p.color};
  color: ${(p) => p.theme.colors.white};
  opacity: ${(p) => (p.disabled ? 0.7 : 1.0)};
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 2em;
  min-height: 58px;
  line-height: 1.35;
  font-size: 17px;
  font-weight: normal;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  text-indent: ${(p) => (p.submitting ? '-9999px' : 0)};
`.withComponent((props) => (
  <Button {...props} disabled={props.disabled || props.submitting}>
    {props.children}
    {props.submitting && (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )}
  </Button>
));

export { Container, EmailInput, SubmitButton };
