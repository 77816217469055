import React from 'react';
import config from 'config';
import update from 'immutability-helper';
import sortBy from 'lodash/sortBy';
import { SignedUploader, UploadField } from '@navjobs/upload';
import { withResize, readImageWithOrientation, applyOrientation } from 'lib/image-utils';
import AddCallToActionButton from 'components/cards/forms/add-call-to-action-button';
import CallToActionButtons from 'components/cards/forms/call-to-action-buttons';
import Scrollable from 'components/cards/preview/scrollable';
import * as Fields from 'components/cards/shared/styles/fields';
import * as Styled from './styled';
import SortableImageGalleryPhotos from './sortable-image-gallery-photos';

function SimpleCardForm(props) {
  const {
    buttonColor,
    card,
    currentAccount,
    formState,
    handleAfterRequest,
    handleBeforeRequest,
    handleDeleteCta,
    handleRequest,
    handleShowCtaForm,
    loadingImageUrls,
    renderField,
    setFormState,
    setLoadingImageUrls,
  } = props;

  return (
    <React.Fragment>
      <Styled.StyleSheet />
      <Scrollable
        cardId={card.id}
        id="image-gallery-form-scroll-container"
        verticalAlign="top"
        xPadding="5px"
        topPadding="68px"
      >
        {renderField({
          name: 'result.title',
          placeholder: 'Tap to add headline',
          selectAllOnFocus: true,
          styledInput: Fields.TitleSmallTextarea,
          styledField: Fields.StyledField,
        })}
        {renderField({
          name: 'result.description',
          placeholder: 'Tap to add body',
          selectAllOnFocus: true,
          styledInput: Fields.DescriptionSmallTextarea,
          styledField: Fields.StyledField,
        })}
        <SortableImageGalleryPhotos
          currentAccount={currentAccount}
          formState={formState}
          images={sortBy(card.images, 'position')}
          loadingImageUrls={loadingImageUrls}
          setFormState={setFormState}
        />
      </Scrollable>

      <Styled.AddButtons hasCta={!!card.ctas.length}>
        <Styled.AddImageButtonContainer>
          <SignedUploader
            afterRequest={handleAfterRequest}
            beforeRequest={handleBeforeRequest}
            request={handleRequest}
            uploadOnSelection={true}
          >
            {(uploadProps) => (
              <UploadField
                uploadProps={{
                  multiple: true,
                  accept: config.ALLOWED_IMAGE_EXTENSIONS,
                }}
                onFiles={(files) => {
                  Array.from(files).forEach((file) => {
                    readImageWithOrientation(file)
                      .then(applyOrientation)
                      .then(({ img }) => {
                        setLoadingImageUrls((state) => update(state, { $push: [img] }));
                      });
                  });
                  withResize(uploadProps.onFiles)(files);
                }}
              >
                <AddCallToActionButton buttonText="Add photos" isButton={false} />
              </UploadField>
            )}
          </SignedUploader>
        </Styled.AddImageButtonContainer>

        {card.ctas.length > 0 ? (
          <Styled.CallToActionButtonsContainer>
            <CallToActionButtons
              brandColor={buttonColor || currentAccount.profile.brandColor}
              ctas={card.ctas}
              onClick={handleShowCtaForm}
              handleDeleteCta={handleDeleteCta}
            />
          </Styled.CallToActionButtonsContainer>
        ) : (
          <Styled.AddCallToActionButtonContainer>
            <AddCallToActionButton onClick={handleShowCtaForm.bind(this, null)} />
          </Styled.AddCallToActionButtonContainer>
        )}
      </Styled.AddButtons>
    </React.Fragment>
  );
}

export default SimpleCardForm;
