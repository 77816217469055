import React from 'react';
import styled from 'styled-components/macro';
import { compose, defaultProps } from 'recompose';
import { providers, defaultProvider } from './providers';

const Container = styled.div`
  display: flex;
  padding-top: 10px;
`;

const Option = styled.div`
  flex: 1;
  & + & {
    margin-left: 10px;
  }
`;

const ProviderItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  border-radius: 5px;
  ${(props) => props.color && `background-color: ${props.color}`};
  ${(props) => props.gradient && `background-image: ${props.gradient}`};
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 1 : 0.45)};
  :after {
    ${(props) =>
      props.selected &&
      `
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      content: '';
      border: 3px solid ${props.theme.colors.primaryPurple};
      border-radius: 8px;
    `};
  }
`;

const ProviderName = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => (props.selected ? props.theme.colors.black : props.theme.colors.grey)};
`;

function ProviderSelector(props) {
  const { provider, onChange } = props;
  return (
    <Container>
      {providers.map(({ id, name, logo, color, gradient }) => (
        <Option key={id} onClick={() => onChange(id)}>
          <ProviderItem color={color} gradient={gradient} selected={id === provider}>
            {logo}
          </ProviderItem>
          <ProviderName selected={id === provider}>{name}</ProviderName>
        </Option>
      ))}
    </Container>
  );
}

export default compose(
  defaultProps({
    provider: defaultProvider,
    onChange: (provider) => null,
  }),
)(ProviderSelector);
