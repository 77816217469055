import React from 'react';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

function Header(props) {
  const { history } = props;
  return (
    <ViewHeader>
      <ViewHeader.LeftButton onClick={history.goBack} />
      <ViewHeader.Title children={props.title || 'Choose Plan'} />
      <ViewHeader.RightButton children="" />
    </ViewHeader>
  );
}

export default Header;
