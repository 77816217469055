import AddCallToActionButton from 'components/cards/forms/add-call-to-action-button';
import SortableCallToActionButtons from 'components/cards/forms/sortable-call-to-action-buttons';
import Scrollable from 'components/cards/preview/scrollable';
import {
  DescriptionSmallTextarea,
  StyledField,
  TitleSmallTextarea,
} from 'components/cards/shared/styles/fields';
import withCallToActionStateHandlers, {
  handleDeleteCta,
} from 'hocs/with-call-to-action-state-handlers';
import withDestroyCallToActionMutation from 'hocs/with-destroy-call-to-action-mutation';
import { compose, mapProps, withHandlers, withProps } from 'lib/recompose';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import set from 'lodash/set';
import React from 'react';
import { connect } from 'react-redux';
import { setSelectedCallToAction } from 'store/modules/card-call-to-action';
import { setSelectedPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const AddNewCtaSection = styled.div`
  color: rgba(255, 255, 255, 0.5);
`;

function LinksCardForm(props) {
  const {
    card,
    buttonColor,
    currentAccount: {
      profile: { brandColor },
    },
    handleShowCtaForm,
    handleSortUp,
    handleSortDown,
    handleDelete,
    renderField,
  } = props;

  return (
    <Layout>
      <Scrollable cardId={card.id} verticalAlign="top" xPadding="20px" topPadding="68px">
        {renderField({
          name: 'result.title',
          placeholder: 'Tap to add headline',
          selectAllOnFocus: true,
          styledInput: TitleSmallTextarea,
          styledField: StyledField,
        })}
        {renderField({
          name: 'result.description',
          placeholder: 'Tap to add body',
          selectAllOnFocus: true,
          styledInput: DescriptionSmallTextarea,
          styledField: StyledField,
        })}
        <SortableCallToActionButtons
          brandColor={buttonColor || brandColor}
          ctas={card.ctas}
          onClick={handleShowCtaForm}
          onSortUpClick={handleSortUp}
          onSortDownClick={handleSortDown}
          onDeleteClick={handleDelete}
        />
        <AddNewCtaSection>
          <AddCallToActionButton onClick={handleShowCtaForm.bind(this, null)} />
        </AddNewCtaSection>
      </Scrollable>
    </Layout>
  );
}

export default compose(
  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setSelectedCallToAction: (id) => dispatch(setSelectedCallToAction(id)),
  })),
  withCallToActionStateHandlers,
  withDestroyCallToActionMutation,
  withProps(({ card }) => ({ hasCtas: card.ctas.length, buttonColor: card.buttonColor })),

  mapProps((props) => {
    if (!props.hasCtas) return props;
    const ctaObjects = get(props.formState, 'entities.ctas');
    const ctas = Object.keys(ctaObjects)
      .map((id) => ctaObjects[id])
      .sort((a, b) => a.position - b.position);

    return {
      ...props,
      card: { ctas, id: props.card.id },
    };
  }),

  withProps(({ card: { ctas }, setFormState }) => ({
    moveCta: (id, n) => {
      const index = ctas.findIndex((cta) => cta.id === id);
      const moveTo = index + n;

      const canMoveUp = n < 0 && index !== 0;
      const canMoveDown = n > 0 && moveTo < ctas.length;

      if (canMoveUp || canMoveDown) {
        const customSort = [...ctas];
        const tmp = customSort[index];
        customSort[index] = customSort[moveTo];
        customSort[moveTo] = tmp;

        customSort.forEach((cta, i) => {
          // Stupid, I know, but subtract 999 number so that new ctas will show at the bottom
          setFormState((state) => set({ ...state }, `entities.ctas.${cta.id}.position`, i - 999));
        });
      }
    },
  })),
  withHandlers({
    handleShowCtaForm:
      ({ initializeNewCallToAction, setSelectedPopover, setSelectedCallToAction }) =>
      (id, event) => {
        event.preventDefault();
        if (isNull(id)) {
          id = initializeNewCallToAction();
        }
        setSelectedPopover('link');
        setSelectedCallToAction(id);
      },
    handleSortUp:
      ({ moveCta }) =>
      (id) =>
        moveCta(id, -1),
    handleSortDown:
      ({ moveCta }) =>
      (id) =>
        moveCta(id, 1),
    handleDelete: handleDeleteCta,
  }),
)(LinksCardForm);
