import React from 'react';
import styled from 'styled-components/macro';
import * as Recompose from 'recompose';
import * as Redux from 'react-redux';
import { unsetToast } from 'store/modules/toast';

const ToastContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const ToastMessage = styled.div`
  background-color: ${(p) => p.theme.colors.messages.notice};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.darkYellow};
  text-align: center;
  font-size: 18px;
  padding: 16px 30px 16px 30px;
  width: 100%;
  min-height: 65px;
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  transition: transform 0.3s ease;
  ${(props) => props.visible && ` transform: translate(-50%, 0%); `};
`;

function Toast(props) {
  const { dismissToast, message, isVisible } = props;
  return (
    <ToastContainer onClick={dismissToast}>
      <ToastMessage visible={isVisible} children={message} />
    </ToastContainer>
  );
}

export default Recompose.compose(
  Redux.connect(
    (state) => ({
      message: state.toast.message,
      isVisible: !!state.toast.visible,
    }),
    (dispatch) => ({
      dismissToast: () => dispatch(unsetToast()),
    }),
  ),

  Recompose.lifecycle({
    componentDidUpdate(prevProps) {
      const { message, isVisible, dismissToast } = this.props;
      const shouldResetTimer = message !== prevProps.message || isVisible !== prevProps.isVisible;
      if (shouldResetTimer && this.timeout) {
        window.clearTimeout(this.timeout);
        this.timeout = null;
      }
      if (message) {
        this.timeout = window.setTimeout(dismissToast, 4000);
      }
    },
  }),
)(Toast);
