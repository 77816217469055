import { withProps } from 'recompose';

const withLoadPhotosUrl = withProps(({ requestManager, user }) => ({
  loadPhotosUrl() {
    const url = window.PATHS.apiV1PhotosPath(user.username);
    if (requestManager.nextMaxId) {
      return url.concat(`?next_max_id=${requestManager.nextMaxId}`);
    }
    return url;
  },
}));

export default withLoadPhotosUrl;
