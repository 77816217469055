import React from 'react';
import tapBioLogo from 'images/logo-grey.svg';
import styled from 'styled-components/macro';

const Layout = styled.div`
  background-color: white;
  height: 100%;
  padding-top: 100px;
  text-align: center;
`;

const Message = styled.p`
  padding: 1.5rem 3rem;
`;

function ErrorMessage(props) {
  const { message } = props;
  return (
    <Layout>
      <img src={tapBioLogo} alt="Tap Bio" />
      <Message children={message} />
    </Layout>
  );
}

ErrorMessage.defaultProps = { message: 'Something went wrong loading this page. :-(' };

export default ErrorMessage;
