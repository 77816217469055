import React from 'react';
import ErrorBoundary from 'components/errors/error-boundary';
import { ScrollContainer, Message } from 'components/ui';
import routes from 'config/routes';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Card from './card';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

const Layout = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
`;

const CardScrollLayout = styled(ScrollContainer)`
  padding: 1.25rem 4%;
  flex: 1;
`;

const CardsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const UpgradeMessage = styled(Message)`
  margin-bottom: 0.75rem;
`;

function NewCard(props) {
  const {
    cardIsCreating,
    cardIsEnabled,
    cards,
    handleAddNewCard,
    isBelowCardLimit,
    currentAccount,
  } = props;
  const { username } = currentAccount;

  return (
    <ErrorBoundary>
      <Layout>
        <ViewHeader>
          <ViewHeader.LeftButton disabled as={Link} to={routes.account(currentAccount.username)} />
          <ViewHeader.Title children="Add Card" />
          <ViewHeader.RightButton children="" />
        </ViewHeader>

        <CardScrollLayout>
          <UpgradeMessage
            level="notice"
            text={[
              'Please  ',
              <Link key="link" to={routes.billing.changeProduct(username)}>
                upgrade your plan
              </Link>,
              ' or remove a card to add additional cards.',
            ]}
            isVisible={!isBelowCardLimit}
          />

          <CardsLayout>
            {cards.map((card) => {
              if (cardIsEnabled(card))
                return (
                  <Card
                    card={card}
                    key={card.kind}
                    handleClick={handleAddNewCard}
                    isCreating={cardIsCreating(card)}
                    isEnabled={cardIsEnabled(card)}
                  />
                );
              else return null;
            })}
            {cards.map((card) => {
              if (!cardIsEnabled(card))
                return (
                  <Card
                    card={card}
                    key={card.kind}
                    handleClick={handleAddNewCard}
                    isCreating={cardIsCreating(card)}
                    isEnabled={cardIsEnabled(card)}
                  />
                );
              else return null;
            })}
          </CardsLayout>
        </CardScrollLayout>
      </Layout>
    </ErrorBoundary>
  );
}

export default NewCard;
