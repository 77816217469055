import initSentry from '@tap-bio/core/init-sentry';
import Application from 'components/application';
import Aside from 'components/aside';
import config from 'config';
import 'config/moment';
import 'css/animations.css';
import 'css/base.css';
import 'css/reset.css';
import 'css/swiper-customizations.css';
import 'lib/disable-pinch-zoom';
import Providers from 'providers';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dynamic-swiper/lib/styles.css';

// Initialize sentry scoping to the dashboard site
initSentry(config, 'dashboard');

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <Providers apollo theme store>
    <Application />
  </Providers>,
  document.getElementById('application-root'),
);

ReactDOM.render(
  <Providers theme store>
    <Aside />
  </Providers>,
  document.getElementById('application-aside'),
);
