import React from 'react';
import Panel from 'components/cards/stats/component';
import YoutubePlayType from 'components/cards/stats/types/youtube-play-type';
import YoutubeTrends from 'components/cards/stats/cards/youtube/trends';
import CardViewType from 'components/cards/stats/types/card-view-type';

export default (props) => (
  <Panel {...props}>
    <YoutubePlayType>
      <YoutubeTrends />
    </YoutubePlayType>
    <CardViewType />
  </Panel>
);
