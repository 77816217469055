import styled from 'styled-components/macro';

const Layout = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.primaryPurple};

  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
`;

const Main = styled.div`
  flex: 1;
`;

const Footer = styled.div`
  padding: 20px;
  padding-top: 40px;
`;

export { Layout, Main, Footer };
