import { Mutation } from '@apollo/react-components';
import AssistedUrlInput from '@tap-bio/ui/patterns/assisted-url-input';
import SubmitButton from 'components/authentication/submit-button';
import { HintText } from 'components/ui/field';
import Button from 'components/ui/form-button';
import Spinner from 'components/ui/spinner';
import { Link as BaseLink, Text as BaseText } from 'components/web-ui';
import { createSessionChallengeMutation } from 'lib/graphql/mutations';
import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import container from './container';
import Layout from './layout';

const Field = styled.div`
  position: relative;
  padding-bottom: 1.5em;
`;

const Text = styled(BaseText)`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
`;

const Error = styled(BaseText)`
  color: ${(props) => props.theme.colors.errorRed};
`;

const Link = styled(BaseLink)`
  color: white;
  text-decoration: underline;
`;

const Input = styled(AssistedUrlInput)`
  background: white;
  color: black;
  color: #33154e;
`;

function LoginForm(props) {
  const { onFieldChange } = props;

  const buttonVerb = props.formErrors.any
    ? 'Send'
    : props.formData.provider === 'sms'
      ? 'Text'
      : 'Email';

  const onFieldChangeCallback = useCallback(
    (event) => {
      if (!event) return;
      let { value = '' } = event.target;
      value = value.replace('*', '');
      onFieldChange && onFieldChange('username', value);
    },
    [onFieldChange],
  );

  return (
    <Layout>
      {props.responseData.errorType === 'EmailInactiveRecipientError' && (
        <Error>We're having trouble sending to your email address. Please contact support.</Error>
      )}

      {props.responseData.errorType === 'SessionIdentityNotFound' && (
        <React.Fragment>
          <p>
            We can’t find an account associated with that
            {props.formData.provider === 'sms' ? ' mobile number' : ' email'}, do you want to sign
            up for a new account instead?
          </p>
          <Button
            ghost
            light
            text="Register for a new account"
            onClick={props.onRedirectToRegister}
          />
          <br />
          <p>I mistyped, I'll try again</p>
        </React.Fragment>
      )}

      <Mutation
        mutation={createSessionChallengeMutation}
        update={(proxy, { data: { error, createSessionChallenge } }) => {
          // If there are any errors returned whilst processing the submission, add them
          // to the state so they can be displayed.
          if (error) {
            props.setformIsPersisting(false);
            props.setResponseData({
              provider: props.provider,
              error: { username: "can't find this identity" },
              errorType: error.type,
            });

            // Otherwise continue with the login
          } else {
            props.setResponseData(createSessionChallenge);
          }
        }}
      >
        {(mutation, { data, loading, error }) => (
          <React.Fragment>
            <form>
              <Field>
                <Input
                  defaultValue=""
                  placeholder="Enter your email or mobile number."
                  onChange={onFieldChangeCallback}
                  helpers={['tel', 'email']}
                  skipTelProtocol={true}
                  skipEmailProtocol={true}
                  autoFocus="on"
                />
                <HintText>{props.formHints.username}</HintText>
              </Field>

              <SubmitButton
                light
                disabled={props.formErrors.any || props.formIsPersisting}
                onClick={(event) => props.onSubmit(event, mutation)}
                type="submit"
              >
                {props.formIsPersisting ? (
                  <Spinner fill="rgba(255,255,255,0.75)" height="50px" />
                ) : (
                  buttonVerb + ' my sign-in code'
                )}
              </SubmitButton>
            </form>
          </React.Fragment>
        )}
      </Mutation>

      {props.responseData.errorType !== 'SessionIdentityNotFound' && (
        <Text>
          Don't have an account yet?
          <Link children="Sign-up." onClick={props.onRedirectToRegister} />
        </Text>
      )}
    </Layout>
  );
}

export default container(LoginForm);
