import CallToActionButtons from 'components/cards/forms/call-to-action-buttons';
import CardLayout from 'components/cards/preview/layout';
import Scrollable from 'components/cards/preview/scrollable';
import {
  ContentSpacer,
  CtaButtonContainerFixed,
  DescriptionSmall,
  TitleSmall,
} from 'components/cards/preview/styles';
import Header from 'components/headers/card-preview-header';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { compose, withProps } from 'recompose';
import { fixLineBreakSpacers } from 'utils';
import FeaturedImage from './featured-image';
import GalleryImagesMasonry from './gallery-images-masonry';
import GalleryImagesSquare from './gallery-images-square';

// --- Styled Components ---

function ImageGalleryCard(props) {
  const { card, cardIndexInDeck, currentAccount, featuredImage, images, isCardVisible } = props;
  const { title, description } = card;

  return (
    <CardLayout
      hasFooterOverlay={card.hasBackgroundGradient}
      card={card}
      isCardVisible={isCardVisible}
    >
      <Header
        currentAccount={currentAccount}
        card={card}
        cardIndexInDeck={cardIndexInDeck}
        isVisible={card.editable}
      />
      <Scrollable
        cardId={card.id}
        xPadding="5px"
        verticalAlign="top"
        bottomPadding="72px"
        topPadding="68px"
      >
        <ContentSpacer title={title} description={description}>
          {title && <TitleSmall children={fixLineBreakSpacers(title)} />}
          {description && <DescriptionSmall children={fixLineBreakSpacers(description)} />}
        </ContentSpacer>
        <FeaturedImage image={featuredImage} />
        <GalleryImagesSquare images={images} view={card.imageGallery.view} />
        <GalleryImagesMasonry
          hasCta={!!card.ctas.length}
          images={images}
          view={card.imageGallery.view}
        />
      </Scrollable>
      <CtaButtonContainerFixed>
        <CallToActionButtons
          brandColor={card.buttonColor || currentAccount.profile.brandColor}
          ctas={card.ctas}
          isPreview
        />
      </CtaButtonContainerFixed>
    </CardLayout>
  );
}

const featuredImageProps = withProps(({ card }) => {
  if (!card.images.length || !card.imageGallery.hasFeaturedImage) return { images: card.images };
  return {
    featuredImage: card.images[0],
    images: card.images.slice(1),
  };
});

const sortedImagesProps = withProps(({ images }) => ({
  images: sortBy(cloneDeep(images), 'position'),
}));

export default compose(sortedImagesProps, featuredImageProps)(ImageGalleryCard);
