import React from 'react';
import * as Recompose from 'lib/recompose';
import { css } from 'styled-components';
import { ButtonCircle, Input as BaseInput, Text } from 'components/web-ui';
import { CardElement, injectStripe } from 'react-stripe-elements';
import renderField from 'hocs/render-field';
import handleLocalFieldChange from 'hocs/handle-local-field-change';
import BlockingSpinner from 'components/ui/spinner-blocking';
import NonFatalErrorMessage from 'components/errors/non-fatal-error-message';
import Turnstile from 'components/ui/turnstile';
import styled from 'styled-components/macro';
import withAddSource from './hocs/with-add-source';

const PayButton = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  color: white;
  height: 4rem;
  font-size: 1rem;
  width: 100%;
  margin-top: 20px;
`;

const createOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: 'black',
      letterSpacing: '0.025em',
      fontFamily: "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu",
      '::placeholder': {
        color: 'rgba(0, 0, 0, .55)',
      },
    },
  },
};

const ElementContainer = styled.div`
  margin-bottom: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 17px 10px;
  border: 1px solid
    ${({
      isFocused,
      theme: {
        colors: {
          profileForm: { inputBorder },
        },
      },
    }) => (isFocused ? 'black' : inputBorder)};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
`.withComponent(({ isFocused, ...props }) => <div {...props} />);

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const StyledInput = styled(BaseInput)`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  ${fieldBorderCSS};
`.withComponent(Recompose.omitProps('hasErrors')(BaseInput));

const StyledField = styled.div`
  margin: 0 0 10px 0;
`;

const CardErrors = styled(Text)`
  margin-top: -9px;
  text-align: center;
  margin-bottom: 12px;
  color: ${(p) => p.theme.colors.errorRed};
`;

CardErrors.defaultProps = {
  color: 'errorRed',
};

const CreditCardForm = ({
  action,
  focusCardElement,
  formErrors,
  handleSubmit,
  history,
  isPersisting,
  paymentError,
  planId,
  renderField,
  setFocusCardElement,
  setTurnstileToken,
  submitButtonText,
}) => (
  <form onSubmit={handleSubmit}>
    <NonFatalErrorMessage message={paymentError} />
    <BlockingSpinner
      active={isPersisting}
      children="Please wait a moment while we add your card."
    />

    <ElementContainer isFocused={focusCardElement}>
      <CardElement
        onBlur={(event) => setFocusCardElement(false)}
        onFocus={(event) => setFocusCardElement(true)}
        {...createOptions}
      />
    </ElementContainer>
    {formErrors.card && <CardErrors children={formErrors.card.message} />}

    {renderField({
      name: 'name',
      placeholder: 'Name on card',
      styledInput: StyledInput,
      styledField: StyledField,
      autoCapitalize: 'off',
      autoCorrect: 'off',
      spellCheck: 'false',
      autoComplete: 'on',
    })}
    {formErrors.name && <CardErrors children={formErrors.name} />}

    <Turnstile onChange={setTurnstileToken} />

    <PayButton children={submitButtonText} />
  </form>
);

export default Recompose.compose(
  injectStripe,
  Recompose.withState('turnstileToken', 'setTurnstileToken', null),
  Recompose.withState('formState', 'setFormState', { name: '', address_country: '' }),
  Recompose.withState('formErrors', 'setFormErrors', { card: null, name: null }),
  Recompose.withState('focusCardElement', 'setFocusCardElement', false),
  Recompose.withState('paymentError', 'setPaymentError', null),
  Recompose.withState('isPersisting', 'setIsPersisting', false),
  handleLocalFieldChange,
  renderField,
  withAddSource,
  Recompose.withHandlers({
    handleSubmit: (props) => async (event) => {
      const {
        addSource,
        currentAccount,
        formState,
        onSuccessfulAddCard,
        planId,
        setFormErrors,
        setIsPersisting,
        stripe,
        turnstileToken,
      } = props;

      event.preventDefault();

      // Simple validation for the name field
      if (!formState.name) {
        setFormErrors((state) => ({ ...state, name: 'Please enter the name on your card' }));
        return;
      } else {
        setFormErrors((state) => ({ ...state, name: null }));
      }

      // This is unset after the token is persisted to our server. That's 2 requests: one to
      // stripe for the source and one to our server to persist it onto the user.
      setIsPersisting(true);

      const ownerInfo = {
        type: 'card',
        currency: 'usd',
        owner: {
          name: formState.name,
          email: currentAccount.profile.email,
        },
      };

      const { source, error } = await stripe.createSource(ownerInfo);
      if (error) {
        setIsPersisting(false);
        setFormErrors((state) => ({ ...state, card: error }));
      } else {
        setFormErrors((state) => ({ ...state, card: null }));
        const success = await addSource(source, planId, currentAccount.owner.id, turnstileToken);
        if (success) onSuccessfulAddCard(); // Display the success modal
      }
    },
  }),
  Recompose.pure,
)(CreditCardForm);
