import React from 'react';
import styled from 'styled-components/macro';
import { tweakAt } from 'lib/css';
import theme from 'css/theme';

const { colors, viewport } = theme;
const { w: width, h: height } = viewport.breakpoints;

const Layout = styled.div`
  display: none;
  position: fixed;
  bottom: 1em;
  right: 1em;
  left: 1em;
  color: ${colors.viewport.darkSilver};
  transform: translate3d(0, 0, 0);

  text-align: center;

  @media ${tweakAt({ height })} {
    display: initial;
  }

  @media ${tweakAt({ width: width + 400 })} {
    display: initial;
    text-align: right;
  }
`;

export default () => <Layout>Made with Tap Bio.</Layout>;
