import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.a`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryPurple};
`;

const Description = styled.a`
  font-size: 13px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.lightPurple};
`;

const Metadata = ({ title, description, href }) => (
  <Wrapper>
    <Title href={href} target="_blank">
      {title}
    </Title>
    <Description href={href} target="_blank">
      {description}
    </Description>
  </Wrapper>
);

export default Metadata;
