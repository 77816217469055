import React from 'react';
import ApolloProvider from './apollo';
import ThemeProvider from './theme';
import StoreProvider from './store';

function Providers(props) {
  return (
    <ApolloProvider renderOnlyIf={props.apollo}>
      <ThemeProvider renderOnlyIf={props.theme}>
        <StoreProvider renderOnlyIf={props.store}>{props.children}</StoreProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default Providers;
