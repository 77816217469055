import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 60px;
  border-top: 1px solid ${(props) => props.theme.colors.veryLightishGray};
  z-index: 200;
  background-color: white;
`;

const PreviewContainer = styled.div`
  position: relative;
  flex: 1;
  cursor: ${(props) => (props.isDragging ? 'move' : 'grab')};
  touch-action: none;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: initial;
  /*
    styled-component complains too many
    classes were generated when position
    or scale changed.
    transform:
      translate3d(
        ${(props) => props.x}px, ${(props) => props.y}px, 0px) scale(${(props) => props.scale}
      );
  */
  pointer-events: none;
`;

const Viewport = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  z-index: 1;
  pointer-events: none;
  background-color: ${(props) => (props.previewing ? 'rgba(133, 133, 133, 0.75)' : 'black')};
  transition: background-color 0.25s ease;
  clip-path: polygon(
    0 0%,
    0 100%,
    ${(props) => props.right}px 100%,
    ${(props) => props.right}px ${(props) => props.top}px,
    ${(props) => props.containerWidth - props.left}px ${(props) => props.top}px,
    ${(props) => props.containerWidth - props.left}px
      ${(props) => props.containerHeight - props.bottom}px,
    ${(props) => props.right}px ${(props) => props.containerHeight - props.bottom}px,
    ${(props) => props.right}px 100%,
    100% 100%,
    100% 0%
  );
`;

const PhotoUploadingSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 4;
`;

const SliderContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 40px;
  right: 90px;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 19px;
  background-color: rgba(50, 50, 50, 0.75);
  z-index: 3;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  height: 38px;
  width: 100%;
  background-color: transparent;
  outline: none;

  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 3px;
    margin-top: -4px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #fff;
    margin-top: -10px;
  }

  ::-moz-range-track {
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 3px;
    margin-top: -4px;
  }

  ::-moz-range-thumb {
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #fff;
    margin-top: -10px;
  }
`;

const RotateButton = styled.button`
  position: absolute;
  top: 20px;
  right: 40px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: none;
  outline: none;
  background-color: rgba(50, 50, 50, 0.75);
  z-index: 3;
`;

export {
  Layout,
  PreviewContainer,
  Image,
  Viewport,
  PhotoUploadingSpinnerContainer,
  SliderContainer,
  Slider,
  RotateButton,
};
