import React from 'react';

function Rotate(props) {
  return (
    <svg
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 6.827 6.827"
      {...props}
    >
      <defs />
      <path d="M2.467 5.549a.213.213 0 0 0 .11-.412 1.745 1.745 0 0 1-1.063-.815c-.223-.388-.3-.861-.174-1.328a1.744 1.744 0 0 1 2.143-1.237A1.744 1.744 0 0 1 4.72 3.9a.213.213 0 0 0 .412.11 2.17 2.17 0 0 0-.218-1.651 2.169 2.169 0 0 0-1.32-1.014 2.17 2.17 0 0 0-1.652.218c-.482.278-.858.74-1.014 1.321a2.17 2.17 0 0 0 .218 1.651c.278.482.74.858 1.321 1.014z" />
      <path d="M5.895 3.433a.213.213 0 0 0-.27-.328l-.67.55-.55-.669a.213.213 0 0 0-.33.27l.675.82.011.015c.075.09.21.104.3.029l.834-.687z" />
      <path fill="none" d="M0 0h6.827v6.827H0z" />
    </svg>
  );
}

Rotate.defaultProps = {
  fill: 'white',
  width: 24,
  height: 24,
};

export default Rotate;
