import React from 'react';
import { branch, compose, pure, renderComponent } from 'recompose';
import ErrorBoundary from 'components/errors/error-boundary';
import BaseHeading from 'components/ui/base/heading';
import BaseSubHeading from 'components/ui/base/sub-heading';
import OnboardingForm from 'components/accounts/onboarding/form';
import styled from 'styled-components/macro';
import ScrollContainer from 'components/ui/scroll-container';
import { Redirect } from 'react-router-dom';
import routes from 'config/routes';
import config from 'config';

const Layout = styled(ScrollContainer)`
  padding: 2rem 0 1rem 0;
`;

const Title = styled(BaseHeading)`
  text-align: center;
  font-size: 34px;
  margin: 1.5rem 0;
  padding: 0 32px;
`;

const Subtitle = styled(BaseSubHeading)`
  text-align: center;
  line-height: 1.25;
  margin: 1.5rem auto;
  padding: 0 32px;
  max-width: 21rem;
`;

function Onboarding() {
  return (
    <ErrorBoundary>
      <Layout>
        <Title>More on You</Title>
        <Subtitle>Let’s start your profile with some basic information.</Subtitle>
        <OnboardingForm />
      </Layout>
    </ErrorBoundary>
  );
}

export default compose(
  branch((props) => config.ENABLE_PROFILE_ONBOARDING, renderComponent(Onboarding)),
  pure,
)(() => <Redirect to={{ pathname: routes.accounts }} />);
