import PropTypes from 'prop-types';
import { compose, withProps, setPropTypes, defaultProps } from 'recompose';
// import getQueryParams from 'lib/get-query-params';

const getQueryParams = (location) => {
  const search = location.search.substring(1);
  const formattedString = '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}';
  const reviverFn = (key, value) => (key === '' ? value : decodeURIComponent(value));
  return JSON.parse(formattedString, reviverFn);
};

export default compose(
  setPropTypes({
    location: PropTypes.PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }),
  defaultProps({
    location: window.location,
  }),
  withProps((props) => {
    const params = getQueryParams(props.location);
    return { ...params };
  }),
);
