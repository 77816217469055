import tinycolor from 'tinycolor2';
import { css } from 'styled-components';

const addLightenFilter = (testColor, gradient = false) => {
  if (!testColor) return;
  const brightness = tinycolor(testColor).toHsv().v;
  const color = brightness > 0.6 ? '#292d99' : '#000366';
  return css`
    background-color: ${color};
    background-blend-mode: ${gradient ? 'darken, lighten' : 'lighten'};
  `;
};

export default addLightenFilter;
