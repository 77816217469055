import routes from 'config/routes';
import Authentication from 'lib/authentication';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { branch, renderComponent } from 'recompose';

export default branch(
  (props) => !Authentication.isAuthenticated(),
  renderComponent(() => <Redirect push to={{ pathname: routes.loginPath }} />),
);
