import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import TapBioLogo from 'components/icons/tap-bio-logo';
import { omitProps } from 'lib/recompose';

const lightVersion = css`
  color: white;
  fill: ${(props) => props.theme.colors.alphaWhite30};
`;

const darkVersion = css`
  color: white;
  fill: ${(props) => props.theme.colors.alphaBlack40};
`;

const Logo = styled.svg`
  color: white;
  fill: ${(props) => props.theme.colors.veryDarkPurple};
  width: ${(props) => (props.small ? 38 : 80)}px;

  ${(props) => props.light && lightVersion};
  ${(props) => props.dark && darkVersion};
`.withComponent(omitProps('light', 'small', 'dark')(TapBioLogo));

Logo.propTypes = {
  small: PropTypes.bool,
  light: PropTypes.bool,
  dark: PropTypes.bool,
};

export default Logo;
