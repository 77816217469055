import update from 'immutability-helper';

const SET = 'tb.cardContentOverlay.showOverlay';
const UNSET = 'tb.cardContentOverlay.hideOverlay';
const initialState = { showOverlayCardIds: new Set([]) };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return update(state, { showOverlayCardIds: { $add: [action.cardId] } });
    case UNSET:
      return update(state, { showOverlayCardIds: { $remove: [action.cardId] } });
    default:
      return state;
  }
}

export function showcardContentOverlay(cardId) {
  return { type: SET, cardId };
}

export function hidecardContentOverlay(cardId) {
  return { type: UNSET, cardId };
}
