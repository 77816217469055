import React from 'react';
import Panel from 'components/cards/stats/component';
import InstagramPhotoClickType from 'components/cards/stats/types/instagram-photo-click-type';
import InstagramPhotoTrends from 'components/cards/stats/cards/instagram/trends';
import CardClickType from 'components/cards/stats/types/card-click-type';
import CardClickTrends from 'components/cards/stats/cards/shared/ctas/trends';
import CardViewType from 'components/cards/stats/types/card-view-type';

export default (props) => (
  <Panel {...props}>
    <InstagramPhotoClickType>
      <InstagramPhotoTrends />
    </InstagramPhotoClickType>
    <CardClickType>
      <CardClickTrends />
    </CardClickType>
    <CardViewType />
  </Panel>
);
