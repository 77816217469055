import { parseToRgb, toColorString } from 'polished';

/**
 * @summary Convert a hex color to an RGBa string
 * @param   {String} hexColor - A hex colour. Can be 6 or 8 character
 * @returns {Object} - An object representing the 8 bit color channels as decimals
 *                     For example:
 *
 *                       {
 *                         r: 100,
 *                         g: 100,
 *                         b: 100,
 *                         a: 100,
 *                         alpha8Bit: 64
 *                       }
 */

export function convertHexToRgbaObject(hexColor) {
  // Seems this can happen on the links card. We need to pass it through.
  if (hexColor === 'transparent') return hexColor;

  // Otherwise convert #RRGGBBaa into rgba(R, G, B, a)
  const channels = hexColor.slice(1).match(/.{1,2}/g);
  if (!Array.isArray(channels)) return 'transparent';

  const rgb = channels.slice(0, 3).map((value) => parseInt(value, 16));
  const alpha = parseFloat(parseInt((parseInt(channels[3], 16) / 255) * 1000, 10) / 1000) || 1;
  const alpha8Bit = parseFloat(parseInt(parseInt(channels[3], 16), 10)) || 255;

  return {
    r: rgb[0],
    g: rgb[1],
    b: rgb[2],
    a: alpha,
    alpha8Bit,
  };
}

/**
 * @summary Convert a base-10 number in the range 0 to 255 into a 2 digit hex string
 * @param   {Integer} alpha8Bit - A number in the range 0 to 255
 * @returns {String} - A 2 digit hex string e.g. FF
 */

export function convert8BitAlphaToHex(alpha8Bit) {
  const hex = parseInt(alpha8Bit, 10).toString(16).toUpperCase();
  if (hex.length === 1) return `0${hex}`;
  return hex;
}

/**
 * @summary Convert a hex color to an RGBa string
 * @param   {String} hexColor - A hex colour. Can be 6 or 8 character
 * @returns {String} - A CSS rgba string e.g. rgba(100,100,100,0.74)
 */

export function convertHexToRgba(hexColor) {
  if (hexColor === 'transparent') return hexColor;
  const rgbaObj = convertHexToRgbaObject(hexColor);
  return `rgba(${rgbaObj.r}, ${rgbaObj.g}, ${rgbaObj.b}, ${rgbaObj.a})`;
}

export function findActualColor(color) {
  const rgba = parseToRgb(color);
  const alpha = typeof rgba.alpha === 'number' ? rgba.alpha : 1.0;
  const red = Math.round((1.0 - alpha) * 255.0) + Math.round(alpha * rgba.red);
  const green = Math.round((1.0 - alpha) * 255.0) + Math.round(alpha * rgba.green);
  const blue = Math.round((1.0 - alpha) * 255.0) + Math.round(alpha * rgba.blue);
  return toColorString({ red, green, blue });
}

/**
 * @summary Extract the opacity from a given 8 character hex colour and apply it to another
 *          6 digit hex colour
 * @param   {String} oldHexColor - An 8 digit hex color
 * @param   {String} newHexColor - A 6 digit hex color
 * @returns {String} An 8 digit hex color, or the original color
 */

export function transferOpacity(oldHexColor, newHexColor) {
  if (oldHexColor.length !== 9) return newHexColor;

  return `${newHexColor}${oldHexColor.slice(-2)}`;
}
