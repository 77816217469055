import { ErrorMessage } from 'components/cards/forms/shared-ui';
import Scrollable from 'components/cards/preview/scrollable';
import {
  DescriptionTextarea,
  InputCSS,
  StyledField,
  TitleTextarea,
} from 'components/cards/shared/styles/fields';
import Button from 'components/ui/base/button';
import withCallToActionStateHandlers from 'hocs/with-call-to-action-state-handlers';
import { compose } from 'lib/recompose';
import React from 'react';
import { connect } from 'react-redux';
import { setSelectedCallToAction } from 'store/modules/card-call-to-action';
import { setSelectedPopover, unsetPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';
import {
  renderSettings,
  withFormHandlers,
  withFormProps,
  withFormReadyState,
  withFormSettingsState,
  withLifeCycle,
  withPreviousFormState,
} from './hocs';
import SetupMailingList from './setup-mailing-list';

const EmailPlaceholderInput = styled.input`
  ${InputCSS};
  margin-bottom: 10px;
  width: 100%;
  padding: 17px;
  border: 1.5px solid #ffffff;
  border-radius: 99999px;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.alphaWhite20} 0%,
    ${(props) => props.theme.colors.alphaWhite30} 100%
  );
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  text-shadow: none;
  outline: none;

  ::placeholder {
    color: ${(props) => props.theme.colors.white};
  }
`;

const SubmitButton = styled(Button)`
  background-color: ${(p) => p.color};
  color: ${(p) => p.theme.colors.white};
  opacity: ${(p) => (p.disabled ? 0.7 : 1.0)};
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 2em;
  min-height: 58px;
  line-height: 1.35;
  font-size: 17px;
  font-weight: normal;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  text-indent: ${(p) => (p.submitting ? '-9999px' : 0)};
`;

function Form(props) {
  const { card, error, renderField, buttonColor, buttonText, showButtonCustomizationOptions } =
    props;
  return (
    <Scrollable cardId={card.id} verticalAlign="top" xPadding="20px">
      {error && <ErrorMessage error={error} />}
      {renderField({
        name: 'result.title',
        placeholder: 'Tap to add headline',
        selectAllOnFocus: true,
        styledField: StyledField,
        styledInput: TitleTextarea,
      })}
      {renderField({
        name: 'result.description',
        placeholder: 'Tap to add body',
        selectAllOnFocus: true,
        styledField: StyledField,
        styledInput: DescriptionTextarea,
      })}
      <EmailPlaceholderInput placeholder="Email address" disabled />
      <SubmitButton
        color={buttonColor}
        onClick={showButtonCustomizationOptions}
        children={buttonText}
      />
    </Scrollable>
  );
}

export default compose(
  connect(
    (state) => ({
      receiveSettingsPopoverEvent: state.cardPopovers.popover === 'mailing-card',
    }),
    (dispatch) => ({
      unsetPopover: () => dispatch(unsetPopover()),
      setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
      setSelectedCallToAction: (id) => dispatch(setSelectedCallToAction(id)),
    }),
  ),
  withCallToActionStateHandlers,
  withFormReadyState,
  withFormSettingsState,
  withFormProps,
  withPreviousFormState,
  withFormHandlers,
  withLifeCycle,

  // render mailing list settings if `settings` is set to `true`
  renderSettings(SetupMailingList),
)(Form);
