import querystring from 'querystring';
import { connect } from 'react-redux';
import * as Recompose from 'recompose';
import { setUploadedBackgroundUrl } from 'store/modules/card-upload-background';

const photoWidth = 750;

const getPhotoParams = function (index, brandColor = '') {
  return [
    {
      label: 'Original',
      brandColor,
      filters: {
        w: photoWidth,
      },
    },
    {
      label: 'Drama',
      brandColor,
      filters: {
        sat: -100,
        bm: 'multiply',
        blend: brandColor.replace('#', ''),
        w: photoWidth,
      },
    },
    {
      label: 'B&W',
      brandColor,
      filters: {
        sat: -100,
        w: photoWidth,
      },
    },
    {
      label: 'Backstage',
      brandColor,
      filters: {
        w: photoWidth,
        bm: 'normal',
        blend: '80000000',
      },
    },
    {
      label: 'Solid',
      brandColor,
      filters: {
        w: photoWidth,
      },
    },
  ][index];
};

export default Recompose.compose(
  connect(
    (state, ownProps) => ({
      photoUrl: state.cardUploadBackground.url,
      uploadImageTitle:
        state.cardPopovers.popover === 'background-image'
          ? 'Change background photo'
          : 'Change profile photo',
    }),
    (dispatch) => ({
      setUploadedBackgroundUrl: (url) => dispatch(setUploadedBackgroundUrl(url)),
    }),
  ),

  Recompose.defaultProps({
    onImageSelected: (img, file) => null,
  }),

  Recompose.withProps(({ photoUrl, profile, card }) => {
    const parts = photoUrl[0].split('?');
    const params = parts[1];
    const qs = querystring.parse(params);
    const hasBackgroundColorSolid = card && !!card.backgroundColorSolid;

    const { bm, blend, sat, w } = qs;
    let initialSlide = 0;
    if (bm && blend && sat && w) {
      initialSlide = 1;
    } else if (sat && w) {
      initialSlide = 2;
    } else if (hasBackgroundColorSolid) {
      initialSlide = 4;
    } else if (bm === 'normal') initialSlide = 3;
    else if (w) {
      initialSlide = 0;
    }
    return { initialSlide };
  }),

  Recompose.withState(
    'selectedPhotoUrl',
    'setSelectedPhotoUrl',
    (props) => `${props.photoUrl}?w=750`,
  ),

  Recompose.withState(
    'selectedBackgroundColorSolid',
    'setSelectedBackgroundColorSolid',
    ({ card, profile }) => {
      const { brandColor } = profile;
      if (typeof card === 'object' && card !== null) {
        const { backgroundColorSolid } = card;
        return backgroundColorSolid || brandColor;
      }
      return brandColor;
    },
  ),

  Recompose.withState('selectedBrandColor', 'setSelectedBrandColor', ({ card, profile }) => {
    const { brandColor } = profile;
    if (typeof card === 'object' && card !== null) {
      const { backgroundColor } = card;
      return backgroundColor || brandColor;
    }
    return brandColor;
  }),

  Recompose.withState(
    'selectedSlideIndex',
    'setSelectedSlideIndex',
    ({ initialSlide }) => initialSlide,
  ),

  Recompose.withState(
    'selectedSlideIsBackgroundColorSolid',
    'setSelectedSlideIsBackgroundColorSolid',
    () => false,
  ),

  Recompose.withProps(({ index, selectedBrandColor, photoUrl }) => {
    function photoParams(index) {
      const params = getPhotoParams(index, selectedBrandColor);

      const filterQuery = Object.keys(params.filters)
        .map((key) => `${key}=${encodeURIComponent(params.filters[key])}`)
        .join('&');

      const a = window.document.createElement('a');
      a.href = photoUrl;
      const backgroundMediaUrl = filterQuery
        ? `${a.protocol}//${a.host}${a.pathname}?${filterQuery}`
        : photoUrl;

      return { ...params, backgroundMediaUrl };
    }
    return {
      photoParams,
    };
  }),

  Recompose.lifecycle({
    componentWillReceiveProps({ photoUrl, selectedPhotoUrl, setSelectedPhotoUrl }) {
      if (this.props.photoUrl !== photoUrl && photoUrl !== selectedPhotoUrl) {
        setSelectedPhotoUrl(photoUrl);
      }
    },
  }),
);
