import React from 'react';
import { compose, pure, withState, withHandlers } from 'recompose';
import TextInput from 'components/ui/base/text-input';
import styled from 'styled-components/macro';
import BaseLabel from './ui/label';
import FormButton from './ui/form-button';
import Field from './ui/field';

const TagInput = styled(TextInput)`
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  background-color: #fafafa;

  ::placeholder {
    color: ${(props) => props.theme.colors.alphaBlack40};
  }

  :focus {
    border: 1px solid ${(props) => props.theme.colors.veryDarkPurple};
    outline: none;
    box-shadow: none;
  }
`;

const TagList = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 10px;

  height: 150px;
`;

const Tag = styled.button`
  background-color: white;
  margin: 4px 8px 4px 0;
  padding: 4px 10px;
  font-size: 15px;
  color: ${(props) => props.theme.colors.veryDarkPurple};
  border: 2px solid ${(props) => props.theme.colors.veryDarkPurple};
  border-radius: 6px;
  text-align: center;

  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.veryDarkPurple};
    color: white;
    outline: none;
  }
`;

const Hint = styled.p`
  color: #aaaaaa;
  font-size: 13px;
  margin-top: -3px;
  margin-bottom: 15px;
`;

const AutoCompleteField = ({
  displayFormButton,
  expanded,
  handleOnChange,
  hidden,
  hint,
  onSubmit,
  setTagList,
  submitText,
  tagList,
  updateInputWithTagValue,
  valid,
  label,
  styledLabel: Label,
  ...props
}) => {
  return (
    <Field hidden={hidden}>
      <Label children={label} />
      <TagInput {...props} onChange={handleOnChange} />
      {expanded && <Hint children={hint} />}
      {expanded && (
        <React.Fragment>
          <TagList>
            {tagList.map((tag) => (
              <Tag key={tag} onClick={updateInputWithTagValue} children={tag} />
            ))}
          </TagList>
          {displayFormButton && (
            <FormButton disabled={!valid} children={submitText} onClick={onSubmit} />
          )}
        </React.Fragment>
      )}
    </Field>
  );
};

AutoCompleteField.defaultProps = {
  expanded: true,
  hidden: false,
  displayFormButton: true,
  styledLabel: BaseLabel,
};

const filterTagList = (tags, value) => {
  return value ? tags.filter((tag) => tag.toLowerCase().startsWith(value.toLowerCase())) : tags;
};

export default compose(
  withState('tagList', 'setTagList', (props) => props.tags),
  withHandlers({
    handleOnChange: (props) => (event) => {
      const { setTagList, name, onChange, tags } = props;
      const { value } = event.target;

      setTagList(filterTagList(tags, value));
      onChange(name, value);
    },

    updateInputWithTagValue: (props) => (event) => {
      const { name, onChange } = props;
      const { innerText: value } = event.target;

      event.preventDefault();
      onChange(name, value);
    },
  }),
  pure,
)(AutoCompleteField);
