import TwitterCard from 'components/cards/preview/twitter';
import routes from 'config/routes';
import { compose, lifecycle, withProps } from 'lib/recompose';
import set from 'lodash/set';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setSelectedPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* Not entirely sure why we need this but we can't scroll without it */
  height: 1px;
`;

function TwitterForm(props) {
  return (
    <CardContainer>
      <TwitterCard {...props} renderHeader={false} renderFormFields={true} />
    </CardContainer>
  );
}

const withRedux = connect(
  (state) => ({ deckViewstate: state.deckViewstate }),
  (dispatch) => ({ setSelectedPopover: (popover) => dispatch(setSelectedPopover(popover)) }),
);

export default compose(
  withRedux,
  withRouter,

  // This form initialization is for the form that displays in the popover
  withProps(({ setFormState, cta }) => ({
    initializeNewTwitter() {
      const id = `new-${Math.random().toString(36).substring(7)}`;
      setFormState((state) => {
        let newState = set({ ...state }, 'entities.twitters', {
          id,
          username: '',
          feed: '',
        });
        return set({ ...newState }, 'result.twitter', id);
      });
      return id;
    },
  })),

  withProps(({ card }) => ({ buttonColor: card.buttonColor })),

  withProps(({ data: { loading, defaultDeck }, currentAccount: { username }, card }) => {
    if (loading) return;
    const { activeCards } = defaultDeck;
    const cardIndex = activeCards.findIndex((c) => c.id === card.id);
    const nextCard = activeCards[cardIndex + 1];
    const prevCard = activeCards[cardIndex - 1];
    const redirectCard = nextCard || prevCard;

    return {
      editCardUrl: routes.accountCardEditPath(username, card.id),
      postDeleteRedirectUrl: routes.accountCardPath(username, redirectCard.id),
    };
  }),

  lifecycle({
    componentDidMount() {
      if (!this.props.formState.result.twitter) {
        this.props.initializeNewTwitter();
      }
      if (!this.props.card.twitter) {
        this.props.setSelectedPopover('twitter-config');
        this.props.history.push(this.props.editCardUrl);
      }
    },
  }),
)(TwitterForm);
