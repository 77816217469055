import React from 'react';
import { compose, withHandlers } from 'lib/recompose';
import { withRouter } from 'react-router';
import { GradientButton } from 'components/cards/forms/shared-ui';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import config from 'config';
import routes from 'config/routes';
import styled from 'styled-components/macro';

const Button = styled(GradientButton)`
  border-radius: 4rem;
  padding: 1.5rem;
  color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

export function AuthButton(props) {
  const { handleMailChimpLogin, text, disabled } = props;
  return (
    <Button
      start="#52bad5"
      end="#007c89"
      disabled={disabled}
      onClick={handleMailChimpLogin}
      children={text}
    />
  );
}

const toQuerystring = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

export default compose(
  withRouter,
  withIdentityAccounts,

  withHandlers({
    handleMailChimpLogin: (props) => async (evt) => {
      evt && evt.preventDefault();

      const provider = 'mailchimp';
      const redirectTo = props.location.pathname;
      const accountId = props.currentAccount ? props.currentAccount.id : props.accounts[0].id;
      const username = props.currentAccount
        ? props.currentAccount.username
        : props.accounts[0].username;

      localStorage.setItem(
        config.MAILCHIMP_AUTH_LS_KEY,
        JSON.stringify({ redirectTo, accountId, username, provider }),
      );

      const redirect_uri = `${window.location.origin}${routes.oauthMailChimpCallbackPath}`;
      const client_id = config.mailchimp.clientId;
      const response_type = 'code';

      const querystring = toQuerystring({
        redirect_uri,
        client_id,
        response_type,
      });

      const url = 'https://login.mailchimp.com/oauth2/authorize?' + querystring;

      window.location.assign(url);
    },
  }),
)(AuthButton);
