import React from 'react';
import { compose, pure } from 'recompose';
import { BrowserRouter as Router } from 'react-router-dom';
import { withStoredParams } from '@tap-bio/core/hocs';

export function ApplicationRouter(props) {
  return <Router children={props.children} />;
}

export default compose(pure, withStoredParams)(ApplicationRouter);
