import React from 'react';
import { compose, defaultProps, lifecycle, withHandlers } from 'recompose';
import { Container, Description, Select } from './styles';

function MailChimpAccountSelector(props) {
  const { identities, selected, onChange } = props;
  return (
    <Container>
      <Select
        value={selected}
        onChange={onChange}
        onBlur={(event) => {
          // Webview bugfix for iOS 12
          // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
          // https://openradar.appspot.com/radar?id=5018321736957952
          window.scrollTo(0, 0);
        }}
      >
        {identities.map(({ id, username }, i) => (
          <option key={i} value={id}>
            {username}
          </option>
        ))}
      </Select>
      <Description>Your connected MailChimp account.</Description>
    </Container>
  );
}

export default compose(
  defaultProps({
    selected: '',
    onSet: () => null,
  }),

  lifecycle({
    componentDidMount() {
      const { selected, onSet, identities } = this.props;
      if (!selected) {
        onSet(identities[0].id);
      }
    },
  }),

  withHandlers({
    onChange:
      ({ onSet }) =>
      (evt) => {
        evt && evt.preventDefault();
        onSet(evt.target.value);
      },
  }),
)(MailChimpAccountSelector);
