import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 512 512"
    fill="currentColor"
    {...props}
  />
);
