import React from 'react';
import * as Redux from 'react-redux';
import routes from 'config/routes';
import { css } from 'styled-components';
import { Text } from 'components/web-ui';
import { RouterLink as BaseRouterLink } from 'components/ui';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Check } from 'components/icons';
import * as Recompose from 'lib/recompose';
import Button from 'components/ui/button';
import Avatar from 'components/avatar';
import styled from 'styled-components/macro';
import { hideMoreMenu } from 'store/modules/more-menu';

const disabledAccountLinkStyles = css`
  :hover,
  :active {
    background-color: none;
    outline: none;
    box-shadow: none;
    opacity: 1;
  }
`;

const AccountLink = styled(BaseRouterLink)`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dividerGrey};
  ${({ disabled }) => disabled && disabledAccountLinkStyles};
`;

const AvatarContainer = styled.div`
  position: relative;
  margin-right: 15px;
`;

const CheckMark = styled.div`
  margin-left: auto;
  background-color: ${(p) => p.theme.colors.appleBlue};
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 0.65em;
    height: 0.65em;
    fill: white;
  }
`;

const UsernameContainer = styled.div`
  color: ${(p) => (p.active ? p.theme.colors.appleBlue : '#021431')};
  font-weight: ${(p) => (p.active ? '500' : 'regular')};
`;

const Username = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const Plan = styled(Text)`
  text-transform: uppercase;
  display: block;
  color: #999999;
  font-size: 75%;
`;

const UpgradeAccountButton = styled(Button)`
  margin-top: 4px;
  width: auto;
  padding: 0.5em 1em;
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  color: ${({ theme }) => theme.colors.white};

  :hover {
    background-color: ${({ theme }) => theme.colors.primaryPurple};
    opacity: 0.75;
  }

  :active {
    background-color: ${({ theme }) => theme.colors.primaryPurple};
    box-shadow: none;
    opacity: 0.75;
    outline: none;
  }
`;

function AccountItem(props) {
  const { linkProps, isCurrent } = props;
  const { username, enabled, profile } = props.account;
  const { status, planStripeId } = props.account.owner.stripeSubscription;
  const isOnTrial = status === 'trialing';

  return (
    <AccountLink p={15} mr={13} {...linkProps} disabled={!enabled}>
      <AvatarContainer>
        <Avatar image={profile.pictureUrl} username={username} active={isCurrent} />
      </AvatarContainer>
      <UsernameContainer active={isCurrent}>
        <Username children={username} />
        <Plan>
          {planStripeId.replace(/-/g, ' ')}
          {isOnTrial && ' (Trialing)'}
        </Plan>
        {!enabled && (
          <UpgradeAccountButton
            children="Upgrade to edit"
            onClick={props.handleUpgradeAccountClick}
          />
        )}
      </UsernameContainer>
      {isCurrent && (
        <CheckMark>
          <Check />
        </CheckMark>
      )}
    </AccountLink>
  );
}

export default Recompose.compose(
  withRouter,

  Redux.connect(null, (dispatch, ownProps) => ({
    hideMoreMenu: () => dispatch(hideMoreMenu()),
  })),

  Recompose.withHandlers({
    handleUpgradeAccountClick: (props) => (event) => {
      const { match, history } = props;
      const route = routes.billing.changeProduct(match.params.accountUsername);
      history.push(route);
    },
  }),

  Recompose.withProps((props) => {
    const {
      account: { username, enabled },
    } = props;
    const linkProps = {
      to: routes.account(username),
      as: Link,
      onClick: (event) => props.hideMoreMenu(),
    };
    // If an account is disabled, clicking on it should do nothing
    if (!enabled) {
      linkProps['onClick'] = (e) => e.preventDefault();
    }
    return { linkProps };
  }),
)(AccountItem);
