import React from 'react';
import { compose, withHandlers } from 'recompose';
import set from 'lodash/set';
import ChooseBackgroundFilter from 'components/image-upload/choose-background-filter';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import { setSelectedPopover } from 'store/modules/card-popovers';
import { setUploadedBackgroundUrl } from 'store/modules/card-upload-background';
import { setSelectedBackgroundImage } from 'store/modules/card-select-background';

const BackgroundImage = ({
  card,
  currentAccount,
  data: { defaultDeck },
  formState,
  handleCardFormSubmit,
  isPersisting,
  selectedCallToAction,
  setFormState,
  setUploadedBackgroundUrl,
  unsetPopover,
  onImageSelected,
}) => (
  <ChooseBackgroundFilter
    handleSubmit={async (url, backgroundColor, backgroundColorSolid) => {
      // Because the save is happening immediately, and setFormState is an asynchronous operation,
      // (with no callback that allows you to access the new state) we're setting the background
      // URL into the state and immediately sending it to save.
      const newCard = { ...formState };
      set(newCard, 'result.backgroundMediaUrl', url);
      set(newCard, 'result.backgroundColor', backgroundColor);
      set(newCard, 'result.backgroundColorSolid', backgroundColorSolid);
      const saveWasSuccessful = await handleCardFormSubmit(newCard, defaultDeck.id);
      if (saveWasSuccessful) {
        unsetPopover();
        setUploadedBackgroundUrl(null);
      }
    }}
    onImageSelected={onImageSelected}
    card={card}
    profile={currentAccount.profile}
  />
);

// prettier-ignore
export default compose(
  connect(
    null,
    dispatch => ({
      unsetPopover: () => dispatch(unsetPopover()),
      setSelectedPopover: name => dispatch(setSelectedPopover(name)),
      setUploadedBackgroundUrl: url => dispatch(setUploadedBackgroundUrl(url)),
      setSelectedBackgroundImage: (image, file) => dispatch(setSelectedBackgroundImage(image, file)),
    }),
  ),
  withHandlers({
    onImageSelected: ({ setSelectedBackgroundImage, setSelectedPopover }) => (image, file) => {
      setSelectedBackgroundImage(image, file);
      setSelectedPopover('background-resize');
    },
  }),
)(BackgroundImage);
