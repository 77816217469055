import { graphql } from '@apollo/react-hoc';
import { currentIdentityQuery } from 'lib/graphql/queries';

const currentAccountFinder =
  (username = '') =>
  (account = {}) => {
    return account.username.toLowerCase() === username.toLowerCase();
  };

export default graphql(currentIdentityQuery, {
  props: ({ ownProps, data }) => {
    if (data.loading) return { data };
    if (!data.currentIdentity) return;

    const { accountUsername } = ownProps.match.params;
    const currentIdentity = data.currentIdentity;
    const accounts = currentIdentity.accounts || [];
    const currentAccount = accounts.find(currentAccountFinder(accountUsername));
    const currentUser = (currentAccount || {}).owner || accounts[0].owner || ownProps.currentUser;

    return { accounts, currentAccount, currentIdentity, currentUser, data };
  },
});
