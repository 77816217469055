import React from 'react';
import { Text } from 'components/web-ui';
import { branch, compose, pure, renderNothing } from 'recompose';
import styled from 'styled-components/macro';

const ChoosePlanMessage = styled.div`
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-top: 0.5em;
  color: rgba(0, 0, 0, 0.4);
`.withComponent(({ isVisible, ...props }) => <Text {...props} />);

export default compose(
  //
  branch(({ isVisible }) => !isVisible, renderNothing),
  pure,
)(ChoosePlanMessage);
