import React from 'react';
import Svg from './svg';

export default (props) => (
  <Svg {...props}>
    <path d="M256 46.1c68.4 0 76.4.3 103.5 1.5 24.9 1.2 38.5 5.3 47.5 8.8a79.5 79.5 0 0 1 29.4 19.2c8.5 8.2 15 18.3 19.2 29.4 3.5 9 7.6 22.6 8.8 47.5 1.2 27 1.5 35.1 1.5 103.5s-.3 76.5-1.5 103.5c-1.2 24.9-5.3 38.5-8.8 47.5a84.8 84.8 0 0 1-48.6 48.6c-9 3.5-22.6 7.6-47.5 8.8-27 1.2-35.1 1.5-103.5 1.5s-76.5-.3-103.5-1.5c-24.9-1.2-38.5-5.3-47.5-8.8a79.3 79.3 0 0 1-29.4-19.2A79.3 79.3 0 0 1 56.4 407c-3.5-9-7.6-22.6-8.8-47.5-1.2-27-1.5-35.1-1.5-103.5s.3-76.4 1.5-103.5c1.2-24.9 5.3-38.5 8.8-47.5a79.5 79.5 0 0 1 19.2-29.4c8.3-8.5 18.3-15 29.4-19.2 9-3.5 22.6-7.6 47.5-8.8 27-1.2 35.1-1.5 103.5-1.5zm0-46c-69.5 0-78.2.2-105.6 1.5a187.6 187.6 0 0 0-62 11.8C71.1 20 55.7 30 43 43a125.5 125.5 0 0 0-29.6 45.3c-6.3 16.3-10.6 35-11.9 62.2C.3 177.7 0 186.5 0 256s.3 78.3 1.5 105.6c1.3 27.2 5.6 45.8 12 62 6.4 17.2 16.4 32.6 29.5 45.4 12.7 13 28.2 23.1 45.3 29.6a188.5 188.5 0 0 0 62.1 11.9c27.4 1.2 36 1.5 105.6 1.5 69.5 0 78.3-.3 105.6-1.5 27.2-1.3 45.8-5.6 62-12a131 131 0 0 0 75-74.8 188.2 188.2 0 0 0 11.9-62.1c1.2-27.4 1.5-36 1.5-105.6 0-69.5-.3-78.3-1.5-105.6a188.2 188.2 0 0 0-12-62C492.2 71.1 482.2 55.7 469 43a125.5 125.5 0 0 0-45.3-29.6c-16.3-6.3-35-10.6-62.2-11.9C334.3.3 325.5 0 256 0z" />
    <path d="M255 124a131 131 0 1 0 0 262 131 131 0 0 0 0-262zm0 216a85 85 0 1 1 0-170 85 85 0 0 1 0 170z" />
    <circle cx="393" cy="119" r="31" />
  </Svg>
);
