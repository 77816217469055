"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.ViewHeaderTitle = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ViewHeaderTitle = _styledComponents["default"].h1.withConfig({
  displayName: "title__ViewHeaderTitle",
  componentId: "sc-2pmbhm-0"
})(["flex:3;font-size:1.25em;line-height:1.1;margin:0;"]);

exports.ViewHeaderTitle = ViewHeaderTitle;
ViewHeaderTitle.propTypes = {
  children: _propTypes["default"].any.isRequired
};
ViewHeaderTitle.displayName = 'ViewHeaderTitle';
var _default = ViewHeaderTitle;
exports["default"] = _default;