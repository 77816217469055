import React from 'react';
import styled from 'styled-components/macro';
import SubmitButton from 'components/authentication/submit-button';
import LoginLayout from 'components/authentication/login/layout';
import CodeInput from 'components/authentication/code-input';
import Spinner from 'components/ui/spinner';
import routes from 'config/routes';

const Hint = styled.p``;

function VerifyForm(props) {
  return (
    <LoginLayout backTo={routes.loginPath}>
      <Hint>Check your email and enter the code below</Hint>
      <CodeInput {...props} />
      <SubmitButton
        disabled={props.formErrors.any || props.formIsPersisting}
        light
        onClick={props.onSubmit}
      >
        {props.formIsPersisting ? <Spinner fill="rgba(255,255,255,1)" height="50px" /> : 'Verify'}
      </SubmitButton>
    </LoginLayout>
  );
}

export default VerifyForm;
