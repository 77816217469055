import BackgroudPhotoCropper from 'components/image-upload/crop-background-photo';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { setSelectedPopover } from 'store/modules/card-popovers';
import { unsetSelectedBackgroundImage } from 'store/modules/card-select-background';
import { setUploadedBackgroundUrl } from 'store/modules/card-upload-background';

function BackgroundImageCropper(props) {
  const { onCancel, onCompletedUpload } = props;
  return <BackgroudPhotoCropper onCancel={onCancel} onCompletedUpload={onCompletedUpload} />;
}

export default compose(
  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setUploadedBackgroundUrl: (url) => dispatch(setUploadedBackgroundUrl(url)),
    unsetSelectedBackgroundImage: () => dispatch(unsetSelectedBackgroundImage()),
  })),
  withHandlers({
    onCancel:
      ({ unsetSelectedBackgroundImage, setSelectedPopover }) =>
      () => {
        setSelectedPopover('background-image');
        unsetSelectedBackgroundImage();
      },
    onCompletedUpload:
      ({ unsetSelectedBackgroundImage, setUploadedBackgroundUrl, setSelectedPopover }) =>
      (name, url) => {
        setUploadedBackgroundUrl(url);
        setSelectedPopover('background-image');
        unsetSelectedBackgroundImage();
      },
  }),
)(BackgroundImageCropper);
