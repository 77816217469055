import { loader } from 'graphql.macro';

const addAccountToCurrentIdentityMutation = loader('./add-account-to-current-identity.gql');
const addConstantContactIdentityMutation = loader('./add-constant-contact-identity.gql');
const addEmailOrSmsIdentityMutation = loader('./add-email-or-sms-identity.gql');
const addInstagramIdentityMutation = loader('./add-instagram-identity.gql');
const addMailchimpIdentityMutation = loader('./add-mailchimp-identity.gql');
const createSessionChallengeMutation = loader('./create-session-challenge.gql');
const createSessionResponseMutation = loader('./create-session-response.gql');
const destroyImageMutation = loader('./destroy-image.gql');
const subscribeMailingListMutation = loader('./subscribe-mailing-list.gql');
const removeAccountIdentityAssociationMutation = loader(
  './remove-account-identity-association.gql',
);
const updateAccountIdentityMutation = loader('./update-account-identity.gql');
const updateAccountProfileMutation = loader('./update-account-profile.gql');
const updateAccountUsernameMutation = loader('./update-account-username.gql');
const updateCardMutation = loader('./update-card.gql');
const updateUserMutation = loader('./update-user.gql');
const createCardMutation = loader('./create-card.gql');
const billingAddSourceMutation = loader('./billing-add-source.gql');
const billingChangePlanMutation = loader('./billing-change-plan.gql');
const toggleCardHiddenMutation = loader('./toggle-card-hidden.gql');
const destroyCardMutation = loader('./destroy-card.gql');
const updateDeckSortOrderMutation = loader('./update-deck-sort-order.gql');

export {
  addAccountToCurrentIdentityMutation,
  addConstantContactIdentityMutation,
  addEmailOrSmsIdentityMutation,
  addInstagramIdentityMutation,
  addMailchimpIdentityMutation, billingAddSourceMutation,
  billingChangePlanMutation, createCardMutation, createSessionChallengeMutation,
  createSessionResponseMutation, destroyCardMutation, destroyImageMutation, removeAccountIdentityAssociationMutation, subscribeMailingListMutation, toggleCardHiddenMutation, updateAccountIdentityMutation,
  updateAccountProfileMutation,
  updateAccountUsernameMutation,
  updateCardMutation, updateDeckSortOrderMutation, updateUserMutation
};

