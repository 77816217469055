import * as Icons from '@tap-bio/ui/icons';
import ListView from '@tap-bio/ui/patterns/list-view';
import ViewHeader from '@tap-bio/ui/patterns/view-header';
import config from 'config';
import routes from 'config/routes';
import React from 'react';
import * as Styled from './styled';

function getPlanTitle(props) {
  const { planStripeId, status } = props.currentAccount.owner.stripeSubscription;
  const trial = status === 'trialing' ? ' (trial)' : '';
  const level = planStripeId.split('-')[0];
  const titleCaseLevel = level.charAt(0).toUpperCase() + level.substr(1).toLowerCase();
  return titleCaseLevel + trial;
}

function AccountMenuComponent(props) {
  const { isShowing, hideMoreMenu, landingPageUrl, currentIdentity, deck, navigateTo } = props;
  const { username } = props.currentAccount;

  return (
    isShowing && (
      <Styled.Layout>
        <Styled.Header>
          <ViewHeader>
            <ViewHeader.LeftButton children="Close" onClick={hideMoreMenu} />
            <ViewHeader.Title children="Your Account" />
            <ViewHeader.LeftButton children="" />
          </ViewHeader>
        </Styled.Header>

        <Styled.Content>
          <ListView>
            <ListView.Section title={`Account: ${username}`}>
              <ListView.Item
                inline
                title="Your Cards"
                actionIcon={<Icons.ArrowChevronRight />}
                onClick={navigateTo(routes.allCardsPath(username))}
              />
              <ListView.Item
                inline
                title="Switch Accounts"
                subtitle={username}
                actionIcon={<Icons.ArrowChevronRight />}
                onClick={navigateTo(routes.accountSwitch(username))}
              />
              {currentIdentity && (
                <ListView.Item
                  inline
                  title="Account Access"
                  actionIcon={<Icons.ArrowChevronRight />}
                  onClick={navigateTo(routes.accountAccess(username))}
                />
              )}
              {currentIdentity && (
                <ListView.Item
                  inline
                  title="Account Notifications"
                  actionIcon={<Icons.ArrowChevronRight />}
                  onClick={navigateTo(routes.accountNotifications(username))}
                />
              )}

              <ListView.Item
                inline
                title="Edit Profile"
                actionIcon={<Icons.ArrowChevronRight />}
                onClick={navigateTo(
                  routes.accountCardEditPath(
                    username,
                    deck.activeCards.find((card) => card.kind === 'profile').id,
                  ),
                )}
              />

              <ListView.Item
                inline
                title="Change Username"
                actionIcon={<Icons.ArrowChevronRight />}
                onClick={navigateTo(routes.accountChangeUsername(username))}
              />
            </ListView.Section>

            <ListView.Section title="Billing">
              <ListView.Item
                inline
                title="Your Plan"
                subtitle={getPlanTitle(props)}
                actionIcon={<Icons.ArrowChevronRight />}
                onClick={navigateTo(routes.billing.changeProduct(username))}
              />
              {props.currentUser.stripeSource && (
                <ListView.Item
                  inline
                  title="Update Payment Method"
                  actionIcon={<Icons.ArrowChevronRight />}
                  onClick={navigateTo(routes.billing.updateCreditCard(username))}
                />
              )}
            </ListView.Section>

            <ListView.Section>
              <ListView.Item
                inline
                title="Follower View"
                actionIcon={<Icons.NavigationExternal />}
                onClick={() => (window.location = `${config.landingHost}/@${username}`)}
              />

              <ListView.Item
                id="copy-link"
                data-clipboard-text={landingPageUrl}
                inline
                onClick={(event) => event.preventDefault()}
                title="Copy Profile Link"
                actionIcon={<Icons.Clipboard />}
              />

              <ListView.Item
                title="Logout"
                onClick={(event) => {
                  props.hideMoreMenu();
                  navigateTo(routes.logoutPath)(event);
                }}
                actionIcon={<Icons.NavigationExit />}
              />
            </ListView.Section>
            <ListView.Section>
              <ListView.Item
                title="Privacy Policy"
                onClick={(event) => {
                  window.location.href = 'https://marketing.tap.bio/privacy-policy';
                }}
                actionIcon={<Icons.NavigationExternal />}
              />
              <ListView.Item
                title="Terms of Service"
                onClick={(event) => {
                  window.location.href = 'https://marketing.tap.bio/terms';
                }}
                actionIcon={<Icons.NavigationExternal />}
              />
              <ListView.Item
                title="Support"
                id="launch-intercom"
                onClick={hideMoreMenu}
                actionIcon={<Icons.LifeSaver />}
              />
            </ListView.Section>
          </ListView>
        </Styled.Content>
      </Styled.Layout>
    )
  );
}
export default AccountMenuComponent;
