import React from 'react';
import styled from 'styled-components/macro';
import Plan from './plan';

const PlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

function Plans(props) {
  const { discounts, plans, handleSelectPlan, selectedPlanStripeId } = props;
  return (
    <PlansContainer>
      {plans.map((plan) => (
        <Plan
          discounts={discounts}
          isSelected={plan.stripeId === selectedPlanStripeId}
          key={plan.stripeId}
          onClick={handleSelectPlan.bind(this, plan)}
          plan={plan}
        />
      ))}
    </PlansContainer>
  );
}

export default Plans;
