import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhotoForm from '../photo-form';
import './edit-photo-details.css';

export default class EditPhotoDetails extends Component {
  static propTypes = {
    instagramPhoto: PropTypes.object.isRequired,
    lipPhoto: PropTypes.object,
    updatePhotoState: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  getClasses() {
    const isPinned = (this.props.lipPhoto || {}).view_status === 'pinned';
    return classNames('edit-photo-details', { '-is-pinned': isPinned });
  }

  render() {
    const { cardId, instagramPhoto, lipPhoto, user } = this.props;
    return (
      <div className={this.getClasses()}>
        <PhotoForm
          cardId={cardId}
          defaultLink={user.default_link || {}}
          instagramPhoto={instagramPhoto}
          lipPhoto={lipPhoto}
          updatePhotoState={this.props.updatePhotoState}
          user={user}
        />
      </div>
    );
  }
}
