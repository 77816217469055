import React from 'react';
import PropTypes from 'prop-types';
import * as StaggeredGrid from '@tap-bio/ui/patterns/staggered-grid';
import { AccountTeaserCard } from '@tap-bio/ui/patterns/account-teaser-card';
import config from 'config';

function GridList(props) {
  return (
    <StaggeredGrid.Layout n={props.friends.length}>
      {props.friends.map((friend) => {
        return (
          <StaggeredGrid.GridSpace key={`friend-${friend.id}`}>
            <AccountTeaserCard
              username={friend.username}
              name={friend.name}
              featuredText={friend.featuredText}
              backgroundImage={friend.defaultBackgroundMediaUrl}
              title={friend.title}
              href={config.landingHost + '/@' + friend.username}
            />
          </StaggeredGrid.GridSpace>
        );
      })}
    </StaggeredGrid.Layout>
  );
}

GridList.propTypes = {
  friends: PropTypes.array,
};

GridList.defaultProps = {
  friends: [],
};

export default GridList;
