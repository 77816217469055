import styled from 'styled-components/macro';
import { deprecatedRebassProps } from './deprecated';

const Label = styled.label`
  margin-bottom: 8px;
  font-size: 0.875em;
  display: flex;
  align-items: center;
`;

Label.propTypes = {
  ...deprecatedRebassProps,
};
export default Label;
