export default function augmentPlanWithDiscount(discount, plan, key = 'plan') {
  if (!discount) return;

  let originalAmount = plan.amount;
  let discountedAmount = plan.amount;
  if (discount.percentOff) {
    discountedAmount = originalAmount - originalAmount * (discount.percentOff / 100);
  }
  if (discount.amountOff) {
    discountedAmount = originalAmount - discount.amountOff;
  }

  return { [key]: { ...plan, discountedAmount } };
}
