import React from 'react';

function NotFoundGhost(props) {
  return (
    <svg height={props.width} {...props}>
      <path d="M63.5 26A28.5 28.5 0 0 1 92 54.5V69h10.6a1 1 0 1 1 0 2H92v6h9a1 1 0 1 1 0 2h-9v21.5c.2.4.3 1-.1 1.5-.3.3-.7.5-1.2.5-5.3 0-10.1-3-12.6-7.3v4.9l.1.4c.1.4.2 1-.2 1.5-.2.3-.6.5-1.1.5-5.3 0-10.2-3-12.6-7.3V100.5c.2.4.3 1-.1 1.5-.3.3-.7.5-1.2.5-5.3 0-10.1-3-12.5-7.3V100.5c.1.4.2 1-.2 1.5-.2.3-.6.5-1.1.5-7.6 0-14.2-6.2-14.2-13.1V54.5A28.5 28.5 0 0 1 63.5 26zM37 89.4c0 5.6 5.4 10.7 11.5 11V86h2.3v3.4c0 5.6 5.4 10.7 11.5 11V86h2.4v3.4c0 5.6 5.4 10.7 11.5 11V86h2.3v3.4c0 5.6 5.4 10.7 11.5 11V79H78.3a1 1 0 1 1 0-2H90v-6h-1.7a1 1 0 1 1 0-2H90V54.5a26.5 26.5 0 0 0-53 0v34.9zm26.5-45c2.6 0 4.8 2.1 4.8 4.8v6a4.8 4.8 0 0 1-9.6 0v-6c0-2.7 2.1-4.8 4.8-4.8zm-2.8 10.8a2.8 2.8 0 0 0 5.6 0v-6a2.8 2.8 0 0 0-5.6 0v6zm-9.2 4.4a4.8 4.8 0 0 1-4.8-4.8v-6a4.8 4.8 0 0 1 9.6 0v6c0 2.7-2.2 4.8-4.8 4.8zm2.8-10.8a2.8 2.8 0 0 0-5.6 0v6a2.8 2.8 0 0 0 5.6 0v-6zM106.9 70c0-.6.4-1 1-1h3.2a1 1 0 1 1 0 2h-3.2a1 1 0 0 1-1-1zm-45.6-1h11.6a1 1 0 1 1 0 2H61.3a1 1 0 1 1 0-2zm-15 8.2h10.3a.8.8 0 1 1 0 1.6H46.3a.8.8 0 1 1 0-1.6zm15.6-.2H65a1 1 0 1 1 0 2h-3.2a1 1 0 1 1 0-2zm36.7 7.1h8.7a.9.9 0 0 1 0 1.8h-8.7a.9.9 0 0 1 0-1.8z" />
    </svg>
  );
}

NotFoundGhost.defaultProps = {
  width: 128,
  viewBox: '0 0 128 128',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
};

export default NotFoundGhost;
