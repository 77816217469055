import UpdateCreditCardSubscriptionItem from 'components/billing/update-credit-card-subscription-item';
import ErrorBoundary from 'components/errors/error-boundary';
import { ScreenContainer } from 'components/ui';
import routes from 'config/routes';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from './header';

/**
 * @summary Determines if an account has a delinquent subscription
 * @param   {Object} account - The account to test
 * @returns {Boolean} - Whether the account was delinquent or not
 */
function isDelinquent(account) {
  return ['past_due', 'unpaid'].includes(account.owner.stripeSubscription.status);
}

/**
 * @summary Determines if an account is on the free plan
 * @param   {Object} account - The account to test
 * @returns {Boolean} - Whether the account was on the free plan or not
 */
function isFreePlan(account) {
  return account.owner.stripeSubscription.planStripeId === 'basic-plan';
}

/**
 * @summary Determines which accounts have delinquent subscriptions
 * @param   {Array} accounts - The accounts to search
 * @returns {Array} - The delinquent accounts
 */
function fetchDelinquentAccounts(accounts) {
  const delinquentAccounts = [];
  accounts.forEach((account) => {
    if (isDelinquent(account) && !isFreePlan(account)) {
      delinquentAccounts.push(account);
    }
  });
  return delinquentAccounts;
}

// We're using Baremetrics for dunning. In the dunning emails we'll use a direct link to this
// component which will determine which account is delinquent and redirect to an update card
// screen for the appropriate user.

const UpdateCreditCardChooser = function (props) {
  const delinquentAccounts = fetchDelinquentAccounts(props.accounts);

  // If all accounts are in good standing, just redirect to the first account
  if (!delinquentAccounts.length) {
    return <Redirect to={{ pathname: routes.accounts }} />;
  }

  // If there's only a single delinquent subscription (e.g. multiple accounts with the same user)
  // We should redirect to the first account
  const ownerIds = delinquentAccounts.map((account) => account.owner.id);
  const isSingleSubscription = new Set(ownerIds).size === 1;
  if (isSingleSubscription) {
    return (
      <Redirect
        to={{ pathname: routes.billing.updateCreditCard(delinquentAccounts[0].username) }}
      />
    );
  }

  // Group the accounts by the owner's subscription id. This is so we can display all accounts
  // under each subscription.
  const subscriptions = groupBy(
    delinquentAccounts,
    (account) => account.owner.stripeSubscription.id,
  );

  // Otherwise, display a list of the accounts that need to have their payment method updated
  return (
    <ErrorBoundary>
      <ScreenContainer p={0}>
        <Header history={props.history} title="Choose Subscription" />
        {Object.entries(subscriptions).map((item) => (
          <UpdateCreditCardSubscriptionItem accounts={item[1]} key={item[0]} />
        ))}
      </ScreenContainer>
    </ErrorBoundary>
  );
};

export default UpdateCreditCardChooser;
