import { withProps } from 'recompose';

const userAgent = (window.navigator.userAgent || '').toLowerCase();
const referer = window.document.referrer || '';

const isStandalone = window.navigator.standalone;
const isSafari = /safari/.test(userAgent);
const isIOS = /iphone|ipod|ipad/.test(userAgent);

const isUIWebView = isIOS && !isStandalone && !isSafari;
const isIGWebView = isUIWebView && referer.indexOf('l.instagram.com') > -1;

export default withProps((props) => ({
  referer,
  userAgent,
  isUIWebView,
  isIGWebView,
}));
