import * as Sentry from '@sentry/browser';
import { graphql } from '@apollo/react-hoc';
import { updateAccountProfileMutation } from 'lib/graphql/mutations';

export default graphql(updateAccountProfileMutation, {
  props: ({ mutate, ownProps }) => ({
    handleSubmit: async ({ accountProfile, currentAccount }) => {
      try {
        await mutate({
          mutation: updateAccountProfileMutation,
          variables: {
            id: currentAccount.id,
            accountProfileInputArguments: accountProfile,
          },
        });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    },
  }),
});
