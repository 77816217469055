import React from 'react';
import { withRouter } from 'react-router';
import routes from 'config/routes';
import { Redirect } from 'react-router';
import { compose, branch, renderNothing, renderComponent, withState } from 'lib/recompose';
import { graphql } from '@apollo/react-hoc';
import { createSessionChallengeMutation } from 'lib/graphql/mutations';
import styled from 'styled-components/macro';
import Spinner from 'components/ui/spinner';

const Button = styled.button`
  border-radius: 1em;
  font-size: 14px;
  width: 100%;
  height: 2em;
  padding: 0 10px 1px 10px;
  display: flex;
  align-items: center;
  color: white;
  background-color: ${(p) => p.theme.colors.primaryPurple};
  border: 0;
  text-align: center;
`;

function IdentityAccessVerifyButton(props) {
  return (
    <Button onClick={props.onClick} disabled={props.loading}>
      {props.loading ? <Spinner /> : 'Verify'}
    </Button>
  );
}

export default compose(
  withRouter,
  branch((props) => props.isVerified || props.identity.provider === 'instagram', renderNothing),
  withState('loading', 'setLoading', false),
  withState('responseData', 'setResponseData', {}),
  withState('needsRefresh', 'setNeedsRefresh', false),
  branch(
    (props) => props.responseData.identity,
    renderComponent(({ responseData, identity, match }) => (
      <Redirect
        push
        to={{
          pathname: `${routes.loginPath}/verify/${responseData.identity.uid}`,
          state: {
            ...responseData.identity,
            action: 'login',
            purpose: 'verify',
            redirectAccountUsername: match.params.accountUsername,
          },
        }}
      />
    )),
  ),

  graphql(createSessionChallengeMutation, {
    props: ({ mutate, ownProps }) => ({
      onClick: () => {
        ownProps.setLoading(true);
        mutate({
          variables: {
            username: ownProps.identity.username,
            provider: ownProps.identity.provider,
            action: 'login',
          },
          update: (proxy, { data: { error, createSessionChallenge } }) => {
            ownProps.setLoading(false);
            if (error) {
              const formErrors = {
                username: "can't find this identity",
              };

              ownProps.setResponseData({
                provider: ownProps.identity.provider,
                error: formErrors,
                errorType: error.type,
              });

              return console.error(error);
            }
            return ownProps.setResponseData(createSessionChallenge);
          },
        });
      },
    }),
  }),
)(IdentityAccessVerifyButton);
