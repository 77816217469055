import backgroundOverlayCSS from 'components/cards/shared/styles/background-overlay-css';
import addLightenFilter from 'components/ui/add-lighten-filter';
import AnimatedBackground from 'components/ui/animated-background';
import React from 'react';
import styled from 'styled-components/macro';

const BackgroundImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${(p) =>
    p.card && p.card.backgroundColorSolid
      ? p.card.backgroundColorSolid
      : p.backgroundAnimated
        ? 'transparent'
        : p.brandColor};
  background-image: url(${(p) =>
    p.card && (p.card.backgroundColorSolid || p.backgroundAnimated)
      ? 'none'
      : p.backgroundMediaUrl});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  /* ---- */
  ${(p) => p.hasBlendMode && addLightenFilter(p.brandColor)};
  /* ---- */
  ${(p) => p.hasBackgroundOverlay && !p.card.backgroundColorSolid && backgroundOverlayCSS};
`;

export default BackgroundImageContainer.withComponent(
  ({
    brandColor,
    backgroundAnimated,
    backgroundMediaUrl,
    hasBlendMode,
    hasBackgroundOverlay,
    card,
    ...rest
  }) => {
    return (
      <React.Fragment>
        {!card.backgroundColorSolid && backgroundAnimated && (
          <AnimatedBackground backgroundMediaUrl={backgroundMediaUrl} />
        )}
        <div data-hash={`card_${card.id}`} {...rest} />;
      </React.Fragment>
    );
  },
);
