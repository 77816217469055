import Back from 'components/authentication/back-button';
import SubmitButton from 'components/authentication/submit-button';
import Logo from 'components/ui/base/logo';
import Field from 'components/ui/field';
import Input from 'components/ui/form-text-input';
import Title from 'components/ui/form-title';
import Spinner from 'components/ui/spinner';
import Turnstile from 'components/ui/turnstile';
import React, { useEffect } from 'react';
import AccountNameHint from './account-name-hint';
import EmailHint from './email-hint';
import * as Styled from './styled';

function Registration(props) {
  // Load Clearbit's Risk API on mount only
  useEffect(() => {
    // Exclude loading this script in tests
    if (process.env.NODE_ENV === 'test') return;

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://risk.clearbit.com/v1/risk.js';
    const parent = document.getElementsByTagName('script')[0];
    parent.parentNode.insertBefore(script, parent);
  }, []);

  return (
    <Styled.Layout>
      <Back to={props.backTo} />
      <Styled.Header>
        <Logo />
      </Styled.Header>
      <Styled.Content>
        <Title children="Create your Tap Bio" />
        <Field
          name="accountName"
          styledInput={Input}
          autoCapitalize="off"
          autoCorrect="off"
          placeholder="Username"
          onChange={props.onFieldChange}
          value={props.formData.accountName}
          validationError={props.formErrors.accountName}
          hint={<AccountNameHint formData={props.formData} formErrors={props.formErrors} />}
        />
        <Field
          name="email"
          styledInput={Input}
          autoCapitalize="off"
          autoCorrect="off"
          placeholder="Email address"
          onChange={props.onFieldChange}
          value={props.formData.email}
          validationError={props.formErrors.email}
          hint={<EmailHint formData={props.formData} formErrors={props.formErrors} />}
        />
        <Turnstile onChange={props.setTurnstileToken} />
        <SubmitButton
          disabled={
            !props.turnstileToken ||
            !props.accountNameValid ||
            props.formErrors.any ||
            props.formIsPersisting
          }
          onClick={props.onSubmit}
        >
          {props.formIsPersisting ? <Spinner fill="rgba(255,255,255,1)" height="50px" /> : 'Next'}
        </SubmitButton>
        <Styled.Text>
          Already have a Tap Bio?
          <Styled.Link children="Sign-in." onClick={props.handleRedirectToLogin} />
        </Styled.Text>
      </Styled.Content>
    </Styled.Layout>
  );
}

export default Registration;
