import React from 'react';
import * as Recompose from 'lib/recompose';
import withDestroyCardMutation from 'hocs/with-destroy-card-mutation';
import { TrashIcon } from 'components/icons';
import { withApollo } from '@apollo/react-hoc';
import routes from 'config/routes';
import withAccountUsername from 'hocs/with-account-username';
import withCurrentCardId from 'hocs/with-current-card-id';
import withDefaultDeck from 'hocs/with-default-deck';
import * as Styled from './styled';

TrashIcon.defaultProps = {
  width: 38,
  height: 18,
};

const deleteConfirmation = () => window.confirm('Do you really want to delete this card?');

export default Recompose.compose(
  withAccountUsername,
  withCurrentCardId,
  withDefaultDeck,

  Recompose.withState('isPersisting', 'setIsPersisting', false),

  withApollo, // Required for `withDestroyCardMutation`
  withDestroyCardMutation,

  Recompose.withProps((props) => {
    return {
      children: React.createElement(TrashIcon),
    };
  }),

  Recompose.branch((props) => props.data.loading, Recompose.renderNothing),
  Recompose.branch((props) => !props.data.defaultDeck, Recompose.renderNothing),

  Recompose.withProps((props) => {
    const { accountUsername } = props;
    const { activeCards } = props.data.defaultDeck;
    const currentCardIndex = activeCards.findIndex((c) => c.id === props.cardId);
    const nextCard = activeCards[currentCardIndex + 1];
    const prevCard = activeCards[currentCardIndex - 1];
    const redirectCard = nextCard || prevCard;
    const route = routes.accountCardPath(accountUsername, redirectCard.id);

    return {
      postDeleteRedirectUrl: route,
    };
  }),

  Recompose.withHandlers({
    onClick: (props) => async (event) => {
      event.preventDefault();

      if (!deleteConfirmation()) return;

      const success = await props.handleCardDestroy(props.cardId, props.accountUsername);

      if (!success) return;

      props.history.replace(props.postDeleteRedirectUrl);
    },
  }),

  Recompose.omitProps(
    'match',
    'client',
    'history',
    'location',
    'postdeleteredirecturl',
    'isVisible',
    'currentAccount',
    'cardId',
    'hideStats',
    'card',
  ),
)(Styled.HeaderButton);
