import Back from 'components/authentication/back-button';
import Logo from 'components/ui/base/logo';
import Title from 'components/ui/form-title';
import BaseLink from 'components/ui/link-with-on-click';
import React from 'react';
import styled from 'styled-components/macro';

const Layout = styled.div`
  padding: 1.5em 20px;
  color: ${(props) => props.theme.colors.veryDarkPurple};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  flex: 1 0 auto;

  display: flex;
  align-items: center;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
`;

const Content = styled.header`
  flex: 3 0 auto;
`;

const Link = styled(BaseLink)`
  color: white;
  font-size: 0.9em;
`;

const Separator = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 14px;
  margin-left: 10px;
  margin-right: 10px;
`;

function RegistrationLayout(props) {
  return (
    <Layout>
      <Back to={props.backTo} />
      <Header>
        <Logo />
      </Header>
      <Content>
        <Title children="Create your Tap Bio" />
        {props.children}
      </Content>
      <Footer>
        <Link href="https://marketing.tap.bio/privacy-policy">Privacy Policy</Link>
        <Separator />
        <Link href="https://marketing.tap.bio/terms">Terms of Service</Link>
      </Footer>
    </Layout>
  );
}

export default RegistrationLayout;
