const production = {
  _config: 'production',
  domain: 'tap.bio',
  apiHost: 'https://api.tap.bio',
  cdnHost: 'https://tb.imgix.net',
  landingHost: 'https://tap.bio',
  dashboardHost: 'https://dashboard.tap.bio',
  featuredProfiles: [
    { username: 'yestheory', featuredText: 'Adventure' },
    { username: 'paleomg', featuredText: 'Food' },
    { username: 'carolinecaracasmarketing', featuredText: 'Marketing Consultant' },
    { username: 'vanessabarajas', featuredText: 'Food' },
    { username: 'mxcchi', featuredText: 'Sports' },
    { username: 'weelicious', featuredText: 'Food' },
    { username: 'dctop20', featuredText: 'Music' },
    { username: 'melissa_hartwig', featuredText: 'Food' },
    { username: 'againstallgrain', featuredText: 'Food' },
    { username: 'aliedwards', featuredText: 'Entrepreneur' },
    { username: 'fit2fat2fit', featuredText: 'Fitness' },
    { username: 'designmom', featuredText: 'Lifestyle' },
    { username: 'nomnompaleo', featuredText: 'Food' },
    { username: 'hairbrained_official', featuredText: 'Professional Community' },
    { username: 'joythebaker', featuredText: 'Food' },
    { username: 'rhettmiller', featuredText: 'Music' },
    { username: 'plantbasednews', featuredText: 'News' },
    { username: 'thepaleomom', featuredText: 'Food' },
    { username: 'suitebrenda', featuredText: 'Social Marketer' },
    { username: 'turntablekitchen', featuredText: 'Online Business' },
    { username: 'archers_all_stars', featuredText: 'Education' },
  ],
  google: {
    cloudStorageMediaBucket: 'tb-production-media',
  },
  instagram: {
    appId: '1698169517407687',
  },
  mailchimp: {
    clientId: '266081311833',
  },
  constantcontact: {
    clientId: 'bxkvgj97xudgxyfh4fr4e9x9',
  },
  stripe: {
    publishableKey: 'pk_live_1mcAGFUcNrKJE5Rvdmo3GAx5',
  },
  sentry: {
    publicDsn: 'https://91a8424f0a6042a8becb4a41bb40c53f@sentry.io/270264',
  },
};

export default production;
