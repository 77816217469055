import withUpdateProfileBackgroundImage from 'hocs/with-update-profile-background-image';
import withUpdateProfileBackgroundColor from 'hocs/with-update-profile-card-background';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withProps } from 'recompose';
import {
  setSelectedBackgroundImage,
  unsetSelectedBackgroundImage,
} from 'store/modules/card-select-background';
import { setUploadedBackgroundUrl } from 'store/modules/card-upload-background';
import { setDeckViewstate } from 'store/modules/deck-viewstate';
import { setOnboardingCard } from 'store/modules/onboarding';

export default compose(
  withRouter,
  withUpdateProfileBackgroundImage,
  withUpdateProfileBackgroundColor,
  withProps(({ cards, match }) => {
    const cardIds = cards.map((card) => card.id);
    return { initialSlide: cardIds.indexOf(parseInt(match.params.cardId, 10)) };
  }),
  connect(
    (state, ownProps) => ({
      uploadedBackgroundPhotoUrl: state.cardUploadBackground.url,
      selectedBackgroundPhoto: state.cardSelectedBackground.image,
      activeIndex: state.deckViewstate.activeIndex,
    }),
    (dispatch) => ({
      setUploadedBackgroundUrl: (url) => dispatch(setUploadedBackgroundUrl(url)),
      setSelectedBackgroundImage: (image, file) =>
        dispatch(setSelectedBackgroundImage(image, file)),
      unsetSelectedBackgroundImage: () => dispatch(unsetSelectedBackgroundImage()),
      setOnboardingCard: (attrs) => dispatch(setOnboardingCard(attrs)),
      setDeckViewstate: (payload) => dispatch(setDeckViewstate(payload)),
    }),
  ),
  withHandlers({
    handleCancelPhotoCropper:
      ({ unsetSelectedBackgroundImage }) =>
      () => {
        unsetSelectedBackgroundImage();
      },
    handleImageUploaded:
      ({ unsetSelectedBackgroundImage, setUploadedBackgroundUrl }) =>
      (name, url) => {
        setUploadedBackgroundUrl(url);
        unsetSelectedBackgroundImage();
      },
  }),
);
