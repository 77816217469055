import Popovers from 'components/cards/forms/popovers/popovers';
import withBackgroundMediaUrl from 'components/cards/shared/hocs/with-background-media-url';
import withHasBackgroundOverlay from 'components/cards/shared/hocs/with-has-background-overlay';
import BackgroundImageContainer from 'components/cards/shared/styles/background-image-container';
import EditModeControls from 'components/headers/card-edit-header';
import routes from 'config/routes';
import withHandleFieldChange from 'hocs/handle-local-field-change';
import withRenderField from 'hocs/render-field';
import withCreateCardMutation from 'hocs/with-create-card-mutation';
import withDefaultDeck from 'hocs/with-default-deck';
import withHasBlendMode from 'hocs/with-has-blend-mode';
import withUpdateCardMutation from 'hocs/with-update-card-mutation';
import withUpdateDeckSortOrderMutation from 'hocs/with-update-deck-sort-order-mutation';
import { normalizeCardData } from 'lib/card-form-utils';
import pick from 'lodash/pick';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { branch, compose, renderComponent, withHandlers, withProps, withState } from 'recompose';
import { setOnboardingCard } from 'store/modules/onboarding';
import styled from 'styled-components/macro';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// --- The Component ---

const BaseCardForm = ({
  FormComponent,
  handleSubmitForm,
  backgroundProps,
  headerProps,
  setOnboardingCard,
  ...props
}) => {
  // Set the currently visible card for onboarding purposes
  useEffect(() => {
    setOnboardingCard({ kind: props.card.kind, id: props.card.id });
  }, [setOnboardingCard, props.card.kind, props.card.id]);

  let children = (
    <React.Fragment>
      <EditModeControls {...headerProps} isEditing={true} />
      <FormContainer>
        <FormComponent {...props} />
      </FormContainer>
      <Popovers {...props} />
    </React.Fragment>
  );

  // If this card has scrollable content we should display the content gradient.
  let hasContentGradient = props.cardOverlayIds.has(props.card.id);

  // Some cards are a special case because the form embeds the preview. This causes
  // the content gradient to display twice so we need to exclude it here.
  if (['twitter', 'youtube'].includes(props.card.kind)) {
    hasContentGradient = false;
  }

  // It's a React error (and just general bad practice) to wrap a form in a form.
  // Our profile form is a special case (it defines its own form) so we should
  // exclude the base form component when rendering the profile form.
  if (props.card.kind !== 'profile') {
    children = (
      <Form hasContentGradient={hasContentGradient} onSubmit={handleSubmitForm}>
        {children}
      </Form>
    );
  }

  return <BackgroundImageContainer {...backgroundProps}>{children}</BackgroundImageContainer>;
};

export default compose(
  withState('validationErrors', 'setValidationErrors', []),
  withState('isPersisting', 'setIsPersisting', false),
  withState('saveComplete', 'setSaveComplete', false),
  withState('formState', 'setFormState', ({ card }) => normalizeCardData(card)),
  connect(
    (state) => ({ cardOverlayIds: state.cardContentOverlay.showOverlayCardIds }),
    (dispatch) => ({ setOnboardingCard: (attrs) => dispatch(setOnboardingCard(attrs)) }),
  ),
  withBackgroundMediaUrl,
  withHasBackgroundOverlay,
  withHasBlendMode,
  withHandleFieldChange,
  withRenderField,
  withDefaultDeck,
  withUpdateDeckSortOrderMutation,

  branch(
    ({ card }) => !card.editable,
    renderComponent(({ card, currentAccount }) => (
      <Redirect to={routes.accountCardPath(currentAccount.username, card.id)} />
    )),
  ),

  withProps((props) => {
    return {
      backgroundProps: {
        ...pick(props, [
          'card',
          'backgroundAnimated',
          'backgroundMediaUrl',
          'hasBackgroundOverlay',
          'hasBlendMode',
        ]),
        ...pick(props.currentAccount.profile, ['brandColor']),
      },
      headerProps: pick(props, [
        'currentAccount',
        'card',
        'isPersisting',
        'data',
        'history',
        'formState',
        'setFormState',
      ]),
    };
  }),
  branch(({ card }) => card.id, withUpdateCardMutation, withCreateCardMutation),
  branch(
    ({ saveComplete }) => saveComplete,
    renderComponent(({ card, currentAccount }) => (
      <Redirect to={routes.accountCardPath(currentAccount.username, card.id)} />
    )),
  ),
  withHandlers({
    handleSubmitForm: (props) => async (event) => {
      event.preventDefault();
      const {
        formState,
        setSaveComplete,
        updateDeckSortOrder,
        data: { defaultDeck },
      } = props;
      const defaultDeckId = defaultDeck.id;
      const activeCards = defaultDeck.activeCards;
      const saveResult = await props.handleCardFormSubmit(formState, defaultDeckId);

      setSaveComplete(saveResult);
      updateDeckSortOrder(
        activeCards.map((i) => i.id),
        defaultDeckId,
      );
    },
  }),
)(BaseCardForm);
