import { Heading, Small, Text } from 'components/web-ui';
import React from 'react';
import { branch, compose, pure, renderNothing, withProps } from 'recompose';
import styled from 'styled-components/macro';
import { withPriceFormatting } from './hocs';
import { augmentPlanWithDiscount, findDiscount } from './lib';
import PlanRibbon from './plan-ribbon';

const cardRadius = '4px';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: ${cardRadius};
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  margin-bottom: 5%;
  padding: 2% 0 3% 0;
  position: relative;
  text-align: center;
  width: 47.5%;
  cursor: pointer;
  background-color: ${({ isSelected, theme: { colors } }) =>
    isSelected ? colors.primaryPurple : '#F5F3F6'};
  color: ${({ isSelected, theme: { colors } }) =>
    isSelected ? colors.white : colors.primaryPurple};
`.withComponent(({ isSelected, ...rest }) => <div {...rest} />);

const Title = styled(Heading)`
  font-size: 14px;
  margin-bottom: 7px;
  text-transform: uppercase;
`;

const Price = styled(Text)`
  font-size: 24px;
  margin: 0 auto 10px auto;
`.withComponent(({ hasDiscount, ...rest }) => <Text {...rest} />);

const StrikeThrough = styled.span`
  text-decoration: line-through;
  margin-right: 3px;
  opacity: 0.5;
`;

const Interval = styled(Small)`
  font-size: 14px;
  margin: 0 auto 10px auto;
`;

const PriceClarification = styled.p`
  font-size: 12px;
  line-height: 1.2;
  color: ${({ isSelected, theme: { colors } }) =>
    isSelected ? colors.alphaWhite50 : 'rgba(51, 21, 78, 0.5)'};
`.withComponent(({ isSelected, ...rest }) => <Text {...rest} />);

const DiscountCredit = branch(
  (props) => !props.discount,
  renderNothing,
)(
  styled.div`
    font-size: 12px;
    margin: 7px auto 0 auto;
  `.withComponent(({ discount, ...rest }) => (
    <Text {...rest} children={`Courtesy of\n${discount.companyName}`} />
  )),
);

const Plan = ({
  discount,
  formattedMonthlyAmount,
  isSelected,
  onClick,
  plan: { name, interval, amount, stripeId, discountedAmount },
  priceClarification,
}) => (
  <Layout isSelected={isSelected} onClick={onClick}>
    <Title children={name} />
    <Price>
      {discountedAmount >= 0 && (
        <StrikeThrough>${formattedMonthlyAmount(interval, amount)}</StrikeThrough>
      )}
      ${formattedMonthlyAmount(interval, discountedAmount >= 0 ? discountedAmount : amount)}
      <Interval children={` /month`} />
    </Price>
    <PriceClarification
      isSelected={isSelected}
      dangerouslySetInnerHTML={{
        __html: priceClarification(
          interval,
          discountedAmount >= 0 ? discountedAmount : amount,
          discount,
        ),
      }}
    />
    <PlanRibbon text="discount" isDiscounted={discountedAmount >= 0} />
    <DiscountCredit discount={discount} />
  </Layout>
);

const withDiscount = withProps(({ discounts, plan: { stripeId } }) =>
  findDiscount(discounts, stripeId),
);

const withDiscountedPlan = withProps(({ discount, plan }) =>
  augmentPlanWithDiscount(discount, plan),
);

export default compose(
  //
  withPriceFormatting,
  withDiscount,
  withDiscountedPlan,
  pure,
)(Plan);
