import { compose } from 'recompose';
import React from 'react';
import Segment from 'components/cards/stats/elements/segment';
import AreaChart from 'components/cards/stats/elements/area-chart';
import ShadowScrollable from 'components/cards/stats/elements/shadow-scrollable';
import withLoader from 'components/cards/stats/elements/loading-panel';
import withInactivePanel from 'components/cards/stats/elements/inactive-panel';
import {
  Panel,
  SVGRules,
  NumbersContainer,
  ExpandedView,
} from 'components/cards/stats/elements/shared';
import {
  withTypeIndex,
  withFrequency,
  withTypeStats,
  withTypeConnections,
  withTypeChartData,
  withExpandedState,
  withExpandedView,
  withSearchParameters,
  withComponentHandlers,
} from 'components/cards/stats/enhancer';

const Component = ({
  loading,
  type,

  // segment frequency
  frequency,
  setFrequency,

  // search range and datetimes
  unit,
  dateTo,
  dateFrom,
  timeline,

  // selected stat type index, default to 0
  typeIndex,
  setTypeIndex,

  // computed chart data
  chartData,
  setTypeStatResult,

  // fetched graphql connections
  typeConnections,
  setTypeConnection,

  // expanded view
  expanded,
  expandedView,
  toggleExpandedState,

  // account and card props
  card,
  currentAccount: account,

  children,
}) => (
  <Panel expanded={expanded && expandedView}>
    {/* bar and area chart background color */}
    <SVGRules>
      <defs>
        <linearGradient id="analytics-uv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
          <stop offset="100%" stopColor="#D6D1DC" stopOpacity={0.6} />
        </linearGradient>
      </defs>
    </SVGRules>

    {/* date range selector */}
    <Segment selected={frequency} onRangeChanged={setFrequency} />

    <ShadowScrollable scrollable={expanded && expandedView}>
      {/* show stats numbers */}
      <NumbersContainer>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            card,
            account,
            frequency,
            unit,
            dateTo,
            dateFrom,
            timeline,
            index,
            typeIndex,
            setTypeIndex,
            setTypeStatResult,
            setTypeConnection,
          }),
        )}
      </NumbersContainer>

      {/* show stats on chart */}
      <AreaChart
        range={frequency}
        data={chartData}
        expanded={expanded}
        onMoreButtonPressed={expandedView && toggleExpandedState}
      />

      {/* expanded view */}
      <ExpandedView>
        {expandedView &&
          React.Children.map(expandedView, (child, index) =>
            React.cloneElement(child, {
              data: typeConnections[typeIndex],
              summary: frequency === 'ALL',
              unit,
              card,
              account,
              dateTo,
              dateFrom,
              timeline,
              toggleExpandedState,
            }),
          )}
      </ExpandedView>
    </ShadowScrollable>
  </Panel>
);

export default compose(
  withInactivePanel,
  withTypeIndex,
  withFrequency,
  withSearchParameters,
  withTypeStats,
  withTypeConnections,
  withTypeChartData,
  withExpandedState,
  withExpandedView,
  withComponentHandlers,
  withLoader,
)(Component);
