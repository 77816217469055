import React from 'react';
import { compose, pure } from 'recompose';
import styled from 'styled-components/macro';
import LayoutBase from './base';

const Layout = styled(LayoutBase)`
  background-color: white;
`;

function LayoutAuth(props) {
  const { accounts, component: Component, ...rest } = props;
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
}

export default compose(
  //
  pure,
)(LayoutAuth);
