import ViewHeader from '@tap-bio/ui/patterns/view-header';
import { Layout as BaseLayout, Main as BaseMain } from 'components/cards/forms/shared-ui';
import { InstagramIcon } from 'components/icons';
import downArrow from 'images/icon-down-arrow.svg';
import { getFieldValue } from 'lib/card-form-utils';
import { compose, withHandlers } from 'lib/recompose';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import styled from 'styled-components';
import ViewSelector from './instagram/view-selector';

const Layout = styled(BaseLayout)`
  padding: 0;
`;

const Main = styled(BaseMain)`
  padding: ${20 / 16}rem 2rem;
`;

const CardLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

const Select = styled.select`
  -webkit-appearance: none;
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  background-image: url('${downArrow}');
  background-position: 7% 50%;
  background-size: 200% 50%;
  background-repeat: no-repeat;
  padding: 15px 10px;
  font-size: 16px;
  width: 100%;

  :active,
  :focus {
    outline: none;
    box-shadow: none;
  }

  :disabled {
    opacity: 0.45;
  }
`;

const FormContainer = styled.div`
  padding: 10px 0 0 0;
`;

const SectionHeading = styled.h1`
  font-weight: bold;
  margin-bottom: 3px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 12px 0;
`;

const LogoContainer = styled.div`
  height: 80px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function TwitterForm(props) {
  const { formState, renderSelect, handleUnset, handleFieldChange } = props;
  return (
    <CardLayout>
      <Layout>
        <ViewHeader>
          <ViewHeader.LeftButton onClick={handleUnset} />
          <ViewHeader.Title children="Card Layout" />
          <ViewHeader.RightButton children="" />
        </ViewHeader>
        <Main>
          <LogoContainer>
            <InstagramIcon width="50px" />
          </LogoContainer>

          <FormContainer>
            <SectionHeading children="Layout" />
            <ViewSelector
              formState={formState}
              name={`entities.instagrams.${formState.result.instagram}.view`}
              choices={[
                { value: 'grid_1', label: 'Grid × 1' },
                { value: 'grid_2', label: 'Grid × 2' },
                { value: 'grid_3', label: 'Grid × 3' },
              ]}
              onClick={handleFieldChange}
              value={getFieldValue(
                formState,
                `entities.instagrams.${formState.result.instagram}.view`,
              )}
            />

            {renderSelect({
              name: `entities.instagrams.${formState.result.instagram}.hasFeaturedImage`,
              placeholder: 'Choose one',
              options: [
                { value: true, label: 'Display a featured image' },
                { value: false, label: "Don't display a featured image" },
              ],
              selectAllOnFocus: true,
              styledSelect: Select,
              disabled:
                get(formState, `entities.instagrams.${formState.result.instagram}.view`) ===
                'grid_1',
            })}
          </FormContainer>
        </Main>
      </Layout>
    </CardLayout>
  );
}

export default compose(
  connect(null, (dispatch) => ({ unsetPopover: () => dispatch(unsetPopover()) })),
  withHandlers({
    handleUnset: (props) => (event) => {
      event.preventDefault();
      props.unsetPopover();
    },
  }),
)(TwitterForm);
