import { graphql } from '@apollo/react-hoc';
import ErrorBoundary from 'components/errors/error-boundary';
import { ScreenContainer } from 'components/ui';
import Spinner from 'components/ui/spinner';
import routes from 'config/routes';
import { billingProductSelectionQuery } from 'lib/graphql/queries';
import React from 'react';
import { compose, pure, withHandlers, withProps, withState } from 'recompose';
import styled from 'styled-components/macro';
import Features from './features';
import ViewHeader from './header';
import { withCurrentProductAndPlan } from './hocs';
import Product from './product';
import SelectPlanButton from './select-plan-button';

const CardContainer = styled.div`
  overflow-y: scroll;
  padding: 1.5rem 1rem;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ChangeProduct = (props) => {
  const {
    currentUser,
    handleChangeProduct,
    handleSelectProduct,
    history,
    platinumProduct,
    productSelectionQuery: { loading, products, activeFeatures },
    selectedProduct,
  } = props;

  return (
    <ErrorBoundary>
      <ScreenContainer p={0}>
        <ViewHeader history={history} />

        <CardContainer>
          {loading ? (
            <Spinner fill="rgba(0,0,0,0.25)" />
          ) : (
            <ProductsContainer>
              {[...products, platinumProduct].map((product) => (
                <Product
                  currentUser={currentUser}
                  isSelected={product.stripeId === selectedProduct.stripeId}
                  key={product.stripeId}
                  onClick={handleSelectProduct.bind(this, product)}
                  product={product}
                />
              ))}
            </ProductsContainer>
          )}
          {!loading && <Features features={activeFeatures} product={selectedProduct} />}
          <SelectPlanButton onClick={handleChangeProduct} selectedProduct={selectedProduct} />
        </CardContainer>
      </ScreenContainer>
    </ErrorBoundary>
  );
};

export default compose(
  graphql(billingProductSelectionQuery, { name: 'productSelectionQuery' }),
  withCurrentProductAndPlan,
  withState(
    'selectedProductStripeId',
    'setSelectedProductStripeId',
    ({ currentProductStripeId }) => currentProductStripeId,
  ),
  withProps(({ productSelectionQuery: { activeFeatures } }) => ({
    platinumProduct: {
      stripeId: 'platinum-product',
      features: activeFeatures && activeFeatures.map((feature) => feature.id),
      name: 'PLATINUM',
      description: 'custom branding',
      metadata: {
        customPricingDisplay: 'Contact us for pricing',
      },
    },
  })),
  withProps(
    ({
      productSelectionQuery: { loading, products },
      platinumProduct,
      selectedProductStripeId,
    }) => ({
      selectedProduct:
        !loading &&
        [...products, platinumProduct].find(
          (product) => product.stripeId === selectedProductStripeId,
        ),
    }),
  ),
  withHandlers({
    handleSelectProduct:
      ({ setSelectedProductStripeId }) =>
      (product, event) => {
        event.preventDefault();
        setSelectedProductStripeId(product.stripeId);
      },
    handleChangeProduct:
      ({ currentAccount, setShowChangeProductModal, selectedProduct, history }) =>
      (event) => {
        event.preventDefault();

        // If we have the Platimun plan selected, show intercom.
        // Otherwise continue to select a plan
        if (selectedProduct.stripeId === 'platinum-product') {
          window.Intercom(
            'showNewMessage',
            'Hello. I would like to chat about the platinum plan, please.',
          );
        } else {
          history.push(
            routes.billing.selectPlan(currentAccount.username, selectedProduct.stripeId),
          );
        }
      },
  }),
  pure,
)(ChangeProduct);
