import React from 'react';
import { branch, renderComponent } from 'recompose';
import Spinner from 'components/ui/spinner';
import { Panel, LoadingWrapper } from 'components/cards/stats/elements/shared';

function Loader() {
  return (
    <Panel>
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    </Panel>
  );
}

export default branch((props) => props.loading, renderComponent(Loader));
