import { denormalize, normalize, schema } from 'normalizr';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import unset from 'lodash/unset';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isString from 'lodash/isString';

const CARD_FIELD_WHITELIST = [
  'backgroundColor',
  'backgroundColorSolid',
  'backgroundMediaUrl',
  'backgroundXPosition',
  'backgroundYPosition',
  'backgroundZoom',
  'buttonColor',
  'ctas',
  'description',
  'hasBackgroundGradient',
  'imageGallery',
  'images',
  'instagram',
  'kind',
  'kind',
  'mailingList',
  'title',
  'twitter',
  'youtube',
];

const GLOBAL_EXCLUDE_FIELDS = ['__typename'];
const CARD_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS];
const CTA_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS];
const YOUTUBE_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS, 'videos'];
const TWITTER_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS, 'tweets'];
const INSTAGRAM_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS];
const IMAGE_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS, 'aspectRatio'];
const IMAGE_GALLERY_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS];
const MAILING_LIST_EXCLUDE_FIELDS = [...GLOBAL_EXCLUDE_FIELDS, 'mergeFields'];

// Let's move these somewhere else as they're global for all cards?
const cta = new schema.Entity('ctas');
const event = new schema.Entity('events');
const image = new schema.Entity('images');
const imageGallery = new schema.Entity('imageGalleries');
const mailingList = new schema.Entity('mailingLists');
const twitter = new schema.Entity('twitters');
const instagram = new schema.Entity('instagrams');
const youtube = new schema.Entity('youtubes');

const cardSchema = {
  ctas: [cta],
  event,
  instagram,
  imageGallery,
  images: [image],
  twitter,
  youtube,
  mailingList,
};

const removeEntityIds = (entityName, attributes) => (id) => {
  if (isString(id)) {
    unset({ ...attributes }, `entities.${entityName}.${id}.id`);
  }
  return null;
};

export function getCardMutationVariables(card) {
  const cardInputArguments = pick(card, CARD_FIELD_WHITELIST);
  cardInputArguments.ctas = cardInputArguments.ctas.map((cta) => omit(cta, CTA_EXCLUDE_FIELDS));
  cardInputArguments.images = cardInputArguments.images.map((image) =>
    omit(image, IMAGE_EXCLUDE_FIELDS),
  );
  return { id: card.id, cardInputArguments };
}

// Apollo adds the __typename field to each type (for better caching or some shit).
// Unfortunately the GraphQL server chokes if we send it any fields it's not expecting
// so we need to filter the data to be only what we want.

export function denormalizeCardMutationVariables(formState) {
  /*
   * If there's any entities with a string ID e.g. 'new-hh456', we need to remove that ID because
   * new records should be submitted without an ID. GraphQL will choke if it receives a string value
   * for `id` when it's expecting an integer.
   *
   * Additionally, we're cloning the formState so that the changes we make to it will be unique
   * to the data sent to the server. If we don't do this then the edited formState will be sent
   * through the form again.
   */

  // Clone attributes because failing to do this can cause weird object mutation issues
  const attributes = cloneDeep(formState);

  // Remove any ids from any new CTA/images entities
  attributes.result.ctas.map(removeEntityIds('ctas', attributes));
  // It seems like ctas have a default array value. not sure where this should be set.
  if (attributes.result.images) {
    attributes.result.images.map(removeEntityIds('images', attributes));
  }

  // Set a default for associated images
  attributes.result.images = attributes.result.images || [];

  let cardInputArguments = denormalize(attributes.result, cardSchema, attributes.entities);

  const id = cardInputArguments.id;
  cardInputArguments = pick(cardInputArguments, CARD_FIELD_WHITELIST);
  cardInputArguments.ctas = cardInputArguments.ctas.map((cta) => omit(cta, CTA_EXCLUDE_FIELDS));
  cardInputArguments.images = cardInputArguments.images.map((image) =>
    omit(image, IMAGE_EXCLUDE_FIELDS),
  );
  cardInputArguments.youtube = omit(cardInputArguments.youtube, YOUTUBE_EXCLUDE_FIELDS);
  cardInputArguments.twitter = omit(cardInputArguments.twitter, TWITTER_EXCLUDE_FIELDS);
  cardInputArguments.instagram = omit(cardInputArguments.instagram, INSTAGRAM_EXCLUDE_FIELDS);
  cardInputArguments.imageGallery = omit(
    cardInputArguments.imageGallery,
    IMAGE_GALLERY_EXCLUDE_FIELDS,
  );
  cardInputArguments.mailingList = omit(
    cardInputArguments.mailingList,
    MAILING_LIST_EXCLUDE_FIELDS,
  );

  if (cardInputArguments.publishedAt) {
    cardInputArguments.publishedAt = cardInputArguments.publishedAt.unix();
  }
  if (cardInputArguments.unpublishedAt) {
    cardInputArguments.unpublishedAt = cardInputArguments.unpublishedAt.unix();
  }
  return { id, cardInputArguments };
}

export function normalizeCardData(card) {
  const cardFormData = { ...omit(card, CARD_EXCLUDE_FIELDS) };
  return normalize(cardFormData, cardSchema);
}

export function denormalizeCardData(attributes) {
  return denormalize(attributes.result, cardSchema, attributes.entities);
}

export function getFieldValue(attributes, name) {
  return get(attributes, name, '');
}
