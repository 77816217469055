import { Text } from 'components/web-ui';
import styled from 'styled-components/macro';

const Layout = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 65px;
  border-top: 1px solid ${(props) => props.theme.colors.veryLightishGray};
  z-index: 200;
  background-color: white;
`;

const SwiperLayout = styled.div`
  .swiper-background-filters-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const ColorPickerLayout = styled.div`
  position: absolute;
  z-index: 100;
  left: 5%;
  right: 5%;
  bottom: 8.75%;
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  background-color: rgba(255, 255, 255, 0.25);

  > div {
    margin: 0;
  }

  button {
    height: 48px;
  }
`;

const UploadPhotoButton = styled.div`
  background-color: ${(props) => props.theme.colors.alphaBlack50};
  color: ${(props) => props.theme.colors.white};
  font-size: 13px;
  padding: 7px 15px;
  position: absolute;
  top: 15px;
  right: 20px;
  border-radius: 20px;
  z-index: 2;

  small {
    font-weight: ${(props) => props.theme.fonts.weights.bold};
  }
`;

const SolidBackground = styled.div`
  background-color: ${(props) => props.selectedBackgroundColorSolid};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

const SolidBackgroundLabel = styled(Text)`
  font-size: 24px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 20%;
  color: rgba(255, 255, 255, 0.5);
`;

export {
  Layout,
  SwiperLayout,
  ColorPickerLayout,
  UploadPhotoButton,
  SolidBackground,
  SolidBackgroundLabel,
};
