import React from 'react';
import * as Sentry from '@sentry/browser';
import * as Recompose from 'recompose';
import * as Redux from 'react-redux';
import { ShowIcon, HideIcon } from 'components/icons';
import { graphql } from '@apollo/react-hoc';
import { defaultDeckQuery } from 'lib/graphql/queries';
import { toggleCardHiddenMutation } from 'lib/graphql/mutations';
import withCompleteOnboarding from 'hocs/with-complete-onboarding';
import withUpdateUserMutation from 'hocs/with-update-user-mutation';
import { setToast } from 'store/modules/toast';

const DISABLED = 'This card cannot be published';
const HIDDEN = 'Unpublish this card';
const VISIBLE = 'Publish this card';

const CARD_REQUIRE_SETUP =
  'Card is not ready for publishing, please complete the setup of this card before running the preceding step';
const TOO_MANY_CARDS =
  'Upgrade your plan to display this card, or move it to the first position in your Tap Bio.';

export default Recompose.compose(
  Redux.connect(null, (dispatch, ownProps) => ({
    setToast: (message) => dispatch(setToast(message)),
  })),

  Recompose.branch((props) => {
    const { removeDiscoveryCard } = props.currentAccount.owner.features;
    const { kind, editable } = props.card;

    // If this is the discover card and the user has the 'remove discover card'
    // feature, render the hidden control.
    if (kind === 'discover') return !removeDiscoveryCard;

    // Otherwise render the hidden control only if this card is editable
    return !editable;
  }, Recompose.renderNothing),

  // Display either the show or hide icon with descriptive tooltips
  Recompose.withProps((props) => {
    const { card, cardIndexInDeck, currentAccount } = props;
    const { hidden, enabled } = card;
    const off = !enabled;

    // Default to showing the 'toggle hidden' icon.
    let children = React.createElement(ShowIcon);

    // Unless this card is hidden or disabled
    if (hidden || off) children = React.createElement(HideIcon);

    // Configure the tooltip text (displayed on hover)
    let title = VISIBLE;
    if (off) title = DISABLED;
    if (hidden) title = HIDDEN;

    const hasTooManyCards = cardIndexInDeck >= currentAccount.owner.features.cardCount && off;

    return { children, title, off, hasTooManyCards };
  }),

  graphql(toggleCardHiddenMutation, {
    props: function mutationHandler(args) {
      const { mutate } = args;
      const { currentAccount } = args.ownProps;

      let success = true;

      return {
        handleToggleCardHidden: async (card) => {
          try {
            await mutate({
              mutation: toggleCardHiddenMutation,
              variables: { input: { cardId: card.id } },
              optimisticResponse: {
                __typename: 'Mutation',
                toggleCardHidden: {
                  __typename: 'ToggleCardHiddenPayload',
                  cardIsHidden: !card.hidden,
                  success: true,
                },
              },
              update: (proxy, { data: { toggleCardHidden } }) => {
                // Read the currently cached query
                const data = proxy.readQuery({
                  query: defaultDeckQuery,
                  variables: { accountUsername: currentAccount.username },
                });

                // Update the query to toggle the hidden status of the card
                const { activeCards } = data.defaultDeck;
                data.defaultDeck.activeCards = activeCards.map((c) => {
                  if (c.id === card.id) c.hidden = toggleCardHidden.cardIsHidden;
                  return c;
                });

                // Write the query back to the cache
                proxy.writeQuery({
                  query: defaultDeckQuery,
                  variables: { accountUsername: currentAccount.username },
                  data,
                });
              },
            });
          } catch (error) {
            Sentry.captureException(error);
            console.error(error);
          } finally {
            return success;
          }
        },
      };
    },
  }),

  withUpdateUserMutation,
  withCompleteOnboarding,

  Recompose.withHandlers({
    onClick: (props) => async (event) => {
      // Mark as completed in the onboarding. This is skipped if it's already complete
      props.handleCompleteOnboarding('hasClickedTogglePublish');

      // Update the hidden status
      const { card, handleToggleCardHidden, hasTooManyCards } = props;
      if (hasTooManyCards) return props.setToast(TOO_MANY_CARDS);
      if (!card.enabled) return props.setToast(CARD_REQUIRE_SETUP);
      await handleToggleCardHidden(card);
    },
  }),
);
