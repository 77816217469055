import React from 'react';
import styled from 'styled-components/macro';

const HintBox = styled.div`
  font-size: 13px;
  background-color: #fefad4;
  border: 1px solid #fcf081;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 0;
  color: #444444;

  > p {
    margin: 8px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const OldUsername = styled.span`
  color: ${(p) => p.theme.colors.errorRed};
  font-weight: bold;
`;

const NewUsername = styled.span`
  color: ${(p) => p.theme.colors.brightGreen};
  font-weight: bold;
`;

function Hint(props) {
  return (
    <HintBox>
      <p>
        You are changing your account's username from <OldUsername>{props.oldUsername}</OldUsername>
        {props.newUsername ? (
          <span>
            {' '}
            to <NewUsername>{props.newUsername}</NewUsername>.
          </span>
        ) : (
          '.'
        )}
      </p>

      {props.newUsername && (
        <p>
          Your new Tap Bio link will be <b>tap.bio/@{props.newUsername}</b>
        </p>
      )}

      <p>
        Please note that we will <strong>not</strong> create redirects from your old profile page.
      </p>
    </HintBox>
  );
}

export default Hint;
