import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import withConditionalRender from './with-conditional-render';

function StoreProvider(props) {
  return <Provider store={props.store} children={props.children} />;
}

StoreProvider.defaultProps = {
  store: store,
};

export default withConditionalRender(StoreProvider);
