import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const Layout = styled.div`
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  background-color: #c0c0c0;
  background-image: url(${(p) => p.image});
  background-size: cover;
  background-position: center;

  box-shadow:
    0 0 0 2px white,
    0 0 0 4px ${(p) => p.theme.colors.primaryPurple};
  box-shadow: ${(p) => !p.active && 'none'};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.span`
  color: white;
  font-weight: bold;
  font-size: 1.375rem;
  text-transform: uppercase;
`;

function Avatar(props) {
  return (
    <Layout active={props.active} image={props.image}>
      <Text children={!props.image && props.username.charAt(0)} />
    </Layout>
  );
}

Avatar.propTypes = {
  username: PropTypes.string.isRequired,
  image: PropTypes.string,
  active: PropTypes.bool,
};

Avatar.defaultProps = {
  active: false,
};

export default Avatar;
