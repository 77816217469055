import { graphql } from '@apollo/react-hoc';
import routes from 'config/routes';
import withRedirection from 'hocs/with-redirection';
import Authentication from 'lib/authentication';
import { createSessionResponseMutation } from 'lib/graphql/mutations';
import { currentIdentityQuery } from 'lib/graphql/queries';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { branch, compose, renderComponent, withHandlers, withProps, withState } from 'recompose';
import VerifyForm from './form';

export default compose(
  withRedirection,
  withState('formData', 'setFormData', (props) => ({ ...props.location.state })),
  withState('responseData', 'setResponseData', {}),
  withState('newAccount', 'setNewAccount', null),
  withState('purpose', 'setPurpose'),
  withState('formIsPersisting', 'setformIsPersisting', false),

  branch(
    (props) => props.newAccount,
    renderComponent((props) => {
      const pathname = {
        verify: routes.accountAccess(props.newAccount),
        undefined: routes.account(props.newAccount),
      }[props.purpose];

      if (props.redirect) {
        return <Redirect to={{ pathname: props.redirect, state: { refresh: true } }} />;
      }

      return <Redirect to={{ pathname, state: { refresh: true } }} />;
    }),
  ),

  withProps((props) => {
    const code = (props.formData.code || '').replace(/\D/g, '');
    return {
      formErrors: {
        any: code.length < 6,
      },
    };
  }),

  withProps(({ formData, formErrors, ...props }) => {
    return {
      formHints: {},
    };
  }),

  graphql(createSessionResponseMutation, {
    props: ({ mutate, ownProps }) => {
      return {
        handleFormSubmit: () =>
          mutate({
            variables: ownProps.formData,
            update: (proxy, { data: { error, createSessionResponse } }) => {
              if (error) {
                ownProps.setformIsPersisting(false);
                return ownProps.setResponseData({ error });
              }

              const { authToken } = createSessionResponse;
              const { identity } = createSessionResponse;
              const { purpose, redirectAccountUsername } = ownProps.location.state;

              if (authToken && !purpose) Authentication.authenticate(authToken);
              if (identity.accounts.length === 0) return { errors: { message: 'no accounts' } };
              ownProps.setPurpose(purpose);
              ownProps.setNewAccount(redirectAccountUsername || identity.accounts[0].username);
            },
            refetchQueries: [
              {
                query: currentIdentityQuery,
              },
            ],
          }),
      };
    },
  }),

  withHandlers({
    onFieldChange:
      ({ setFormData }) =>
      (name, value) => {
        setFormData((state) => ({ ...state, [name]: value }));
      },

    onSubmit: (props) => () => {
      props.setformIsPersisting(true);
      props.handleFormSubmit(props);
    },
  }),
)(VerifyForm);
