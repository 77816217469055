import React from 'react';
import * as Recompose from 'lib/recompose';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';
import ErrorBoundary from 'components/errors/error-boundary';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import { Footer } from 'components/accounts/styled';
import AddAccountButton from './add-button';
import AccountSwitcher from './account-switcher';
import ViewHeader from '@tap-bio/ui/patterns/view-header';
import verticalSmoothScrollingCSS from 'components/cards/shared/styles/vertical-smooth-scrolling-css';

const Layout = styled.section`
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  ${verticalSmoothScrollingCSS};
`;

function AccountsSwitch(props) {
  return (
    <ErrorBoundary>
      <Layout>
        <ViewHeader>
          <ViewHeader.LeftButton onClick={props.onClickBack} />
          <ViewHeader.Title children="Accounts" />
          <ViewHeader.RightButton children="" />
        </ViewHeader>
        <Content>
          <AccountSwitcher {...props} />
          <Footer>
            <AddAccountButton />
          </Footer>
        </Content>
      </Layout>
    </ErrorBoundary>
  );
}

export default Recompose.compose(
  withRouter,
  withIdentityAccounts,
  Recompose.withHandlers({
    onClickBack: (props) => props.history.goBack,
  }),
  Recompose.lifecycle({
    componentDidMount() {
      const { history, location, data } = this.props;
      if (!(location.state || {}).needsRefetch) return;

      data.refetch();
      history.replace(location.pathname, { needsRefetch: false });
    },
  }),
)(AccountsSwitch);
