import { Absolute as AbsoluteBase } from 'components/web-ui';
import PostsPanel from 'legacy/posts-panel';
import { branch, compose, omitProps, renderComponent, withProps, withState } from 'lib/recompose';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

window.PATHS = {
  apiV1PhotosPath: (username) => `/api/v1/${username}/photos`,
  apiV1Reports: (username) => `/api/v1/${username}/reports`,
  apiV1SubscriptionsPath: (username) => `/api/v1/${username}/subscriptions`,
  apiV1UserPath: (username) => `/api/v1/users/${username}`,
  apiV1DefaultLinksShowPath: (username, cardId) => `/api/v1/${username}/default_links/${cardId}`,
  apiV1DefaultLinksCreateUpdatePath: (username, cardId) =>
    `/api/v1/${username}/default_links/create_update/${cardId}`,
  authorizeInstagramPath: (username) => `/signup`,
  changeStatusApiV1PhotosPath: (username) => `/api/v1/${username}/photos/change_status`,
  deletePhotoUrl: (username, id) => `/api/v1/${username}/photos/${id}`,
  updatePhotoUrl: (username, id) => `/api/v1/${username}/photos/${id}`,
};

export const DEFAULT_DISPLAY_COUNT = 33;

const DashboardContainer = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  top: 128px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  /* When a popover is showing, push the instagram links behind it */
  z-index: ${({ isShowingCardPopover }) => (isShowingCardPopover ? 0 : 5)};
`.withComponent(omitProps('isShowingCardPopover')(AbsoluteBase));

const InstagramLinks = ({
  card,
  handleAddFirstLink,
  isShowingCardPopover,
  renderField,
  setDisplayCount,
  user,
}) => (
  <DashboardContainer isShowingCardPopover={isShowingCardPopover}>
    <PostsPanel
      cardId={card.id}
      displayCount={10}
      globallyActivePanel={'edit'}
      handleAddFirstLink={handleAddFirstLink}
      setDisplayCount={setDisplayCount}
      user={user}
    />
  </DashboardContainer>
);

function convertTapBioAccountToLipUser(account, defaultLink) {
  return {
    ...account,
    // token_is_valid: account.identities[0].tokenIsValid,
    token_is_valid: false,
    default_link: defaultLink,
  };
}

const userProps = withProps(({ card, currentAccount, defaultLink }) => ({
  user: convertTapBioAccountToLipUser(currentAccount, defaultLink),
}));

const mapStateToProps = (state, ownProps) => ({
  isShowingCardPopover: !!state.cardPopovers.popover,
});

export default compose(
  branch(
    (props) => false,
    renderComponent(() => <div>foobar</div>),
  ),
  connect(mapStateToProps),

  withState('displayCount', 'setDisplayCount', {
    count: DEFAULT_DISPLAY_COUNT,
    max: DEFAULT_DISPLAY_COUNT * 6,
  }),
  userProps,
)(InstagramLinks);
