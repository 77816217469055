import React from 'react';
import styled from 'styled-components/macro';
import { compose, branch, renderComponent } from 'recompose';
import ZapierProviderOptions, { isZapierProvider } from './setup-zapier';
import MailChimpProviderOptions, { isMailChimpProvider } from './setup-mailchimp';
import ConstantContactProviderOptions, { isConstantContactProvider } from './constantcontact';

const Error = styled.div``;

const ProviderUnavailable = () => <Error children="Coming soon" />;

export default compose(
  branch(isZapierProvider, renderComponent(ZapierProviderOptions)),
  branch(isMailChimpProvider, renderComponent(MailChimpProviderOptions)),
  branch(isConstantContactProvider, renderComponent(ConstantContactProviderOptions)),
)(ProviderUnavailable);
