import React from 'react';
import routes from 'config/routes';
import { Text } from 'components/web-ui';
import { RouterLink as BaseRouterLink } from 'components/ui';
import { Link } from 'components/web-ui';
import * as Recompose from 'lib/recompose';
import styled from 'styled-components/macro';

const UpdateLink = styled(BaseRouterLink)`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dividerGrey};
`;

const UsernameContainer = styled.div`
  color: ${(p) => (p.active ? p.theme.colors.appleBlue : '#021431')};
`;

const Usernames = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-top: 10px;
`;

const Username = styled.p`
  padding: 0 0 2px 0;
  margin: 0;
`;

const Plan = styled(Text)`
  text-transform: uppercase;
  display: block;
  color: #999999;
  font-size: 75%;
`;

const UpdateButton = styled(Text)`
  margin-left: auto;
  border-radius: 1em;
  font-size: 14px;
  height: 2em;
  padding: 0 12px 1px 12px;
  display: flex;
  align-items: center;
  color: white;
  background-color: ${(p) => p.theme.colors.primaryPurple};
  border: 0;
  text-align: center;
`;

function SubscriptionItem(props) {
  const { linkProps } = props;
  const { planStripeId } = props.accounts[0].owner.stripeSubscription;

  return (
    <UpdateLink p={15} mr={13} {...linkProps}>
      <UsernameContainer>
        <Plan>{planStripeId.replace(/-/g, ' ')}</Plan>
        <Usernames>
          {props.accounts.map((account) => (
            <Username key={account.id}>@{account.username}</Username>
          ))}
        </Usernames>
      </UsernameContainer>
      <UpdateButton>Update Payment</UpdateButton>
    </UpdateLink>
  );
}

export default Recompose.compose(
  Recompose.withProps((props) => ({
    linkProps: {
      to: routes.billing.updateCreditCard(props.accounts[0].username),
      as: Link,
    },
  })),
)(SubscriptionItem);
