import React from 'react';
import routes from 'config/routes';
import * as Recompose from 'recompose';
import { Redirect } from 'react-router-dom';

export default Recompose.compose(
  Recompose.branch(
    (props) => props.responseData.identity,
    Recompose.renderComponent(({ responseData, formData }) => (
      <Redirect
        push
        to={{
          pathname: `${routes.registrationPath}/verify/${responseData.identity.uid}`,
          state: {
            ...responseData.identity,
            accountName: formData.accountName,
            action: 'register',
          },
        }}
      />
    )),
  ),
);
