"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListView = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ListView = _styledComponents["default"].div.withConfig({
  displayName: "list-view__ListView",
  componentId: "sc-1bbcf5-0"
})(["display:flex;flex-direction:column;justify-content:flex-start;align-items:stretch;background-color:#f0f0f4;"]);

exports.ListView = ListView;
ListView.propTypes = {
  children: _propTypes["default"].any
};
ListView.displayName = 'ListView';