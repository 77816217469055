"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.List = exports.Overlay = exports.OptionsPanel = exports.Layout = exports.inputStyles = exports.Tag = exports.Flag = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _flag = _interopRequireDefault(require("@tap-bio/ui/elements/flag"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 0.5em;\n  padding-right: 0.5em;\n  height: 3.125em;\n  border: 1px solid #e6e6e6;\n  background-color: #fafafa;\n  border-radius: 6px;\n  font-size: 1em;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var inputStyles = (0, _styledComponents.css)(_templateObject());
exports.inputStyles = inputStyles;
var Flag = (0, _styledComponents["default"])(_flag["default"]).withConfig({
  displayName: "styled__Flag",
  componentId: "sc-1lbvjub-0"
})(["font-size:1.25em;margin:0 0.25em;"]);
exports.Flag = Flag;

var Tag = _styledComponents["default"].span.withConfig({
  displayName: "styled__Tag",
  componentId: "sc-1lbvjub-1"
})(["background-color:#eceff1;font-size:1em;font-family:monospace;text-align:center;padding:1px 0.5em;border-radius:3px;"]);

exports.Tag = Tag;

var Layout = _styledComponents["default"].div.withConfig({
  displayName: "styled__Layout",
  componentId: "sc-1lbvjub-2"
})(["display:", ";"], function (p) {
  return p.show ? 'flex' : 'none';
});

exports.Layout = Layout;

var OptionsPanel = _styledComponents["default"].div.withConfig({
  displayName: "styled__OptionsPanel",
  componentId: "sc-1lbvjub-3"
})(["position:absolute;top:3em;line-height:1em;background:white;border-radius:3px;box-shadow:0 2px 10px rgba(0,0,0,0.2);padding:0.5em 1em;display:", ";flex-direction:column;"], function (p) {
  return p.show ? 'flex' : 'none';
});

exports.OptionsPanel = OptionsPanel;

var Overlay = _styledComponents["default"].div.withConfig({
  displayName: "styled__Overlay",
  componentId: "sc-1lbvjub-4"
})(["display:", ";position:fixed;top:0;left:0;bottom:0;right:0;background-color:rgba(0,0,0,0.5);"], function (p) {
  return p.show ? 'block' : 'none';
});

exports.Overlay = Overlay;

var List = _styledComponents["default"].div.withConfig({
  displayName: "styled__List",
  componentId: "sc-1lbvjub-5"
})(["display:", ";max-height:14em;overflow-y:scroll;"], function (p) {
  return p.children ? 'block' : 'none';
});

exports.List = List;
var _default = {
  Flag: Flag,
  Tag: Tag,
  inputStyles: inputStyles,
  Layout: Layout,
  OptionsPanel: OptionsPanel,
  Overlay: Overlay,
  List: List
};
exports["default"] = _default;