import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { compose, branch, renderNothing, mapProps, setPropTypes } from 'lib/recompose';
import Chevron from 'components/icons/chevron';

const Link = styled.a`
  font-size: 17px;
  padding: 23px 20px;
  margin: -20px;
  color: inherit;

  & > * {
    vertical-align: middle;
  }

  & > hr {
    padding: 0;
    padding-top: 23px;
    margin: 0 -20px;
    border: none;
    border-bottom: 1px solid currentColor;
    opacity: 0.2;
  }
`;

function BackButton(props) {
  return (
    <Link {...props}>
      <Chevron fill="currentColor" width="0.9em" height="0.9em" />
      <span> Back</span>
      <hr />
    </Link>
  );
}

export default compose(
  setPropTypes({
    to: PropTypes.string,
  }),
  branch((props) => !props.to, renderNothing),
  mapProps(({ to, ...props }) => ({
    href: to,
    ...props,
  })),
)(BackButton);
