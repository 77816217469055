import AutoCompleteField from 'components/accounts/onboarding/auto-complete-field';
import CustomizeAvatarField from 'components/accounts/onboarding/customize-avatar-field';
import TagCompleteField from 'components/accounts/onboarding/tag-complete-field';
import DeleteAccount from 'components/cards/forms/profile/delete-account';
import EditSocialAccounts from 'components/cards/forms/profile/edit-social-accounts';
import ExportCardStats from 'components/cards/forms/profile/export-stats';
import Header from 'components/cards/forms/profile/header';
import SocialAccounts from 'components/cards/forms/profile/social-accounts';
import ChooseBackgroundFilter from 'components/image-upload/choose-background-filter';
import BackgroudPhotoCropper from 'components/image-upload/crop-background-photo';
import { Message, RouterLink } from 'components/ui';
import { Text } from 'components/web-ui';
import config from 'config';
import routes from 'config/routes';
import pick from 'lodash/pick';
import set from 'lodash/set';
import PropTypes from 'prop-types';
import React from 'react';
import * as Styled from './styled';

function EditProfileForm(props) {
  const {
    card,
    card: { backgroundColorSolid },
    cards,
    defaultBackgroundMediaUrl,
    formState,
    handleFieldChange,
    handleProfileFormSubmit,
    handleUpdateProfileBackgroundImage,
    handleUpdateProfileBackgroundColor,
    hasBlendMode,
    filterColor,
    profile,
    renderField,
    setSaveComplete,
    setShowEditSocialAccounts,
    setTitleAutocompleteIsExpanded,
    setUploadedBackgroundUrl,
    showEditSocialAccounts,
    titleAutocompleteIsExpanded,
    uploadedBackgroundPhotoUrl,
    selectedBackgroundPhoto,
    unsetSelectedBackgroundImage,
    handleAvatarUploadStarted,
    handleAvatarUploadCompleted,
    handleShowProfilePhotoFilter,
    handleShowProfilePhotoCropper,
    handleCancelPhotoCropper,
    handleImageUploaded,
    handleCardFormSubmit,
    currentAccount,
  } = props;

  const hasThirdPartyAnalytics = props.currentAccount.owner.features.hasThirdPartyAnalytics;

  return (
    <React.Fragment>
      {/*
        Popover for editing the social accounts.
        TODO: Should we make this a separate URL? The URL here is actually for
              card editing but it's actually editing the profile.
      */}
      {showEditSocialAccounts && (
        <EditSocialAccounts
          {...pick(props, [
            'card',
            'currentAccount',
            'isPersisting',
            'match',
            'setIsPersisting',
            'setShowEditSocialAccounts',
            'setValidationErrors',
          ])}
        />
      )}

      <Styled.Form
        onSubmit={async (event) => {
          event.preventDefault();
          const saveResult = await handleProfileFormSubmit(event);
          setSaveComplete(saveResult);
        }}
      >
        <Header {...pick(props, ['isUploadingAvatar', 'isPersisting', 'card', 'currentAccount'])} />

        {/* Popover for editing the background photo */}
        {uploadedBackgroundPhotoUrl && (
          <ChooseBackgroundFilter
            handleSubmit={async (url, backgroundColor, backgroundColorSolid) => {
              // Update the profile card (used for changing the filter)
              const targetCard = card
                ? card
                : cards.reduce((acc, o) => (o.kind === 'profile' ? o : acc));
              const targetDeck = currentAccount.decks[0];
              const { id: cardId, ctas } = targetCard;
              const { id: deckId } = targetDeck;

              const newCard = { ...formState };
              set(newCard, 'result.id', cardId);
              set(newCard, 'result.ctas', ctas);
              set(newCard, 'result.backgroundColor', backgroundColor);
              set(newCard, 'result.backgroundColorSolid', backgroundColorSolid);
              await handleCardFormSubmit(newCard, deckId);

              // Update the profile
              await handleUpdateProfileBackgroundImage(profile.id, {
                defaultBackgroundMediaUrl: url,
              });
              // Update the card image background color
              const saveWasSuccessful = await handleUpdateProfileBackgroundColor(backgroundColor);
              // Hide the image upload screen
              if (saveWasSuccessful) {
                setUploadedBackgroundUrl(null);
                unsetSelectedBackgroundImage();
              }
            }}
            backgroundPhotoUrl={uploadedBackgroundPhotoUrl}
            onImageSelected={handleShowProfilePhotoCropper}
            card={card}
            profile={profile}
          />
        )}

        {selectedBackgroundPhoto && (
          <BackgroudPhotoCropper
            onCancel={handleCancelPhotoCropper}
            onCompletedUpload={handleImageUploaded}
          />
        )}

        {/* Main profile form */}
        <Styled.ScrollContainer>
          <Styled.PhotoContainer>
            <Styled.PhotoView
              backgroundColorSolid={backgroundColorSolid}
              defaultBackgroundMediaUrl={defaultBackgroundMediaUrl}
              hasBlendMode={hasBlendMode}
              filterColor={filterColor}
            >
              <Styled.EditPhotoButton onClick={handleShowProfilePhotoFilter}>
                <Text>Change profile photo</Text>
              </Styled.EditPhotoButton>
            </Styled.PhotoView>
          </Styled.PhotoContainer>
          <Styled.FormContainer>
            <Styled.Field>
              <CustomizeAvatarField
                hidden={false}
                label="Avatar"
                name="avatar"
                username={currentAccount.username}
                styledLabel={Styled.Label}
                value={formState.pictureUrl}
                onChange={handleFieldChange}
                onUploadStarted={handleAvatarUploadStarted}
                onUploadCompleted={handleAvatarUploadCompleted}
              />
            </Styled.Field>
            {renderField({
              label: 'Name',
              name: 'name',
              placeholder: 'Tap to add your name',
              styledField: Styled.Field,
              styledInput: Styled.Input,
              styledLabel: Styled.Label,
            })}
            <Styled.Field>
              <AutoCompleteField
                displayFormButton={false}
                expanded={titleAutocompleteIsExpanded}
                hint="Select one by searching or tapping from below."
                label="Title (what you do)"
                styledLabel={Styled.Label}
                name="title"
                onChange={(name, value) => {
                  setTitleAutocompleteIsExpanded(false);
                  handleFieldChange(name, value);
                }}
                onFocus={(event) => setTitleAutocompleteIsExpanded(true)}
                placeholder="Tap to add your title"
                tags={config.profileTitleChoices}
                valid={!!formState.name && !!formState.title}
                value={formState.title}
              />
            </Styled.Field>
            {renderField({
              label: 'Bio',
              name: 'bio',
              placeholder: 'Tap to add your bio',
              styledField: Styled.Field,
              styledInput: Styled.Textarea,
              styledLabel: Styled.Label,
            })}
            {renderField({
              label: 'Website',
              name: 'website',
              placeholder: 'Tap to add your URL',
              styledField: Styled.Field,
              styledInput: Styled.Input,
              styledLabel: Styled.Label,
              type: 'url',
            })}
            <Styled.Field>
              <Styled.SocialAccountsContainer>
                <Styled.SectionTitle>Your Links</Styled.SectionTitle>
                <SocialAccounts ctas={card.ctas} />
                <Styled.EditSocialAccountsButton
                  children="Manage Your Links"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEditSocialAccounts(true);
                  }}
                />
              </Styled.SocialAccountsContainer>
            </Styled.Field>
            {renderField({
              label: 'Email',
              name: 'email',
              placeholder: 'Tap to add your email',
              styledField: Styled.Field,
              styledInput: Styled.Input,
              styledLabel: Styled.Label,
              type: 'email',
            })}
            <Styled.Field>
              <TagCompleteField
                displayFormButton={false}
                expanded
                hidden={false}
                hint="Select at least 3 things that best describe your business."
                limit={config.profileTagChoices.length}
                name="tags"
                onChange={handleFieldChange}
                placeholder="Tags"
                submitText="Continue to brand color"
                tags={config.profileTagChoices}
                valid={!!formState.tags}
                value={formState.tags}
              />
            </Styled.Field>
            {/* --- Begin Analytics Section --- */}
            <Styled.SectionTitle>Analytics</Styled.SectionTitle>
            <Message
              level="notice"
              text={
                <React.Fragment>
                  Please{' '}
                  <RouterLink to={routes.billing.changeProduct(currentAccount.username)}>
                    upgrade your plan
                  </RouterLink>{' '}
                  to add Google and Facebook analytics
                </React.Fragment>
              }
              isVisible={!hasThirdPartyAnalytics}
            />
            <Styled.Field disabled={!hasThirdPartyAnalytics}>
              {renderField({
                disabled: !hasThirdPartyAnalytics,
                label: 'Google Tracking ID',
                name: 'googleTrackingId',
                placeholder: 'G-123456789',
                styledField: Styled.Field,
                styledInput: Styled.Input,
                styledLabel: Styled.Label,
              })}
              {renderField({
                disabled: !hasThirdPartyAnalytics,
                label: 'Facebook Pixel ID',
                name: 'facebookPixelId',
                placeholder: '123456789012345',
                styledField: Styled.Field,
                styledInput: Styled.Input,
                styledLabel: Styled.Label,
              })}
              <ExportCardStats />
            </Styled.Field>
            {/* --- End Analytics Section --- */}
            <Styled.Field>
              <Styled.SectionTitle danger>Danger zone</Styled.SectionTitle>
              <DeleteAccount {...pick(props, ['currentAccount'])} />
            </Styled.Field>
          </Styled.FormContainer>
        </Styled.ScrollContainer>
      </Styled.Form>
    </React.Fragment>
  );
}

EditProfileForm.propTypes = {
  card: PropTypes.object.isRequired,
  defaultBackgroundMediaUrl: PropTypes.string.isRequired,
  formState: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleProfileFormSubmit: PropTypes.func.isRequired,
  handleUpdateProfileBackgroundImage: PropTypes.func.isRequired,
  handleUpdateProfileBackgroundColor: PropTypes.func.isRequired,
  hasBlendMode: PropTypes.bool.isRequired,
  filterColor: PropTypes.string,
  profile: PropTypes.object.isRequired,
  renderField: PropTypes.func.isRequired,
  setSaveComplete: PropTypes.func.isRequired,
  setShowEditSocialAccounts: PropTypes.func.isRequired,
  setTitleAutocompleteIsExpanded: PropTypes.func.isRequired,
  setUploadedBackgroundUrl: PropTypes.func.isRequired,
  showEditSocialAccounts: PropTypes.bool.isRequired,
  titleAutocompleteIsExpanded: PropTypes.bool.isRequired,
  uploadedBackgroundPhotoUrl: PropTypes.array,
  selectedBackgroundPhoto: PropTypes.bool,
  unsetSelectedBackgroundImage: PropTypes.func.isRequired,
  handleShowProfilePhotoFilter: PropTypes.func.isRequired,
  handleShowProfilePhotoCropper: PropTypes.func.isRequired,
  handleCancelPhotoCropper: PropTypes.func.isRequired,
  handleImageUploaded: PropTypes.func.isRequired,
  backgroundColorSolid: PropTypes.string,
};

export default EditProfileForm;
