import { graphql } from '@apollo/react-hoc';
import routes from 'config/routes';
import Cookies from 'js-cookie';
import Authentication from 'lib/authentication';
import { createSessionResponseMutation } from 'lib/graphql/mutations';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { branch, compose, renderComponent, withHandlers, withProps, withState } from 'recompose';
import VerifyForm from './form';

export default compose(
  withState('formData', 'setFormData', (props) => ({
    ...props.location.state,
    ...Cookies.getJSON('initialQueryParams'), // Merge in our params from when the visitor landed
  })),

  withState('responseData', 'setResponseData', {}),
  withState('newAccount', 'setNewAccount', null),
  withState('formIsPersisting', 'setformIsPersisting', false),

  branch(
    (props) => props.newAccount,
    renderComponent(({ newAccount }) => <Redirect to={routes.account(newAccount)} />),
  ),

  withProps((props) => {
    const code = (props.formData.code || '').replace(/\D/g, '');
    return {
      formErrors: {
        any: code.length < 6,
      },
    };
  }),

  withProps(({ formData, formErrors, ...props }) => {
    return {
      formHints: {},
    };
  }),

  graphql(createSessionResponseMutation, {
    props: ({ mutate, ownProps }) => ({
      handleFormSubmit: () =>
        mutate({
          variables: ownProps.formData,
          update: (proxy, { data: { error, createSessionResponse } }) => {
            if (error) {
              ownProps.setformIsPersisting(false);
              return ownProps.setResponseData({ error });
            }

            const { authToken } = createSessionResponse;
            if (authToken) Authentication.authenticate(authToken);
            ownProps.setNewAccount(ownProps.formData.accountName);

            // Tidy up cookies - we don't need this after a successful signup
            Cookies.remove('initialQueryParams');
          },
        }),
    }),
  }),

  withHandlers({
    onFieldChange:
      ({ setFormData }) =>
      (name, value) =>
        setFormData((state) => ({ ...state, [name]: value })),

    onSubmit: (props) => () => {
      props.setformIsPersisting(true);
      props.handleFormSubmit(props);
    },
  }),
)(VerifyForm);
