import React from 'react';
import { branch, compose, renderNothing, pure } from 'recompose';
import Features from './features';

const LostFeatures = ({ features }) => <Features features={features} showOnlyEnabled />;

export default compose(
  //
  branch(({ features }) => features.length < 1, renderNothing),
  pure,
)(LostFeatures);
