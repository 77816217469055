import React from 'react';
import styled from 'styled-components/macro';

const HintBox = styled.div`
  font-size: 13px;
  background-color: #fefad4;
  border-top: 1px solid #fcf081;
  border-bottom: 1px solid #fcf081;
  padding: 15px 4%;
  margin-bottom: 15px;
  margin-top: 0;
  color: #444444;

  > p {
    margin: 8px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

function Hint(props) {
  return <HintBox>{props.children}</HintBox>;
}

export default Hint;
