import React from 'react';
import { Link } from 'react-router-dom';
import * as Recompose from 'lib/recompose';
import routes from 'config/routes';
import { CloseIcon } from 'components/icons';
import withAccountUsername from 'hocs/with-account-username';
import withCurrentCardId from 'hocs/with-current-card-id';
import * as Styled from './styled';

CloseIcon.defaultProps = {
  height: 12,
};

export default Recompose.compose(
  withAccountUsername,
  withCurrentCardId,
  Recompose.withProps((props) => {
    const { accountUsername, cardId } = props;
    const to = routes.accountCardPath(accountUsername, cardId);
    const children = React.createElement(CloseIcon);

    return {
      to,
      children,
      as: Link,
    };
  }),

  Recompose.omitProps('staticContext', 'cardId', 'accountUsername'),
  Recompose.pure,
)(Styled.HeaderButtonCircle);
