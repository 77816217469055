import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import CardLayout from 'components/cards/preview/layout';
import CallToActionButton from 'components/cards/forms/call-to-action-button';
import * as Styled from './styled';
import FriendsList from './friends-list';

function DiscoverCard(props) {
  return (
    <CardLayout hasFooterOverlay={props.hasBackgroundGradient} card={props}>
      <Styled.Scrollable scroll>
        <Styled.Header>
          <Styled.CardHeading children={props.title} />
          <Styled.CardSubHeading children={props.description} />
        </Styled.Header>
        <Styled.Content>
          <FriendsList friends={props.friends} />
        </Styled.Content>
      </Styled.Scrollable>
      <Styled.CallToActionButtonContainer>
        <CallToActionButton brandColor={props.cta.color} cta={props.cta} isPreview />
      </Styled.CallToActionButtonContainer>
    </CardLayout>
  );
}

DiscoverCard.propTypes = {
  friends: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorSolid: PropTypes.string,
  backgroundMediaUrl: PropTypes.string,
  buttonColor: PropTypes.string,
  hasBackgroundGradient: PropTypes.bool,
  editable: PropTypes.bool,
  enabled: PropTypes.bool,
  hidden: PropTypes.bool,
  moveable: PropTypes.bool,
  cta: PropTypes.object,
};

DiscoverCard.defaultProps = {
  friends: [],
  cta: {
    color: '#33154EE1',
    title: 'Get your Tap Bio',
    url: `${config.landingHost}/@tap.bio`,
  },
};

export default DiscoverCard;
