import React from 'react';
import { compose, branch, renderComponent } from 'lib/recompose';
import withMailingListCampaigns from 'hocs/with-mailing-list-campaigns';
import { ProgressBar } from 'components/cards/forms/shared-ui';
import IdentitiesSelector from './identity-selector';
import ListsSelector from './list-selector';
import {
  withIdentity,
  withContactLists,
  withFormHandlers,
  withAutoFormState,
  isIdentitySelected,
  isLoading,
} from './hocs';
import { Wrapper } from './styles';

const Manage = ({
  // identity
  identities,
  identityId,
  setIdentityId,

  // contact list
  lists,
  listId,
  setListId,
}) => (
  <Wrapper>
    {/* provider identity selector */}
    <IdentitiesSelector identities={identities} identityId={identityId} onSet={setIdentityId} />

    {/* provider campaign or contact list selector */}
    <ListsSelector lists={lists} listId={listId} onSet={setListId} />
  </Wrapper>
);

export default compose(
  withIdentity,
  branch(isIdentitySelected, withMailingListCampaigns),
  branch(isLoading, renderComponent(ProgressBar)),
  withContactLists,
  withFormHandlers,
  withAutoFormState,
)(Manage);
