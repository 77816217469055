const resolveCtaUrl = require('../utils/resolve-cta-url');

function ctaOnClickHandler({ url = '' }) {
  return function onClickHandler(event) {
    event.preventDefault();
    if (!url) return;

    window.location = resolveCtaUrl(url);
  };
}

module.exports = ctaOnClickHandler;
