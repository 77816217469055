import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

function Select(props) {
  const { options, onChange, defaultValue } = props;

  return (
    <Styled.Select
      onChange={onChange}
      defaultValue={defaultValue}
      onBlur={(event) => {
        // Webview bugfix for iOS 12
        // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
        // https://openradar.appspot.com/radar?id=5018321736957952
        window.scrollTo(0, 0);
      }}
    >
      {options.map((option) => {
        return <option key={option} value={option} children={option} />;
      })}
    </Styled.Select>
  );
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

Select.defaultProps = {
  options: [],
};

export default Select;
