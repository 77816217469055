import styled from 'styled-components/macro';
import logo from 'images/icon-down-arrow.svg';

const Select = styled.select`
  -webkit-appearance: none;
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  background-image: url('${logo}');
  background-position: 7% 50%;
  background-size: 200% 50%;
  background-repeat: no-repeat;
  padding: 15px 10px;
  font-size: 16px;
  width: 100%;

  :active,
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

export default Select;
