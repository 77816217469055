import { graphql } from '@apollo/react-hoc';
import { Modal } from 'components/ui';
import { Image as BaseImage, ButtonCircle, Heading, Text } from 'components/web-ui';
import fbq from 'lib/fbq';
import { billingProductSelectionQuery } from 'lib/graphql/queries';
import React from 'react';
import { branch, compose, pure, renderComponent, withHandlers, withProps } from 'recompose';
import styled from 'styled-components/macro';
import { withPriceFormatting } from './hocs';
import { augmentPlanWithDiscount, findDiscount } from './lib';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const PrimaryHeading = styled(Heading)`
  text-align: center;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  line-height: 1.3;
  margin: 17px auto 5% auto;
  width: 90%;
`;

const Image = styled(BaseImage)`
  margin-bottom: 6%;
  border-radius: 3px;
`;

const ConfirmButton = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  color: white;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1rem;
  height: 4rem;
`;

function CardAddedSuccessModal(props) {
  const { plan, product, handleClose, formatAmount, imageSrc } = props;

  return (
    <Modal onClickClose={handleClose}>
      <Layout>
        {product && (
          <PrimaryHeading>
            <Text>
              Your card was successfully charged <strong>${formatAmount(plan.amount)}</strong> and
              you subscribed to the{' '}
              <strong>
                {product.name} - {plan.interval === 'month' ? 'Monthly' : 'Yearly'} Plan
              </strong>
            </Text>
          </PrimaryHeading>
        )}
        <Image src={imageSrc} />
        <ConfirmButton children="Continue" onClick={handleClose} />
      </Layout>
    </Modal>
  );
}

export default compose(
  withPriceFormatting,
  branch(
    ({ isActive }) => !isActive,
    renderComponent((props) => null),
  ),
  graphql(billingProductSelectionQuery, { name: 'productSelectionQuery' }),
  withProps(({ newPlanStripeId, productSelectionQuery: { loading, products } }) => {
    // Based on the plan's stripe ID, find the product

    if (loading) return null;

    let product = null;
    products.map((p) => {
      if (p.plans.find((plan) => plan.stripeId === newPlanStripeId)) {
        product = p;
      }
      return null;
    });
    const plan = product.plans.find((plan) => plan.stripeId === newPlanStripeId);

    return { product, plan };
  }),

  withProps(({ currentUser: { discounts }, newPlanStripeId }) =>
    findDiscount(discounts, newPlanStripeId),
  ),
  withProps(({ discount, plan }) => {
    if (!plan) return;
    return augmentPlanWithDiscount(discount, plan);
  }),

  withProps(({ toTwoDecimalPlaces, plan }) => ({
    formatAmount: () =>
      toTwoDecimalPlaces((plan.discountedAmount >= 0 ? plan.discountedAmount : plan.amount) / 100),
  })),

  withHandlers({
    handleClose: (props) => (event) => {
      fbq('track', 'Subscribe', { value: props.plan.amount, currency: 'USD' });
      props.onClickClose(event);
    },
  }),

  pure,
)(CardAddedSuccessModal);
