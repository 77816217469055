/**
 * extract the chart data and the number of total events from
 * the AnalyticsListTypeConnection object
 */
const parseListItems = (connection, dates) => {
  const total = connection.edges.reduce((n, edge) => edge.node.total + n, 0);
  const events = connection.edges.reduce(
    (a, edge) => [...edge.node.events.edges.map((edge) => edge.node), ...a],
    [],
  );
  const counters = events.reduce(
    (o, evt) => ({
      ...o,
      [evt.datetime]: (o[evt.datetime] || 0) + evt.total,
    }),
    {},
  );
  const data = dates.map((datetime) => ({
    datetime,
    total: counters[datetime] || 0,
  }));
  return { data, total };
};

/**
 * extract the chart data and the number of total events from
 * the AnalyticsCounterTypeConnection object
 */
const parseCounterItems = (connection) => {
  const total = connection.edges.reduce((n, edge) => edge.node.total + n, 0);
  const data = connection.edges.map((edge) => edge.node);
  return { data, total };
};

/**
 * compute the event timeline and the number of events for
 * the chart on the card analytics view
 */
export const compute = (data, dates) => {
  if (data) {
    switch (data.__typename) {
      case 'AnalyticsListTypeConnection':
        return parseListItems(data, dates);
      case 'AnalyticsCounterTypeConnection':
        return parseCounterItems(data);
      default:
        break;
    }
  }
  return { data: [], total: 0 };
};
