export const validEmail = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/gi;

// http://www.rubular.com/r/ga546JrYpQ
export const validPhoneNumber = /^\+?[(]?[*0-9]+[)]?.?[0-9]{3,4}?.?[0-9]{3,4}$/;
export const validUrl =
  /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const forbiddenCharacters = /[^\w.\-+]/;

export const errors = {
  ACCOUNT_NAME_INVALID:
    'Your account username may only contain A-Z, 0-9, period, underscore, and hyphen characters.',
  ACCOUNT_NAME_NON_UNIQUE: 'This account name already exists!',
  EMAIL_INVALID: 'Should be an email',
  PHONE_NUMBER_INVALID: 'Should be a phone number',
  EMAIL_OR_PHONE_NUMBER_INVALID: 'Should be an email address or phone number',
  EMAIL_OR_PHONE_NUMBER_OR_URL_INVALID: 'Should be an email address, phone number or URL',
  EMAIL_EXISTS: 'This email already exists',
  EMAIL_NOT_EXISTS: "We can't find this email in our system",
  EMAIL_INACTIVE:
    "We can't send email to your address. Please contact support or use a different email",
};
