export const mailingListNotReady = (props) => {
  const { formState, currentAccount } = props;
  const { result, entities } = formState;
  if (result.ctas.length === 0) {
    return true;
  }
  const id = result.ctas[0];
  if (typeof entities.ctas[id].url !== 'string') {
    return true;
  }
  if (entities.ctas[id].url.trim().length === 0) {
    return true;
  }
  if (entities.ctas[id].url.indexOf('list-manage.com') > -1) {
    const identities = currentAccount.identities.filter((id) => id.provider === 'mailchimp');
    if (identities.length === 0) {
      return true;
    }
  }
  return false;
};
