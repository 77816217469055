import React from 'react';
import { compose } from 'recompose';
import withGetSocialAccountUsername from 'hocs/with-get-social-account-username';
import styled from 'styled-components/macro';
import SocialIcon from './social-icon';

const SocialAccountLayout = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  border-radius: 6px;
  margin-bottom: 12px;
  font-size: 16px;
`;

const IconLayout = styled.div`
  display: flex;
  border-right: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  align-items: center;
  justify-content: center;
  width: 56px;
  min-width: 56px;
`;

const UsernameLayout = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  color: ${(props) => props.theme.colors.alphaBlack60};
  padding: 15px 10px;
  border-top-right-radius: 6px;
  flex-grow: 1;
  overflow: hidden;
`;

function SocialAccount({ cta, getSocialAccountUsername }) {
  return (
    <SocialAccountLayout>
      <IconLayout>
        <SocialIcon url={cta.url} fill="#000000" width={26} height={26} />
      </IconLayout>
      <UsernameLayout>{getSocialAccountUsername(cta.url)}</UsernameLayout>
    </SocialAccountLayout>
  );
}

// prettier-ignore
export default compose(
  withGetSocialAccountUsername,
)(SocialAccount);
