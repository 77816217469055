import styled from 'styled-components/macro';
import { compose, defaultProps, withHandlers } from 'lib/recompose';
import * as Sentry from '@sentry/browser';
import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import routes from 'config/routes';

const DeleteAccountButton = styled.a`
  text-decoration: underline;
  font-size: 14px;
  color: ${(props) => props.theme.colors.danger};
  cursor: pointer;
`;

const mutation = gql`
  mutation DeleteAccountProfile($id: Int!) {
    closeAccount(accountId: $id) {
      logout
    }
  }
`;

export default compose(
  defaultProps({
    children: 'Delete my account',
    confirmationMessage:
      'Deleting your account is permanent and will remove all content including cards, avatars and profile settings. \n\nPlease type YES to confirm.',
  }),
  graphql(mutation, {
    props: ({ mutate, ownProps }) => ({
      deleteAccount: async ({ currentAccount }) => {
        try {
          const response = await mutate({
            mutation,
            variables: {
              id: currentAccount.id,
            },
          });
          const {
            data: {
              closeAccount: { logout },
            },
          } = response;
          const redirectUrl = logout ? routes.logoutPath : routes.accounts;
          window.location.href = redirectUrl;
        } catch (error) {
          console.error(error);
          Sentry.captureException(error);

          window.location.href = routes.accounts;
        }
      },
    }),
  }),
  withHandlers({
    onClick: (props) => (evt) => {
      evt && evt.preventDefault();
      const input = window.prompt(props.confirmationMessage);
      if (typeof input === 'string' && input.toLowerCase() === 'yes') {
        props.deleteAccount(props);
      }
    },
  }),
)(DeleteAccountButton);
