import * as Sentry from '@sentry/browser';
import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { defaultDeckQuery } from 'lib/graphql/queries';
import { removeCta } from 'lib/deck-mutation-utils';

const destroyCtaMutation = gql`
  mutation destroyCta($id: Int!) {
    destroyCta(id: $id) {
      id
    }
  }
`;

export default graphql(destroyCtaMutation, {
  props: ({ mutate, ownProps: { card, currentAccount } }) => ({
    handleDestroyCallToAction: async (id) => {
      if (!window.confirm('Are you sure?')) return false;

      try {
        const result = await mutate({
          mutation: destroyCtaMutation,
          variables: { id },
          update: (proxy) => {
            const oldDeck = proxy.readQuery({
              query: defaultDeckQuery,
              variables: { accountUsername: currentAccount.username },
            }).defaultDeck;
            const newDeck = removeCta(oldDeck, card.id, id);
            proxy.writeQuery({
              query: defaultDeckQuery,
              variables: { accountUsername: currentAccount.username },
              data: { defaultDeck: newDeck },
            });
          },
        });

        // Return whether an ID was returned (i.e. the mutation was successful)
        return !!result.data.destroyCta.id;
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    },
  }),
});
