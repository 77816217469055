import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import SocialIcon from 'components/cards/forms/profile/social-icon';
import { withRenderProtocol } from 'hocs/with-validation-handlers';

const SocialIconLink = styled.a`
  min-height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.48);
`;

const renderSocialLink = (cta, renderHrefWithProtocol) => (
  <SocialIconLink key={cta.url} href={renderHrefWithProtocol(cta.url)}>
    <SocialIcon url={cta.url} />
  </SocialIconLink>
);

function SocialLinks({ ctas, renderHrefWithProtocol }) {
  return ctas.map((cta) => renderSocialLink(cta, renderHrefWithProtocol));
}

SocialLinks.propTypes = {
  ctas: PropTypes.array.isRequired,
};

SocialLinks.defaultProps = {
  ctas: [],
};

export default withRenderProtocol(SocialLinks);
