/*
 *  A HOC that returns an identity from a given account.
 *
 *  Usage:
 *
 *    compose(
 *      ...
 *      withIdentity(provider)
 *      ...
 *    )(SomeComponent);
 *
 */

import { withProps } from 'recompose';

export default (provider) =>
  withProps(({ currentAccount: { identities } }) => ({
    identity: identities.find((identity) => identity.provider === provider),
  }));
