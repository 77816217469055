import { graphql } from '@apollo/react-hoc';
import ErrorBoundary from 'components/errors/error-boundary';
import { ScreenContainer } from 'components/ui';
import Spinner from 'components/ui/spinner';
import { Heading as HeadingBase } from 'components/web-ui';
import fbq from 'lib/fbq';
import { billingProductSelectionQuery } from 'lib/graphql/queries';
import React from 'react';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import styled from 'styled-components/macro';
import Checkout from './checkout';
import ChoosePlanMessage from './choose-plan-message';
import CurrentPlanMessage from './current-plan-message';
import Features from './features';
import Header from './header';
import Heading from './heading';
import PlanChangedSuccessModal from './plan-changed-success-modal';
import Plans from './plans';

// This lifecycle method exists to set the initial plan selection. There's two options:
//
//   - If one of the displayed plans is the current one, select it.
//   - If there's only a single plan, select it.
//
// Note that it ONLY sets the initial plan if there's no plan currently set. If you forget this
// test you'll be in an infinite loop.

function onComponentDidMountAndComponentWillReceiveProps(currentProps) {
  const {
    setSelectedPlanStripeId,
    selectedPlanStripeId,
    currentUser: {
      stripeSubscription: { planStripeId },
    },
    product,
  } = currentProps || this.props; // The order of these is important

  // Only set the initial plan if there's not currently one set
  if (!selectedPlanStripeId) {
    if (!product) return; // Not sure why this can happen. Perhaps the data's just not loaded yet.

    // Set the plan to the current one if the current one is one of the options
    const currentPlan = product.plans.find((plan) => plan.stripeId === planStripeId);
    if (currentPlan) {
      setSelectedPlanStripeId(currentPlan.stripeId);
      return;
    }

    // If there's only a single plan (e.g. basic) then select that by default
    if (product.plans.length === 1) {
      setSelectedPlanStripeId(product.plans[0].stripeId);
    }
  }
}

const CardContainer = styled.div`
  padding: 0% 4%;
  display: flex;
  flex-direction: column;
`;

const SelectPlanTypeHeading = styled(HeadingBase)`
  font-size: 17px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 1em;
`;

const SelectPlan = ({
  changeText,
  currentAccount,
  currentUser,
  handleCloseModal,
  handleSelectPlan,
  handleSuccessfulPlanChange,
  history,
  isOnTrial,
  match,
  plan,
  product,
  productSelectionQuery: { loading, activeFeatures },
  products,
  selectedPlanIsCurrentPlan,
  selectedPlanStripeId,
  showCheckoutButton,
  showPlanChangedSuccessModal,
}) => (
  <ErrorBoundary>
    <ScreenContainer p={0}>
      <Header history={history} />
      <CardContainer>
        <Heading children={`${changeText} ${product.name || ''}`} />
        {loading ? (
          <Spinner fill="rgba(0,0,0,0.25)" />
        ) : (
          <React.Fragment>
            <Features features={activeFeatures} product={product} showOnlyEnabled />
            <SelectPlanTypeHeading children="Select monthly or yearly" />
            <Plans
              discounts={currentUser.discounts}
              handleSelectPlan={handleSelectPlan}
              plans={product.plans}
              selectedPlanStripeId={selectedPlanStripeId}
            />
          </React.Fragment>
        )}

        <CurrentPlanMessage
          children={`This is your current plan ${isOnTrial ? '(on trial)' : ''} `}
          isVisible={selectedPlanIsCurrentPlan}
        />

        <ChoosePlanMessage
          children="Tap to select a plan"
          isVisible={!selectedPlanIsCurrentPlan && !selectedPlanStripeId}
        />
      </CardContainer>

      {/*
        Only show the checkout button if:
          - the currently selected plan is NOT the current one
          - the current plan is NOT a trial
      */}
      <Checkout
        activeFeatures={activeFeatures}
        currentAccount={currentAccount}
        currentUser={currentUser}
        history={history}
        isVisible={showCheckoutButton}
        onSuccessfulPlanChange={handleSuccessfulPlanChange}
        plan={plan}
        product={product}
        products={products}
      />

      {/* The confirm modal displays on clicking the 'Change to X Plan button' */}
      <PlanChangedSuccessModal
        isActive={showPlanChangedSuccessModal}
        onClickClose={handleCloseModal}
        plan={plan}
        product={product}
      />
    </ScreenContainer>
  </ErrorBoundary>
);

export default compose(
  graphql(billingProductSelectionQuery, { name: 'productSelectionQuery' }),
  withState('selectedPlanStripeId', 'setSelectedPlanStripeId', null),
  withState('showPlanChangedSuccessModal', 'setShowPlanChangedSuccessModal', false),

  withProps(({ productSelectionQuery: { loading, products }, match }) => ({
    product: !loading && products.find((p) => p.stripeId === match.params.productStripeId),
    products: !loading && products,
  })),

  withProps(({ productSelectionQuery: { loading }, product, selectedPlanStripeId }) => ({
    plan: !loading && product.plans.find((p) => p.stripeId === selectedPlanStripeId),
  })),

  withProps(({ products, product, currentUser }) => {
    // Products are ordered by increasing value so we just need to determine whether the
    // new product's index is higher or lower than the current one.

    let changeText = 'Change payment plan for';

    if (products) {
      const oldProduct =
        products &&
        products.find((p) => p.stripeId === currentUser.stripeSubscription.productStripeId);
      const oldPlan =
        oldProduct &&
        oldProduct.plans.find((p) => p.stripeId === currentUser.stripeSubscription.planStripeId);
      const newPlan = oldPlan && product.plans.find((p) => p.interval === oldPlan.interval);
      if (oldProduct && product && oldProduct.stripeId !== product.stripeId) {
        const oldPaymentAmount = (oldPlan && Number(oldPlan.amount)) || 0;
        const newPaymentAmount = (newPlan && Number(newPlan.amount)) || 0;
        changeText = oldPaymentAmount < newPaymentAmount ? 'Upgrade to' : 'Downgrade to';
      }
    }

    return { changeText };
  }),

  lifecycle({
    componentDidMount: onComponentDidMountAndComponentWillReceiveProps,
    componentWillReceiveProps: onComponentDidMountAndComponentWillReceiveProps,
  }),

  withProps(
    ({
      selectedPlanStripeId,
      currentUser: {
        stripeSubscription: { planStripeId },
      },
    }) => ({
      selectedPlanIsCurrentPlan: selectedPlanStripeId === planStripeId,
    }),
  ),

  withProps(
    ({
      currentUser: {
        stripeSubscription: { status },
      },
    }) => ({
      isOnTrial: status === 'trialing',
    }),
  ),

  withProps(({ selectedPlanIsCurrentPlan, isOnTrial }) => ({
    showCheckoutButton: !selectedPlanIsCurrentPlan || isOnTrial,
  })),

  withHandlers({
    handleSelectPlan:
      ({ setSelectedPlanStripeId }) =>
      (plan, event) => {
        event.preventDefault();
        setSelectedPlanStripeId(plan.stripeId);
      },
    handleSuccessfulPlanChange:
      ({ setShowPlanChangedSuccessModal, plan }) =>
      (props) => {
        const subscriptionValue = plan.amount / 100;
        fbq('track', 'Subscribe', { value: subscriptionValue, currency: 'USD' });
        setShowPlanChangedSuccessModal(true);
      },
    handleCloseModal:
      ({ setShowPlanChangedSuccessModal, history }) =>
      (event) => {
        event.preventDefault();
        setShowPlanChangedSuccessModal(false);
      },
  }),
)(SelectPlan);
