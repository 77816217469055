import { lifecycle, withHandlers, withProps } from 'lib/recompose';
import set from 'lodash/set';

export const isLoading = ({ withMailingListCampaigns }) =>
  !withMailingListCampaigns || withMailingListCampaigns.loading;

export const isIdentitySelected = ({ identityId }) =>
  typeof identityId === 'string' && identityId.length > 0;

export const withIdentity = withProps(({ identities, formState }) => ({
  identityId:
    formState.entities.mailingLists[formState.result.mailingList].identityId ||
    (identities[0] && String(identities[0].id)) ||
    '',
}));

export const withContactLists = withProps(({ withMailingListCampaigns, formState }) => {
  const lists = withMailingListCampaigns.mailingListCampaigns.edges;
  const listId =
    formState.entities.mailingLists[formState.result.mailingList].listId ||
    (lists[0] && lists[0].node && String(lists[0].node.id)) ||
    '';
  const listUrl =
    formState.entities.mailingLists[formState.result.mailingList].url ||
    (lists[0] && lists[0].node && String(lists[0].node.url)) ||
    '';
  return { lists, listId, listUrl };
});

export const withFormHandlers = withHandlers({
  setIdentityId:
    ({ formState, setFormState }) =>
    (identityId) => {
      const id = formState.result.mailingList;
      setFormState((state) =>
        set({ ...state }, `entities.mailingLists.${id}.identityId`, identityId),
      );
    },
  setListId:
    ({ formState, setFormState }) =>
    (listId, url) => {
      const id = formState.result.mailingList;
      setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.listId`, listId));
      setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.url`, url));
    },
  setDefaultFormState: () => (props) => {
    const { listId, listUrl, identityId, formState, setFormState } = props;
    const id = formState.result.mailingList;
    const state = formState.entities.mailingLists[id];
    const path = `entities.mailingLists.${id}`;

    if (state.identityId !== identityId) {
      setFormState((state) => set({ ...state }, `${path}.identityId`, identityId));
    }
    if (state.listId !== listId) {
      setFormState((state) => set({ ...state }, `${path}.listId`, listId));
      setFormState((state) => set({ ...state }, `${path}.url`, listUrl));
    }
  },
});

export const withAutoFormState = lifecycle({
  componentDidMount() {
    this.props.setDefaultFormState(this.props);
  },
  componentDidUpdate() {
    this.props.setDefaultFormState(this.props);
  },
});
