import React from 'react';
import styled from 'styled-components/macro';
import { compose, withProps, defaultProps } from 'recompose';
import { Button as BaseButton } from 'components/web-ui';
import Spinner from 'components/ui/spinner';

export const ProgressBar = compose(
  defaultProps({
    fill: 'rgba(0,0,0,0.25)',
  }),
)(Spinner);

export const Link = styled.a`
  display: inline;
  color: ${(props) => props.theme.colors.primaryPurple};
  text-decoration: underline;
`;

export const Layout = styled.div`
  height: 100%;
  padding: 20px;
  padding-bottom: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.primaryPurple};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Footer = styled.div`
  font-size: 14px;
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 30px;
`;

export const Wrapper = styled.div`
  flex: 1;
`;

export const HeaderButton = compose(
  defaultProps({
    onClick: (evt) => evt && evt.preventDefault(),
  }),
)(styled.a`
  display: flex;
  flex: 1;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  margin: -20px;
  font-size: 1em;
  color: black;
  cursor: pointer;
  &:nth-child(2) {
    justify-content: flex-end;
  }
`);

export const GradientButton = compose(
  defaultProps({
    children: '',
    onClick: (evt) => evt && evt.preventDefault(),
    start: '#52BAD5',
    end: '#B7B7B7',
  }),
)(
  styled.div`
    margin-top: 14px;
    padding: 16px;
    font-size: 16px;
    align-items: center;
    border-radius: 50em;
    width: 100%;

    display: flex;
    justify-content: center;

    background-image: linear-gradient(to right, ${(props) => props.start}, ${(props) => props.end});
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

    &:disabled {
      opacity: 1;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:active {
      opacity: 0.75;
    }
    &:hover {
      box-shadow: none;
    }
  `.withComponent(({ gradient, ...props }) => <BaseButton {...props} />),
);

export const ErrorMessage = compose(
  withProps(({ error }) => {
    if (typeof error === 'string') {
      return { children: error };
    }
    const children =
      error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0
        ? error.graphQLErrors[0].message
        : null;
    return { children };
  }),
)(styled.p`
  position: relative;
  padding: 34px 10px 10px 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.veryLightYellow};
  :before {
    position: absolute;
    content: 'ERROR';
    padding: 1px 4px;
    top: 10px;
    left: 10px;
    font-size: 10px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`);
