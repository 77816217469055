import React from 'react';

function TapBioLogo(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
      <circle cx="125" cy="125" r="125" />
      <path
        fill="currentColor"
        d="M134.5 152.5a24.7 24.7 0 0 1 6.4-16.1c2.2-2.3 4.8-4 7.8-5s6.2-1.6 9.8-1.6a21.8 21.8 0 0 1 23.6 22.7c0 6.5-2.2 12-6.6 16.2a23.8 23.8 0 0 1-17.3 6.3 26 26 0 0 1-11.4-2.4 21.5 21.5 0 0 1-10.4-10.6c-1.3-3-1.9-6.2-1.9-9.5zm15.9.2c0 2.4.7 4.3 2.2 5.8a7.7 7.7 0 0 0 5.6 2.2 7.8 7.8 0 0 0 8-8.1c0-2.6-.8-4.6-2.3-6.2a7.5 7.5 0 0 0-5.6-2.4c-2.2 0-4.1.8-5.7 2.4a8.7 8.7 0 0 0-2.2 6.3zm-32.2-22.1h5.7c2.8 0 5 2.2 5 5v32.7c0 2.8-2.2 5.1-5 5.1h-5.7a5.1 5.1 0 0 1-5-5v-32.7c0-2.9 2.2-5.1 5-5.1zm-47.9 37.7v-32.6c0-2.9 2.3-5.1 5.1-5.1H92c4.7 0 8.3 1.3 10.8 4a11 11 0 0 1 2.9 7.7 9 9 0 0 1-2.7 6.7c-1 1-2.4 1.8-4 2.2 1.8.5 3.3 1 4.3 1.7a9.3 9.3 0 0 1 4.4 8.5 10.4 10.4 0 0 1-4.6 9c-1.4 1-3.2 1.9-5.5 2.4a25 25 0 0 1-5.9.6H75.4a5.1 5.1 0 0 1-5-5zm14.3-4.4h2.5c1.6 0 2.7 0 3.3-.3a3 3 0 0 0 1.5-1c.4-.6.6-1.4.6-2.3 0-1.3-.5-2.3-1.5-3-.8-.3-2-.6-3.8-.6h-2.6v7.2zm0-16.7h1.7c1.4 0 2.4 0 2.8-.3.5-.1 1-.5 1.3-1 .4-.7.5-1.3.5-2 0-1-.4-2-1.3-2.5-.5-.4-1.6-.5-3.2-.5h-1.8v6.3zm64.2-34.4V80c0-2.8 2.3-5.1 5-5.1h13.3c3.6 0 6.3.3 8 .8a15.4 15.4 0 0 1 7.7 5c1 1.1 1.7 2.4 2.3 4 .6 1.6.9 3.5.9 5.7 0 2.8-.5 5.3-1.6 7.4-1 2-2.3 3.7-3.9 4.8-.8.7-2 1.2-3.4 1.6-2 .5-4.5.8-7.6.8h-.4a5.1 5.1 0 0 0-5 5.1v2.6c0 2.8-2.4 5-5.2 5h-5a5.1 5.1 0 0 1-5.1-5zm15-18.8h1.7c1.9 0 3.1-.1 3.7-.5a3.4 3.4 0 0 0 1.7-3c0-1.3-.3-2.1-1-2.7-.8-.6-2.2-.9-4.3-.9h-1.8V94zm-64.2 17l11.7-32.7a5 5 0 0 1 4.8-3.3h10a5 5 0 0 1 4.8 3.4l11.6 32.6a5.1 5.1 0 0 1-4.8 6.9H131a3.7 3.7 0 0 1-3.5-2.8l-.2-.8c-.3-1.1-1.3-2-2.5-2h-7.8c-1.2 0-2.2.9-2.5 2l-.2.8a3.6 3.6 0 0 1-3.5 2.8h-6.5a5.1 5.1 0 0 1-4.8-6.9zM118 99.6h6.3l-1.8-6.6a1.4 1.4 0 0 0-2.7 0l-1.8 6.6zm-32.6 18.3H80a5.1 5.1 0 0 1-5-5.1V94c0-2.9-2.4-5.1-5.2-5.1h-.6a5.1 5.1 0 0 1-5.1-5.1V80c0-2.8 2.3-5.1 5.1-5.1h27c2.8 0 5.1 2.3 5.1 5.1v3.8c0 2.8-2.3 5-5 5h-.7a5.1 5.1 0 0 0-5 5.2v18.7c0 2.8-2.4 5-5.2 5z"
      />
    </svg>
  );
}

TapBioLogo.defaultProps = {
  width: '72px',
  fill: 'rgba(255,255,255,0.33)',
};

export default TapBioLogo;
