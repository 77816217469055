import React from 'react';
import AccountSwitcherItem from './item';

function AccountSwitcher(props) {
  const { accounts, currentAccount } = props;
  return accounts.map((account, i) => (
    <AccountSwitcherItem
      key={i}
      account={account}
      isCurrent={currentAccount.username === account.username}
    />
  ));
}

export default AccountSwitcher;
