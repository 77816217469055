import React from 'react';
import styled from 'styled-components/macro';
import { Input, Label, Text } from 'components/web-ui';

const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Field = ({
  children,
  disabled,
  label,
  name,
  onChange,
  styledSelect: Select,
  styledLabel: Label,
  validationError,
  value,
  options,
}) => {
  const select = (
    <Select
      key={name}
      onBlur={(event) => {
        // Webview bugfix for iOS 12
        // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
        // https://openradar.appspot.com/radar?id=5018321736957952
        window.scrollTo(0, 0);
        // this.handleBlur && this.handleBlur(event);
      }}
      onChange={(event) => onChange(name, event.target.value)}
      value={value}
      disabled={disabled}
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </Select>
  );

  if (label) {
    return (
      <FieldContainer>
        <Label m={0} f={16} htmlFor={name}>
          <Text>
            {label}
            {validationError}
          </Text>
        </Label>
        {select}
      </FieldContainer>
    );
  }

  return <FieldContainer>{[select, children, validationError]}</FieldContainer>;
};

Field.defaultProps = {
  disabled: false,
  placeholder: '',
  styledInput: Input,
  styledLabel: Label,
  value: '',
};

// prettier-ignore
export default Field;
