import React from 'react';
import PhotoCards from '../photo-cards';
import './posts-panel.css';

const PostsPanel = ({
  cardId,
  displayCount,
  globallyActivePanel,
  handleAddFirstLink,
  setDisplayCount,
  user,
}) => (
  <div className="posts-panel">
    <PhotoCards
      cardId={cardId}
      displayCount={displayCount}
      globallyActivePanel={globallyActivePanel}
      handleAddFirstLink={handleAddFirstLink}
      setDisplayCount={setDisplayCount}
      user={user}
    />
  </div>
);

export default PostsPanel;
