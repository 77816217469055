import React from 'react';
import PropTypes from 'prop-types';
import * as Recompose from 'recompose';

function EmailPreviewHint(props) {
  return (
    <span>
      We'll send a code to <b>{props.formData.email}</b> that you'll use to create your account on
      the next screen.
    </span>
  );
}

const EmailHint = Recompose.compose(
  Recompose.branch((props) => {
    const { formData, formErrors } = props;
    return !formData.email || formErrors.email;
  }, Recompose.renderNothing),
)(EmailPreviewHint);

EmailHint.propTypes = {
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
};

export default EmailHint;
