import React from 'react';
import styled from 'styled-components/macro';
import UpgradeAccount from 'components/cards/stats/elements/upgrade-panel';
import Default from 'components/cards/stats/cards/default';
import Profile from 'components/cards/stats/cards/profile';
import Links from 'components/cards/stats/cards/links';
import ImageGallery from 'components/cards/stats/cards/image-gallery/panel';
import Instagram from 'components/cards/stats/cards/instagram/panel';
import Youtube from 'components/cards/stats/cards/youtube/panel';
import Twitter from 'components/cards/stats/cards/twitter/panel';
import MailingList from 'components/cards/stats/cards/mailing-list';

const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

function renderStats(props) {
  if (!props.currentUser.features.hasStats && !window.enableStatsDebugging) {
    return <UpgradeAccount {...props} />;
  }
  switch (props.card.kind) {
    case 'profile':
      return <Profile {...props} />;
    case 'instagram':
      return <Instagram {...props} />;
    case 'image-gallery':
      return <ImageGallery {...props} />;
    case 'mailing-list':
      return <MailingList {...props} />;
    case 'youtube':
      return <Youtube {...props} />;
    case 'twitter':
      return <Twitter {...props} />;
    case 'links':
      return <Links {...props} />;
    case 'simple':
      return <Links {...props} />;
    case 'tap.bio':
      return;
    case 'discover':
      return;
    default:
      return <Default {...props} />;
  }
}

const Base = ({ isShowing, ...props }) => isShowing && <Container children={renderStats(props)} />;

export default Base;
