import React from 'react';
import { css } from 'styled-components';
import { Input as BaseInput, Textarea as BaseTextarea } from 'components/web-ui';
import ScrollContainerBase from 'components/ui/scroll-container';
import { omitProps } from 'lib/recompose';
import addLightenFilter from 'components/ui/add-lighten-filter';
import { ButtonCircle } from 'components/web-ui';
import styled from 'styled-components/macro';

const Form = styled.form`
  background-color: white;
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;
  /*
    Firefox bug
    https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox/28639686#28639686
    min-height: 0;
   */
  min-height: 0;
`;

const ScrollContainer = styled(ScrollContainerBase)`
  background-color: white;
  flex: 1;
`;

const FormContainer = styled.div`
  padding: 10px 16px 0 16px;
`;

const Field = styled.div`
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 15px;
  font-size: 16px;
  position: relative;
  font-weight: ${(props) => props.theme.fonts.weights.bold};
  ${(props) => props.disabled && 'opacity: 0.5;'};
`;

const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.colors.black};
  text-transform: uppercase;
  margin-bottom: 3px;
  font-size: 11px;
  position: relative;

  p {
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    margin-bottom: 7px;
  }
`;

const SectionTitle = styled.h1`
  color: ${(props) => (props.danger ? props.theme.colors.danger : props.theme.colors.black)};
  padding-bottom: 3px;
  border-bottom: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
`;

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const Input = styled.input`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  ${fieldBorderCSS};

  &:disabled {
    opacity: 1;
  }
`.withComponent(omitProps('hasErrors')(BaseInput));

const Textarea = styled.textarea`
  display: block;
  min-height: 150px;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  line-height: 1.3;
  ${fieldBorderCSS};
`.withComponent(omitProps('hasErrors')(BaseTextarea));

const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.veryLightBlue};
`;

const PhotoView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 36px 0;
  width: 200px;
  max-width: calc(100% - 80px);
  border-radius: 10px;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.09);

  ${(props) => props.backgroundColorSolid && `background-color: ${props.backgroundColorSolid};`}
  background-image: url('${(props) =>
    props.backgroundColorSolid ? 'none' : props.defaultBackgroundMediaUrl}');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  ${(props) =>
    props.hasBlendMode && !props.backgroundColorSolid && addLightenFilter(props.filterColor)}

  /* Make the photo square */
  &::after {
    content: '';
    padding-bottom: 180%;
    display: block;
  }
`.withComponent(
  omitProps('backgroundColorSolid')(
    ({ filterColor, defaultBackgroundMediaUrl, hasBlendMode, ...rest }) => <div {...rest} />,
  ),
);

const FormButton = styled(ButtonCircle)`
  font-size: 1rem;
  width: 100%;
  height: 4rem;
`;

const EditPhotoButton = styled.button`
  background-color: ${(props) => props.theme.colors.alphaBlack50};
  color: ${(props) => props.theme.colors.white};
  font-size: 13px;
  padding: 7px 15px;
  position: absolute;
  bottom: 15px;
  display: block;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  small {
    font-weight: ${(props) => props.theme.fonts.weights.bold};
  }
`;

const EditSocialAccountsButton = styled(FormButton)`
  background-color: ${(props) => props.theme.colors.profileForm.inactiveButton};
  font-weight: bold;
  color: black;
`;

const ExportCardStatsButton = styled(FormButton)`
  background-color: ${(props) => props.theme.colors.primaryPurple};
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
`;

const SocialAccountsContainer = styled.div`
  margin-bottom: 20px;
`;

export {
  EditPhotoButton,
  EditSocialAccountsButton,
  ExportCardStatsButton,
  Field,
  Form,
  FormContainer,
  Input,
  Label,
  PhotoContainer,
  PhotoView,
  SectionTitle,
  Textarea,
  SocialAccountsContainer,
  ScrollContainer,
};
