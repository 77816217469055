import styled from 'styled-components/macro';

const ScrollContainer = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  position: relative;
`;

export default ScrollContainer;
