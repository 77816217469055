import React from 'react';
import { compose } from 'recompose';
import { withErrorBoundary } from 'components/errors/error-boundary';
import displayLoadingState from 'hocs/display-loading-state';
import withCard from 'hocs/with-card';
import EditProfileForm from 'components/cards/forms/profile';
import SimpleForm from 'components/cards/forms/simple/form';
import InstagramForm from 'components/cards/forms/instagram/form';
import InstagramLinks from 'components/cards/forms/instagram/links';
import MailingListForm from 'components/cards/forms/mailing-list/form';
import ImageGalleryForm from 'components/cards/forms/image-gallery';
import LinksForm from 'components/cards/forms/links/form';
import EventForm from 'components/cards/forms/event';
import DiscoverForm from 'components/cards/forms/discover';
import YoutubeForm from 'components/cards/forms/youtube';
import TwitterForm from 'components/cards/forms/twitter';
import BaseForm from 'components/cards/forms/base';

function EditCard(props) {
  if (props.card.kind === 'instagram') {
    return (
      <React.Fragment>
        <BaseForm {...props} FormComponent={InstagramForm} />
        <InstagramLinks {...props} />
      </React.Fragment>
    );
  }

  const FormComponent = {
    profile: EditProfileForm,
    simple: SimpleForm,
    links: LinksForm,
    event: EventForm,
    discover: DiscoverForm,
    'mailing-list': MailingListForm,
    'image-gallery': ImageGalleryForm,
    youtube: YoutubeForm,
    twitter: TwitterForm,
  }[props.card.kind];

  return <BaseForm {...props} FormComponent={FormComponent} />;
}

export default compose(
  //
  withCard,
  displayLoadingState,
  withErrorBoundary,
)(EditCard);
