import withExportStatsQuery from 'hocs/with-export-stats';
import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { ExportCardStatsButton } from './styled';

function Component(props) {
  const { onClick, requested } = props;
  return (
    <ExportCardStatsButton
      children={
        requested ? 'Your data will be in your inbox shortly!' : 'Click to Export Card Stats'
      }
      onClick={onClick}
      disabled={requested}
    />
  );
}

export default compose(
  withState('requested', 'setRequested', false),
  withExportStatsQuery,
  withHandlers({
    onClick:
      ({ mutate, requested, setRequested }) =>
      (evt) => {
        evt.preventDefault();
        // ignore future actions if if archive was been requested
        if (requested) {
          return;
        }
        mutate().then(() => setRequested(true));
      },
  }),
)(Component);
