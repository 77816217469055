import { denormalize, normalize, schema } from 'normalizr';
import findIndex from 'lodash/findIndex';

const cta = new schema.Entity('ctas');
const event = new schema.Entity('events');
const card = new schema.Entity('cards', { event, ctas: [cta] });
const deckSchema = { activeCards: [card], draftCards: [card] };

export function normalizeDeck(deck) {
  return normalize(deck, deckSchema);
}

export function denormalizeDeck(normalizedDeck) {
  return denormalize(normalizedDeck.result, deckSchema, normalizedDeck.entities);
}

export function addNewCard(deck, card) {
  const normalizedDeck = normalizeDeck(deck);
  normalizedDeck.entities.cards[card.id] = card;
  normalizedDeck.result.activeCards.unshift(card.id);
  return denormalizeDeck(normalizedDeck);
}

export function removeCta(deck, cardId, ctaId) {
  const normalizedDeck = normalizeDeck(deck);
  const ctas = normalizedDeck.entities.cards[cardId].ctas;
  const index = ctas.indexOf(ctaId);
  normalizedDeck.entities.cards[cardId].ctas.splice(index, 1);
  return denormalizeDeck(normalizedDeck);
}

export function removeImage(deck, cardId, imageId) {
  const normalizedDeck = normalizeDeck(deck);
  const images = normalizedDeck.entities.cards[cardId].images;
  const index = findIndex(images, ['id', imageId]);
  normalizedDeck.entities.cards[cardId].images.splice(index, 1);
  return denormalizeDeck(normalizedDeck);
}
