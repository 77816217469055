import { SettingsIcon } from 'components/icons';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { setSelectedPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';

const CustomSettingsIcon = styled(SettingsIcon)`
  width: 38px;
  path {
    fill: white;
  }
`;

export default compose(
  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
  })),
  withHandlers({
    handleShowCustomSettings:
      ({ popover, setSelectedPopover }) =>
      (event) => {
        event.preventDefault();
        setSelectedPopover(popover);
      },
  }),
)(({ handleShowCustomSettings }) => (
  <CustomSettingsIcon width={18} height={18} onClick={handleShowCustomSettings} />
));
