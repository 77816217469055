import PropTypes from 'prop-types';
import { css } from 'styled-components';
import Button from 'components/ui/base/button';
import { omitProps, compose, defaultProps, setPropTypes, withProps } from 'lib/recompose';
import styled from 'styled-components/macro';

const variations = {
  default: {
    light: {
      block: css`
        color: ${(props) => props.theme.colors.primaryPurple};
        background-color: white;

        &:active {
          opacity: 0.8;
        }
      `,
      ghost: css`
        color: white;
        border: 2px solid currentColor;
        padding-top: 13px;
        padding-bottom: 13px;
        background-color: transparent;

        &:active {
          opacity: 0.8;
        }
      `,
    },
    dark: {
      block: css`
        color: white;
        background-color: ${(props) => props.theme.colors.primaryPurple};
        -webkit-font-smoothing: antialiased;

        &:active {
          opacity: 0.8;
        }
      `,
      ghost: css`
        color: ${(props) => props.theme.colors.primaryPurple};
        border: 2px solid currentColor;
        padding-top: 13px;
        padding-bottom: 13px;
        background-color: transparent;

        &:active {
          opacity: 0.8;
        }
      `,
    },
  },
  disabled: {
    light: {
      block: css`
        color: ${(props) => props.theme.colors.alphaWhite50};
        background-color: ${(props) => props.theme.colors.alphaWhite10};
      `,
      ghost: css`
        color: ${(props) => props.theme.colors.alphaWhite50};
        border: 2px solid currentColor;
        background-color: transparent;
      `,
    },
    dark: {
      block: css`
        color: white;
        background-color: ${(props) => props.theme.colors.disabledGrey};
      `,
      ghost: css`
        color: ${(props) => props.theme.colors.disabledGrey};
        border: 2px solid currentColor;
        background-color: transparent;
      `,
    },
  },
};

const FormButton = styled.button`
  ${(props) => variations[props.variations[0]][props.variations[1]][props.variations[2]]};
`.withComponent(omitProps('variations')(Button));

export default compose(
  setPropTypes({
    light: PropTypes.bool,
    ghost: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  defaultProps({
    light: false,
    ghost: false,
    disabled: false,
  }),
  withProps((props) => ({
    variations: [
      props.disabled ? 'disabled' : 'default',
      props.light ? 'light' : 'dark',
      props.ghost ? 'ghost' : 'block',
    ],
  })),
  omitProps('light', 'ghost'),
)(FormButton);
