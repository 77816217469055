// A module…
//
// 1. MUST export default a function called reducer()
// 2. MUST export its action creators as functions
// 3. MUST have action types in the form npm-module-or-app/reducer/ACTION_TYPE
// 4. MAY export its action types as UPPER_SNAKE_CASE, if an external reducer needs to listen
//    for them, or if it is a published reusable library

const SET = 'tb.toast.setToast';
const UNSET = 'tb.toast.unsetToast';

const initialState = { message: null, visible: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, message: action.message, visible: true };
    case UNSET:
      return { ...state, visible: false };
    default:
      return state;
  }
}

export function setToast(message) {
  return { type: SET, message };
}

export function unsetToast() {
  return { type: UNSET };
}
