import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import StorySlider from '@tap-bio/ui/patterns/story-slider';
import PreviewCard from 'components/cards/preview';
import routes from 'config/routes';
import { useHistory } from 'react-router-dom';

const StyledStorySlider = styled(StorySlider)`
  height: 100%;
`;

function MyComponent(props) {
  const {
    children,
    currentAccount,
    currentUser,
    activeIndex,
    onSwiperInit,
    onSlideChangeEnd,
    ...rest
  } = props;

  const history = useHistory();

  const cards = children.map((card, index) => {
    return (
      <PreviewCard
        id={card.id}
        card={card}
        currentAccount={currentAccount}
        currentActiveCardIndex={activeIndex}
        currentUser={currentUser}
        cardIndexInDeck={index}
        isCardVisible={activeIndex === index}
      />
    );
  });

  const baseUrl = routes.account(currentAccount.username);

  return (
    <StyledStorySlider
      {...rest}
      cards={cards}
      onInit={onSwiperInit}
      onSlideChangeTransitionEnd={(swiper) => {
        const { value: currentCardId } = swiper.history.getPathValues();
        history.replace(`${baseUrl}/cards/${currentCardId}`);
        onSlideChangeEnd(swiper);
      }}
      history={{ key: `${baseUrl}/cards` }}
    />
  );
}

MyComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  activeIndex: PropTypes.number,
  onSwiperInit: PropTypes.func,
  onSlideChangeEnd: PropTypes.func,
};

MyComponent.defaultProps = {};

export default MyComponent;
