import ErrorBoundary from 'components/errors/error-boundary';
import { Hint, ScreenContainer } from 'components/ui';
import config from 'config';
import routes from 'config/routes';
import fbq from 'lib/fbq';
import React, { useState } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import styled from 'styled-components/macro';
import CardUpdatedSuccessModal from './card-updated-success-modal';
import CreditCardForm from './credit-card-form';
import Header from './header';

// http://localhost:4000/billing/update-credit-card

const CardContainer = styled.div`
  padding: 0% 4%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 15px;
`;

const UpdateCreditCard = function (props) {
  const { currentAccount, history, planId } = props;

  const [showCardUpdatedSuccessModal, setShowCardUpdatedSuccessModal] = useState(false);

  function handleSuccessfulCardUpdate() {
    setShowCardUpdatedSuccessModal(true);
  }

  function handleCloseModal(event) {
    event.preventDefault();
    fbq('track', 'UpdatePaymentInfo');
    setShowCardUpdatedSuccessModal(false);
    history.push(routes.accounts);
  }

  // Handle users that for some reason don't have a credit card on file
  if (!props.currentUser.stripeSource) {
    return (
      <ScreenContainer p={0}>
        <Header history={props.history} title="Update Card" />
        <Hint>
          <p>We currently do not have a credit card on file for your account.</p>
        </Hint>
      </ScreenContainer>
    );
  }

  const { cardBrand, cardExpMonth, cardExpYear, cardLast4 } = props.currentUser.stripeSource;

  // Otherwise, display a list of the accounts that need to have their payment methods updated
  return (
    <ErrorBoundary>
      <StripeProvider apiKey={config.stripe.publishableKey}>
        <ScreenContainer p={0}>
          <Header history={props.history} title="Update Card" />
          <Hint>
            <p>
              We currently have a <b>{cardBrand}</b> ending in <b>{cardLast4}</b> on file.
              <br />
              This card expires on{' '}
              <b>
                {cardExpMonth} / {cardExpYear}
              </b>
              .
            </p>
          </Hint>

          <CardContainer>
            <Elements>
              <CreditCardForm
                currentAccount={currentAccount}
                onSuccessfulAddCard={handleSuccessfulCardUpdate}
                planId={planId}
                submitButtonText="Update Card"
              />
            </Elements>
          </CardContainer>
          <CardUpdatedSuccessModal
            isActive={showCardUpdatedSuccessModal}
            onClickClose={handleCloseModal}
          />
        </ScreenContainer>
      </StripeProvider>
    </ErrorBoundary>
  );
};

export default UpdateCreditCard;
