import { compose, defaultProps, withHandlers } from 'lib/recompose';
import React from 'react';
import styled from 'styled-components/macro';

const Clickable = styled.a`
  height: ${(props) => props.size}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Checkbox = styled.div`
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: ${(props) => (props.checked ? props.size / 2 : 1)}px solid
    ${(props) => props.theme.colors.primaryPurple};
  border-radius: ${(props) => props.size / 2}px;
  background-color: white;
`;

const Description = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primaryPurple};
  margin-left: 12px;
`;

const AdditionalInformation = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey};
`;

const Checkmark = styled.div`
  position: relative;
  top: -${(props) => props.size / 3}px;
  left: -${(props) => props.size / 2}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: inline-block;
  transform: rotate(45deg);
  opacity: ${(props) => (props.checked ? 1 : 0)};
  transition: opacity 0.5s ease;
`;

const CheckmarkStem = styled.div`
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: ${(props) => props.theme.colors.white};
  left: 11px;
  top: 6px;
`;

const CheckmarkKick = styled.div`
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.white};
  left: 8px;
  top: 12px;
`;

function Component(props) {
  const { size, description, checked, additionalInformation, onCheckboxClick } = props;
  return (
    <React.Fragment>
      <Clickable href="#" size={size} onClick={onCheckboxClick}>
        <Checkbox size={size} checked={checked}>
          <Checkmark size={size} checked={checked}>
            <CheckmarkStem />
            <CheckmarkKick />
          </Checkmark>
        </Checkbox>
        <Description children={description} />
      </Clickable>
      {!!additionalInformation && (
        <AdditionalInformation children={additionalInformation} size={size} />
      )}
    </React.Fragment>
  );
}

const withDefaultProps = defaultProps({
  checked: true,
  size: 30,
  description: '',
  additionalInformation: '',
});

const withComponentHandlers = withHandlers({
  onCheckboxClick:
    ({ checked, onClick }) =>
    (evt) => {
      evt && evt.preventDefault();
      if (typeof onClick === 'function') {
        onClick(!checked);
      }
    },
});

export default compose(withDefaultProps, withComponentHandlers)(Component);
