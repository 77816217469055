import withImagesStateHandlers from 'hocs/with-images-state-handlers';
import update from 'immutability-helper';
import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { compose, mapProps, withHandlers } from 'recompose';
import { setSelectedImage } from 'store/modules/card-image';
import { setSelectedPopover } from 'store/modules/card-popovers';
import SortableImageGrid from './sortable-image-grid';

const SortableImageGalleryPhotos = ({
  handleDelete,
  handleShowImageForm,
  loadingImageUrls,
  onSortEnd,
  orderedImages,
  removeImageFromStateById,
  scrollContainer,
}) => (
  <SortableImageGrid
    axis="xy"
    handleShowImageForm={handleShowImageForm}
    helperClass="sortableImage"
    loadingImageUrls={loadingImageUrls}
    removeImageFromStateById={removeImageFromStateById}
    onSortEnd={onSortEnd}
    orderedImages={orderedImages}
    useDragHandle
    getContainer={() => document.getElementById('image-gallery-form-scroll-container')}
  />
);

export default compose(
  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setSelectedImage: (id) => dispatch(setSelectedImage(id)),
  })),
  withImagesStateHandlers,

  withHandlers({
    handleShowImageForm:
      ({ setSelectedPopover, setSelectedImage }) =>
      (id) => {
        setSelectedPopover('image-gallery');
        setSelectedImage(id);
      },
    onSortEnd:
      ({ setFormState }) =>
      ({ oldIndex, newIndex }, event) => {
        setFormState((state) => {
          const newImageEntities = {};

          // Create an array of image ids for the new order
          const orderedImageIds = arrayMove(state.result.images, oldIndex, newIndex);

          // Update each image object's position attribute with the new index
          orderedImageIds.forEach((imageId, index) => {
            newImageEntities[imageId] = { ...state.entities.images[imageId], position: index };
          });

          // Update the form state with both the images and their order
          return update(state, {
            entities: { images: { $set: newImageEntities } },
            result: { images: { $set: orderedImageIds } },
          });
        });
      },
  }),

  // Use the updated formState to create an array of the sorted image objects.
  // This is what gets displayed
  mapProps((props) => {
    const {
      formState: { entities, result },
    } = props;
    return { ...props, orderedImages: result.images.map((id) => entities.images[id]) };
  }),
)(SortableImageGalleryPhotos);
