import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';
import { compose, withProps, withHandlers } from 'recompose';
import CardLayout from 'components/cards/preview/layout';
import Header from 'components/headers/card-preview-header';
import Media from 'components/cards/preview/instagram/media';
import withIdentity from 'hocs/with-identity';
import Scrollable from 'components/cards/preview/scrollable';
import {
  CtaButtonContainerFixed,
  DescriptionSmall,
  TitleSmall,
} from 'components/cards/preview/styles';
import withInstagramLogin from 'hocs/instagram/with-instagram-login';
import { fixLineBreakSpacers } from 'utils';
import CallToActionButtons from 'components/cards/forms/call-to-action-buttons';
import Button from 'components/ui/base/button';

// Temporary Instagram migration button
const MigrateLayout = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  border-radius: 4px;
  margin: 0 5px 15px 5px;

  > button {
    width: 90%;
    background-color: ${(p) => p.theme.colors.alert};
    color: white;
  }

  > p {
    text-align: center;
    width: 90%;
    margin: 10px 0 0 0;
    font-size: 13px;
  }
`;

function InstagramPreviewCard(props) {
  const { identity, card, cardIndexInDeck, currentAccount, isCardVisible } = props;

  // Start temporary reconnect/migrate V1 buttons
  const reconnectButton = (
    <MigrateLayout>
      <Button
        children="Reconnect your Instagram Account"
        onClick={props.handleInstagramMigration}
      />
    </MigrateLayout>
  );

  const migrateButton = (
    <MigrateLayout>
      <Button children="Migrate My Instagram Account" onClick={props.handleInstagramMigration} />
      <p>
        Instagram is moving their data, and we don&rsquo;t want it to affect your Tap Bio. Please
        migrate your account.
      </p>
    </MigrateLayout>
  );
  // End temporary reconnect/migrate V1 buttons

  return (
    <CardLayout
      card={card}
      hasFooterOverlay={card.hasBackgroundGradient}
      isCardVisible={isCardVisible}
    >
      <Header
        currentAccount={currentAccount}
        card={card}
        cardIndexInDeck={cardIndexInDeck}
        isVisible={card.editable}
      />
      <Scrollable
        // If the CTA is displaying we should pad the bottom to allow viewing of the entire bottom
        // row of images.
        cardId={card.id}
        topPadding="68px"
        verticalAlign="top"
        xPadding="0"
      >
        {/* Start temporary reconnect/migrate V1 buttons */}
        {!!identity ? (
          <>
            {!identity.tokenIsValid && reconnectButton}
            {identity.tokenIsValid && identity.apiVersion === 1 && migrateButton}
          </>
        ) : null}
        {/* End temporary reconnect/migrate V1 buttons */}

        {card.title && <TitleSmall children={fixLineBreakSpacers(card.title)} />}
        <DescriptionSmall>
          {card.description
            ? fixLineBreakSpacers(card.description)
            : 'Tap on any photo to visit the link'}
        </DescriptionSmall>
        <Media card={card} />
      </Scrollable>
      <CtaButtonContainerFixed>
        <CallToActionButtons
          brandColor={card.buttonColor || currentAccount.profile.brandColor}
          ctas={card.ctas}
          isPreview
        />
      </CtaButtonContainerFixed>
    </CardLayout>
  );
}

export default compose(
  withRouter,
  withIdentity('instagram'),
  withProps(({ card }) => ({
    hasDescription: !!card.description,
  })),
  withInstagramLogin,
  withHandlers({
    handleInstagramMigration: (props) => (event) => {
      event.preventDefault();
      props.handleInstagramLogin();
    },
  }),
)(InstagramPreviewCard);
