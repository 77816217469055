import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'config/routes';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

function Header(props) {
  const { card, isUploadingAvatar, isPersisting, currentAccount } = props;
  return (
    <ViewHeader>
      <ViewHeader.LeftButton
        as={Link}
        to={routes.accountCardPath(currentAccount.username, card.id)}
      />
      <ViewHeader.Title children="Profile Card" />
      <ViewHeader.RightButton
        children={isPersisting ? 'Saving...' : 'Save'}
        type="submit"
        disabled={isUploadingAvatar || isPersisting}
      />
    </ViewHeader>
  );
}

export default Header;
