import ErrorBoundary from 'components/errors/error-boundary';
import { ScreenContainer } from 'components/ui';
import config from 'config';
import routes from 'config/routes';
import happy from 'images/happy.gif';
import fbq from 'lib/fbq';
import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import * as Recompose from 'recompose';
import styled from 'styled-components/macro';
import CardAddedSuccessModal from './card-added-success-modal';
import CreditCardForm from './credit-card-form';
import Header from './header';
import Heading from './heading';

// Preload the GIF that we'll show on the thanks modal
new Image().src = happy;

const CardContainer = styled.div`
  padding: 0% 4%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddCreditCard = function (props) {
  const {
    currentAccount,
    currentUser,
    handleCloseModal,
    handleSuccessfulCardAddition,
    history,
    planId,
    showCardAddedSuccessModal,
  } = props;

  return (
    <ErrorBoundary>
      <StripeProvider apiKey={config.stripe.publishableKey}>
        <ScreenContainer p={0}>
          <Header history={history} />
          <CardContainer>
            <Heading children="Add Payment" />
            <Elements>
              <CreditCardForm
                currentAccount={currentAccount}
                onSuccessfulAddCard={handleSuccessfulCardAddition}
                planId={planId}
                submitButtonText="Add Card"
              />
            </Elements>
          </CardContainer>

          {/* The confirm modal displays on clicking the 'Change to X Plan button' */}
          <CardAddedSuccessModal
            currentUser={currentUser}
            imageSrc={happy}
            isActive={showCardAddedSuccessModal}
            newPlanStripeId={planId}
            onClickClose={handleCloseModal}
          />
        </ScreenContainer>
      </StripeProvider>
    </ErrorBoundary>
  );
};

export default Recompose.compose(
  Recompose.withProps(({ match }) => ({ planId: match.params.planId })),
  Recompose.withState('showCardAddedSuccessModal', 'setShowCardAddedSuccessModal', false),
  Recompose.withHandlers({
    handleSuccessfulCardAddition:
      ({ setShowCardAddedSuccessModal }) =>
      (props) => {
        setShowCardAddedSuccessModal(true);
      },
    handleCloseModal:
      ({ setShowCardAddedSuccessModal, history }) =>
      (event) => {
        event.preventDefault();
        fbq('track', 'AddPaymentInfo');
        setShowCardAddedSuccessModal(false);
        history.push(routes.accounts);
      },
  }),
  Recompose.pure,
)(AddCreditCard);
