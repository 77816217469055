import styled, { createGlobalStyle } from 'styled-components';
import ScrollContainer from 'components/ui/scroll-container';
import { Heading as HeadingBase } from 'components/web-ui';

const StyleSheet = createGlobalStyle`
  .sortableRow {
    opacity: 0.7;
    background-color: #EEEEEE;
    z-index: 9999;
  }
`;

const Layout = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  height: 100%;
`;

const Scrollable = styled(ScrollContainer)`
  padding-left: 10px;
  padding-top: 0.375rem;
`;

const Heading = styled(HeadingBase)`
  font-size: 27px;
  line-height: 1;
`;

export { StyleSheet, Layout, Scrollable, Heading };
