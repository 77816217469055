import * as Sentry from '@sentry/browser';
import Authentication from 'lib/authentication';
import config from 'config';

export function fetchJSON(url, method = 'GET', data = {}) {
  if (process.env.NODE_ENV === 'development') console.log(url, method, data);

  const token = Authentication.getToken();

  // https://github.com/github/fetch/issues/424#issuecomment-259070410
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: token ? `Bearer ${token}` : null,
  };

  const params = {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers,
    method,
  };

  if (method === 'GET') {
    delete params.body;
  }

  return fetch(url, params);
}

function objectToQueryString(params) {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

// Note: Originally we were parsing the text from the response and if the JSON
// could not be parsed from it we'd send it with the Sentry error. However,
// using `response.text()` uses a polyfill that uses `FileReader` API which
// is poorly implemented on some mobile platforms causing A LOT of issues.
// Related issue - https://github.com/github/fetch/issues/475

export default function request(url, method = 'GET', data = {}) {
  let requestUrl = url;

  return new Promise(async (resolve, reject) => {
    let response = null;
    let json = null;

    if (method === 'GET') {
      requestUrl = `${requestUrl}?${objectToQueryString(data)}`;
    }

    const debugData = {
      data,
      method,
      response,
      requestUrl,
    };

    const requestUrlWithHost = `${config.apiHost}${requestUrl}`;
    try {
      response = await fetchJSON(requestUrlWithHost, method, data);
      json = await response.json();
      if (response && response.ok) {
        resolve(json);
      } else {
        reject(json);
      }
    } catch (error) {
      Sentry.captureException(error, { extra: { ...debugData } });
      reject(error);
    }
  });
}

export function getJSON(url, data) {
  return request(url, 'GET', data);
}

export function postJSON(url, data) {
  return request(url, 'POST', data);
}

export function patchJSON(url, data) {
  return request(url, 'PATCH', data);
}
