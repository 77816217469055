import React from 'react';

function Heart(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 26 26" {...props}>
      <path
        fill="#929EA8"
        d="M 17.867188 3.890625 C 15.773438 3.890625 13.980469 5.382813 12.996094 6.410156 C 12.015625 5.382813 10.226563 3.890625 8.132813 3.890625 C 4.519531 3.890625 2 6.40625 2 10.007813 C 2 13.980469 5.132813 16.546875 8.160156 19.027344 C 9.589844 20.199219 11.070313 21.410156 12.203125 22.757813 C 12.394531 22.980469 12.675781 23.109375 12.96875 23.109375 L 13.027344 23.109375 C 13.324219 23.109375 13.601563 22.980469 13.792969 22.757813 C 14.929688 21.410156 16.40625 20.199219 17.839844 19.027344 C 20.867188 16.546875 24 13.980469 24 10.007813 C 24 6.40625 21.480469 3.890625 17.867188 3.890625 Z "
      />
    </svg>
  );
}

export default Heart;
