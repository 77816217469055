import React from 'react';
import PropTypes from 'prop-types';
import { branch, renderNothing } from 'lib/recompose';
import styled from 'styled-components/macro';

const Button = styled.button`
  border-radius: 50%;
  font-size: 1em;
  width: 100%;
  height: 100%;
  padding: 0;
  color: white;
  background-color: #d8d8d8;
  border: 0;
  text-align: center;
`;

function IdentityAccessDeleteButton(props) {
  return <Button onClick={props.onClick}>✕</Button>;
}

IdentityAccessDeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default branch(
  //
  (props) => props.isCurrent,
  renderNothing,
)(IdentityAccessDeleteButton);
