import React from 'react';
import config from 'config';
import Header from 'components/cards/forms/profile/header-background-photo';
import { Swiper, Slide } from 'react-dynamic-swiper';
import ColorSwatchPickerField from 'components/accounts/onboarding/color-swatch-picker-field';
import SelectBackgroundPhoto from 'components/cards/preview/profile/select-background-photo';
import { Text } from 'components/web-ui';
import FilteredPhoto from '../filtered-photo';
import * as Styled from './styled';

function ChooseBackgroundFilterComponent(props) {
  const {
    handleSubmit,
    photoParams,
    initialSlide,
    selectedSlideIndex,
    selectedBrandColor,
    selectedPhotoUrl,
    setSelectedSlideIndex,
    setSelectedBrandColor,
    selectedBackgroundColorSolid,
    setSelectedBackgroundColorSolid,
    setSelectedPhotoUrl,
    uploadImageTitle,
    onImageSelected,
    selectedSlideIsBackgroundColorSolid,
    setSelectedSlideIsBackgroundColorSolid,
  } = props;

  function onSlideActive(swiper) {
    const photoParamsObj = photoParams(swiper.activeIndex, selectedBrandColor);
    const { backgroundMediaUrl, label } = photoParamsObj;
    setSelectedPhotoUrl(backgroundMediaUrl);
    setSelectedSlideIndex(swiper.activeIndex);
    setSelectedSlideIsBackgroundColorSolid(label === 'Solid');
  }

  return (
    <Styled.Layout>
      <Header
        // handleSubmit either saves a card form, or a profile form
        onClickDone={handleSubmit}
        selectedPhotoUrl={selectedPhotoUrl}
        selectedBrandColor={selectedBrandColor}
        selectedBackgroundColorSolid={
          selectedSlideIsBackgroundColorSolid ? selectedBackgroundColorSolid : null
        }
      />
      <Styled.SwiperLayout>
        <Styled.UploadPhotoButton>
          <Text children={uploadImageTitle} />
          <SelectBackgroundPhoto onImageSelected={onImageSelected} />
        </Styled.UploadPhotoButton>
        <Swiper
          containerClassName="swiper-background-filters-container"
          swiperOptions={{ sliderCount: 4, initialSlide }}
          onInitSwiper={() => {
            setSelectedPhotoUrl(
              photoParams(selectedSlideIndex, selectedBrandColor).backgroundMediaUrl,
            );
          }}
          navigation={false}
          pagination
        >
          <Slide onActive={onSlideActive}>
            <FilteredPhoto {...photoParams(0, selectedBrandColor)} colorChooser />
          </Slide>
          <Slide onActive={onSlideActive}>
            <FilteredPhoto {...photoParams(1, selectedBrandColor)} colorChooser />

            <Styled.ColorPickerLayout>
              <ColorSwatchPickerField
                name="brandColor"
                placeholder="#HEX color value"
                swatches={config.profileColorChoices}
                value={selectedBrandColor}
                onChange={(name, value) => {
                  setSelectedBrandColor(value);
                }}
              />
            </Styled.ColorPickerLayout>
          </Slide>
          <Slide onActive={onSlideActive}>
            <FilteredPhoto {...photoParams(2)} />
          </Slide>
          <Slide onActive={onSlideActive}>
            <FilteredPhoto {...photoParams(3)} />
          </Slide>
          <Slide onActive={onSlideActive}>
            <Styled.SolidBackground selectedBackgroundColorSolid={selectedBackgroundColorSolid} />
            <Styled.SolidBackgroundLabel children={'Solid'} />
            <Styled.ColorPickerLayout>
              <ColorSwatchPickerField
                name="backgroundColorSolid"
                placeholder="#HEX color value"
                swatches={config.profileColorChoices}
                value={selectedBackgroundColorSolid}
                onChange={(name, value) => {
                  setSelectedBackgroundColorSolid(value);
                }}
              />
            </Styled.ColorPickerLayout>
          </Slide>
        </Swiper>
      </Styled.SwiperLayout>
    </Styled.Layout>
  );
}
export default ChooseBackgroundFilterComponent;
