import React from 'react';

function ButtonRightArrowIcon(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M 12.21875 2.28125 L 10.78125 3.71875 L 18.0625 11 L 2 11 L 2 13 L 18.0625 13 L 10.78125 20.28125 L 12.21875 21.71875 L 21.21875 12.71875 L 21.90625 12 L 21.21875 11.28125 Z " />
      </g>
    </svg>
  );
}

export default ButtonRightArrowIcon;
