import React from 'react';
import { withState } from 'recompose';
import { SortableHandle, SortableElement } from 'react-sortable-hoc';
import { MoveIcon } from 'components/icons';
import TextTruncate from 'react-text-truncate';
import Spinner from 'components/ui/spinner';
import {
  ImageWrapper,
  Controls,
  ImageBackground,
  EditIconWrapper,
  SpinnerContainer,
  Title,
  Description,
  CopyWrapper,
} from './styled';
import DestroyImage from './destroy-image';

// Wrap this around the drag zone, which is the image
const DragHandle = SortableHandle((props) => props.children);

const Component = SortableElement((props) => {
  const {
    handleShowImageForm,
    image,
    isLoading,
    isPersisting,
    removeImageFromStateById,
    setIsPersisting,
  } = props;

  return (
    <ImageWrapper>
      <Controls>
        <DestroyImage
          id={image.id}
          removeImageFromStateById={removeImageFromStateById}
          setIsPersisting={setIsPersisting}
        />
        <DragHandle>
          <EditIconWrapper>
            <MoveIcon width={17} />
          </EditIconWrapper>
        </DragHandle>
      </Controls>
      <ImageBackground
        imageUrl={image.imageUrl}
        isLoading={isLoading}
        onClick={() => handleShowImageForm(image.id)}
      />
      <CopyWrapper>
        <Title>
          <TextTruncate line={1} truncateText="…" text={image.title} />
        </Title>
        <Description>
          <TextTruncate line={2} truncateText="…" text={image.description} />
        </Description>
      </CopyWrapper>
      <SpinnerContainer isVisible={isLoading || isPersisting}>
        <Spinner />
      </SpinnerContainer>
    </ImageWrapper>
  );
});

export default withState('isPersisting', 'setIsPersisting', false)(Component);
