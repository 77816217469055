import React from 'react';
import styled from 'styled-components/macro';
import { TreasureMap } from 'components/icons';

const Layout = styled.div`
  flex: 1;
  height: 100%;
  color: #ccc;
  background-color: ${(p) => p.theme.colors.veryDarkPurple};
  color: white;
  padding: 1.4em 32px;

  font-size: 32px;
  font-weight: normal;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

const Title = styled.h1`
  font-size: inherit;
  font-weight: bold;
  margin: 0;
`;

const Subtitle = styled.h1`
  font-size: inherit;
  font-weight: normal;
  margin: 0;
  color: #f5f7f8;
  -webkit-font-smoothing: antialiased;
`;

function NoMatchingRoute(props) {
  return (
    <Layout>
      <TreasureMap opacity="0.5" width={80} />
      <Title>Profile Not Found</Title>
      <Subtitle>We couldn't find a profile at this address.</Subtitle>
    </Layout>
  );
}

export default NoMatchingRoute;
