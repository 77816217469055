import React from 'react';
import routes from 'config/routes';
import { Redirect } from 'react-router-dom';

function RedirectToFirstCard(props) {
  const { username } = props.currentAccount;
  const firstCardId = props.data.defaultDeck.activeCards[0].id;
  const pathname = routes.accountCardPath(username, firstCardId);

  return <Redirect to={{ pathname }} />;
}

function RedirectToOnboarding(props) {
  const { username } = props.currentAccount;
  const pathname = routes.accountOnboarding(username);

  return <Redirect to={{ pathname }} />;
}

export { RedirectToFirstCard, RedirectToOnboarding };
