import styled from 'styled-components/macro';
import Input from './base/text-input';

const FormTextInput = styled(Input)`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fafafa;
`;

FormTextInput.defaultProps = {
  mb: '5px',
};

export default FormTextInput;
