import omggif from 'omggif';
import NeuQuant from './neuquant';

/**
 * The default options object
 */
const defaultOptions = {
  maxPixel: 2000,
  maxWidth: 1440,
  patchOrientation: true,
};

/**
 * @summary Checks if file is gif image type
 *
 * @param   {File} file - The input file instance
 * @returns {Boolean} returns true if file is gif
 */
export function isGif(file) {
  return file.type === 'image/gif';
}

/**
 * @summary Reads gif frames from GifReader
 *
 * @param {Buffer} The bytes buffer of the image file
 * @returns {Promise} Promise result callback
 */
function decodeGif(buffer) {
  const reader = new omggif.GifReader(new Uint8Array(buffer));
  const numFrames = reader.numFrames();
  const loop = reader.loopCount();
  const promises = Array(numFrames)
    .fill(0)
    .map(
      (_, i) =>
        new Promise((resolve) => {
          const info = reader.frameInfo(i);
          info.pixels = new Uint8ClampedArray(reader.width * reader.height * 4);
          reader.decodeAndBlitFrameRGBA(i, info.pixels);
          resolve(info);
        }),
    );
  return Promise.all(promises).then((frames) => ({ frames, loop }));
}

/**
 * @summary Compress and encode GIF
 *
 * @param {Canvas} The canvas element
 * @param {File} The input file instance
 * @param {Function} The resolve callback function
 * @returns {Promise} callback function
 */
function compressGif(canvas, file, resolve) {
  const { width, height } = canvas;
  return ({ frames, loop }) => {
    const buf = new Buffer(width * height * frames.length);
    const writer = new omggif.GifWriter(buf, width, height, { loop });
    // only compress image without transparent layer
    for (const frame of frames) {
      if (typeof frame.transparent_index === 'number') {
        resolve({ file });
        return;
      }
    }
    for (const frame of frames) {
      const { x, y, delay, disposal } = frame;
      const pixels = compressFrame(canvas, frame);
      const { palette, indexed } = composeFrame(width, height, pixels);
      const opts = { delay, disposal, palette };
      writer.addFrame(x, y, width, height, indexed, opts);
    }
    const data = buf.slice(0, writer.end());
    const blob = new Blob([data]);
    resolve({ blob, file });
  };
}

/**
 * @summary Compress frame image
 */
function compressFrame(canvas, frame) {
  const context = canvas.getContext('2d');

  // create image from git reader frame pixels
  const img = context.createImageData(frame.width, frame.height);
  try {
    img.data.set(frame.pixels);
  } catch (_) {
    return frame.pixels;
  }

  // fill canvas with image
  const rx = canvas.width / frame.width;
  const ry = canvas.height / frame.height;
  const ratio = rx > ry ? ry : rx;
  const x = -frame.x * ratio;
  const y = -frame.y * ratio;
  const w = frame.width * ratio;
  const h = frame.height * ratio;
  context.putImageData(img, x, y, 0, 0, w, h);

  // reads image data from canvas
  const image = context.getImageData(0, 0, canvas.width, canvas.height);
  // clear canvas
  context.clearRect(0, 0, canvas.width, canvas.height);

  return image.data;
}

/**
 * @summary Creates new GIF frame
 */
function composeFrame(width, height, data) {
  let i = 0;
  const rgb = [];
  const len = width * height * 4;
  while (i < len) {
    rgb.push(data[i++]);
    rgb.push(data[i++]);
    rgb.push(data[i++]);
    i++; // skip alpha channel
  }
  const nq = new NeuQuant(rgb, rgb.length, 10);
  const paletteRGB = nq.process();
  const paletteArray = [];
  for (let i = 0; i < paletteRGB.length; i += 3) {
    const r = paletteRGB[i];
    const g = paletteRGB[i + 1];
    const b = paletteRGB[i + 2];
    paletteArray.push((r << 16) | (g << 8) | (b << 0));
  }
  const palette = new Uint32Array(paletteArray);
  const numberPixels = width * height;
  const indexed = new Uint8Array(numberPixels);
  for (let i = 0, k = 0; i < numberPixels; i++) {
    let r = rgb[k++];
    let g = rgb[k++];
    let b = rgb[k++];
    indexed[i] = nq.map(r, g, b);
  }
  return { palette, indexed };
}

/**
 * @summary Reads image orientation
 *
 * @param   {File} file - The input file instance
 * @returns {Promise} result with orientation number.
 */
export function orientationOf(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dv = new DataView(reader.result);
      if (dv.getUint16(0, false) !== 0xffd8) {
        return resolve(-2);
      }
      const len = dv.byteLength;
      let offset = 2;
      while (offset < len) {
        const marker = dv.getUint16(offset, false);
        if (dv.getUint16((offset += 2), false) <= 8) {
          return resolve(-1);
        }
        if (marker === 0xffe1) {
          if (dv.getUint32((offset += 2), false) !== 0x45786966) {
            return resolve(-1);
          }
          const df = dv.getUint16((offset += 6), false) === 0x4949;
          offset += dv.getUint32(offset + 4, df);
          const tags = dv.getUint16(offset, df);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (dv.getUint16(offset + i * 12, df) === 0x0112) {
              return resolve(dv.getUint16(offset + i * 12 + 8, df));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += dv.getUint16(offset, false);
        }
      }
      return resolve(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 << 10));
  });
}

/**
 * @summary Reads data with FileReader and creates image object
 *
 * @param   {File} file - The input file instance
 * @returns {Promise} - The transform result
 */
export function readImage(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    // successful if function fires
    img.onload = () => resolve({ img, file });
    // error handling
    img.onerror = () => reject();

    reader.onloadend = () => {
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  });
}

/**
 * @summary Reads image file to array buffer
 */
export function readImageBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // successful if function fires
    reader.onload = (evt) => resolve(evt.target.result);
    // error handling
    reader.onerror = () => reject();

    reader.readAsArrayBuffer(file);
  });
}

/**
 * @summary Reads image data with orientation
 */
export function readImageWithOrientation(file) {
  return Promise.all([readImage(file), readImageBuffer(file), orientationOf(file)]).then(
    ([data, buffer, orientation]) => ({
      ...data,
      file,
      buffer,
      orientation,
    }),
  );
}

/**
 * @summary Reads image data and apply orientation
 */
export function applyOrientation(resolved, options = defaultOptions) {
  return new Promise((resolve, reject) => {
    const { img, file, orientation } = resolved;
    const { width: imageWidth, height: imageHeight } = img;
    const { maxPixel } = options;

    if (
      (!orientation || orientation <= 1 || orientation > 8) &&
      imageWidth <= maxPixel &&
      imageHeight <= maxPixel
    ) {
      return resolve(resolved);
    }

    const rx = maxPixel / imageWidth;
    const ry = maxPixel / imageHeight;
    const ratio = rx > ry ? ry : rx;

    const targetWidth = imageWidth * ratio;
    const targetHeight = imageHeight * ratio;

    const canvas = document.createElement('canvas');
    canvas.width = orientation > 4 && orientation < 9 ? targetHeight : targetWidth;
    canvas.height = orientation > 4 && orientation < 9 ? targetWidth : targetHeight;

    const ctx = canvas.getContext('2d');
    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, targetWidth, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, targetWidth, targetHeight);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, targetHeight);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, targetHeight, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, targetHeight, targetWidth);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, targetWidth);
        break;
      default:
    }
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

    const output = new Image();
    output.onload = () => resolve({ img: output, file, orientation });
    output.onerror = () => reject();

    output.src = canvas.toDataURL(file.type);
  });
}

/**
 * @summary Calculates the image output dimensions
 *
 * @param   {Image}  The image object
 * @param   {Object} The image processor Options
 */
function calculateImageDimensions(img, options = defaultOptions) {
  const { width: imageWidth, height: imageHeight } = img;

  const res = {
    skip: false,
    crop: false,
    targetWidth: imageWidth,
    targetHeight: imageHeight,
    patchOrientation: options.patchOrientation,
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  };

  // image width is less than the target output width,
  // skips the resizing process.
  if (typeof options.maxWidth === 'number' && imageWidth < options.maxWidth) {
    return res;
  }

  if (
    typeof options.x1 === 'number' &&
    typeof options.x2 === 'number' &&
    typeof options.y1 === 'number' &&
    typeof options.y2 === 'number'
  ) {
    res.crop = true;
    res.targetWidth = options.x2 - options.x1;
    res.targetHeight = options.y2 - options.y1;
    res.x1 = options.x1;
    res.x2 = options.x2;
    res.y1 = options.y1;
    res.y2 = options.y2;
    return res;
  }

  const ratio = options.maxWidth / imageWidth;
  res.targetWidth = options.maxWidth;
  res.targetHeight = Math.round(imageHeight * ratio);

  return res;
}

/**
 * @summary Starts image resizing
 *
 * @param   {Object} resolved - Promise resolved object
 * @param   {Image}  resolved.img - The image object
 * @param   {File}   resolved.file - The file instance
 *
 * @returns {Promise} - The promise result
 */
function processImage(resolved, options = defaultOptions) {
  const { img, buffer, file, orientation } = resolved;
  return new Promise((resolve, reject) => {
    const { skip, crop, patchOrientation, targetWidth, targetHeight, x1, y1 } =
      calculateImageDimensions(img, options);

    // image width is less than the target output width,
    // skips the resizing process.
    if (skip) {
      return resolve({ file });
    }

    const canvas = document.createElement('canvas');
    canvas.width =
      orientation > 4 && orientation < 9 && patchOrientation ? targetHeight : targetWidth;
    canvas.height =
      orientation > 4 && orientation < 9 && patchOrientation ? targetWidth : targetHeight;

    const ctx = canvas.getContext('2d');
    if (patchOrientation) {
      switch (orientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, targetWidth, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, targetWidth, targetHeight);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, targetHeight);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, targetHeight, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, targetHeight, targetWidth);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, targetWidth);
          break;
        default:
        // Possibly trigger false alarm?
        // It is normal that some images will have no exif metadata.
        // Sentry.captureMessage('Orientation not found', orientation);
      }
    }

    // resize gif image handler
    if (isGif(file)) {
      return decodeGif(buffer).then(compressGif(canvas, file, resolve));
    }

    // resize image type other than gif
    if (crop) {
      ctx.drawImage(img, x1, y1, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight);
    } else {
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
    }

    // create output file blob
    canvas.toBlob((blob) => resolve({ blob, file }), file.type);
  });
}

/**
 * @summary Converts blob to file
 *
 * @param   {Object} resolved - Promise resolved object
 * @param   {Blob}   resolved.blob - The blob input
 * @param   {File}   resolved.file - The original file instance
 *
 * @returns {Promise} - The output result
 */
function toFile(resolved) {
  const { blob, file } = resolved;
  return new Promise((resolve) => {
    // only return compressed file if the output file size is smaller
    // than original input file.
    if (blob && blob.size < file.size) {
      const { type, name, lastModified } = file;
      // add prefix to filename, prevent gcloud storage caching
      const filename = `${Date.now()}-${name}`;
      const output = new File([blob], filename, { type, lastModified });
      return resolve(output);
    }
    return resolve(file);
  });
}

/**
 * @summary Resizes image by passing in a File object
 *
 * @param {File} file - The input File instance
 * @return {Promise} - The resized result.
 */
export function resizeImage(file) {
  return readImageWithOrientation(file).then(processImage).then(toFile);
}

/**
 * @summary Resizes image with options
 *
 * @param   {Image}  The image object
 * @param   {File}   The file instance
 * @param   {Object} The image processor optionS
 */
export function cropImage(img, file, options) {
  return orientationOf(file)
    .then((orientation) => processImage({ img, file, orientation }, options))
    .then(toFile);
}

/**
 * @summary Wrapper for `SignedUploader` onFiles prop.
 */
export function withResize(onFiles) {
  return (files) => Promise.all([...files].map(resizeImage)).then(onFiles);
}

/**
 * @summary Rotate image function
 */
export function rotateImage(image, file, angle = 90) {
  return new Promise((resolve, reject) => {
    if (!~[0, 90, 180, 270, 360].indexOf(angle)) {
      return reject('invalid rotation angle');
    }

    const flipWH = [90, 270].indexOf(angle) > -1;
    const canvas = document.createElement('canvas');
    canvas.width = flipWH ? image.height : image.width;
    canvas.height = flipWH ? image.width : image.height;

    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((angle * Math.PI) / 180);
    context.drawImage(image, -image.width / 2, -image.height / 2);
    context.restore();

    const output = new Image();
    output.width = canvas.width;
    output.height = canvas.height;
    output.onload = () => resolve(output);
    output.onerror = () => reject();

    output.src = canvas.toDataURL(file.type);
  });
}
