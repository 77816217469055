import React from 'react';
import * as Recompose from 'lib/recompose';
import { Link } from 'react-router-dom';
import { PlusIcon as BasePlusIcon } from 'components/icons';
import routes from 'config/routes';
import withCompleteOnboarding from 'hocs/with-complete-onboarding';
import withUpdateUserMutation from 'hocs/with-update-user-mutation';
import styled from 'styled-components/macro';
import * as Styled from './styled';

const PlusIcon = styled(BasePlusIcon)`
  width: 38px;
  height: 16px;
  path {
    fill: white;
  }
`;

const Button = styled(Styled.HeaderButton)`
  padding: 0;
  position: absolute;
  left: 3%;
  top: calc(${({ theme }) => theme.cardForm.headerTopOffset} + 16px);
  z-index: 5;
  width: 38px;

  @media (min-width: 23rem) {
    padding: 0;
  }
`;

export default Recompose.compose(
  withUpdateUserMutation,
  withCompleteOnboarding,

  Recompose.withProps((props) => {
    const to = routes.newCardPath(props.currentAccount.username);
    const children = React.createElement(PlusIcon);
    const as = Link;

    return { to, as, children };
  }),

  Recompose.withHandlers({
    onClick: (props) => () => {
      props.handleCompleteOnboarding('hasClickedAddNewCard');
    },
  }),

  Recompose.omitProps('currentAccount', 'currentUser', 'handleCompleteOnboarding', 'submit'),

  Recompose.pure,
)(Button);
