import { datesBetween, datesFromNow } from 'components/cards/stats/helpers/date';
import { searchParams } from 'components/cards/stats/params';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { defaultProps, lifecycle, withHandlers, withProps, withState } from 'recompose';

export const withFrequency = withState('frequency', 'setFrequency', '1D');
export const withTypeIndex = withState('typeIndex', 'setTypeIndex', 0);
export const withTypeStats = withState('typeStats', 'setTypeStats', ({ children }) => {
  return React.Children.toArray(children).map(() => []);
});
export const withTypeConnections = withState(
  'typeConnections',
  'setTypeConnections',
  ({ children }) => {
    return React.Children.toArray(children).map(() => []);
  },
);
export const withExpandedState = withState('expanded', 'setExpanded', false);

export const withExpandedView = withProps(({ typeIndex, children, chartData }) => {
  const total = chartData.reduce((a, b) => a + b.total, 0);
  const types = React.Children.toArray(children);
  const expandedViews = types.map((o) => o.props.children);
  const expandedView = total > 0 && expandedViews[typeIndex];
  return { expandedView, expandedViews };
});

export const withComponentHandlers = withHandlers({
  setTypeStatResult:
    ({ typeStats, setTypeStats }) =>
    (index, records) => {
      const res = typeStats.map((o, i) => {
        return index === i ? (Array.isArray(records) ? records : []) : o;
      });
      setTypeStats(res);
    },
  setTypeConnection:
    ({ typeConnections, setTypeConnections }) =>
    (index, connection) => {
      const res = typeConnections.map((o, i) => {
        return index === i ? connection : o;
      });
      setTypeConnections(res);
    },
  toggleExpandedState:
    ({ expanded, setExpanded }) =>
    (evt) => {
      evt && evt.preventDefault();
      setExpanded(!expanded);
    },
});

export const withSearchParameters = withProps((props) => {
  const [unit, n] = searchParams[props.frequency] || searchParams.DEF;
  const [dateFrom, dateTo] = datesFromNow(unit, n);
  const timeline = datesBetween(unit, dateFrom, dateTo);
  return { dateFrom, dateTo, timeline, unit };
});

export const withTypeChartData = withProps(({ typeStats, typeIndex, frequency }) => {
  const chartData = Array.isArray(typeStats[typeIndex]) ? typeStats[typeIndex] : [];
  return { chartData };
});

export const withTypeActiveState = withProps(({ index, typeIndex }) => ({
  active: index === typeIndex,
}));

export const withTypeDefaultProps = (name) =>
  defaultProps({
    name,
    data: [],
    connection: null,
    total: 0,
    frequency: '1D',
  });

export const withTypeSelectHandler = withHandlers({
  onClick:
    ({ index, setTypeIndex }) =>
    (evt) => {
      evt && evt.preventDefault();
      setTypeIndex(index);
    },
});

export const withAnalyticsLoader = lifecycle({
  componentDidUpdate(prevProps) {
    const { index, data, connection, setTypeStatResult, setTypeConnection } = this.props;
    if (!isEqual(prevProps.data, data) && Array.isArray(data) && data.length > 0) {
      setTypeStatResult(index, data);
    }
    if (!isEqual(prevProps.connection, connection) && connection) {
      setTypeConnection(index, connection);
    }
  },
});
