import styled from 'styled-components/macro';
import React from 'react';
import grid_1 from '../icons/grid_1.svg';
import grid_2 from '../icons/grid_2.svg';
import grid_3 from '../icons/grid_3.svg';
import masonry_2 from '../icons/masonry_2.svg';
import masonry_3 from '../icons/masonry_3.svg';

const ICONS = { grid_1, grid_2, grid_3, masonry_2, masonry_3 };

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 30px;
`;

const Selector = styled.div`
  opacity: 0.3;
  ${(props) => props.selected && 'opacity: 1'};
  width: 29%;
  cursor: pointer;
  margin-bottom: 5%;
`;

function ViewSelectorChoice(props) {
  const { choice, formState, onClick: handleFieldChange, name, value } = props;
  return (
    <Selector
      selected={value === choice.value}
      onClick={() => {
        handleFieldChange(name, choice.value);

        // Disable the featured image if the selected layout is 'grid_1'
        if (choice.value === 'grid_1') {
          handleFieldChange(
            `entities.imageGalleries.${formState.result.imageGallery}.hasFeaturedImage`,
            false,
          );
        }
      }}
    >
      <img src={ICONS[choice.value]} alt={`Choice for ${choice.label}`} />
    </Selector>
  );
}

export default ({ choices, ...rest }) => (
  <Container>
    {choices.map((choice) => (
      <ViewSelectorChoice key={choice.value} choice={choice} {...rest} />
    ))}
  </Container>
);
