import routes from 'config/routes';
import withCreateCardMutation from 'hocs/with-create-card-mutation';
import withDefaultDeck from 'hocs/with-default-deck';
import React from 'react';
import * as Redux from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as Recompose from 'recompose';
import { setOnboardingCard } from 'store/modules/onboarding';
import CARD_CONFIG from './config';

const withIsBelowCardLimit = Recompose.withProps(
  ({ data: { loading, defaultDeck }, currentUser: { features } }) => {
    let isBelowCardLimit = true; // Default to NOT showing any upgrade messages
    if (!loading) {
      // defaultDeck.activeCards is the number of cards in the deck.
      // enabledActiveCards is the number of cards in the deck that are currently visible.
      // We need to always allow the addition of a card if the number of publicly visible cards
      // is below the limit.
      // Replicated cards should *not* count towards the limit
      // Discussion - https://github.com/revisionco/tap.bio-issues/issues/419
      const enabledActiveCards = defaultDeck.activeCards
        .filter((card) => card.enabled)
        .filter((card) => !card.isReplica)
        .filter((card) => !['discover', 'profile'].includes(card.kind));
      isBelowCardLimit = enabledActiveCards.length < features.cardCount;
    }
    return { isBelowCardLimit };
  },
);

const withCardIsCreating = Recompose.withProps(({ newCardType }) => ({
  cardIsCreating: (card) => newCardType === card.kind,
}));

const withCardIsEnabled = Recompose.withProps(
  ({
    newCardType,
    isBelowCardLimit,
    data: { loading, defaultDeck },
    currentUser: { features },
  }) => ({
    cardIsEnabled: (card) => {
      if (loading || newCardType) return false;

      const { activeCards } = defaultDeck;

      // Only allow adding of an instagram card if there is not an existing one
      if (card.kind === 'instagram' && activeCards.find((card) => card.kind === 'instagram')) {
        return false;
      }

      // If the total number of cards is greater than or equal to the plan limit, disable all cards.
      if (!isBelowCardLimit) {
        return false;
      }

      // Default to `CARD_CONFIG` object. This is for cards that are coming soon.
      return card.isEnabled;
    },
  }),
);

const handlers = Recompose.withHandlers({
  handleAddNewCard:
    ({
      data: { defaultDeck },
      handleCardFormSubmit,
      setNewCardId,
      setNewCardType,
      setOnboardingCard,
      setRedirectUrl,
      ...props
    }) =>
    async (cardKind, cardEnabled, event) => {
      event.preventDefault();

      if (!cardEnabled) return;
      setNewCardType(cardKind);

      const cardId = await handleCardFormSubmit(event, cardKind, defaultDeck.id);
      setNewCardId(cardId);
      setOnboardingCard({ id: cardId, kind: cardKind });
    },
});

export default Recompose.compose(
  Redux.connect(null, (dispatch) => ({
    setOnboardingCard: (attrs) => dispatch(setOnboardingCard(attrs)),
  })),
  Recompose.defaultProps({
    cards: [],
  }),
  Recompose.withState('newCardId', 'setNewCardId', null),
  Recompose.withState('newCardType', 'setNewCardType', null),
  withDefaultDeck,
  withCreateCardMutation,
  Recompose.withProps((props) => ({ cards: CARD_CONFIG })),
  withIsBelowCardLimit,
  withCardIsCreating,
  withCardIsEnabled,
  handlers,
  Recompose.pure,
  Recompose.branch(
    ({ newCardId }) => !!newCardId,
    Recompose.renderComponent(({ currentAccount: { username }, newCardId }) => (
      <Redirect to={routes.accountCardEditPath(username, newCardId)} />
    )),
  ),
);
