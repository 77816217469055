import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import styled from 'styled-components/macro';
import { Crop } from './styles';

const FeaturedPhoto = styled.div`
  width: 100%;
  margin-bottom: 5px;
`;

export default compose(
  //
  branch((props) => !props.photo, renderNothing),
)(({ photo }) => (
  <FeaturedPhoto key={photo.id} width={1}>
    <a
      href={photo.href}
      data-photo-id={photo.id}
      data-photo-permalink={photo.permalink}
      data-photo-media-url={photo.src}
    >
      <Crop style={{ backgroundImage: `url(${photo.src})` }} data-src={photo.src} />
    </a>
  </FeaturedPhoto>
));
