import React from 'react';
import { compose, defaultProps, withHandlers } from 'recompose';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  height: 60px;
  min-height: 60px;
  flex-direction: row;
  border-bottom: 1px solid #e4e8ec;
`;

const Item = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
  border: ${(props) => (props.active ? '1px solid #271539' : 'none')};
  box-shadow: ${(props) => (props.active ? 'inset 0px 2px 2px 2px #2f2f2f36' : 'none')};
  background-color: ${(props) =>
    props.active ? '#5b3283' || props.theme.colors.primaryPurple : 'transparent'};
  color: ${(props) => (props.active ? '#FFFFFF' : props.theme.colors.primaryPurple)};
  font-weight: ${(props) => (props.active ? 600 : 400)};
  font-size: 14px;
`;

const ranges = ['1D', '1W', '1M', 'ALL'];

function Segment(props) {
  const { selected, onButtonClick } = props;
  return (
    <Layout>
      {ranges.map((range) => (
        <Item key={range}>
          <Button active={selected === range} data-range={range} onClick={onButtonClick}>
            {range}
          </Button>
        </Item>
      ))}
    </Layout>
  );
}

export default compose(
  defaultProps({
    onRangeChanged: () => null,
  }),
  withHandlers({
    onButtonClick:
      ({ onRangeChanged }) =>
      (evt) => {
        evt && evt.preventDefault();
        const { range } = evt.target.dataset;
        onRangeChanged(range);
      },
  }),
)(Segment);
