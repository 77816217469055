import React, { useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import { setOnboardingTarget } from 'store/modules/onboarding';
import styled from 'styled-components/macro';
import EditCardButton from './buttons/show-card-edit';
import ViewStatsButton from './buttons/show-card-stats';
import ToggleHiddenButton from './buttons/toggle-card-hidden';

const HeaderLayout = styled.div`
  display: flex;
  border: none;
  justify-content: center;
  position: fixed;
  top: ${({ theme }) => theme.cardForm.headerTopOffset};
  width: 100%;
  z-index: 5;
  min-height: ${({ theme }) => theme.cardForm.headerHeight};
  align-items: center;
  visibility: ${(p) => (p.hidden ? 'hidden' : 'visible')};
`;

// Width is required for onboarding elements to be positioned properly
const HeaderControlsContainer = styled.div`
  width: ${(p) => (p.isEditableReplica ? '85px' : '200px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function Header(props) {
  const { card, cardIndexInDeck, currentAccount, isVisible, unsetPopover, setOnboardingTarget } =
    props;

  function handleUnset(event) {
    event.preventDefault();
    unsetPopover();
  }

  // This element is a target for onboarding
  // We'll set in in the onboarding state
  const targetRef = useRef();
  useLayoutEffect(() => {
    setOnboardingTarget(targetRef.current);
  }, [setOnboardingTarget]);

  return (
    <HeaderLayout hidden={!isVisible}>
      <HeaderControlsContainer ref={targetRef} isEditableReplica={card.isReplica && card.editable}>
        <ToggleHiddenButton
          currentAccount={currentAccount}
          card={card}
          cardIndexInDeck={cardIndexInDeck}
        />
        <ViewStatsButton currentAccount={currentAccount} cardId={card.id} />
        <EditCardButton
          card={card}
          currentAccount={currentAccount}
          handleUnset={handleUnset}
          isVisible={card.editable}
        />
      </HeaderControlsContainer>
    </HeaderLayout>
  );
}

export default connect(null, (dispatch) => ({
  unsetPopover: () => dispatch(unsetPopover()),
  setOnboardingTarget: (args) => dispatch(setOnboardingTarget(args)),
}))(Header);
