import React from 'react';
import * as Recompose from 'recompose';
import * as Redux from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import { setUploadedBackgroundUrl } from 'store/modules/card-upload-background';
import styled from 'styled-components/macro';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

const headerHeight = '65px';

const HeaderBackgroundPhotoLayout = styled.div`
  min-height: ${headerHeight};
  padding: 0;
  margin-top: calc(0px - ${headerHeight} - 1px);
  background-color: white;
`;

function HeaderBackgroundPhoto(props) {
  return (
    <HeaderBackgroundPhotoLayout>
      <ViewHeader>
        <ViewHeader.LeftButton onClick={props.onClickCancel} />
        <ViewHeader.Title children={props.popoverTitle} />
        <ViewHeader.RightButton onClick={props.onClickDone} />
      </ViewHeader>
    </HeaderBackgroundPhotoLayout>
  );
}

export default Recompose.compose(
  Redux.connect(
    (state) => ({
      popoverTitle:
        state.cardPopovers.popover === 'background-image' ? 'Choose Background' : 'Choose Profile',
    }),
    (dispatch) => ({
      unsetPopover: () => dispatch(unsetPopover()),
      setUploadedBackgroundUrl: (url) => dispatch(setUploadedBackgroundUrl(url)),
    }),
  ),
  Recompose.withHandlers({
    onClickCancel: (props) => (event) => {
      event.preventDefault();
      props.unsetPopover();
      props.setUploadedBackgroundUrl(null);
    },
    onClickDone: (props) => (event) => {
      const { selectedPhotoUrl, selectedBrandColor, selectedBackgroundColorSolid } = props;
      event.preventDefault();
      props.onClickDone(selectedPhotoUrl, selectedBrandColor, selectedBackgroundColorSolid);
    },
  }),
)(HeaderBackgroundPhoto);
