import routes from 'config/routes';
import { withRouter } from 'react-router';
import debounce from 'lodash/debounce';
import { compose, withProps, withHandlers, withState, pure } from 'recompose';
import { graphql } from '@apollo/react-hoc';
import { usernameSearchQuery } from 'lib/graphql/queries';
import { updateAccountUsernameMutation } from 'lib/graphql/mutations';
import { forbiddenCharacters, errors } from 'lib/validations';

export default compose(
  withRouter,
  withState('username', 'setUsername', ''),
  withState('throttledUsername', 'setThrottledUsername', ''),

  withHandlers({
    throttledSetUsername: (props) => debounce((value) => props.setThrottledUsername(value), 500),
    onClickBack: (props) => props.history.goBack,
  }),

  graphql(usernameSearchQuery, {
    options: (props) => ({
      variables: { username: props.throttledUsername },
      fetchPolicy: 'no-cache',
    }),
    props: ({ ownProps, data }) => ({
      loading: data.loading || ownProps.throttledUsername !== ownProps.username,
      usernameTaken: !!data.publicAccount,
    }),
  }),

  graphql(updateAccountUsernameMutation, {
    props: ({ mutate, ownProps }) => ({
      handleFormSubmit: () => {
        const username = ownProps.throttledUsername;
        const accountId = ownProps.currentAccount.id;

        return mutate({
          variables: {
            username,
            accountId,
          },
          update: (proxy, { data: { error } }) => {
            if (error) return console.error(error);
            const pathname = routes.accountSwitch(username);
            ownProps.history.replace(pathname, { needsRefetch: true });
          },
        });
      },
    }),
  }),

  withHandlers({
    validateUsername: (props) => () => {
      const { username } = props;
      if (!username) return;

      if (username.match(forbiddenCharacters)) {
        return errors.ACCOUNT_NAME_INVALID;
      }
      if (props.usernameTaken) {
        return errors.ACCOUNT_NAME_NON_UNIQUE;
      }
    },
  }),

  withProps((props) => {
    const usernameErrs = props.validateUsername(props);
    return {
      errors: {
        username: usernameErrs,
        any: !!usernameErrs || !props.username,
      },
    };
  }),

  withHandlers({
    onFieldChange: (props) => (name, value) => {
      props.setUsername(value);
      props.throttledSetUsername(value);
    },
    onSubmit: (props) => (event) => {
      event.preventDefault();
      props.handleFormSubmit(props);
    },
  }),

  pure,
);
