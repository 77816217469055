import AutoCompleteField from 'components/accounts/onboarding/auto-complete-field';
import TagCompleteField from 'components/accounts/onboarding/tag-complete-field';
import { Text } from 'components/web-ui';
import config from 'config';
import routes from 'config/routes';
import handleLocalFieldChange from 'hocs/handle-local-field-change';
import renderField from 'hocs/render-field';
import withDefaultDeck from 'hocs/with-default-deck';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import withUpdateAccountProfileMutation from 'hocs/with-update-account-profile-mutation';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { branch, compose, mapProps, renderComponent, withHandlers, withState } from 'recompose';
import styled from 'styled-components/macro';
import Field from './ui/field';
import FormButton from './ui/form-button';
import Input from './ui/input';
import Label from './ui/label';

const Form = styled.form`
  display: inline;
`;

const Section = styled.section`
  padding: 0.5em 20px;
`;

const NameInput = styled(Input)`
  padding-top: 15px;
  padding-bottom: 15px;
`;

// --- The Component ---

const OnboardingForm = (
  {
    renderField,
    handleFieldChange,
    formState,
    setFormState,
    handleFormSubmit,
    stepTo,
    profileCardId,
    setTitleAutocompleteIsExpanded,
    titleAutocompleteIsExpanded,
    ...props
  },
  context,
) => {
  return (
    <React.Fragment>
      <Form onSubmit={handleFormSubmit}>
        <Section>
          {renderField({
            name: 'name',
            placeholder: 'Full Name or Business Name',
            styledInput: NameInput,
            autoCapitalize: 'words',
            autoCorrect: 'off',
            spellCheck: 'false',
            autoComplete: 'on',
            defaultValue: '',
            styledLabel: Label,
            styledField: Field,
          })}
        </Section>

        <Section>
          <AutoCompleteField
            onChange={(name, value) => {
              setTitleAutocompleteIsExpanded(false);
              handleFieldChange(name, value);
            }}
            name="title"
            placeholder="Title (what you do) or tagline"
            hint="Enter title or select by tapping below."
            tags={config.profileTitleChoices}
            value={formState.title}
            valid={!!formState.name && !!formState.title}
            expanded={formState.step === 1}
            submitText="Continue"
            onSubmit={stepTo(2)}
            onFocus={stepTo(1)}
            autoCapitalize="words"
            autoCorrect="on"
            spellCheck="true"
            autoComplete="on"
          />
        </Section>

        <Section>
          <TagCompleteField
            name="tags"
            placeholder="Tags"
            hint="Select a few things that best describe your business, or add your own and tap return."
            tags={config.profileTagChoices}
            value={formState.tags}
            limit={12}
            expanded={formState.step === 2}
            hidden={formState.step < 2}
            valid={(formState.tags !== '') & (formState.tags.split(',').length >= 1)}
            displayFormButton={false}
            onChange={handleFieldChange}
            onFocus={stepTo(2)}
            autoCapitalize="words"
            autoCorrect="on"
            spellCheck="true"
            autoComplete="on"
          />
        </Section>

        <Section>
          <Field hidden={formState.step < 2}>
            <FormButton
              type="submit"
              hidden={formState.step < 2}
              disabled={
                !formState.name ||
                !formState.title ||
                !formState.tags ||
                formState.processing ||
                formState.submitted
              }
              children="Continue"
            />
            <Text>
              By continuing, you agree to our <br />
              Terms & Privacy Policy.
            </Text>
          </Field>
        </Section>
      </Form>
    </React.Fragment>
  );
};

function RedirectToDashboard({ currentAccount, profileCardId }) {
  const pathname = routes.accountCardPath(currentAccount.username, profileCardId);
  return <Redirect to={{ pathname }} />;
}

const withRedirectIfComplete = branch(
  ({ formState }) => !!formState.submitted,
  renderComponent(RedirectToDashboard),
);

const withProfileCardId = mapProps((props) => {
  if (!props.data) return props;
  if (!props.data.defaultDeck) return props;

  const { activeCards } = props.data.defaultDeck;
  const { id: profileCardId } = activeCards.find((card) => card.kind === 'profile');

  return {
    ...props,
    profileCardId,
  };
});

OnboardingForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default compose(
  withState('formState', 'setFormState', {
    name: '',
    title: '',
    tags: '',
    brandColor: '#8E44AD',
    step: 1,
    processing: false,
    submitted: false,
  }),
  withState('titleAutocompleteIsExpanded', 'setTitleAutocompleteIsExpanded', false),

  handleLocalFieldChange,
  withUpdateAccountProfileMutation,
  withRouter,
  withIdentityAccounts,
  withDefaultDeck,
  withProfileCardId,
  renderField,
  withRedirectIfComplete,
  withHandlers({
    stepTo: (props) => (step) => (event) => props.setFormState((state) => ({ ...state, step })),
    handleFormSubmit:
      ({ currentAccount, ...props }) =>
      async (event) => {
        event.preventDefault();
        const accountProfile = { ...props.formState };

        props.setFormState((state) => ({ ...state, processing: true, submitted: false }));

        delete accountProfile.step;
        delete accountProfile.processing;
        delete accountProfile.submitted;

        await props.handleSubmit({ accountProfile, currentAccount });

        props.setFormState((state) => ({ ...state, processing: false, submitted: true }));

        return false;
      },
  }),
)(OnboardingForm);
