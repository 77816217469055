import React from 'react';
import styled from 'styled-components/macro';
import NavButton from '@tap-bio/ui/patterns/story-slider/nav-button';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const Layout = styled.div`
  display: ${(p) => (p.visible ? 'flex' : 'none')};
  position: absolute;
  width: 36rem;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
`;

function SwiperMouseNav(props) {
  const { moreMenu, location } = props;
  const isCardPreviewUrl = /cards\/[0-9]+$/.test(location.pathname);
  const showSwiperNav = isCardPreviewUrl && !moreMenu.isShowing;

  return (
    <Layout visible={showSwiperNav}>
      <NavButton direction="prev" />
      <NavButton direction="next" />
    </Layout>
  );
}

export default compose(
  withRouter,
  connect((state, ownProps) => {
    const { moreMenu } = state;
    return { moreMenu };
  }),
)(SwiperMouseNav);
