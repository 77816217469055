import React from 'react';
import styled from 'styled-components/macro';

const Svg = styled.svg``;

Svg.defaultProps = {
  width: '1em',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
};

function InstagramIcon(props) {
  return (
    <Svg height={props.width} {...props} viewBox="0 0 20 20">
      <g fillRule="nonzero" fill={props.fill}>
        <path d="M10 1.804c2.67 0 2.985.01 4.04.059.974.044 1.504.207 1.856.344.434.16.827.416 1.15.748.331.322.587.715.747 1.15.137.351.3.88.344 1.855.049 1.055.059 1.37.059 4.04s-.01 2.986-.059 4.04c-.044.974-.207 1.504-.344 1.856a3.31 3.31 0 0 1-1.897 1.897c-.352.137-.882.3-1.856.345-1.054.048-1.37.058-4.04.058s-2.986-.01-4.04-.058c-.974-.045-1.504-.208-1.856-.345a3.097 3.097 0 0 1-1.15-.747 3.097 3.097 0 0 1-.747-1.15c-.137-.352-.3-.882-.344-1.856-.049-1.054-.059-1.37-.059-4.04s.01-2.985.059-4.04c.044-.974.207-1.504.344-1.856.16-.434.416-.827.748-1.15a3.097 3.097 0 0 1 1.15-.747c.351-.137.88-.3 1.855-.344 1.055-.048 1.37-.059 4.04-.059zm0-1.8c-2.715 0-3.055.01-4.122.06-1.064.048-1.79.217-2.426.464a4.9 4.9 0 0 0-1.77 1.153 4.9 4.9 0 0 0-1.154 1.77C.28 4.089.112 4.815.063 5.879.015 6.944.003 7.285.003 10s.012 3.056.06 4.122c.05 1.064.218 1.79.465 2.426a4.9 4.9 0 0 0 1.153 1.77 4.9 4.9 0 0 0 1.77 1.154c.637.247 1.363.416 2.427.465 1.067.048 1.407.06 4.122.06s3.056-.012 4.122-.06c1.064-.049 1.79-.218 2.426-.465a5.112 5.112 0 0 0 2.924-2.924c.247-.636.416-1.362.465-2.426.048-1.067.06-1.407.06-4.122s-.012-3.056-.06-4.122c-.049-1.064-.218-1.79-.465-2.426a4.9 4.9 0 0 0-1.153-1.77A4.9 4.9 0 0 0 16.55.527C15.911.28 15.185.112 14.121.063 13.056.015 12.715.003 10 .003z" />
        <path d="M10 4.866a5.134 5.134 0 1 0 0 10.268 5.134 5.134 0 0 0 0-10.268zm0 8.466a3.332 3.332 0 1 1 0-6.664 3.332 3.332 0 0 1 0 6.664z" />
        <circle cx={15.336} cy={4.664} r={1.2} />
      </g>
    </Svg>
  );
}

export default InstagramIcon;
