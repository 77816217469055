import React from 'react';
import Panel from 'components/cards/stats/component';
import PageViewType from 'components/cards/stats/types/page-view-type';
import CardViewType from 'components/cards/stats/types/card-view-type';

export default (props) => (
  <Panel {...props}>
    <PageViewType />
    <CardViewType />
  </Panel>
);
