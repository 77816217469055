import React from 'react';
import styled from 'styled-components/macro';

const Svg = styled.svg``;

Svg.defaultProps = {
  width: '1em',
  xmlns: 'http://www.w3.org/2000/svg',
};

function ZapierLogo(props) {
  return (
    <Svg height={props.width} {...props} viewBox="0 0 400 400">
      <path d="M250 200.087c-.01 14.862-2.727 29.09-7.68 42.224-13.132 4.957-27.366 7.68-42.234 7.69h-.172c-14.86-.01-29.09-2.725-42.222-7.68-4.954-13.13-7.682-27.364-7.692-42.23v-.173c.01-14.862 2.732-29.09 7.678-42.223 13.138-4.954 27.37-7.68 42.236-7.69h.172c14.868.01 29.102 2.736 42.233 7.69 4.95 13.134 7.67 27.362 7.68 42.224v.173-.002zm147.22-33.42H280.474l82.55-82.55a200.92 200.92 0 0 0-21.612-25.548l-.004-.008a201.057 201.057 0 0 0-25.524-21.59l-82.55 82.55V2.782A201.232 201.232 0 0 0 200.104 0h-.212a201.23 201.23 0 0 0-33.226 2.78v116.747l-82.55-82.55a200.942 200.942 0 0 0-25.535 21.6l-.03.027A201.01 201.01 0 0 0 36.98 84.116l82.55 82.55H2.78S.004 188.596 0 199.926v.145a201.324 201.324 0 0 0 2.78 33.266h116.746l-82.55 82.55a201.137 201.137 0 0 0 47.14 47.14l82.55-82.548V397.22A201.347 201.347 0 0 0 199.86 400h.286a201.44 201.44 0 0 0 33.19-2.78V280.473l82.55 82.55a201.038 201.038 0 0 0 25.528-21.592l.02-.015a201.182 201.182 0 0 0 21.59-25.527l-82.55-82.552H397.22a201.347 201.347 0 0 0 2.78-33.19v-.287a201.44 201.44 0 0 0-2.78-33.19z" />
    </Svg>
  );
}

export default ZapierLogo;
