import { withHandlers } from 'recompose';

const handleLoadMorePhotos = withHandlers({
  handleLoadMorePhotos:
    ({ setRequestManager, setDisplayCount }) =>
    async (event) => {
      event.preventDefault();
      const additionalRequests = 13; // 13 * 15 = 195 photos

      // We load 15 photos per request
      // To load 195 new photos we increase the number of requests by 13 (13 * 15 = 195)
      setRequestManager((requestManager) => ({
        ...requestManager,
        maxRequestCount: requestManager.maxRequestCount + additionalRequests,
      }));

      // Immediately update the max display count. This is displayed when viewing the
      // Top Performing tab.
      setDisplayCount((displayCount) => ({
        ...displayCount,
        max: displayCount.max + additionalRequests * 15,
      }));
    },
});

export default handleLoadMorePhotos;
