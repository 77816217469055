import React from 'react';
import styled from 'styled-components/macro';
import { compose, withHandlers, defaultProps } from 'recompose';
import { readImageWithOrientation, applyOrientation } from 'lib/image-utils';

// --- Styled Components ---

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  max-width: 100%;
  z-index: 1;
`;

// --- The Component ---

function UploadBackgroundPhoto(props) {
  const { onFileSelected } = props;
  return <Input type="file" onChange={onFileSelected} />;
}

// prettier-ignore
export default compose(
  defaultProps({
    onImageSelected: (img, file) => null,
  }),
  withHandlers({
    onFileSelected: ({ onImageSelected }) => evt => {
      if (evt.target.files.length === 0) {
        return;
      }
      const file = evt.target.files[0];
      evt.target.value = null;

      readImageWithOrientation(file)
        .then(applyOrientation)
        .then(({ img }) => onImageSelected(img, file));
    },
  }),
)(UploadBackgroundPhoto);
