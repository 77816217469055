import * as Sentry from '@sentry/browser';
import config from 'config';
import { signedUrlMultipleQuery, signedUrlQuery } from 'lib/graphql/queries';
import cloneDeep from 'lodash/cloneDeep';
import { withHandlers } from 'recompose';

function handleAfterRequestSingleFile(before, name, onCompletedUpload) {
  const url = before.url
    .split('?')[0]
    .replace(
      `https://storage.googleapis.com/${config.google.cloudStorageMediaBucket}`,
      config.cdnHost,
    );

  onCompletedUpload && onCompletedUpload(name, [url]);
  new Promise((resolve) => resolve('finished the upload!'));
}

function handleAfterRequestMultipleFiles(before, name, onCompletedUpload, files) {
  const firstRemovedUrls = cloneDeep(before.urls);
  firstRemovedUrls.shift();
  files.shift();
  let requests = firstRemovedUrls.map((url, index) => {
    return fetch(url.url, {
      method: 'PUT',
      body: files[index],
      headers: {
        'Content-Type': files[index].type,
      },
    });
  });

  // Once all other files uploaded can complete the promise
  Promise.all(requests).then(() => {
    const urls = before.urls.map((url) =>
      url.url
        .split('?')[0]
        .replace(
          `https://storage.googleapis.com/${config.google.cloudStorageMediaBucket}`,
          config.cdnHost,
        ),
    );
    onCompletedUpload && onCompletedUpload(name, urls);
    new Promise((resolve) => resolve('finished the upload!'));
  });
}

export default (identifier) =>
  withHandlers({
    handleBeforeRequest:
      ({ client }) =>
      async ({ files, handlesignedUrlQuery }) => {
        const hasMultipleFiles = files.length > 1;
        const dataKey = hasMultipleFiles ? 'signedUploadUrlMultiple' : 'signedUploadUrl';
        const variables = hasMultipleFiles
          ? {
              files: files.map((file) => {
                return { contentType: file.type, fileName: file.name, identifier };
              }),
            }
          : { contentType: files[0].type, fileName: files[0].name, identifier };

        try {
          // Returns an object with a `url` and `urls` key that identifies the URL to upload to
          const {
            data: {
              [dataKey]: { url, urls },
            },
          } = await client.query({
            query: hasMultipleFiles ? signedUrlMultipleQuery : signedUrlQuery,
            variables,
          });

          // If we adjusted the response from apollo we could skip this line
          return new Promise((resolve) => resolve({ url, urls }));
        } catch (error) {
          console.error(error);
          Sentry.captureException(error);
        }
      },

    handleRequest:
      () =>
      ({ before, files }) => {
        return {
          url: !!before.urls ? before.urls[0].url : before.url,
          method: 'PUT',
          headers: { 'Content-Type': files[0].type },
        };
      },

    handleAfterRequest:
      ({ name, onCompletedUpload }) =>
      ({ before, files, status }) => {
        const hasMultipleFiles = files.length > 1;
        // let urls;
        if (hasMultipleFiles) {
          handleAfterRequestMultipleFiles(before, name, onCompletedUpload, files);
        } else {
          handleAfterRequestSingleFile(before, name, onCompletedUpload);
        }
      },

    handleClear:
      ({ name, onCompletedUpload }) =>
      (event) =>
        onCompletedUpload(name, null),
  });
