import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const query = gql`
  query availableAvatars($username: String!) {
    publicAccount(username: $username) {
      id
      profile {
        id
        availablePictures {
          id
          identity {
            id
            provider
          }
          pictureUrl
        }
      }
    }
  }
`;

export default graphql(query, {
  options: ({ username, currentAccount }) => ({
    fetchPolicy: 'no-cache',
    variables: { username: username || currentAccount.username },
  }),
  props: ({ data: { publicAccount, ...request } }) => ({
    request,
    loadingPictures: request.loading,
    profilePictures: publicAccount ? publicAccount.profile.availablePictures : [],
  }),
});
