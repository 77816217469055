import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router';
import { graphql } from '@apollo/react-hoc';
import { compose } from 'lib/recompose';
import Button from 'components/ui/form-button';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import { addEmailOrSmsIdentityMutation } from 'lib/graphql/mutations';
import AssistedUrlInput from '@tap-bio/ui/patterns/assisted-url-input';
import isEmailValid from '@tap-bio/core/validators/email';
import isTelValid from '@tap-bio/core/validators/tel';

const Field = styled.div`
  padding: 10px 0;
`;

const StyledInput = styled(AssistedUrlInput)`
  border-color: #e6e6e6;

  .helper-field {
    border-color: #e6e6e6;
  }
`;

function AddIdentityForm(props) {
  const { addEmailOrSmsIdentity } = props;

  const [username, setUsername] = useState('');
  const [provider, setProvider] = useState(false);
  const [resetForm, setResetForm] = useState(null);

  const onChangeCallback = useCallback(
    (event) => {
      const value = event.target.value || '';
      const username = value.replace('*', '');
      const validTel = isTelValid(username);
      const validEmail = isEmailValid(username);
      const provider = (validTel && 'sms') || (validEmail && 'email');

      setUsername(username);
      setProvider(provider);
    },
    [setUsername, setProvider],
  );

  const onSubmitCallback = useCallback(
    (event) => {
      event.preventDefault();

      setUsername('');
      setProvider('');
      setResetForm(Date.now());
      addEmailOrSmsIdentity && addEmailOrSmsIdentity(username, provider);
    },
    [username, setUsername, provider, setProvider, setResetForm, addEmailOrSmsIdentity],
  );

  return (
    <form>
      <Field>
        <StyledInput
          defaultValue=""
          reset={resetForm}
          placeholder="Enter an email address or sms number"
          onChange={onChangeCallback}
          helpers={['tel', 'email']}
          skipTelProtocol={true}
          skipEmailProtocol={true}
          autoFocus="on"
        />
      </Field>
      <Field>
        <Button onClick={onSubmitCallback} disabled={!provider} text="Add Access" />
      </Field>
    </form>
  );
}

export default compose(
  withRouter,
  withIdentityAccounts,

  graphql(addEmailOrSmsIdentityMutation, {
    props: ({ mutate, ownProps }) => ({
      addEmailOrSmsIdentity: (username, provider) => {
        const accountId = parseInt(ownProps.currentAccount.id, 10);
        const variables = { accountId, username, provider };

        function update(proxy, props) {
          const { data } = props;
          const { addEmailOrSmsIdentity } = data;
          const { errors } = addEmailOrSmsIdentity;

          if (errors && errors.type === 'AccountIdentityExists') {
            return console.error(errors);
          }

          ownProps.onAdd();
        }

        return mutate({ variables, update });
      },
    }),
  }),
)(AddIdentityForm);
