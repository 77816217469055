import PropTypes from 'prop-types';
import { compose, withProps, setPropTypes } from 'recompose';
import socialNetwork from 'lib/social-network-utils';

export default compose(
  setPropTypes({
    url: PropTypes.string.isRequired,
  }),
  withProps(({ url }) => {
    return { socialNetwork: socialNetwork.urlToId(url) };
  }),
);
