import { withRouter } from 'react-router';
import * as Recompose from 'lib/recompose';

export default Recompose.compose(
  withRouter,
  Recompose.withProps((props) => {
    const { accountUsername } = props.match.params;
    return { accountUsername };
  }),
);
