import { ButtonCircle } from 'components/web-ui';
import styled from 'styled-components/macro';

const HeaderButton = styled(ButtonCircle)`
  padding: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 19px;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  height: 38px;
  font-weight: 700;
  margin: 0 4px;

  path {
    fill: white;
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.2);

    path {
      fill: rgba(255, 255, 255, 0.5);
    }
  }
  &:disabled:hover {
    box-shadow: none;
    cursor: initial;
  }

  ${(p) =>
    p.off &&
    `
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.2);
    path {
      fill: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      box-shadow: none;
      cursor: initial;
    }
  `};
`;

const HeaderButtonCircle = styled(HeaderButton)`
  padding: 0;
  margin: 0;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderButtonPill = styled(HeaderButton)`
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;

  @media (min-width: 23rem) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const HeaderButtonPillLeft = styled(HeaderButtonPill)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
`;

const HeaderButtonPillRight = styled(HeaderButtonPill)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 1px;
`;

export {
  HeaderButton,
  HeaderButtonCircle,
  HeaderButtonPill,
  HeaderButtonPillLeft,
  HeaderButtonPillRight,
};
