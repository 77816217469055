import * as Sentry from '@sentry/browser';
import { graphql } from '@apollo/react-hoc';
import { defaultDeckQuery } from 'lib/graphql/queries';
import { destroyCardMutation } from 'lib/graphql/mutations';

export default graphql(destroyCardMutation, {
  props: ({ mutate, ownProps: { setIsPersisting, client: apolloClient } }) => ({
    handleCardDestroy: async (id, accountUsername) => {
      setIsPersisting(true);
      let saveSuccess = false;

      // Generate an optimistic response using the default deck from the Apollo cache.
      // All we need to do is remove the card from the deck's active cards.
      // We're defining this here so we can check the existence of the `client` prop. If `client`
      // is not passed (via the prior use of the `withApollo` HOC) then don't generate an
      // optimistic response.

      let optimisticResponse;
      if (apolloClient) {
        optimisticResponse = () => {
          const data = apolloClient.cache.readQuery({
            query: defaultDeckQuery,
            variables: { accountUsername },
          });

          data.defaultDeck.activeCards = data.defaultDeck.activeCards.filter((c) => c.id !== id);
          data.__typename = 'CardDestroyPayload';

          // The response's expected shape (that's passed to `update`) is like this:
          //
          //   {
          //     destroyCard: {
          //       defaultDeck: {
          //         id: 1,
          //         title: 'My Deck',
          //         activeCards: [...],
          //         draftCards: [...],
          //         __typename: 'DeckType'
          //       },
          //       __typename: 'CardDestroyPayload'
          //     }
          //   }
          return { destroyCard: data };
        };
      }

      try {
        await mutate({
          mutation: destroyCardMutation,
          variables: { id },
          optimisticResponse,
          update: (proxy, { data: destroyCard }) => {
            // Read the currently cached query
            const data = proxy.readQuery({
              query: defaultDeckQuery,
              variables: { accountUsername },
            });

            // Update the query to update the client's cache of the cardsre
            data.defaultDeck = destroyCard.destroyCard.defaultDeck;

            // Write the query back to the cache
            proxy.writeQuery({
              query: defaultDeckQuery,
              variables: { accountUsername },
              data,
            });
            saveSuccess = true;
          },
        });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        return false;
      } finally {
        setIsPersisting(false);
      }
      return saveSuccess;
    },
  }),
});
