import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import styled from 'styled-components/macro';
import {
  CopyWrapper as BaseCopyWrapper,
  Crop,
  Description as BaseDescription,
  Title as BaseTitle,
} from 'components/cards/forms/image-gallery/styled';
import TextTruncate from 'react-text-truncate';

const FeaturedImage = styled.div`
  width: 100%;
  margin-bottom: 5px;
  position: relative;
`;

const Title = styled(BaseTitle)`
  font-size: 20px;
  line-height: 1.35;
  margin-bottom: 3px;
  padding-left: 12px;
`;

const Description = styled(BaseDescription)`
  font-size: 16px;
  line-height: 1.35;
  padding-left: 12px;
`;

const CopyWrapper = styled(BaseCopyWrapper)`
  bottom: 5px;
`;

export default compose(
  //
  branch((props) => !props.image, renderNothing),
)(({ image }) => (
  <FeaturedImage key={image.id} width={1}>
    <a href={image.url}>
      <Crop style={{ backgroundImage: `url(${image.imageUrl})` }} />
    </a>
    <CopyWrapper>
      <Title>
        <TextTruncate line={1} truncateText="…" text={image.title} />
      </Title>
      <Description>
        <TextTruncate line={2} truncateText="…" text={image.description} />
      </Description>
    </CopyWrapper>
  </FeaturedImage>
));
