import styled from 'styled-components/macro';
import React from 'react';
import DestroyCardButton from './buttons/destroy-card';
import UploadBackgroundButton from './buttons/show-card-backround';
import CustomStylesButton from './buttons/show-card-styles';
import CustomSettingsButton from './buttons/show-card-settings';
import SaveCardButton from './buttons/save-card';
import CancelButton from './buttons/show-card-preview';

const Layout = styled.div`
  position: absolute;
  height: ${({ theme }) => theme.cardForm.headerHeight};
  top: 0;
  left: 3%;
  right: 3%;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  display: flex;
`;

const Spacer = styled.div`
  flex: 1;
`;

function Header(props) {
  const { card, isPersisting } = props;
  const { profile } = props.currentAccount;

  return (
    <Layout>
      <Spacer>
        <CancelButton title="Close" />
      </Spacer>
      <CustomSettingsButton card={card} title="Edit Custom Settings" />
      <CustomStylesButton
        card={card}
        brandColor={profile.brandColor}
        title="Edit Styles and Colors"
      />
      <UploadBackgroundButton
        backgroundMediaUrl={card.backgroundMediaUrl}
        defaultBackgroundMediaUrl={profile.defaultBackgroundMediaUrl}
        title="Edit Card Background"
      />
      <DestroyCardButton title="Delete this Card" />
      <SaveCardButton isPersisting={isPersisting} />
    </Layout>
  );
}

export default Header;
