import React from 'react';
import { Redirect } from 'react-router-dom';
import { prefixPath } from 'utils';
import routes from 'config/routes';
import Account from 'components/account';
import AccountOnboarding from 'components/accounts/onboarding';
import AccountsSwitch from 'components/accounts/switch';
import AccountsAccess from 'components/accounts/access';
import AccountsNotifications from 'components/accounts/notifications';
import AccountsAdd from 'components/accounts/add';
import AccountsChangeUsername from 'components/accounts/change-username';
import Accounts from 'components/accounts';
import NoMatchingRoute from 'components/screens/no-matching-route';
import LayoutDashboard from 'components/application/layouts/dashboard';
import LayoutAuth from 'components/application/layouts/auth';
import LayoutOnboarding from 'components/application/layouts/onboarding';
import NewCard from 'components/cards/new';
import EditCard from 'components/cards/edit';
import AllCards from 'components/cards/all';
import {
  ChangeProduct,
  SelectPlan,
  AddCreditCard,
  UpdateCreditCard,
  UpdateCreditCardChooser,
} from 'components/billing';
import InstagramAccessError from 'components/authentication/instagram-access-error';
import InstagramCallback from 'components/authentication/instagram-callback';
import MailChimpCallback from 'components/authentication/mailchimp-callback';
import ConstantContactCallback from 'components/authentication/constantcontact-callback';
import Login from 'components/authentication/login';
import VerifyLogin from 'components/authentication/verify-login';
import Logout from 'components/authentication/logout';
import Registration from 'components/authentication/registration';
import VerifyRegistration from 'components/authentication/verify-registration';
import MigrateAccount from 'components/authentication/migrate-account';

export default [
  {
    exact: true,
    path: routes.loginPath,
    render: (props) => <LayoutAuth {...props} component={Login} />,
  },

  {
    exact: true,
    path: routes.loginPath + '/verify/:uid',
    render: (props) => <LayoutAuth {...props} component={VerifyLogin} />,
  },

  {
    exact: true,
    path: '/signin',
    render: () => <Redirect to={{ pathname: routes.loginPath, search: window.location.search }} />,
  },

  {
    // alias
    exact: true,
    path: '/login',
    render: () => <Redirect to={{ pathname: routes.loginPath, search: window.location.search }} />,
  },

  {
    // alias
    exact: true,
    path: prefixPath('signin'),
    render: () => <Redirect to={{ pathname: routes.loginPath, search: window.location.search }} />,
  },

  {
    // alias
    exact: true,
    path: prefixPath('sign-in'),
    render: () => <Redirect to={{ pathname: routes.loginPath, search: window.location.search }} />,
  },

  {
    // alias
    exact: true,
    path: prefixPath('login'),
    render: () => <Redirect to={{ pathname: routes.loginPath, search: window.location.search }} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.logoutPath,
    component: Logout,
  },

  {
    // alias
    exact: true,
    path: prefixPath('signout'),
    render: () => <Redirect to={routes.logoutPath} />,
  },

  {
    // alias
    exact: true,
    path: prefixPath('logout'),
    render: () => <Redirect to={routes.logoutPath} />,
  },

  {
    exact: true,
    path: routes.registrationPath,
    render: (props) => <LayoutAuth {...props} component={Registration} />,
  },

  {
    exact: true,
    path: routes.registrationWhitelistedPath,
    render: (props) => <LayoutAuth {...props} component={Registration} whitelisted />,
  },

  {
    exact: true,
    path: routes.registrationPath + '/verify/:uid',
    render: (props) => <LayoutAuth {...props} component={VerifyRegistration} />,
  },

  {
    exact: true,
    path: '/signup',
    render: (props) => <Redirect to={routes.registrationPath} />,
  },

  {
    // alias
    exact: true,
    path: '/register',
    render: (props) => <Redirect to={routes.registrationPath} />,
  },

  {
    // alias
    exact: true,
    path: prefixPath('signup'),
    render: (props) => <Redirect to={routes.registrationPath} />,
  },

  {
    // alias
    exact: true,
    path: prefixPath('sign-up'),
    render: ({ location }) => <Redirect to={`${routes.registrationPath}${location.search}`} />,
  },

  {
    // alias
    exact: true,
    path: prefixPath('register'),
    render: (props) => <Redirect to={routes.registrationPath} />,
  },

  {
    exact: true,
    path: `${routes.registrationPath}/:username`,
    render: (props) => <LayoutAuth {...props} component={Registration} />,
  },
  {
    exact: true,
    path: routes.oauthIGFailurePath,
    render: (props) => <LayoutAuth {...props} component={InstagramAccessError} />,
  },
  {
    exact: true,
    path: routes.oauthIGCallbackPath,
    render: (props) => <InstagramCallback {...props} />,
  },
  {
    exact: true,
    path: routes.oauthMailChimpCallbackPath,
    render: (props) => <MailChimpCallback {...props} />,
  },
  {
    exact: true,
    path: routes.oauthConstantContactCallbackPath,
    render: (props) => <ConstantContactCallback {...props} />,
  },
  {
    exact: true,
    path: routes.migrateFromLipPath,
    component: MigrateAccount,
  },

  {
    exact: true,
    path: prefixPath('accounts/migrate-from-link-in-profile'),
    render: (props) => <LayoutDashboard {...props} component={Account} />,
  },

  // --- Billing --------------------------------------------------------------

  {
    exact: true,
    requireAuthentication: true,
    path: routes.billing.changeProduct(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={ChangeProduct} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.billing.selectPlan(':accountUsername', ':productStripeId'),
    render: (props) => <LayoutDashboard {...props} component={SelectPlan} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.billing.addCreditCard(':accountUsername', ':planId'),
    render: (props) => <LayoutDashboard {...props} component={AddCreditCard} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.billing.updateCreditCardChooser,
    render: (props) => <LayoutDashboard {...props} component={UpdateCreditCardChooser} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.billing.updateCreditCard(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={UpdateCreditCard} />,
  },

  // --- Accounts -------------------------------------------------------------

  // A utility route that redirects the user to their first account
  {
    exact: true,
    requireAuthentication: true,
    path: prefixPath('accounts'),
    render: (props) => <Accounts {...props} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: prefixPath(''),
    render: (props) => <Accounts {...props} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountSwitch(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={AccountsSwitch} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountAccess(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={AccountsAccess} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountNotifications(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={AccountsNotifications} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountAdd(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={AccountsAdd} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountChangeUsername(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={AccountsChangeUsername} />,
  },

  // 'Root' page for an account. Displays the active / draft cards.
  {
    exact: true,
    requireAuthentication: true,
    path: routes.account(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={Account} />,
  },

  // Immediately after signup a user is directed here to complete their account.
  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountOnboarding(':accountUsername'),
    render: (props) => <LayoutOnboarding {...props} component={AccountOnboarding} />,
  },

  // --- Cards ----------------------------------------------------------------

  {
    exact: true,
    requireAuthentication: true,
    path: prefixPath('accounts/:accountUsername/cards/new'),
    render: (props) => <LayoutDashboard {...props} component={NewCard} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.allCardsPath(':accountUsername'),
    render: (props) => <LayoutDashboard {...props} component={AllCards} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountCardPath(':accountUsername', ':cardId'),
    render: (props) => <LayoutDashboard {...props} component={Account} />,
  },

  {
    exact: true,
    requireAuthentication: true,
    path: routes.accountCardEditPath(':accountUsername', ':cardId'),
    render: (props) => <LayoutDashboard {...props} component={EditCard} />,
  },

  // --- Fallback -------------------------------------------------------------

  {
    render: (props) => <NoMatchingRoute {...props} />,
  },
];
