import { withApollo } from '@apollo/react-hoc';
import { UploadRequest } from '@navjobs/upload';
import * as Sentry from '@sentry/browser';
import withBrowser from 'hocs/with-browser';
import withUploadImage from 'hocs/with-upload-image';
import { cropImage, isGif, resizeImage, rotateImage } from 'lib/image-utils';
import { connect } from 'react-redux';
import { compose, defaultProps, withHandlers, withProps, withState } from 'recompose';
import { setSelectedBackgroundImage } from 'store/modules/card-select-background';

const withPhotoDefaultSize = withHandlers({
  registerChild: (props) => (ref) => {
    const {
      boundryTop,
      boundryBottom,
      boundryRight,
      boundryLeft,
      imageFile,
      imageOriginalWidth,
      imageOriginalHeight,
      setImageScale,
      setImageMinScale,
      setImageX,
      setImageY,
      setContainerWidth,
      setContainerHeight,
      setImageEnableResizing,
    } = props;

    if (ref && ref.getBoundingClientRect) {
      const { width: containerWidth, height: containerHeight } = ref.getBoundingClientRect();
      setContainerWidth(containerWidth);
      setContainerHeight(containerHeight);

      if (
        !isGif(imageFile) &&
        imageOriginalWidth > containerWidth &&
        imageOriginalHeight > containerHeight
      ) {
        setImageEnableResizing(true);

        const sx = containerWidth / imageOriginalWidth;
        const sy = containerHeight / imageOriginalHeight;
        const scale = sx >= sy ? sx : sy;
        const imageX =
          (imageOriginalWidth * scale - imageOriginalWidth) / 2.0 -
          (imageOriginalWidth * scale - containerWidth) / 2.0;
        const imageY =
          (imageOriginalHeight * scale - imageOriginalHeight) / 2.0 -
          (imageOriginalHeight * scale - containerHeight) / 2.0;

        const viewportWidth = containerWidth - boundryLeft - boundryRight;
        const viewportHeight = containerHeight - boundryTop - boundryBottom;
        const vx = viewportWidth / imageOriginalWidth;
        const vy = viewportHeight / imageOriginalHeight;
        const minScale = vx >= vy ? vx : vy;

        setImageScale(scale);
        setImageMinScale(minScale);
        setImageX(imageX);
        setImageY(imageY);
      }
    }
  },
});

const withGestureHandlers = withHandlers({
  onSliderFocus: (props) => (evt) => {
    const { isZooming, setZooming } = props;
    if (!isZooming) setZooming(true);
  },
  onSliderBlur: (props) => (evt) => {
    const { isZooming, setZooming } = props;
    if (isZooming) setZooming(false);
  },
  onScaleChange: (props) => (evt) => {
    const {
      boundryTop,
      boundryBottom,
      boundryRight,
      boundryLeft,
      containerWidth,
      containerHeight,
      imageX,
      imageY,
      imageOriginalWidth,
      imageOriginalHeight,
      setImageScale,
      setImageX,
      setImageY,
    } = props;

    const scale = Number(evt.target.value);
    setImageScale(scale);

    const vw = imageOriginalWidth * scale;
    const vh = imageOriginalHeight * scale;
    const vx = imageX - (vw - imageOriginalWidth) / 2.0;
    const vy = imageY - (vh - imageOriginalHeight) / 2.0;
    const tx = boundryLeft + (vw - imageOriginalWidth) / 2.0;
    const ty = boundryTop + (vh - imageOriginalHeight) / 2.0;

    if (!(vx <= boundryLeft && vx + vw >= containerWidth - boundryRight)) {
      setImageX(tx);
    }
    if (!(vy <= boundryTop && vy + vh >= containerHeight - boundryBottom)) {
      setImageY(ty);
    }
  },
  onTouchStart:
    ({ setDragging, setLastTouchPositionX, setLastTouchPositionY }) =>
    (evt) => {
      setDragging(true);
      switch (evt.type) {
        case 'touchstart':
          const { clientX: tx, clientY: ty } = evt.touches[0];
          setLastTouchPositionX(tx);
          setLastTouchPositionY(ty);
          break;
        case 'mousedown':
          const { clientX: mx, clientY: my } = evt;
          setLastTouchPositionX(mx);
          setLastTouchPositionY(my);
          break;
        default:
          break;
      }
    },
  onTouchEnd:
    ({ setDragging, setLastTouchPositionX, setLastTouchPositionY }) =>
    (evt) => {
      setDragging(false);
      setLastTouchPositionX(0.0);
      setLastTouchPositionY(0.0);
    },
  onTouchMove: (props) => (evt) => {
    // pinch or zoom should only run when touch dragging
    if (!props.isDragging) return;

    const {
      draggable,
      isIGWebView,
      boundryTop,
      boundryBottom,
      boundryRight,
      boundryLeft,
      containerWidth,
      containerHeight,
      imageScale,
      imageOriginalWidth,
      imageOriginalHeight,
      imageX,
      imageY,
      setImageX,
      setImageY,
      lastTouchPositionX,
      lastTouchPositionY,
      setLastTouchPositionX,
      setLastTouchPositionY,
    } = props;

    let dx, dy;
    switch (evt.type) {
      case 'touchmove':
        const { clientX: tx, clientY: ty } = evt.changedTouches[0];
        dx = lastTouchPositionX - tx;
        dy = lastTouchPositionY - ty;
        setLastTouchPositionX(tx);
        setLastTouchPositionY(ty);
        break;
      case 'mousemove':
        const { clientX: mx, clientY: my } = evt;
        dx = lastTouchPositionX - mx;
        dy = lastTouchPositionY - my;
        setLastTouchPositionX(mx);
        setLastTouchPositionY(my);
        break;
      default:
        break;
    }
    const nx = imageX - dx;
    const ny = imageY - dy;

    const vw = imageOriginalWidth * imageScale;
    const vh = imageOriginalHeight * imageScale;
    const vx = nx - (vw - imageOriginalWidth) / 2.0;
    const vy = ny - (vh - imageOriginalHeight) / 2.0;

    if (
      draggable &&
      !isIGWebView &&
      vx <= boundryLeft &&
      vx + vw >= containerWidth - boundryRight
    ) {
      setImageX(nx);
    }
    if (
      draggable &&
      !isIGWebView &&
      vy <= boundryTop &&
      vy + vh >= containerHeight - boundryBottom
    ) {
      setImageY(ny);
    }
  },
});

const withPhotoRotation = withHandlers({
  rotateImageSource: (props) => async (evt) => {
    evt && evt.preventDefault();

    const { setImageId, setSelectedBackgroundImage, image, imageId, imageFile } = props;

    const nextId = imageId + 1;
    const output = await rotateImage(image, imageFile, 90);
    setSelectedBackgroundImage(output, imageFile);
    setImageId(nextId);
  },
});

const withPhotoSubmit = withHandlers({
  onDone: (props) => () => {
    const {
      boundryTop,
      boundryBottom,
      boundryRight,
      boundryLeft,
      containerWidth,
      containerHeight,
      image,
      imageFile,
      imageOriginalWidth,
      imageOriginalHeight,
      imageScale,
      imageX,
      imageY,
      imageEnableResizing,
      handleRequest,
      handleAfterRequest,
      handleBeforeRequest,
      setUploadProgress,
      setUploadComplete,
    } = props;

    const uploadImage = async (file) => {
      const files = [file];
      try {
        setUploadProgress(0.0);
        setUploadComplete(false);
        const before = await handleBeforeRequest({ files });
        const { error, aborted, status } = await UploadRequest({
          files,
          request: handleRequest({ before, files }),
          progress: setUploadProgress,
        });
        if (error) {
          Sentry.captureException(error);
          return;
        }
        setUploadComplete(true);
        if (aborted) return;
        await handleAfterRequest({ before, files, status });
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    if (!imageEnableResizing) {
      resizeImage(imageFile).then(uploadImage);
      return;
    }

    const viewportWidth = containerWidth - boundryLeft - boundryRight;
    const viewportHeight = containerHeight - boundryTop - boundryBottom;
    const vw = imageOriginalWidth * imageScale;
    const vh = imageOriginalHeight * imageScale;
    const x1 = Math.abs(imageX - (vw - imageOriginalWidth) / 2.0 - boundryLeft) / imageScale;
    const x2 = x1 + viewportWidth / imageScale;
    const y1 = Math.abs(imageY - (vh - imageOriginalHeight) / 2.0 - boundryTop) / imageScale;
    const y2 = y1 + viewportHeight / imageScale;

    cropImage(image, imageFile, {
      x1,
      x2,
      y1,
      y2,
      patchOrientation: false,
    }).then(uploadImage);
  },
});

export default compose(
  connect(
    (state) => ({
      image: state.cardSelectedBackground.image,
      imageFile: state.cardSelectedBackground.file,
      imageSource: state.cardSelectedBackground.image && state.cardSelectedBackground.image.src,
      imageOriginalWidth:
        state.cardSelectedBackground.image && state.cardSelectedBackground.image.width,
      imageOriginalHeight:
        state.cardSelectedBackground.image && state.cardSelectedBackground.image.height,
    }),
    (dispatch) => ({
      setSelectedBackgroundImage: (image, file) =>
        dispatch(setSelectedBackgroundImage(image, file)),
    }),
  ),
  defaultProps({
    draggable: true,
    boundryTop: 80,
    boundryBottom: 130,
    boundryRight: 80,
    boundryLeft: 80,
    onCompletedUpload: (name, url) => null,
    onCancel: () => null,
  }),
  withBrowser,
  withApollo,
  withState('imageId', 'setImageId', 0),
  withState('imageMinScale', 'setImageMinScale', 0.1),
  withState('imageScale', 'setImageScale', 1.0),
  withState('imageX', 'setImageX', 0.0),
  withState('imageY', 'setImageY', 0.0),
  withState('imageEnableResizing', 'setImageEnableResizing', false),
  withState('isDragging', 'setDragging', false),
  withState('isZooming', 'setZooming', false),
  withState('lastTouchPositionX', 'setLastTouchPositionX', 0.0),
  withState('lastTouchPositionY', 'setLastTouchPositionY', 0.0),
  withState('containerWidth', 'setContainerWidth', 0.0),
  withState('containerHeight', 'setContainerHeight', 0.0),
  withState('uploadComplete', 'setUploadComplete', true),
  withState('uploadProgress', 'setUploadProgress', 0.0),
  withProps((props) => {
    const { imageEnableResizing, imageX, imageY, imageScale, imageSource } = props;

    const previewContainerStyle = {};
    const previewImageStyle = {};
    if (imageEnableResizing) {
      previewImageStyle.transform = `translate3d(${imageX}px, ${imageY}px, 0px) scale(${imageScale})`;
    } else {
      previewContainerStyle.backgroundImage = `url(${imageSource})`;
      previewContainerStyle.backgroundSize = 'cover';
      previewContainerStyle.backgroundPosition = 'center';
      previewContainerStyle.cursor = 'default';
      previewImageStyle.display = 'none';
    }
    return { previewContainerStyle, previewImageStyle };
  }),
  withUploadImage('cards/backgrounds'),
  withPhotoDefaultSize,
  withPhotoRotation,
  withGestureHandlers,
  withPhotoSubmit,
);
