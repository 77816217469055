import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ScrollableView } from 'components/cards/stats/elements/shared';

const Shadow = styled.div`
  position: fixed;
  min-height: 20px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  z-index: 1;

  /* force hardware acceleration */
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
`;

function useScroll() {
  const [y, setY] = useState(0);
  const bindFn = (evt) => {
    const el = evt.target || evt.current;
    setY(el.scrollTop);
  };
  return [y, bindFn];
}

const Component = ({ children, scrollable }) => {
  const el = useRef(null);
  const [y, setY] = useScroll();
  const [shadow, setShadow] = useState(false);

  useEffect(() => {
    if (!el.current) return;
    setY(el);
  }, [el, setY]);

  useEffect(() => {
    setShadow(scrollable && el.current.scrollTop > 100);
  }, [scrollable, y]);

  useEffect(() => {
    if (scrollable || !el.current) return;
    el.current.scrollTop = 0;
  }, [scrollable]);

  return (
    <ScrollableView ref={el} onScroll={setY} scrollable={scrollable}>
      {shadow && <Shadow />}
      {children}
    </ScrollableView>
  );
};

export default Component;
