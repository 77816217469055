import React from 'react';

export default (props) => (
  <svg width={122} height={85} viewBox="0 0 122 85" {...props}>
    <path
      fill="#FF0000"
      d="M118.9,13.3 C121.5,22.8 121.4,42.6 121.4,42.6 C121.4,42.6 121.4,62.3 118.9,71.8 C117.5,77 113.4,81.1 108.2,82.5 C98.7,85 60.7,85 60.7,85 C60.7,85 22.8,85 13.2,82.4 C8,81 3.9,76.9 2.5,71.7 C0,62.3 0,42.5 0,42.5 C0,42.5 0,22.8 2.5,13.3 C3.9,8.1 8.1,3.9 13.2,2.5 C22.7,0 60.7,0 60.7,0 C60.7,0 98.7,0 108.2,2.6 C113.4,4 117.5,8.1 118.9,13.3 Z M48.6,60.7 L80.2,42.5 L48.6,24.3 L48.6,60.7 Z"
    />
  </svg>
);
