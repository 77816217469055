import React from 'react';
import { compose } from 'recompose';
import BaseButton from 'components/ui/button';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router';
import withReduxState from './hocs/with-redux-state';
import withButtonClickHandler from './hocs/with-button-click-handler';

const Button = styled.div`
  color: white;
  width: 41%;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.backgroundColor};

  :active,
  :focus {
    background-color: ${(props) => props.backgroundColor};
    opacity: 0.7;
    color: white;
    outline: none;
  }

  :active {
    background-color: ${(props) => props.backgroundColor};
  }
`.withComponent(({ backgroundColor, ...props }) => <BaseButton {...props} />);

function OpenInAppButton(props) {
  const { backgroundColor, handleShowCustomSettings } = props;
  return (
    <Button
      backgroundColor={backgroundColor}
      onClick={handleShowCustomSettings}
      text="Open In-app"
    />
  );
}

export default compose(
  //
  withReduxState,
  withRouter,
  withButtonClickHandler,
)(OpenInAppButton);
