module.exports = [
  { name: 'Afghanistan', code: 'AF', dialPrefix: '93' },
  { name: 'Albania', code: 'AL', dialPrefix: '355' },
  { name: 'Algeria', code: 'DZ', dialPrefix: '213' },
  { name: 'American Samoa', code: 'AS', dialPrefix: '1684' },
  { name: 'Andorra', code: 'AD', dialPrefix: '376' },
  { name: 'Angola', code: 'AO', dialPrefix: '244' },
  { name: 'Anguilla', code: 'AI', dialPrefix: '1264' },
  { name: 'Antigua and Barbuda', code: 'AG', dialPrefix: '1268' },
  { name: 'Argentina', code: 'AR', dialPrefix: '54' },
  { name: 'Armenia', code: 'AM', dialPrefix: '374' },
  { name: 'Aruba', code: 'AW', dialPrefix: '297' },
  { name: 'Australia', code: 'AU', dialPrefix: '61' },
  { name: 'Austria', code: 'AT', dialPrefix: '43' },
  { name: 'Azerbaijan', code: 'AZ', dialPrefix: '994' },
  { name: 'Bahamas', code: 'BS', dialPrefix: '1242' },
  { name: 'Bahrain', code: 'BH', dialPrefix: '973' },
  { name: 'Bangladesh', code: 'BD', dialPrefix: '880' },
  { name: 'Barbados', code: 'BB', dialPrefix: '1246' },
  { name: 'Belarus', code: 'BY', dialPrefix: '375' },
  { name: 'Belgium', code: 'BE', dialPrefix: '32' },
  { name: 'Belize', code: 'BZ', dialPrefix: '501' },
  { name: 'Benin', code: 'BJ', dialPrefix: '229' },
  { name: 'Bermuda', code: 'BM', dialPrefix: '1441' },
  { name: 'Bhutan', code: 'BT', dialPrefix: '975' },
  { name: 'Bolivia, Plurinational State of bolivia', code: 'BO', dialPrefix: '591' },
  { name: 'Bosnia and Herzegovina', code: 'BA', dialPrefix: '387' },
  { name: 'Botswana', code: 'BW', dialPrefix: '267' },
  { name: 'Brazil', code: 'BR', dialPrefix: '55' },
  { name: 'British Indian Ocean Territory', code: 'IO', dialPrefix: '246' },
  { name: 'Bulgaria', code: 'BG', dialPrefix: '359' },
  { name: 'Burkina Faso', code: 'BF', dialPrefix: '226' },
  { name: 'Burundi', code: 'BI', dialPrefix: '257' },
  { name: 'Cambodia', code: 'KH', dialPrefix: '855' },
  { name: 'Cameroon', code: 'CM', dialPrefix: '237' },
  { name: 'Canada', code: 'CA', dialPrefix: '1' },
  { name: 'Cape Verde', code: 'CV', dialPrefix: '238' },
  { name: 'Cayman Islands', code: 'KY', dialPrefix: '345' },
  { name: 'Central African Republic', code: 'CF', dialPrefix: '236' },
  { name: 'Chad', code: 'TD', dialPrefix: '235' },
  { name: 'Chile', code: 'CL', dialPrefix: '56' },
  { name: 'China', code: 'CN', dialPrefix: '86' },
  { name: 'Christmas Island', code: 'CX', dialPrefix: '61' },
  { name: 'Colombia', code: 'CO', dialPrefix: '57' },
  { name: 'Comoros', code: 'KM', dialPrefix: '269' },
  { name: 'Congo, The Democratic Republic of the Congo', code: 'CD', dialPrefix: '243' },
  { name: 'Congo', code: 'CG', dialPrefix: '242' },
  { name: 'Cook Islands', code: 'CK', dialPrefix: '682' },
  { name: 'Costa Rica', code: 'CR', dialPrefix: '506' },
  { name: "Cote d'Ivoire", code: 'CI', dialPrefix: '225' },
  { name: 'Croatia', code: 'HR', dialPrefix: '385' },
  { name: 'Cuba', code: 'CU', dialPrefix: '53' },
  { name: 'Curacao', code: 'CW', dialPrefix: '599' },
  { name: 'Cyprus', code: 'CY', dialPrefix: '357' },
  { name: 'Czech Republic', code: 'CZ', dialPrefix: '420' },
  { name: 'Denmark', code: 'DK', dialPrefix: '45' },
  { name: 'Djibouti', code: 'DJ', dialPrefix: '253' },
  { name: 'Dominica', code: 'DM', dialPrefix: '1767' },
  { name: 'Dominican Republic', code: 'DO', dialPrefix: '1849' },
  { name: 'Ecuador', code: 'EC', dialPrefix: '593' },
  { name: 'Egypt', code: 'EG', dialPrefix: '20' },
  { name: 'El Salvador', code: 'SV', dialPrefix: '503' },
  { name: 'Equatorial Guinea', code: 'GQ', dialPrefix: '240' },
  { name: 'Eritrea', code: 'ER', dialPrefix: '291' },
  { name: 'Estonia', code: 'EE', dialPrefix: '372' },
  { name: 'Ethiopia', code: 'ET', dialPrefix: '251' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', dialPrefix: '500' },
  { name: 'Faroe Islands', code: 'FO', dialPrefix: '298' },
  { name: 'Fiji', code: 'FJ', dialPrefix: '679' },
  { name: 'Finland', code: 'FI', dialPrefix: '358' },
  { name: 'France', code: 'FR', dialPrefix: '33' },
  { name: 'French Guiana', code: 'GF', dialPrefix: '594' },
  { name: 'French Polynesia', code: 'PF', dialPrefix: '689' },
  { name: 'French Southern Territories', code: 'TF', dialPrefix: '262' },
  { name: 'Gabon', code: 'GA', dialPrefix: '241' },
  { name: 'Gambia', code: 'GM', dialPrefix: '220' },
  { name: 'Georgia', code: 'GE', dialPrefix: '995' },
  { name: 'Germany', code: 'DE', dialPrefix: '49' },
  { name: 'Ghana', code: 'GH', dialPrefix: '233' },
  { name: 'Gibraltar', code: 'GI', dialPrefix: '350' },
  { name: 'Greece', code: 'GR', dialPrefix: '30' },
  { name: 'Greenland', code: 'GL', dialPrefix: '299' },
  { name: 'Grenada', code: 'GD', dialPrefix: '1473' },
  { name: 'Guadeloupe', code: 'GP', dialPrefix: '590' },
  { name: 'Guam', code: 'GU', dialPrefix: '1671' },
  { name: 'Guatemala', code: 'GT', dialPrefix: '502' },
  { name: 'Guernsey', code: 'GG', dialPrefix: '44' },
  { name: 'Guinea-Bissau', code: 'GW', dialPrefix: '245' },
  { name: 'Guinea', code: 'GN', dialPrefix: '224' },
  { name: 'Guyana', code: 'GY', dialPrefix: '592' },
  { name: 'Haiti', code: 'HT', dialPrefix: '509' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', dialPrefix: '672' },
  { name: 'Holy See (Vatican City State)', code: 'VA', dialPrefix: '379' },
  { name: 'Honduras', code: 'HN', dialPrefix: '504' },
  { name: 'Hong Kong', code: 'HK', dialPrefix: '852' },
  { name: 'Hungary', code: 'HU', dialPrefix: '36' },
  { name: 'Iceland', code: 'IS', dialPrefix: '354' },
  { name: 'India', code: 'IN', dialPrefix: '91' },
  { name: 'Indonesia', code: 'ID', dialPrefix: '62' },
  { name: 'Iran, Islamic Republic of Persian Gulf', code: 'IR', dialPrefix: '98' },
  { name: 'Iraq', code: 'IQ', dialPrefix: '964' },
  { name: 'Ireland', code: 'IE', dialPrefix: '353' },
  { name: 'Isle of Man', code: 'IM', dialPrefix: '44' },
  { name: 'Israel', code: 'IL', dialPrefix: '972' },
  { name: 'Italy', code: 'IT', dialPrefix: '39' },
  { name: 'Jamaica', code: 'JM', dialPrefix: '1876' },
  { name: 'Japan', code: 'JP', dialPrefix: '81' },
  { name: 'Jersey', code: 'JE', dialPrefix: '44' },
  { name: 'Jordan', code: 'JO', dialPrefix: '962' },
  { name: 'Kazakhstan', code: 'KZ', dialPrefix: '7' },
  { name: 'Kenya', code: 'KE', dialPrefix: '254' },
  { name: 'Kiribati', code: 'KI', dialPrefix: '686' },
  { name: "Korea, Democratic People's Republic of Korea", code: 'KP', dialPrefix: '850' },
  { name: 'Korea, Republic of South Korea', code: 'KR', dialPrefix: '82' },
  { name: 'Kosovo', code: 'XK', dialPrefix: '383' },
  { name: 'Kuwait', code: 'KW', dialPrefix: '965' },
  { name: 'Kyrgyzstan', code: 'KG', dialPrefix: '996' },
  { name: 'Laos', code: 'LA', dialPrefix: '856' },
  { name: 'Latvia', code: 'LV', dialPrefix: '371' },
  { name: 'Lebanon', code: 'LB', dialPrefix: '961' },
  { name: 'Lesotho', code: 'LS', dialPrefix: '266' },
  { name: 'Liberia', code: 'LR', dialPrefix: '231' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', dialPrefix: '218' },
  { name: 'Liechtenstein', code: 'LI', dialPrefix: '423' },
  { name: 'Lithuania', code: 'LT', dialPrefix: '370' },
  { name: 'Luxembourg', code: 'LU', dialPrefix: '352' },
  { name: 'Macao', code: 'MO', dialPrefix: '853' },
  { name: 'Macedonia', code: 'MK', dialPrefix: '389' },
  { name: 'Madagascar', code: 'MG', dialPrefix: '261' },
  { name: 'Malawi', code: 'MW', dialPrefix: '265' },
  { name: 'Malaysia', code: 'MY', dialPrefix: '60' },
  { name: 'Maldives', code: 'MV', dialPrefix: '960' },
  { name: 'Mali', code: 'ML', dialPrefix: '223' },
  { name: 'Malta', code: 'MT', dialPrefix: '356' },
  { name: 'Marshall Islands', code: 'MH', dialPrefix: '692' },
  { name: 'Martinique', code: 'MQ', dialPrefix: '596' },
  { name: 'Mauritania', code: 'MR', dialPrefix: '222' },
  { name: 'Mauritius', code: 'MU', dialPrefix: '230' },
  { name: 'Mayotte', code: 'YT', dialPrefix: '262' },
  { name: 'Mexico', code: 'MX', dialPrefix: '52' },
  { name: 'Micronesia, Federated States of Micronesia', code: 'FM', dialPrefix: '691' },
  { name: 'Moldova', code: 'MD', dialPrefix: '373' },
  { name: 'Monaco', code: 'MC', dialPrefix: '377' },
  { name: 'Mongolia', code: 'MN', dialPrefix: '976' },
  { name: 'Montenegro', code: 'ME', dialPrefix: '382' },
  { name: 'Montserrat', code: 'MS', dialPrefix: '1664' },
  { name: 'Morocco', code: 'MA', dialPrefix: '212' },
  { name: 'Mozambique', code: 'MZ', dialPrefix: '258' },
  { name: 'Myanmar', code: 'MM', dialPrefix: '95' },
  { name: 'Namibia', code: 'NA', dialPrefix: '264' },
  { name: 'Nauru', code: 'NR', dialPrefix: '674' },
  { name: 'Nepal', code: 'NP', dialPrefix: '977' },
  { name: 'Netherlands', code: 'NL', dialPrefix: '31' },
  { name: 'New Caledonia', code: 'NC', dialPrefix: '687' },
  { name: 'New Zealand', code: 'NZ', dialPrefix: '64' },
  { name: 'Nicaragua', code: 'NI', dialPrefix: '505' },
  { name: 'Niger', code: 'NE', dialPrefix: '227' },
  { name: 'Nigeria', code: 'NG', dialPrefix: '234' },
  { name: 'Niue', code: 'NU', dialPrefix: '683' },
  { name: 'Norfolk Island', code: 'NF', dialPrefix: '672' },
  { name: 'Northern Mariana Islands', code: 'MP', dialPrefix: '1670' },
  { name: 'Norway', code: 'NO', dialPrefix: '47' },
  { name: 'Oman', code: 'OM', dialPrefix: '968' },
  { name: 'Pakistan', code: 'PK', dialPrefix: '92' },
  { name: 'Palau', code: 'PW', dialPrefix: '680' },
  { name: 'Palestinian Territory, Occupied', code: 'PS', dialPrefix: '970' },
  { name: 'Panama', code: 'PA', dialPrefix: '507' },
  { name: 'Papua New Guinea', code: 'PG', dialPrefix: '675' },
  { name: 'Paraguay', code: 'PY', dialPrefix: '595' },
  { name: 'Peru', code: 'PE', dialPrefix: '51' },
  { name: 'Philippines', code: 'PH', dialPrefix: '63' },
  { name: 'Pitcairn', code: 'PN', dialPrefix: '64' },
  { name: 'Poland', code: 'PL', dialPrefix: '48' },
  { name: 'Portugal', code: 'PT', dialPrefix: '351' },
  { name: 'Puerto Rico', code: 'PR', dialPrefix: '1939' },
  { name: 'Qatar', code: 'QA', dialPrefix: '974' },
  { name: 'Reunion', code: 'RE', dialPrefix: '262' },
  { name: 'Romania', code: 'RO', dialPrefix: '40' },
  { name: 'Russia', code: 'RU', dialPrefix: '7' },
  { name: 'Rwanda', code: 'RW', dialPrefix: '250' },
  { name: 'Saint Helena, Ascension and Tristan Da Cunha', code: 'SH', dialPrefix: '290' },
  { name: 'Saint Kitts and Nevis', code: 'KN', dialPrefix: '1869' },
  { name: 'Saint Lucia', code: 'LC', dialPrefix: '1758' },
  { name: 'Saint Martin', code: 'MF', dialPrefix: '590' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', dialPrefix: '508' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', dialPrefix: '1784' },
  { name: 'Samoa', code: 'WS', dialPrefix: '685' },
  { name: 'San Marino', code: 'SM', dialPrefix: '378' },
  { name: 'Sao Tome and Principe', code: 'ST', dialPrefix: '239' },
  { name: 'Saudi Arabia', code: 'SA', dialPrefix: '966' },
  { name: 'Senegal', code: 'SN', dialPrefix: '221' },
  { name: 'Serbia', code: 'RS', dialPrefix: '381' },
  { name: 'Seychelles', code: 'SC', dialPrefix: '248' },
  { name: 'Sierra Leone', code: 'SL', dialPrefix: '232' },
  { name: 'Singapore', code: 'SG', dialPrefix: '65' },
  { name: 'Sint Maarten', code: 'SX', dialPrefix: '599' },
  { name: 'Slovakia', code: 'SK', dialPrefix: '421' },
  { name: 'Slovenia', code: 'SI', dialPrefix: '386' },
  { name: 'Solomon Islands', code: 'SB', dialPrefix: '677' },
  { name: 'Somalia', code: 'SO', dialPrefix: '252' },
  { name: 'South Africa', code: 'ZA', dialPrefix: '27' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS', dialPrefix: '500' },
  { name: 'South Sudan', code: 'SS', dialPrefix: '211' },
  { name: 'Spain', code: 'ES', dialPrefix: '34' },
  { name: 'Sri Lanka', code: 'LK', dialPrefix: '94' },
  { name: 'Sudan', code: 'SD', dialPrefix: '249' },
  { name: 'Suriname', code: 'SR', dialPrefix: '597' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', dialPrefix: '47' },
  { name: 'Swaziland', code: 'SZ', dialPrefix: '268' },
  { name: 'Sweden', code: 'SE', dialPrefix: '46' },
  { name: 'Switzerland', code: 'CH', dialPrefix: '41' },
  { name: 'Syrian Arab Republic', code: 'SY', dialPrefix: '963' },
  { name: 'Taiwan', code: 'TW', dialPrefix: '886' },
  { name: 'Tajikistan', code: 'TJ', dialPrefix: '992' },
  { name: 'Tanzania, United Republic of Tanzania', code: 'TZ', dialPrefix: '255' },
  { name: 'Thailand', code: 'TH', dialPrefix: '66' },
  { name: 'Timor-Leste', code: 'TL', dialPrefix: '670' },
  { name: 'Togo', code: 'TG', dialPrefix: '228' },
  { name: 'Tokelau', code: 'TK', dialPrefix: '690' },
  { name: 'Tonga', code: 'TO', dialPrefix: '676' },
  { name: 'Trinidad and Tobago', code: 'TT', dialPrefix: '1868' },
  { name: 'Tunisia', code: 'TN', dialPrefix: '216' },
  { name: 'Turkey', code: 'TR', dialPrefix: '90' },
  { name: 'Turkmenistan', code: 'TM', dialPrefix: '993' },
  { name: 'Turks and Caicos Islands', code: 'TC', dialPrefix: '1649' },
  { name: 'Tuvalu', code: 'TV', dialPrefix: '688' },
  { name: 'Uganda', code: 'UG', dialPrefix: '256' },
  { name: 'Ukraine', code: 'UA', dialPrefix: '380' },
  { name: 'United Arab Emirates', code: 'AE', dialPrefix: '971' },
  { name: 'United Kingdom', code: 'GB', dialPrefix: '44' },
  { name: 'United States', code: 'US', dialPrefix: '1' },
  { name: 'Uruguay', code: 'UY', dialPrefix: '598' },
  { name: 'Uzbekistan', code: 'UZ', dialPrefix: '998' },
  { name: 'Vanuatu', code: 'VU', dialPrefix: '678' },
  { name: 'Venezuela, Bolivarian Republic of Venezuela', code: 'VE', dialPrefix: '58' },
  { name: 'Vietnam', code: 'VN', dialPrefix: '84' },
  { name: 'Virgin Islands, British', code: 'VG', dialPrefix: '1284' },
  { name: 'Virgin Islands, U.S.', code: 'VI', dialPrefix: '1340' },
  { name: 'Wallis and Futuna', code: 'WF', dialPrefix: '681' },
  { name: 'Yemen', code: 'YE', dialPrefix: '967' },
  { name: 'Zambia', code: 'ZM', dialPrefix: '260' },
  { name: 'Zimbabwe', code: 'ZW', dialPrefix: '263' },
];
