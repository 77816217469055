import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subYears from 'date-fns/subYears';
import isAfter from 'date-fns/isAfter';

const iso = (date) => date.toISOString().replace(/\.[0-9]{3}/, '');

const endOfHour = (d) =>
  new Date(
    Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), 59, 0, 0),
  );

const startOfHour = (d) =>
  new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), 0, 0, 0));

const startOfDay = (d) =>
  new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0, 0));

const startOfYear = (date) => new Date(Date.UTC(date.getUTCFullYear(), 0, 1, 0, 0, 0, 0));

export const formatter = (num) => (num > 999 ? (num / 1000).toFixed(1) + 'k' : num);

export const datesFromNow = (frequency, n) => {
  let dateFrom;

  const now = new Date();
  const dateTo = endOfHour(now);

  switch (frequency) {
    case 'HOURLY':
      dateFrom = subHours(now, n);
      break;
    case 'DAILY':
      dateFrom = subDays(now, n);
      break;
    case 'YEARLY':
      dateFrom = subYears(now, n);
      break;
    default:
      dateFrom = startOfHour(now);
      break;
  }
  dateFrom = startOfHour(dateFrom);

  return [dateFrom, dateTo];
};

export const datesBetween = (frequency, dateFrom, dateTo) => {
  let sub, of;
  switch (frequency) {
    case 'DAILY':
      sub = subDays;
      of = startOfDay;
      break;
    case 'YEARLY':
      sub = subYears;
      of = startOfYear;
      break;
    default:
      sub = subHours;
      of = startOfHour;
      break;
  }

  const dates = [dateTo];
  for (let d = sub(dates[0], 1); isAfter(d, dateFrom); d = sub(d, 1)) {
    dates.unshift(d);
  }

  return dates.map(of).map(iso);
};
