import { compose, lifecycle } from 'recompose';
import styled from 'styled-components/macro';

const LayoutBase = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
`;

const withLifecycle = lifecycle({
  componentDidMount() {
    // According to https://docs.intercom.com/install-on-your-product-or-site/other-ways-to-get-started/integrate-intercom-in-a-single-page-app
    // this will register the change of URL with Intercom
    // componenentDidMount seems to fire most of the time when clicking elements, but
    // not when swiping cards.
    window.Intercom('update');
  },
});

export default compose(withLifecycle)(LayoutBase);
