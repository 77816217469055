import React from 'react';
import { MailChimpLogo, ConstantContactLogo, ZapierLogo } from './shared-elements';

export const providers = [
  {
    id: 'mailchimp',
    name: 'MailChimp',
    logo: <MailChimpLogo width={52} height={52} />,
    color: '#AEEBFB',
  },
  {
    id: 'constantcontact',
    name: 'ConstantContact',
    logo: <ConstantContactLogo width={52} height={52} fill="#FFFFFF" />,
    color: '#00299d',
  },
  {
    id: 'zapier',
    name: 'Zapier',
    logo: <ZapierLogo width={50} height={50} fill="#FFFFFF" />,
    color: '#FF4A00',
  },
];

export const defaultProvider = providers[0].id;
