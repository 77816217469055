import React from 'react';
import styled from 'styled-components/macro';
import { branch, compose, renderNothing, pure } from 'recompose';

const Container = styled.div`
  -webkit-transform: rotate(45deg);
  background: #de2b56;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  color: ${(props) => props.theme.colors.white};
  left: auto;
  line-height: 16px;
  position: absolute;
  right: -30px;
  text-align: center;
  top: 12px;
  padding-bottom: 1px;
  transform: rotate(45deg);
  width: 100px;
  font-size: 10px;
`;

const ProductRibbon = ({ text }) => <Container children={text} />;

export default compose(
  //
  branch(({ isDiscounted }) => !isDiscounted, renderNothing),
  pure,
)(ProductRibbon);
