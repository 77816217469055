import React from 'react';

function Instagram(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <path d="M10 0H5.9L3.4.6c-.6.3-1.2.6-1.7 1.2C1 2.2.7 2.8.5 3.5.3 4 .1 4.8.1 5.9a71 71 0 0 0 .4 10.7c.3.6.6 1.2 1.2 1.7.5.6 1 1 1.7 1.2.7.2 1.4.4 2.5.4a71 71 0 0 0 10.6-.4c.7-.3 1.3-.6 1.8-1.2.6-.5 1-1 1.2-1.7.2-.7.4-1.4.4-2.5a71 71 0 0 0-.4-10.6c-.3-.7-.6-1.3-1.2-1.8-.5-.6-1.1-1-1.8-1.2-.6-.2-1.3-.4-2.4-.4H10zm0 1.8a69.4 69.4 0 0 1 5.9.4c.5.2.8.4 1.1.8.4.3.6.6.8 1.1.1.4.3.9.3 1.9a69.4 69.4 0 0 1-.3 9.9c-.2.5-.4.8-.8 1.1-.3.4-.6.6-1.1.8-.4.1-.9.3-1.9.3a69.4 69.4 0 0 1-9.9-.3c-.5-.2-.8-.4-1.1-.8-.4-.3-.6-.6-.8-1.1-.1-.4-.3-.9-.3-1.9a69.4 69.4 0 0 1 .3-9.9c.2-.5.4-.8.8-1.1.3-.4.6-.6 1.1-.8C4.5 2.1 5 2 6 2h4z" />
      <path d="M10 13.3a3.3 3.3 0 1 1 0-6.6 3.3 3.3 0 0 1 0 6.6zm0-8.4a5 5 0 1 0 0 10.2 5 5 0 0 0 0-10.2zM16.5 4.6a1.2 1.2 0 1 1-2.3 0 1.2 1.2 0 0 1 2.3 0" />
    </svg>
  );
}

Instagram.defaultProps = {
  fill: 'currentColor',
  width: 20,
  height: 20,
};

export default Instagram;
