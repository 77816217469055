import React from 'react';

function TapBioWordMark(props) {
  return (
    <svg width={35} height={35} viewBox="0 0 35 35" {...props}>
      <g transform="translate(7.5 10)">
        <path d="M4.2 7.7c.5 0 .9-.4.9-.8v-3c0-.5.3-.8.8-.8s.9-.4.9-.9v-.6c0-.4-.4-.8-.8-.8H1.6c-.4 0-.8.4-.8.8v.6c0 .5.4.9.8.9h.1c.5 0 .8.3.8.8v3c0 .4.4.8.9.8h.8zM14.4 6.9V7 1.6c0-.4.4-.7.8-.7h3.5l.7.4c.4.3.7.6.9 1.1l.1.9c0 .5 0 .8-.2 1.2l-.6.8-.6.3h-1.3c-.4 0-.8.4-.8.9v.4c0 .4-.4.8-.8.8h-.9a.9.9 0 0 1-.8-.8zm2.5-3v-.1h.9c.2-.1.2-.3.2-.5l-.1-.4c-.2-.2-.4-.2-.7-.2h-.3v1.2zM6.6 6.6l1.9-5.2c.1-.4.4-.6.7-.6h1.7c.3 0 .6.3.7.6l1.9 5.2a.8.8 0 0 1-.8 1.1h-1c-.3 0-.6-.2-.6-.5v-.1l-.4-.3H9.4c-.2 0-.4.1-.4.4-.1.3-.3.4-.6.4h-1a.8.8 0 0 1-.8-1zm3-1.9h1l-.3-1a.2.2 0 1 0-.5 0l-.2 1zM2.1 15.2h.1V9.9c0-.5.4-.8.8-.8h2.6c.7 0 1.3.2 1.7.6.3.3.4.8.4 1.3 0 .6-.4 1.2-1 1.4l.7.3.5.5.2.8-.2.9-.6.6-.8.4-1 .1H2.9a.9.9 0 0 1-.8-.8zm2.3-3.5h.8l.2-.2v-.3c0-.2 0-.3-.2-.5h-.8v1zm0 2.6v-.1h1l.2-.1.1-.4c0-.2-.1-.3-.3-.5h-1v1.1zM9.4 9.1c-.4 0-.8.3-.8.8v5.3c0 .4.4.8.9.8h.8c.5 0 .8-.4.8-.8V9.9c0-.5-.3-.8-.8-.8h-.9zM11.7 12.6c0-.5.1-1 .3-1.4.1-.5.4-.9.7-1.2.4-.4.8-.7 1.3-.8.5-.3 1-.3 1.6-.3a3.5 3.5 0 0 1 3.7 3.7c0 1-.3 1.9-1 2.6a4.3 4.3 0 0 1-4.6.6l-1-.7-.7-1-.3-1.5zm2.6 0v-.1c0 .5.1.8.3 1 .3.3.6.4.9.4.4 0 .7-.1.9-.3.3-.3.4-.6.4-1s-.1-.8-.4-1a1 1 0 0 0-.8-.4c-.4 0-.7.1-1 .4-.3.2-.3.6-.3 1z" />
      </g>
    </svg>
  );
}

export default TapBioWordMark;
