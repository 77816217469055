// A module…
//
// 1. MUST export default a function called reducer()
// 2. MUST export its action creators as functions
// 3. MUST have action types in the form npm-module-or-app/reducer/ACTION_TYPE
// 4. MAY export its action types as UPPER_SNAKE_CASE, if an external reducer needs to listen
//    for them, or if it is a published reusable library

const SHOW = 'tb.more-menu.show';
const HIDE = 'tb.more-menu.hide';
const TOGGLE = 'tb.more-menu.toggle';

const initialState = { isShowing: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW:
      return { ...state, isShowing: true };
    case HIDE:
      return { ...initialState };
    case TOGGLE:
      return { ...state, isShowing: !state.isShowing };
    default:
      return state;
  }
}

export function showMoreMenu() {
  return { type: SHOW };
}

export function hideMoreMenu() {
  return { type: HIDE };
}

export function toggleMoreMenu() {
  return { type: TOGGLE };
}
