import { createGlobalStyle } from 'styled-components';
import { PlusIcon as BasePlusIcon } from 'components/icons';
import styled from 'styled-components/macro';

const StyleSheet = createGlobalStyle`
  .sortableImage {
    z-index: 9999;
  }
`;

const AddButtons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 5;
  left: 10px;
  right: 10px;
  bottom: ${(p) => (p.hasCta ? '78px' : '10px')};
  color: rgba(255, 255, 255, 0.5);
`;

const CallToActionButtonsContainer = styled.div`
  position: absolute;
  top: 67px;
  z-index: 8;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
`;

const DraggableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const SpinnerContainer = styled.div`
  ${(p) => !p.isVisible && 'display:none;'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 3px;
  padding-top: 30px;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 49.5%;
`;

const GutterSizer = styled.div`
  width: 5px;
`;

const Controls = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  padding: 7px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CloseIconWrapper = styled.span`
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.65);
  height: 19px;
  width: 19px;
  display: flex;
  align-items: center;
`;

const CloseIcon = styled(BasePlusIcon)`
  width: 38px;
  transform: rotate(-45deg);

  path {
    fill: white;
  }
`;

const EditIconWrapper = styled.span`
  padding: 0;
  display: flex;
  align-items: right;
  margin-right: 2px;

  path {
    fill: #000000;
  }
`;

const AddImageButtonContainer = styled.div`
  display: flex;
  flex: 1;

  > div {
    display: flex;
    flex: 1;
  }
`;

const AddCallToActionButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 8px;
`;

const CopyWrapper = styled.div`
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ImageBackground = styled.div`
  height: 135px;
  width: 100%;
  opacity: ${(props) => (props.isLoading ? 0.5 : 1)};
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.85);
`;

const Image = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin: 0 auto;
  width: 100%;
  border-radius: 6px;

  /*
    If the aspect ratio is null this div will have a zero height
    And the child ImageFallback component will be visible
  */
  ${(props) => `padding-top: ${props.aspectRatio * 100}%`};
`;

const ImageFallback = styled.img``;

const Title = styled.div`
  width: 100%;
  color: #fff;
  font-size: 13px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  margin: 0;
  line-height: 1.35;
  padding-left: 7px;
`;

const Description = styled.div`
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: 7px;
  padding: 0 7px;
  line-height: 1.2;
`;

const Crop = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export {
  StyleSheet,
  AddButtons,
  CallToActionButtonsContainer,
  DraggableWrapper,
  SpinnerContainer,
  ImageWrapper,
  GutterSizer,
  Controls,
  CloseIconWrapper,
  CloseIcon,
  EditIconWrapper,
  AddImageButtonContainer,
  AddCallToActionButtonContainer,
  CopyWrapper,
  ImageBackground,
  Image,
  ImageFallback,
  Title,
  Description,
  Crop,
};
