import React from 'react';
import PropTypes from 'prop-types';
import * as Recompose from 'recompose';

function AccountNamePromptHint(props) {
  return <span>Claim your Tap Bio link.</span>;
}

function AccountNamePreviewHint(props) {
  return (
    <span>
      Your Tap Bio link will be <b>tap.bio/@{props.formData.accountName}</b>
    </span>
  );
}

const AccountNameHint = Recompose.compose(
  Recompose.branch((props) => {
    const { formData, formErrors } = props;
    return !formData.accountName || formErrors.accountName;
  }, Recompose.renderComponent(AccountNamePromptHint)),
)(AccountNamePreviewHint);

AccountNameHint.propTypes = {
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
};

export default AccountNameHint;
