import * as Recompose from 'lib/recompose';
import * as Styled from './styled';

export default Recompose.compose(
  Recompose.withProps((props) => {
    const children = props.isPersisting ? 'Saving...' : 'Save';
    const disabled = props.isPersisting;

    return {
      children,
      disabled,
      as: 'button',
    };
  }),
  // Recompose.omitProps('isVisible', 'currentAccount', 'cardId', 'hideStats'),
  Recompose.pure,
)(Styled.HeaderButtonPill);
