import * as Sentry from '@sentry/browser';
import { graphql } from '@apollo/react-hoc';
import { updateDeckSortOrderMutation } from 'lib/graphql/mutations';

export default graphql(updateDeckSortOrderMutation, {
  props: ({ mutate, ownProps }) => ({
    updateDeckSortOrder: (cardIds, deckId) => {
      try {
        return mutate({ variables: { cardIds, deckId } });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    },
  }),
});
