import React from 'react';
import moment from 'moment';
import { Text, Link } from 'components/web-ui';
import styled from 'styled-components/macro';

const Container = styled.div`
  margin-bottom: 5px;
  color: #000;
  background-color: #fff;
  padding: 10px 12px;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

const TwitterHandle = styled(Text)`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  margin-bottom: 6px;

  & span {
    font-weight: normal;
  }
`;

const TweetText = styled(Text)`
  font-size: 16px;
  line-height: 1.35;
`;

const TweetLink = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
`;

const dateFormat = (date) => {
  return moment(date, 'YYYY-MM-DD HH-mm-ss').format('MMM Do');
};

function Tweet(props) {
  const { username, tweet } = props;
  return (
    <TweetLink target="_blank" to={`https://twitter.com/${username}/status/${tweet.id}`}>
      <Container key={tweet.id}>
        <TwitterHandle>
          @{username} - <span>{dateFormat(tweet.created_at)}</span>
        </TwitterHandle>
        <TweetText>{tweet.text}</TweetText>
      </Container>
    </TweetLink>
  );
}

export default Tweet;
