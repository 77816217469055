import React from 'react';
import { Modal } from 'components/ui';
import { Text, ButtonCircle, Heading } from 'components/web-ui';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const PrimaryHeading = styled(Heading)`
  text-align: center;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  line-height: 1.3;
  margin: 17px auto 5% auto;
  width: 90%;
`;

const ConfirmButton = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  color: white;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1rem;
  height: 4rem;
`;

function CardUpdatedSuccessModal(props) {
  const { onClickClose: handleClose } = props;

  if (!props.isActive) return null;

  return (
    <Modal onClickClose={handleClose}>
      <Layout>
        <PrimaryHeading>
          <Text>Your card was updated successfully!</Text>
        </PrimaryHeading>
        <ConfirmButton children="Continue" onClick={handleClose} />
      </Layout>
    </Modal>
  );
}

export default CardUpdatedSuccessModal;
