import React, { Component } from 'react';
import Authentication from 'lib/authentication';
import Account from 'lib/current-account';
import { Redirect } from 'react-router-dom';
import { withApollo } from '@apollo/react-hoc';
import routes from 'config/routes';

class Logout extends Component {
  componentWillMount() {
    // Delete the auth token
    Authentication.logOut();

    // Delete current account name
    Account.removeCurrentAccount();

    // Clear Apollo's cache
    this.props.client.resetStore();
  }

  render() {
    return <Redirect to={{ pathname: routes.logoutRedirectPath }} />;
  }
}

export default withApollo(Logout);
