// XXX This is old and will be removed soon

import Authentication from 'lib/authentication';
import config from 'config';

export function fetchJSON(url, method, data = {}) {
  if (process.env.NODE_ENV === 'development') console.log(url, method, data);

  // https://github.com/github/fetch/issues/424#issuecomment-259070410
  const token = Authentication.getToken();
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: token ? `Bearer ${token}` : null,
  };

  const params = {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers,
    method,
  };

  if (method === 'GET') {
    delete params.body;
  }

  const urlWithHost = `${config.apiHost}${url}`;
  return fetch(urlWithHost, params);
}

export function postJSON(url, data) {
  return fetchJSON(url, 'POST', data);
}

export function patchJSON(url, data) {
  return fetchJSON(url, 'PATCH', data);
}

export function deleteJSON(url, data) {
  return fetchJSON(url, 'DELETE', data);
}
