import { ErrorMessage, GradientButton, ProgressBar } from 'components/cards/forms/shared-ui';
import Checkbox from 'components/ui/form-checkbox';
import withCallToActionStateHandlers from 'hocs/with-call-to-action-state-handlers';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import withMailChimpLists from 'hocs/with-mailchimp-lists';
import set from 'lodash/set';
import React from 'react';
import { branch, compose, renderComponent, withHandlers, withProps } from 'recompose';
import styled from 'styled-components/macro';
import MailChimpOAuthButton from './button-mailchimp-oauth';
import IdentitiesSelector from './dropdown-mailchimp-accounts';
import MailingListSelector from './dropdown-mailchimp-lists';

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const Description = styled.p`
  margin-bottom: 20px;
  font-size: ${(props) => (props.small ? 12 : 14)}px;
  text-align: center;
`;

function SetupMailingList(props) {
  const {
    identities,
    listId,
    identityId,
    doubleOptIn,
    setListId,
    setIdentityId,
    setDoubleOptIn,
    onListCreateButtonClick,
    withMailchimpLists,
  } = props;

  const { error, mailchimpLists } = withMailchimpLists;

  return (
    <Wrapper>
      {identities.length === 0 ? (
        <React.Fragment>
          <Description>Start by connecting your MailChimp account.</Description>
          <MailChimpOAuthButton gradient mailchimp text="Add MailChimp" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <IdentitiesSelector
            identities={identities}
            identityId={identityId}
            onSet={setIdentityId}
          />
          {error ? (
            <ErrorMessage error={error} />
          ) : mailchimpLists &&
            Array.isArray(mailchimpLists.edges) &&
            mailchimpLists.edges.length > 0 ? (
            <MailingListSelector lists={mailchimpLists.edges} selected={listId} onSet={setListId} />
          ) : (
            <GradientButton start="#c0998a" end="#c0998a" onClick={onListCreateButtonClick}>
              Create MailChimp List
            </GradientButton>
          )}
          <Checkbox
            description="Enable double opt-in"
            additionalInformation="Subscriber will receive the opt-in confirmation email, and click the link to confirm subscription."
            checked={doubleOptIn}
            onClick={setDoubleOptIn}
          />
        </React.Fragment>
      )}
    </Wrapper>
  );
}

const withMailChimpProps = withProps(({ currentAccount, formState }) => {
  const username = currentAccount.username;
  const identities = currentAccount.identities.filter((id) => id.provider === 'mailchimp');
  const withMailchimpLists = {};
  const doubleOptIn = !!formState.entities.mailingLists[formState.result.mailingList].doubleOptIn;
  const identityId = formState.entities.mailingLists[formState.result.mailingList].identityId || '';
  const listId = formState.entities.mailingLists[formState.result.mailingList].listId || '';
  return { username, identities, first: 50, withMailchimpLists, doubleOptIn, identityId, listId };
});

const withMailChimpHandlers = withHandlers({
  onListCreateButtonClick: (props) => (evt) => {
    evt && evt.preventDefault();
    window.location.href = 'https://admin.mailchimp.com/lists/new-list/';
  },
  setIdentity:
    ({ formState, setFormState }) =>
    () => {
      const id = formState.result.mailingList;
      setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.identityId`, ''));
    },
  setListId:
    ({ formState, setFormState }) =>
    (listId, url) => {
      const id = formState.result.mailingList;
      setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.listId`, listId));
      setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.url`, url));
    },
  setDoubleOptIn:
    ({ formState, setFormState, doubleOptIn }) =>
    () => {
      const id = formState.result.mailingList;
      setFormState((state) =>
        set({ ...state }, `entities.mailingLists.${id}.doubleOptIn`, !doubleOptIn),
      );
    },
});

const withMailChimpData = branch(
  (props) => props.provider === 'mailchimp' && props.identities.length > 0,
  withMailChimpLists,
);

export const isMailChimpProvider = (props) => props.provider === 'mailchimp';
export const isMailChimpListsLoading = ({ withMailchimpLists }) =>
  !withMailchimpLists || withMailchimpLists.loading;
export const isMailChimpSettingsValid = ({ provider, formState }) => {
  if (provider !== 'mailchimp') return true;
  const { entities, result } = formState;
  const { mailingList: id } = result;
  const mailingList = entities.mailingLists[id];
  return typeof mailingList.url === 'string' && mailingList.url.indexOf('https://') === 0;
};

export default compose(
  withIdentityAccounts,
  withCallToActionStateHandlers,

  withMailChimpProps,
  withMailChimpData,

  withMailChimpHandlers,

  branch(isMailChimpListsLoading, renderComponent(ProgressBar)),
)(SetupMailingList);
