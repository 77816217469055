import Counter from 'components/cards/stats/elements/counter';
import Segment from 'components/cards/stats/elements/segment';
import {
  ChartPlaceholder,
  NumbersContainer,
  Overlay,
  Panel,
  ProgressBar,
} from 'components/cards/stats/elements/shared';
import { withFrequency, withTypeIndex } from 'components/cards/stats/enhancer';
import React from 'react';
import { branch, compose, renderComponent } from 'recompose';

function Component(props) {
  const { frequency, children } = props;
  return (
    <Panel>
      {/* progress bar with white overlay */}
      <Overlay>
        <ProgressBar />
      </Overlay>

      {/* date range selector */}
      <Segment selected={frequency} />

      {/* show stats numbers */}
      <NumbersContainer>
        {React.Children.map(children, (_, i) => (
          <Counter key={i} total={0} loading={true} active={i === 0} name="▄▄▄" />
        ))}
      </NumbersContainer>

      {/* show chart placeholder */}
      <ChartPlaceholder />
    </Panel>
  );
}

const Extended = compose(withTypeIndex, withFrequency)(Component);

const shouldRender = ({ currentActiveCardIndex, cardIndexInDeck, ...props }) => {
  return currentActiveCardIndex !== cardIndexInDeck;
};

export default branch(shouldRender, renderComponent(Extended));
