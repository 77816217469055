import React from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components/macro';
import NumberFormatter from 'components/cards/stats/elements/number-formatter';
import BarChart from 'components/cards/stats/elements/bar-chart/index';
import enhance from 'components/cards/stats/cards/shared/enhancer';
import { TrendsItemWrapper, TrendsSummary } from 'components/cards/stats/elements/shared';
import DeletedTrendItem from 'components/cards/stats/elements/deleted-trend-item';

const VideoWrapper = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
`;

const Video = ({ unit, graph, events, summary, total, video }) => {
  return (
    <TrendsItemWrapper>
      {video ? (
        <VideoWrapper>
          <YouTube
            videoId={video.id}
            containerClassName="youtube-video-container"
            opts={{
              width: '100%',
              height: '200px',
              playerVars: {
                autoplay: 0,
                showinfo: 0,
                modestbranding: 1,
                rel: 0,
                playsinline: 0,
              },
            }}
          />
        </VideoWrapper>
      ) : (
        <DeletedTrendItem separator={!summary} />
      )}
      {!summary && <BarChart data={graph} unit={unit} />}
      <TrendsSummary>
        <NumberFormatter value={total} /> Plays
      </TrendsSummary>
    </TrendsItemWrapper>
  );
};

export default enhance(Video);
