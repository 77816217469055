"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.REGEXS = exports.PROTOCOLS = exports.HELPERS = void 0;
var HELPERS = {
  WEB: 'web',
  EMAIL: 'email',
  TEL: 'tel',
  NONE: 'none'
};
exports.HELPERS = HELPERS;
var PROTOCOLS = {
  HTTP: 'http:',
  HTTPS: 'https:',
  MAILTO: 'mailto:',
  TEL: 'tel:',
  NONE: ''
};
exports.PROTOCOLS = PROTOCOLS;
var REGEXS = {
  LEADING_SLASHES: /^[/]+/,
  PROTOCOLS: /^(https?:|mailto:|tel:)/i,
  // https://emailregex.com/
  EMAIL_TEST: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL_PROTOCOL: /^(mailto:)(.+)/i,
  // still working on this one, see test for what works
  TEL_TEST: /^[0-9-.()+\s]+$/,
  TEL_PROTOCOL: /^(tel:)/i,
  TEL_PREFIX: /^(\d{1,}\*)/,
  // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
  // modified to remove single-character tlds
  WEB_TEST: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  WEB_PROTOCOL: /^(https?:)/i,
  WEB_PREFIX: /^([/]+)/
};
exports.REGEXS = REGEXS;
var _default = {
  HELPERS: HELPERS,
  PROTOCOLS: PROTOCOLS,
  REGEXS: REGEXS
};
exports["default"] = _default;