import styled from 'styled-components/macro';
import { compose, withProps, defaultProps } from 'recompose';

const formatter = (n) => {
  if (n < 1) return 0;
  const k = Math.floor(n);
  if (k >= 10000000000) return `${(k / 1000000000).toFixed(0)}B`;
  if (k >= 1000000000) return `${(k / 1000000000).toFixed(1)}B`;
  if (k >= 10000000) return `${(k / 1000000).toFixed(0)}M`;
  if (k >= 1000000) return `${(k / 1000000).toFixed(1)}M`;
  if (k >= 10000) return `${(k / 1000).toFixed(0)}K`;
  if (k >= 1000) return `${(k / 1000).toFixed(1)}K`;
  return k;
};

const Formatter = styled.div`
  display: flex;
  align-items: center;
  color: #341552;
  font-size: 16px;
  font-weight: 600;
`;

export default compose(
  defaultProps({
    value: 0,
  }),
  withProps(({ value }) => {
    const children = formatter(value);
    return { children };
  }),
)(Formatter);
