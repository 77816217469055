import { graphql } from '@apollo/react-hoc';
import { defaultDeckQuery } from 'lib/graphql/queries';

export default graphql(defaultDeckQuery, {
  options: (props) => {
    const { accountUsername } = props.match.params;

    return { variables: { accountUsername } };
  },
});
