import React from 'react';
import Panel from 'components/cards/stats/component';
import TwitterTweetClickType from 'components/cards/stats/types/twitter-tweet-click-type';
import TwitterTrends from 'components/cards/stats/cards/twitter/trends';
import CardViewType from 'components/cards/stats/types/card-view-type';

export default (props) => (
  <Panel {...props}>
    <TwitterTweetClickType>
      <TwitterTrends />
    </TwitterTweetClickType>
    <CardViewType />
  </Panel>
);
