import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  background-color: white;
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
`;

function NotConfigured(props) {
  return (
    <Container>
      This Twitter card is not correctly configured. Tap the cog above to update your settings.
    </Container>
  );
}

export default NotConfigured;
