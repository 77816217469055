import React from 'react';
import { RouterLink } from 'components/ui';
import { Link } from 'react-router-dom';
import routes from 'config/routes';

function MigrateAccount(props) {
  return (
    <div>
      <h1>Migration</h1>
      <p>
        Hello, <strong>{props.match.params.username}</strong>!
      </p>
      <p>This process will migration your entire Link in Profile account to the all-new tap.bio.</p>
      <p>
        Please note that after you create your tap.bio account your migration make take up to an
        hour, depending on your data size
      </p>
      <RouterLink
        to={`${routes.registrationPath}/${props.match.params.username}`}
        is={Link}
        children="Begin Migration"
      />
    </div>
  );
}

export default MigrateAccount;
