// Default values

import theme from 'components/web-ui/theme';

const primaryPurple = '#341552';

export default {
  ...theme,
  colors: {
    ...theme.colors,
    alphaBlack10: 'rgba(0, 0, 0, .1)',
    alphaBlack20: 'rgba(0, 0, 0, .2)',
    alphaBlack30: 'rgba(0, 0, 0, .3)',
    alphaBlack40: 'rgba(0, 0, 0, .4)',
    alphaBlack50: 'rgba(0, 0, 0, .5)',
    alphaBlack60: 'rgba(0, 0, 0, .6)',
    alphaBlack70: 'rgba(0, 0, 0, .7)',
    alphaBlack80: 'rgba(0, 0, 0, .8)',
    alphaBlack90: 'rgba(0, 0, 0, .9)',
    alphaWhite10: 'rgba(255, 255, 255, .1)',
    alphaWhite20: 'rgba(255, 255, 255, .2)',
    alphaWhite30: 'rgba(255, 255, 255, .3)',
    alphaWhite40: 'rgba(255, 255, 255, .4)',
    alphaWhite50: 'rgba(255, 255, 255, .5)',
    alphaWhite60: 'rgba(255, 255, 255, .6)',
    alphaWhite70: 'rgba(255, 255, 255, .7)',
    alphaWhite80: 'rgba(255, 255, 255, .8)',
    alphaWhite90: 'rgba(255, 255, 255, .9)',
    alphaPurple90: 'rgba(198, 83, 255, .9)',
    alert: '#E52952',
    danger: '#DE2B56',
    grey: '#888888',
    lightGrey: '#999999',
    lightYellow: '#fdfad4',
    black: '#000000',
    almostBlack: '#080808',
    appleBlue: '#3d9bf5',
    veryLightBlue: '#F5F7F8',
    avatarLoadingGray: '#C5CCDF',
    avatarGrey: '#C0C0C0',
    brightGreen: '#0DD573',
    dividerGrey: '#cccbd0',
    listBorder: '#E6E6E6',
    disabledGrey: '#CAC8CC',
    errorRed: '#DE3736',
    primaryPurple,
    lightPurple: '#8f7f9e',
    veryLightPurple: '#c2bec8',
    highlightPurple: '#542580',
    darkPurple: '#27103D',
    veryDarkPurple: '#33154E',
    lightGray: '#DDDDDD',
    veryLightishGray: '#E4E4E4',
    veryLightGray: '#FAFAFA',
    veryLightYellow: '#FFF8BA',
    darkYellow: '#D08C29',
    navigationDividerGrey: '#DCE1E6',
    white: '#ffffff',
    messages: {
      notice: '#FCEE6D',
      error: '#DE3736',
    },
    authForm: {
      border: '#E6E6E6',
      background: '#FAFAFA',
      disabledButtonBackground: '#CAC8CC',
      enabledButtonBackground: primaryPurple,
    },
    profileForm: {
      inputBorder: '#E6E6E6',
      inputBackground: '#FAFAFA',
      inactiveButton: '#E6E6E6',
      headerBorder: '#EBEBEB',
    },
    genericForm: {
      hint: '#AAAAAA',
    },
    viewport: {
      silver: '#d5e0e8',
      darkSilver: '#7C93A5',
    },
    billing: {
      featuresBackground: '#FAFAFA',
      divider: '#e9e9e9',
      renewalText: '#4A4A4A',
    },
  },

  cardForm: {
    headerTopOffset: '0px',
    headerHeight: '70px',
  },

  // https://stackoverflow.com/a/47880631/453405
  fonts: {
    // SanFranciscoText-Regular
    body: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Ubuntu',
    header: `'Open Sans', sans-serif`,
    weights: {
      ultraLight: 100,
      extraLight: 100,
      light: 200, // iOS Available
      thin: 200, // iOS Available
      book: 300,
      demi: 300,
      normal: 400, // iOS Available
      regular: 400, // iOS Available
      medium: 500,
      semiBold: 600, // iOS Available
      demiBold: 600, // iOS Available
      bold: 700, // iOS Available
      extraBold: 800, // iOS Available
      black: 800, // iOS Available
      heavy: 800, // iOS Available
      extraBlack: 900,
      fat: 900,
      poster: 900,
      ultraBlack: 900,
    },
  },

  viewport: {
    w: 375,
    h: 667,
    breakpoints: {
      w: 414 + 120, // The iPhone X's 414px width + padding for the swiper nav arrows
      h: 897, // The iPhone XS Max's 896px width + 1
    },
  },
};
