import { withHandlers } from 'recompose';
import { getJSON } from '../lib/request';

const withHandleForceRefresh = withHandlers({
  handleForceRefresh: (props) => async () => {
    console.log('handleForceRefresh');
    const url = window.PATHS.apiV1PhotosPath(props.user.username) + '?skip_cache=1';
    await getJSON(url);
    window.location.reload();
  },
});

export default withHandleForceRefresh;
