import { rejectBlank } from 'utils';
import common from './common';
import development from './development';
import staging from './staging';
import production from './production';

const overrides = rejectBlank({
  domain: process.env.REACT_APP_DOMAIN,
  apiHost: process.env.REACT_APP_API_URL,
  landingHost: process.env.REACT_APP_LANDING_URL,
  dashboardHost: process.env.REACT_APP_DASHBOARD_URL,
});

const configEnv = process.env.REACT_APP_CONFIG || 'development';
const config = {
  development,
  staging,
  production,
}[configEnv];

console.debug('[Config]', { ...common, ...config, ...overrides });

export default {
  ...common,
  ...config,
  ...overrides,
};
