import React from 'react';
import * as Recompose from 'recompose';
import CardLayout from 'components/cards/preview/layout';
import Header from 'components/headers/card-preview-header';
import { Title, Description } from 'components/cards/preview/styles';
import Scrollable from 'components/cards/preview/scrollable';
import CallToActionButtons from 'components/cards/forms/call-to-action-buttons';
import { fixLineBreakSpacers } from 'utils';

function SimpleCard(props) {
  const { currentAccount, card, cardIndexInDeck, isCardVisible } = props;
  return (
    <CardLayout
      hasFooterOverlay={card.hasBackgroundGradient}
      card={card}
      isCardVisible={isCardVisible}
    >
      <Header
        currentAccount={currentAccount}
        card={card}
        cardIndexInDeck={cardIndexInDeck}
        isVisible={card.editable}
      />
      <Scrollable cardId={card.id} verticalAlign="top" xPadding="20px">
        {card.title && <Title children={fixLineBreakSpacers(card.title)} />}
        {card.description && <Description children={fixLineBreakSpacers(card.description)} />}
        <CallToActionButtons
          brandColor={card.buttonColor || currentAccount.profile.brandColor}
          ctas={card.ctas}
          isPreview
        />
      </Scrollable>
    </CardLayout>
  );
}

export default Recompose.withProps(({ card }) => ({ hasCtas: card.ctas.length }))(SimpleCard);
