import { Modal } from 'components/ui';
import { Button, Heading, Text } from 'components/web-ui';
import React from 'react';
import { branch, compose, pure, renderComponent } from 'recompose';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const PrimaryHeading = styled(Heading)`
  text-align: center;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  line-height: 1.5;
  margin: 17px auto 5% auto;
  width: 90%;
`;

const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  color: white;
  border-radius: 100px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1rem;
  height: 4rem;
`;

function PlanChangedSuccessModal(props) {
  const { onClickClose, product, plan } = props;
  return (
    <Modal onClickClose={onClickClose}>
      <Layout>
        <PrimaryHeading>
          <Text children="Thanks for subscribing to the" />
          <strong>
            {product.name} - {plan.interval === 'month' ? 'Monthly' : 'Yearly'} Plan
          </strong>
          <Text children="We’re glad you’re here!" />
        </PrimaryHeading>
        <ConfirmButton children="Continue" onClick={onClickClose} />
      </Layout>
    </Modal>
  );
}

export default compose(
  //
  branch(
    ({ isActive }) => !isActive,
    renderComponent((props) => null),
  ),
  pure,
)(PlanChangedSuccessModal);
