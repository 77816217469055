import * as Sentry from '@sentry/browser';
import set from 'lodash/set';
import { graphql } from '@apollo/react-hoc';
import { createCardMutation } from 'lib/graphql/mutations';
import { defaultDeckQuery } from 'lib/graphql/queries';

export default graphql(createCardMutation, {
  props: ({ mutate, ownProps }) => ({
    handleCardFormSubmit: async (event, cardKind, deckId) => {
      event.preventDefault();
      const newCardVariables = {
        title: '',
        description: '',
        kind: cardKind,
        ctas: [],
        images: [],
      };
      try {
        let cardId = null;
        await mutate({
          mutation: createCardMutation,
          variables: { cardInputArguments: newCardVariables, deckId },
          update: (proxy, { data: createCard }) => {
            const card = createCard.createCard.card;
            // We need to refresh the default deck so that this card is available within the deck
            try {
              cardId = card.id;

              const oldDeckQuery = proxy.readQuery({
                query: defaultDeckQuery,
                variables: { accountUsername: ownProps.match.params.accountUsername },
              });

              let newCards = oldDeckQuery.defaultDeck.activeCards;
              newCards.unshift(card);
              const newDeckQuery = { ...set(oldDeckQuery, 'defaultDeck.activeCards', newCards) };

              proxy.writeQuery({
                query: defaultDeckQuery,
                data: { ...newDeckQuery },
              });
            } catch (error) {
              console.error(error);
            }
          },
        });
        return cardId;
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    },
  }),
});
