import React from 'react';
import { DeletedTrendItemMessage } from 'components/cards/stats/elements/shared';

const DeletedTrendItem = ({ separator }) => (
  <DeletedTrendItemMessage separator={separator}>
    Sorry, this item has been removed and is no longer available
  </DeletedTrendItemMessage>
);

export default DeletedTrendItem;
