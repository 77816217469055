import React from 'react';
import { compose, omitProps } from 'lib/recompose';
import { Text } from 'components/web-ui';
import CardLayout from 'components/cards/preview/layout';
import Header from 'components/headers/card-preview-header';
import TextareaAutosize from 'react-autosize-textarea';
import { TitleSmall, DescriptionSmall } from 'components/cards/preview/styles';
import { fixLineBreakSpacers } from 'utils';
import {
  BaseCSS,
  DescriptionSmallCSS,
  InputCSS,
  InputPlaceholderCSS,
  TitleSmallCSS,
  StyledField,
} from 'components/cards/shared/styles/fields';
import Scrollable from 'components/cards/preview/scrollable';
import { connect } from 'react-redux';
import { setSelectedPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';
import NotConfigured from './not-configured';
import Tweets from './tweets';
import SubscribeButton from './subscribe-button';

const InlineTitleTextarea = styled.textarea`
  ${InputCSS};
  ${InputPlaceholderCSS};
  ${BaseCSS};
  ${TitleSmallCSS};
`.withComponent(omitProps('hasErrors')(TextareaAutosize));

const InlineDescriptionTextarea = styled.textarea`
  ${InputCSS};
  ${InputPlaceholderCSS};
  ${BaseCSS};
  ${DescriptionSmallCSS};
`.withComponent(omitProps('hasErrors')(TextareaAutosize));

const FieldsContainer = styled.div`
  display: flex;
  bottom: 0;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const HeaderTwitterHandle = styled(Text)`
  font-size: 13px;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  margin: 0 0 15px 0;
  text-align: center;
`;

// --- The Component ---

function TwitterCard(props) {
  const {
    currentAccount,
    card,
    cardIndexInDeck,
    isCardVisible,
    renderHeader,
    renderField,
    renderFormFields,
  } = props;
  const username = card.twitter ? card.twitter.username : null;
  const feed = card.twitter ? card.twitter.feed : null;

  return (
    <CardLayout
      card={card}
      hasFooterOverlay={card.hasBackgroundGradient}
      hasHeaderOverlay={false}
      hasBackgroundOverlay={false}
      hasTransparentBackground={false}
      isCardVisible={isCardVisible}
    >
      {renderHeader && (
        <Header
          currentAccount={currentAccount}
          card={card}
          cardIndexInDeck={cardIndexInDeck}
          isVisible={card.editable}
        />
      )}
      <Scrollable
        bottomPadding="0"
        cardId={card.id}
        topPadding="68px"
        verticalAlign="top"
        xPadding="5px"
      >
        <FieldsContainer>
          {renderFormFields ? (
            <React.Fragment>
              {renderField({
                name: 'result.title',
                placeholder: 'Tap to add headline',
                selectAllOnFocus: true,
                styledInput: InlineTitleTextarea,
                styledField: StyledField,
              })}
              {renderField({
                name: 'result.description',
                placeholder: 'Tap to add body',
                selectAllOnFocus: true,
                styledInput: InlineDescriptionTextarea,
                styledField: StyledField,
              })}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {card.title && <TitleSmall children={fixLineBreakSpacers(card.title)} />}
              {card.description && (
                <DescriptionSmall children={fixLineBreakSpacers(card.description)} />
              )}
            </React.Fragment>
          )}
          {card.twitter ? (
            <React.Fragment>
              <HeaderTwitterHandle children={`${feed} by @${username}`} />
              <SubscribeButton
                backgroundColor={card.buttonColor || '#059FF5'}
                href={`https://twitter.com/${username}`}
                target="_blank"
              />
              <Tweets username={username} feed={feed} />
            </React.Fragment>
          ) : (
            <NotConfigured card={card} />
          )}
        </FieldsContainer>
      </Scrollable>
    </CardLayout>
  );
}

TwitterCard.defaultProps = {
  renderFormFields: false,
};

const withRedux = connect(
  (state) => ({ deckViewstate: state.deckViewstate }),
  (dispatch) => ({ setSelectedPopover: (popover) => dispatch(setSelectedPopover(popover)) }),
);

export default compose(withRedux)(TwitterCard);
