import React from 'react';
import styled from 'styled-components/macro';

const ScreenContainer = styled.div`
  padding: ${(p) => p.p}px;
  display: flex;
  background-color: white;
  flex-direction: column;
  height: 100%;
`;

ScreenContainer.defaultProps = {
  p: 10,
};

export default ({ children, p }) => <ScreenContainer p={p} children={children} />;
