export const fetchParams = {
  fetchPolicy: 'cache-and-network',
  notifyOnNetworkStatusChange: true,
  pollInterval: 60000,
};

export const searchParams = {
  '1D': ['HOURLY', 24],
  '1W': ['DAILY', 7],
  '1M': ['DAILY', 31],
  ALL: ['YEARLY', 10],
  DEF: ['HOURLY', 24],
};
