import update from 'immutability-helper';
import isNull from 'lodash/isNull';
import set from 'lodash/set';
import { withProps } from 'recompose';

function ctaIsPersisted(ctaId) {
  return !String(ctaId).startsWith('new-');
}

export const handleDeleteCta =
  ({ handleDestroyCallToAction, removeCallToActionFromState }) =>
  async (event, id) => {
    event.preventDefault();
    // The delete icon is contained within the button to edit the CTA. If the delete icon is
    // clicked we should not let the click event propagate through to the button.
    event.stopPropagation();
    if (ctaIsPersisted(id)) {
      const success = await handleDestroyCallToAction(id);
      if (success) removeCallToActionFromState(id);
    } else {
      removeCallToActionFromState(id);
    }
  };

export const handleShowCtaForm =
  ({ initializeNewCallToAction, setSelectedPopover, setSelectedCallToAction }) =>
  (id, event) => {
    event.preventDefault();
    if (isNull(id)) {
      id = initializeNewCallToAction();
    }
    setSelectedPopover('link');
    setSelectedCallToAction(id);
  };

const withCallToActionStateHandlers = withProps(({ setFormState, cta }) => ({
  initializeNewCallToAction() {
    const id = `new-${Math.random().toString(36).substring(7)}`;
    setFormState((state) => {
      let newState = set({ ...state }, `entities.ctas.${id}`, { id, title: '', url: '' });
      return set({ ...newState }, 'result.ctas', [...state.result.ctas, id]);
    });
    return id;
  },

  // The deck is properly updated in Apollo's cache, but we need to also remove the
  // appropriate CTA on the form's internal (normalized) state.
  // We could have the form listen for changes in it's `card` prop and update the form's
  // state then, but I've done this before and it's generally a bad idea to have multiple
  // sources of truth.
  removeCallToActionFromState(ctaId) {
    setFormState((state) => {
      const ctaIndexToRemove = state.result.ctas.indexOf(ctaId);
      const newState = update(state, { result: { ctas: { $splice: [[ctaIndexToRemove, 1]] } } });
      delete newState.entities.ctas[ctaId];
      return newState;
    });
  },

  resetCallToActionState(cta) {
    // No need to do state.result.ctas as the CTA should still exist,
    // we're just resetting its values.
    setFormState((state) => set({ ...state }, `entities.ctas.${cta.id}`, cta));
  },
}));

export default withCallToActionStateHandlers;
