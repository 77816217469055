import { withApollo } from '@apollo/react-hoc';
import ViewHeader from '@tap-bio/ui/patterns/view-header';
import {
  Footer as BaseFooter,
  Layout as BaseLayout,
  Main as BaseMain,
  Link,
} from 'components/cards/forms/shared-ui';
import routes from 'config/routes';
import withCallToActionStateHandlers from 'hocs/with-call-to-action-state-handlers';
import withDefaultDeck from 'hocs/with-default-deck';
import withDestroyCardMutation from 'hocs/with-destroy-card-mutation';
import withUpdateCardMutation from 'hocs/with-update-card-mutation';
import set from 'lodash/set';
import React from 'react';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import styled from 'styled-components';
import MailingProviderOptions from './provider-options';
import MailingProviderSelector from './selector-mailing-provider';
import { isMailChimpSettingsValid } from './setup-mailchimp';
import { isZapierSettingsValid } from './setup-zapier';

const providerNames = {
  zapier: 'Zapier',
  mailchimp: 'MailChimp',
  constantcontact: 'ConstantContact',
};

const providerSignupUrls = {
  zapier: 'https://zapier.com/sign-up/',
  mailchimp: 'https://login.mailchimp.com/signup/',
  constantcontact: 'https://www.constantcontact.com/signup.jsp?pn=tapbio',
};

const Layout = styled(BaseLayout)`
  padding: 0;
`;

const Main = styled(BaseMain)`
  padding: ${20 / 16}rem 1rem;
`;

const Footer = styled(BaseFooter)`
  padding: ${20 / 16}rem 1rem;
`;

function SetupMailingList(props) {
  const { providerName, providerSignupUrl, provider, onProviderChange, isValid, onDone, onCancel } =
    props;
  return (
    <Layout>
      <ViewHeader>
        <ViewHeader.LeftButton onClick={onCancel} />
        <ViewHeader.Title children="Mailing List Card" />
        <ViewHeader.RightButton disabled={!isValid} onClick={onDone} />
      </ViewHeader>
      <Main>
        <MailingProviderSelector provider={provider} onChange={onProviderChange} />
        <MailingProviderOptions {...props} />
      </Main>
      <Footer>
        Don't have a {providerName} account? Not to worry! You can sign up{' '}
        <Link href={providerSignupUrl} target="_blank">
          here
        </Link>
      </Footer>
    </Layout>
  );
}

const withValidation = withProps((props) => ({
  isValid: !!(isZapierSettingsValid(props) && isMailChimpSettingsValid(props)),
}));

const withSettingsHandlers = withHandlers({
  onCancel:
    ({
      wasNotReady,
      setFormState,
      setSettings,
      setProvider,
      card,
      formState,
      previousFormState,
      currentAccount,
      resetCallToActionState,
      handleCardDestroy,
      history,
    }) =>
    async (evt) => {
      evt && evt.preventDefault();

      if (!wasNotReady) {
        const id = formState.result.mailingList;
        const mailingList = previousFormState.entities.mailingLists[id];
        setFormState((state) =>
          set({ ...state }, `entities.mailingLists.${id}.kind`, mailingList.kind),
        );
        setFormState((state) =>
          set({ ...state }, `entities.mailingLists.${id}.url`, mailingList.url),
        );
        return setSettings(false);
      }

      await handleCardDestroy(card.id, currentAccount.username);
      const url = routes.account(currentAccount.username);
      history.push(url);
    },
  onDone: (props) => async (evt) => {
    const { data, formState, handleCardFormSubmit, setSettings } = props;

    evt && evt.preventDefault();
    await handleCardFormSubmit(formState, data.defaultDeck.id);
    setSettings(false);
  },
  onProviderChange:
    ({ formState, setFormState }) =>
    (provider) => {
      const id = formState.result.mailingList;
      setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.kind`, provider));
      setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.url`, ''));
    },
});

const withSettingsProps = withProps(({ formState }) => {
  const id = formState.result.mailingList;
  const { kind: provider } = formState.entities.mailingLists[id];
  const providerName = providerNames[provider];
  const providerSignupUrl = providerSignupUrls[provider];
  return {
    provider,
    providerName,
    providerSignupUrl,
  };
});

export default compose(
  withApollo, // Required for `withDestroyCardMutation`
  withCallToActionStateHandlers,
  withDefaultDeck,
  withDestroyCardMutation,
  withUpdateCardMutation,
  withValidation,
  withSettingsProps,
  withSettingsHandlers,

  lifecycle({
    componentDidMount() {
      const { formState, setFormState, initializeNewCallToAction } = this.props;
      if (formState.result.ctas.length === 0) {
        const id = initializeNewCallToAction();
        setFormState((state) => set({ ...state }, `entities.ctas.${id}.title`, 'Sign up'));
        setFormState((state) => set({ ...state }, `entities.ctas.${id}.url`, 'https://tap.bio'));
        setFormState((state) =>
          set({ ...state }, `entities.ctas.${id}.message`, 'Thanks for signing up'),
        );
      }
    },
  }),
)(SetupMailingList);
