import React from 'react';
import ErrorBoundary from 'components/errors/error-boundary';
import { Link } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import * as Styled from './styled';
import CardListItem from './card-list-item';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

const SortableRow = SortableElement(({ card, currentAccount, features }) => (
  <CardListItem key={card.id} card={card} currentAccount={currentAccount} features={features} />
));

const SortableRows = SortableContainer(({ cards, currentAccount, features }) => (
  <div>
    {cards.map((card, index) => (
      <SortableRow
        card={card}
        currentAccount={currentAccount}
        disabled={!card.moveable}
        features={features}
        index={index}
        key={card.id}
      />
    ))}
  </div>
));

function SortableCards(props) {
  return (
    <ErrorBoundary>
      <Styled.StyleSheet />
      <Styled.Layout>
        <ViewHeader>
          <ViewHeader.LeftButton as={Link} to={props.backURL} />
          <ViewHeader.Title children="All Cards" />
          <ViewHeader.RightButton children="" />
        </ViewHeader>
        <Styled.Scrollable>
          <SortableRows
            cards={props.sortedCards}
            currentAccount={props.currentAccount}
            features={props.currentUser.features}
            helperClass="sortableRow"
            lockAxis="y"
            onSortEnd={props.onSortEnd}
            useDragHandle
          />
        </Styled.Scrollable>
      </Styled.Layout>
    </ErrorBoundary>
  );
}

export default SortableCards;
