import { Input as BaseInput } from 'components/web-ui';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import { compose, omitProps, withHandlers, withProps } from 'lib/recompose';
import set from 'lodash/set';
import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const Input = styled.input`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  ${fieldBorderCSS};
`.withComponent(omitProps('hasErrors')(BaseInput));

const LinkButton = styled.button`
  border: none;
  display: inline;
  color: #341552;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;
`;

function ZapierWizard(props) {
  const { onURLChange, onSetupInstructionClick, url } = props;
  return (
    <Wrapper>
      <Input
        name="webhook"
        placeholder="https://hooks.zapier.com/hooks/..."
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck="false"
        autoComplete="on"
        value={url}
        onChange={onURLChange}
      />
      <p>
        <span>Read instructions for</span>
        <LinkButton onClick={onSetupInstructionClick}>setting up Zapier</LinkButton>
      </p>
    </Wrapper>
  );
}

const withWebHookURL = withProps(({ formState }) => {
  const { entities, result } = formState;
  const { mailingList: id } = result;
  const { url } = entities.mailingLists[id];
  return { url };
});

export const isZapierProvider = (props) => props.provider === 'zapier';
export const isZapierSettingsValid = ({ provider, formState }) => {
  if (provider !== 'zapier') {
    return true;
  }
  const { entities, result } = formState;
  const { mailingList: id } = result;
  const mailingList = entities.mailingLists[id];
  return (
    typeof mailingList.url === 'string' &&
    mailingList.url.indexOf('https://hooks.zapier.com/hooks/') === 0
  );
};

export default compose(
  withWebHookURL,
  withIdentityAccounts,
  withHandlers({
    onURLChange:
      ({ card, formState, setFormState, initializeNewCallToAction, resetCallToActionState }) =>
      (evt) => {
        const value = evt.target.value;
        const id = formState.result.mailingList;
        setFormState((state) => set({ ...state }, `entities.mailingLists.${id}.url`, value));
      },
    onSetupInstructionClick: (props) => (evt) => {
      evt && evt.preventDefault();
      window.open('https://zapier.com/apps/webhook/integrations', '_blank');
    },
  }),
)(ZapierWizard);
