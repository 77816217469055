import querystring from 'querystring';
import React from 'react';
import { compose, withHandlers } from 'lib/recompose';
import { GradientButton } from 'components/cards/forms/shared-ui';
import config from 'config';
import routes from 'config/routes';
import styled from 'styled-components/macro';
import { Wrapper, Description } from './styles';

const Button = styled(GradientButton)`
  border-radius: 4rem;
  padding: 1.5rem;
  color: white;
`;

function Authorize(props) {
  const { onButtonClick } = props;
  return (
    <Wrapper>
      <Description>Start by connecting your ConstantContact account.</Description>
      <Button
        start="#367aa7"
        end="#003d6c"
        children="Add ConstantContact"
        onClick={onButtonClick}
      />
    </Wrapper>
  );
}

const withOAuthLinkHandler = withHandlers({
  onButtonClick: (props) => (evt) => {
    evt && evt.preventDefault();

    const provider = 'constantcontact';
    const redirectTo = props.location.pathname;
    const accountId = props.currentAccount ? props.currentAccount.id : props.accounts[0].id;
    const username = props.currentAccount
      ? props.currentAccount.username
      : props.accounts[0].username;

    const params = {
      redirect_uri: `${window.location.origin}${routes.oauthConstantContactCallbackPath}`,
      client_id: config.constantcontact.clientId,
      response_type: 'code',
    };

    localStorage.setItem(
      config.CONSTANTCONTACT_AUTH_LS_KEY,
      JSON.stringify({ redirectTo, accountId, username, provider }),
    );

    const qs = querystring.stringify(params);
    const url = 'https://oauth2.constantcontact.com/oauth2/oauth/siteowner/authorize?' + qs;

    window.location.assign(url);
  },
});

export default compose(withOAuthLinkHandler)(Authorize);
