import React, { useCallback } from 'react';
import Header from 'components/cards/forms/profile/header-background-cropper';
import Spinner from 'components/ui/spinner';
import * as Styled from './styled';
import RotateIcon from 'components/icons/rotate';

// const measuredRef = ;

// const PreviewContainer = Styled.PreviewContainer;

function CropBackgroundPhotoComponent(props) {
  const {
    boundryTop,
    boundryRight,
    boundryBottom,
    boundryLeft,
    containerWidth,
    containerHeight,
    registerChild,
    rotateImageSource,
    imageId,
    imageSource,
    imageScale,
    imageMinScale,
    imageEnableResizing,
    previewContainerStyle,
    previewImageStyle,
    uploadComplete,
    uploadProgress,
    isDragging,
    isZooming,
    onSliderFocus,
    onSliderBlur,
    onTouchStart,
    onTouchEnd,
    onTouchMove,
    onScaleChange,
    onDone,
    onCancel,
  } = props;

  return (
    <Styled.Layout key={imageId}>
      <Header onDone={onDone} onCancel={onCancel} allowResizing={imageEnableResizing} />
      <Styled.PreviewContainer
        ref={useCallback(registerChild, [registerChild])}
        isDragging={isDragging}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onMouseDown={onTouchStart}
        onMouseUp={onTouchEnd}
        onMouseMove={onTouchMove}
        style={previewContainerStyle}
      >
        <Styled.Image src={imageSource} style={previewImageStyle} />
        {imageEnableResizing && (
          <Styled.Viewport
            top={boundryTop}
            right={boundryRight}
            bottom={boundryBottom}
            left={boundryLeft}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            previewing={isDragging || isZooming}
          />
        )}
        {!uploadComplete && uploadProgress < 100.0 && (
          <Styled.PhotoUploadingSpinnerContainer>
            <Spinner width={40} />
          </Styled.PhotoUploadingSpinnerContainer>
        )}
      </Styled.PreviewContainer>
      {imageEnableResizing && (
        <React.Fragment>
          <Styled.SliderContainer>
            <Styled.Slider
              type="range"
              step={0.0001}
              min={imageMinScale}
              max={2.0}
              value={`${imageScale}`}
              onTouchStart={onSliderFocus}
              onTouchEnd={onSliderBlur}
              onMouseDown={onSliderFocus}
              onMouseUp={onSliderBlur}
              onChange={onScaleChange}
            />
          </Styled.SliderContainer>
          <Styled.RotateButton onClick={rotateImageSource}>
            <RotateIcon />
          </Styled.RotateButton>
        </React.Fragment>
      )}
    </Styled.Layout>
  );
}

export default CropBackgroundPhotoComponent;
