import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { lifecycle } from 'recompose';

const defaultMaxRequestCount = 9;

export default lifecycle({
  async componentWillReceiveProps(nextProps) {
    const { requestManager, hasDefaultLink } = nextProps;
    const { instagramRequestCount, morePhotosToLoad } = requestManager;

    // Don't auto-iterate if there's a default link - we'll already be showing 33 photos
    if (hasDefaultLink) return;

    // Only auto-iterate instagram if the appropriate data has changed
    // i.e. there's been a request for IG data since the last render.
    const keysToCompare = ['instagramRequestCount', 'morePhotosToLoad', 'nextMaxId'];
    const obj1 = pick(requestManager, keysToCompare);
    const obj2 = pick(this.props.requestManager, keysToCompare);
    if (isEqual(obj1, obj2)) return;

    if (
      instagramRequestCount < (requestManager.maxRequestCount || defaultMaxRequestCount) &&
      morePhotosToLoad
    ) {
      await nextProps.loadPhotos();
    }
  },
});
