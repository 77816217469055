import { Modal } from 'components/ui';
import { ButtonCircle, Heading } from 'components/web-ui';
import difference from 'lodash/difference';
import React from 'react';
import { branch, compose, pure, renderComponent, withProps } from 'recompose';
import styled from 'styled-components/macro';
import { withCurrentProductAndPlan, withPriceFormatting } from './hocs';
import { augmentPlanWithDiscount, findDiscount } from './lib';
import LostFeatures from './lost-features';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const PrimaryHeading = styled(Heading)`
  text-align: center;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  line-height: 1.5;
  margin: 25px auto 8% auto;
  width: 90%;
`;

const ConfirmButton = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  font-weight: bold;
  color: white;
  width: 100%;
  height: 4rem;
  font-size: 1rem;
`;

// A few situations to handle here:
//
// - Changing pricing from yearly to monthly and vice versa
// - Upgrading a plan
// - Downgrading a plan

const SelectPlanConfirmModal = ({
  currentPlanStripeId,
  currentProductStripeId,
  lostFeatures,
  message,
  newPlan,
  newProduct,
  onClickClose,
  onClickConfirm,
  plans,
}) => (
  <Modal onClickClose={onClickClose}>
    <Layout>
      <PrimaryHeading dangerouslySetInnerHTML={{ __html: message }} />
      <LostFeatures features={lostFeatures} />
      <ConfirmButton children="Confirm" onClick={onClickConfirm} />
    </Layout>
  </Modal>
);

const withMessage = withProps(
  ({
    activeFeatures,
    currentPlanStripeId,
    currentProductStripeId,
    newPlan,
    newProduct,
    products,
    priceClarification,
    discount,
  }) => {
    let message = `Are you sure you want to change to <b>${newProduct.name}</b>?`;
    let lostFeatures = [];
    const interval = (i) => (i === 'year' ? 'yearly' : 'monthly');
    const newPricing = priceClarification(
      newPlan.interval,
      newPlan.discountedAmount >= 0 ? newPlan.discountedAmount : newPlan.amount,
      discount,
    );
    const plans = products.map((i) => i.plans).reduce((acc, val) => acc.concat(val), []);
    const oldPlan = plans.find((plan) => plan.stripeId === currentPlanStripeId);
    const oldPricing = priceClarification(oldPlan.interval, oldPlan.amount);
    const oldProduct = products.find((product) => currentProductStripeId);

    // If the product is not changing this must be a pricing interval change
    if (newProduct.stripeId === currentProductStripeId) {
      message = `
        Are you sure you want to change your <b>${newProduct.name}</b> billing interval from
        ${interval(oldPlan.interval)} <b>(${oldPricing})</b> to
        ${interval(newPlan.interval)} <b>(${newPricing})</b>?
      `.trim();

      // If the product is changing
    } else {
      message += ` Your new pricing will be <b>${newPricing}</b>.`;
      lostFeatures = difference(
        activeFeatures.filter((feature) => oldProduct.features.includes(feature.id)),
        activeFeatures.filter((feature) => newProduct.features.includes(feature.id)),
      );
    }

    return { message, lostFeatures };
  },
);

const withDiscount = withProps(({ currentUser: { discounts }, newPlan: { stripeId } }) =>
  findDiscount(discounts, stripeId),
);

const withDiscountedPlan = withProps(({ discount, newPlan }) => {
  return {
    ...augmentPlanWithDiscount(discount, newPlan, 'newPlan'),
    discount,
  };
});

export default compose(
  //
  branch(
    ({ isActive }) => !isActive,
    renderComponent((props) => null),
  ),
  withCurrentProductAndPlan,
  withPriceFormatting,
  withDiscount,
  withDiscountedPlan,
  withMessage,
  pure,
)(SelectPlanConfirmModal);
