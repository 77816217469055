import React from 'react';
import styled from 'styled-components/macro';
import { formatter } from 'components/cards/stats/helpers/date';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Clickable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colors.primaryPurple : '#C1B9C9')};
`;

const NumberFormatter = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const DisplayName = styled.div`
  font-size: 14px;
`;

const Counter = ({ active, total, name, loading, onClick }) => (
  <Container>
    <Clickable active={active} onClick={onClick}>
      <NumberFormatter children={loading ? '•••' : formatter(total)} />
      <DisplayName children={name} />
    </Clickable>
  </Container>
);

export default Counter;
