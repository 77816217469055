import React from 'react';
import * as Recompose from 'recompose';
import { PictureIcon } from 'components/icons';
import * as Redux from 'react-redux';
import { setSelectedPopover } from 'store/modules/card-popovers';
import { setUploadedBackgroundUrl } from 'store/modules/card-upload-background';
import * as Styled from './styled';

PictureIcon.defaultProps = {
  width: 38,
  height: 18,
};

export default Recompose.compose(
  Redux.connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setUploadedBackgroundUrl: (url) => dispatch(setUploadedBackgroundUrl(url)),
  })),

  Recompose.withProps((props) => {
    return {
      children: React.createElement(PictureIcon),
    };
  }),

  Recompose.withHandlers({
    onClick: (props) => (event) => {
      const { backgroundMediaUrl, defaultBackgroundMediaUrl } = props;

      event.preventDefault();

      props.setUploadedBackgroundUrl(backgroundMediaUrl || defaultBackgroundMediaUrl);
      props.setSelectedPopover('background-image');
    },
  }),
)(Styled.HeaderButton);
