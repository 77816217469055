import React from 'react';
import styled from 'styled-components/macro';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import NumberFormatter from 'components/cards/stats/elements/number-formatter';
import BarChart from 'components/cards/stats/elements/bar-chart/index';
import enhance from 'components/cards/stats/cards/shared/enhancer';
import DeletedTrendItem from 'components/cards/stats/elements/deleted-trend-item';
import {
  TrendsItemWrapper,
  TrendsItemClickable,
  TrendsItemContainer,
  TrendsItemHeader,
  TrendsSummary,
} from 'components/cards/stats/elements/shared';

const TweetCreatedAt = styled.span`
  font-weight: normal;
`;

const TweetMessage = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.35;
`;

const date = (input) => {
  const datetime = parseISO(input);
  return format(datetime, 'MMM do');
};

const Tweet = ({ unit, graph, events, summary, username, total, tweet }) => (
  <TrendsItemWrapper>
    {tweet ? (
      <TrendsItemClickable
        target="_blank"
        href={`https://twitter.com/${username}/status/${tweet.id}`}
      >
        <TrendsItemContainer>
          <TrendsItemHeader>
            @{username} - <TweetCreatedAt>{date(tweet.created_at)}</TweetCreatedAt>
          </TrendsItemHeader>
          <TweetMessage>{tweet.text}</TweetMessage>
        </TrendsItemContainer>
      </TrendsItemClickable>
    ) : (
      <DeletedTrendItem separator={!summary} />
    )}
    {!summary && <BarChart data={graph} unit={unit} />}
    <TrendsSummary>
      <NumberFormatter value={total} /> Clicks
    </TrendsSummary>
  </TrendsItemWrapper>
);

export default enhance(Tweet);
