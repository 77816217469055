import React from 'react';
import styled from 'styled-components/macro';
import NumberFormatter from 'components/cards/stats/elements/number-formatter';
import BarChart from 'components/cards/stats/elements/bar-chart/index';
import TwoLevelMetadata from 'components/cards/stats/elements/two-level-metadata';
import enhance from 'components/cards/stats/cards/shared/enhancer';
import DeletedTrendItem from 'components/cards/stats/elements/deleted-trend-item';
import {
  TrendsItemWrapper,
  TrendsItemContainer,
  TrendsItemHeader,
  TrendsBarChartContainer,
  TrendsSummary,
} from 'components/cards/stats/elements/shared';

const ImageThumbnail = styled.a`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  display: block;
  background-image: url(${(props) => props.src});
  background-color: #e6e3ea;
  background-size: cover;
  border-radius: 5px;
  margin-right: 10px;
`;

const Photo = ({ feedItem, summary, graph, unit, total }) => (
  <TrendsItemWrapper>
    {feedItem ? (
      <TrendsItemContainer>
        <TrendsItemHeader>
          <ImageThumbnail
            src={feedItem.imageUrl}
            href={`https://${feedItem.feedUrl}`}
            target="_blank"
          />
          <TwoLevelMetadata
            title={feedItem.feedUrl}
            description={feedItem.href}
            href={feedItem.href}
          />
        </TrendsItemHeader>
      </TrendsItemContainer>
    ) : (
      <DeletedTrendItem />
    )}
    {!summary && (
      <TrendsBarChartContainer>
        <BarChart data={graph} unit={unit} />
      </TrendsBarChartContainer>
    )}
    <TrendsSummary>
      <NumberFormatter value={total} /> Clicks
    </TrendsSummary>
  </TrendsItemWrapper>
);

export default enhance(Photo);
