import React from 'react';
import { compose } from 'recompose';
import BaseButton from 'components/ui/button';
import styled from 'styled-components/macro';
import { YoutubeIconTransparent } from 'components/icons';
import { withRouter } from 'react-router';
import withReduxState from './hocs/with-redux-state';
import withButtonClickHandler from './hocs/with-button-click-handler';

// --- Styled Components ---

const Button = styled.div`
  color: white;
  width: 55%;
  padding: 10px 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.backgroundColor};

  span {
    margin-left: 8px;
  }

  :active,
  :focus {
    background-color: ${(props) => props.backgroundColor};
    opacity: 0.7;
    color: white;
    outline: none;
  }

  :active {
    background-color: ${(props) => props.backgroundColor};
  }
`.withComponent(({ backgroundColor, ...props }) => <BaseButton {...props} />);

const Icon = styled.div`
  path {
    fill: #ffffff;
  }
  #youtube-icon-chevron {
    fill: ${(props) => props.backgroundColor};
  }
`.withComponent(({ backgroundColor, ...props }) => <div {...props} />);

// --- Component ---

function SubscribeButton(props) {
  const { backgroundColor, handleShowCustomSettings } = props;
  return (
    <Button backgroundColor={backgroundColor} onClick={handleShowCustomSettings}>
      <Icon backgroundColor={backgroundColor}>
        <YoutubeIconTransparent width={24} height={16} />
        <span>Subscribe</span>
      </Icon>
    </Button>
  );
}

export default compose(
  //
  withReduxState,
  withRouter,
  withButtonClickHandler,
)(SubscribeButton);
