import { Text } from 'components/web-ui';
import React from 'react';
import { branch, compose, pure, renderComponent } from 'recompose';
import styled from 'styled-components/macro';

const ErrorContainer = styled.div`
  color: white;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.33);
  border-radius: 5px;
  padding: 10px;
`;

function ErrorMessage(props) {
  const { errorType, errorMessages } = props;
  return (
    <ErrorContainer>
      <Text children={errorMessages[errorType] || 'Sorry, we could not load these photos.'} />
    </ErrorContainer>
  );
}

ErrorMessage.defaultProps = {
  errorMessages: {
    InstagramAuthenticate: "Sorry, we couldn't access your Instagram account.",
    UserIdentityHasWrongProvider:
      "Sorry, we couldn't load these photos. Please try logging in again, or contact support.",
    InstagramRequestFailed: 'Sorry, Instagram is not responding right now.',
  },
};

export default compose(
  //
  branch(
    ({ errorType }) => !errorType,
    renderComponent((props) => null),
  ),
  pure,
)(ErrorMessage);
