import React from 'react';
import ViewHeader from '@tap-bio/ui/patterns/view-header';
import { Layout, Content, Message } from './styled';

const OAuthAccessError = (props) => (
  <Layout>
    <ViewHeader>
      <ViewHeader.LeftButton children="Back" onClick={props.onBack} />
      <ViewHeader.Title children={props.provider} />
      <ViewHeader.RightButton children="" />
    </ViewHeader>
    <Content>
      <Message children={props.message} />
    </Content>
  </Layout>
);

export default OAuthAccessError;
