import styled from 'styled-components/macro';
import { deprecatedRebassProps } from './deprecated';

const Input = styled.input`
  margin: 0px;
  padding: 8px;
  width: 100%;
  font-size: 1rem;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  line-height: inherit;
  display: inline-block;
  vertical-align: middle;
  border: 0;
  appearance: none;
`;

Input.propTypes = {
  ...deprecatedRebassProps,
};
export default Input;
