import { joinPath, prefixPath } from 'utils';

const routes = {
  landingPage: (landingHost, accountUsername) => joinPath(landingHost, `@${accountUsername}`),
  accountSwitch: (accountUsername) => prefixPath('accounts', accountUsername, 'manage'),
  accountAccess: (accountUsername) => prefixPath('accounts', accountUsername, 'access'),
  accountNotifications: (accountUsername) =>
    prefixPath('accounts', accountUsername, 'notifications'),
  accountAdd: (accountUsername) => prefixPath('accounts', accountUsername, 'add'),
  accountChangeUsername: (accountUsername) =>
    prefixPath('accounts', accountUsername, 'change-username'),
  accounts: prefixPath('accounts'),
  account: (accountUsername) => prefixPath('accounts', accountUsername),
  accountOnboarding: (accountUsername) => prefixPath('accounts', accountUsername, 'onboarding'),
  allCardsPath: (accountUsername) => prefixPath('accounts', accountUsername, 'cards/all'),
  accountCardPath: (accountUsername, cardId) =>
    prefixPath('accounts', accountUsername, 'cards', cardId),
  accountCardEditPath: (accountUsername, cardId) =>
    prefixPath('accounts', accountUsername, 'cards', cardId, 'edit'),
  accountCardSettingsPath: (accountUsername, cardId) =>
    prefixPath('accounts', accountUsername, 'cards', cardId, 'settings'),
  billing: {
    changeProduct: (accountUsername) =>
      prefixPath('accounts', accountUsername, 'billing/change-product'),
    selectPlan: (accountUsername, productStripeId) =>
      prefixPath('accounts', accountUsername, 'billing/select-plan', productStripeId),
    addCreditCard: (accountUsername, planId) =>
      prefixPath('accounts', accountUsername, 'billing/add-credit-card', planId),
    updateCreditCardChooser: prefixPath('billing/update-credit-card'),
    updateCreditCard: (accountUsername) =>
      prefixPath('accounts', accountUsername, 'billing/update-credit-card'),
  },
  newCardPath: (accountUsername) => prefixPath('accounts', accountUsername, 'cards/new'),
  dashboardPath: prefixPath(''),
  loginRedirectPath: prefixPath('accounts'),
  loginPath: '/sign-in',
  logoutRedirectPath: '/sign-in',
  logoutPath: prefixPath('sign-out'),
  registrationPath: '/sign-up',
  registrationWhitelistedPath: '/4@P-b10/sign-up',
  migrateFromLipPath: prefixPath('migrate-from-link-in-profile/:username'),
  oauthIGCallbackPath: '/auth/instagram/callback',
  oauthIGFailurePath: '/auth/instagram/error',
  oauthMailChimpCallbackPath: '/auth/mailchimp/callback',
  oauthMailChimpFailurePath: '/auth/mailchimp/error',
  oauthConstantContactCallbackPath: '/auth/constantcontact/callback',
  oauthConstantContactFailurePath: '/auth/constantcontact/error',
};

export default routes;
