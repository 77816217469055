import React from 'react';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Message = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: white;
`;

const Error = () => (
  <Layout>
    <Message>
      Wups! Something's not quite right here. Don't worry, we've recorded this and will be taking
      care of it shortly.
    </Message>
  </Layout>
);

export default Error;
