import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { omitProps } from 'lib/recompose';
import { Input as BaseInput } from 'components/web-ui';

const errorState = css`
  border-color: ${(props) => props.theme.colors.errorRed};
  color: ${(props) => props.theme.colors.errorRed};

  &:focus {
    border-color: ${(props) => props.theme.colors.errorRed};
    color: ${(props) => props.theme.colors.errorRed};
  }
`;

const TextInput = styled.input`
  border-radius: 6px;
  line-height: 1.2;
  border: 1px solid ${(props) => props.theme.colors.authForm.border};
  padding-left: 10px;
  padding-right: 10px;

  ::placeholder {
    color: ${(props) => props.theme.colors.alphaBlack40};
  }

  :focus {
    border: 1px solid ${(props) => props.theme.colors.veryDarkPurple};
    box-shadow: none;
  }

  ${(props) => props.hasErrors && errorState};
`.withComponent(omitProps('hasErrors', 'mb')(BaseInput));

TextInput.defaultProps = {
  mb: '5px',
};

export default TextInput;
