import React from 'react';
import { Button, Image as BaseImage } from 'components/web-ui';
import buttonClickPsuedoSelectors from 'components/styles/button-click-psuedo-selectors';
import logo from 'images/logo-purple.svg';
import styled from 'styled-components/macro';

const headerHeight = '60px';

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${headerHeight};
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.profileForm.headerBorder};
`;

const HeaderButton = styled(Button)`
  padding: 0.75rem 1rem;
  font-weight: normal;
  font-size: 17px;
  flex: 1;
  background-color: white;
  ${buttonClickPsuedoSelectors}
`;

const HeaderButtonLeft = styled(HeaderButton)`
  text-align: left;
`;

const HeaderButtonRight = styled(HeaderButton)`
  text-align: right;
`;

const Logo = styled(BaseImage)`
  flex: 1;
  max-width: 36px;
`;

const HeaderEditSocialAccounts = ({
  formState,
  handleSubmit,
  isPersisting,
  setShowEditSocialAccounts,
  ...rest
}) => (
  <Layout>
    <HeaderButtonLeft
      onClick={(e) => {
        e.preventDefault();
        setShowEditSocialAccounts(false);
      }}
      children="Cancel"
    />
    <Logo src={logo} alt="Logo" />
    <HeaderButtonRight children={isPersisting ? 'Saving...' : 'Save'} disabled={isPersisting} />
  </Layout>
);

export default HeaderEditSocialAccounts;
