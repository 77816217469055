import * as Sentry from '@sentry/browser';
import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const updateAccountProfileBackgroundImageMutation = gql`
  mutation updateAccountProfileBackgroundImage(
    $id: Int!
    $accountProfileBackgroundImageInputArguments: AccountProfileBackgroundImageInput!
  ) {
    updateAccountProfileBackgroundImage(
      id: $id
      accountProfileBackgroundImageInput: $accountProfileBackgroundImageInputArguments
    ) {
      profile {
        id
        defaultBackgroundMediaUrl
        brandColor
      }
    }
  }
`;

export default graphql(updateAccountProfileBackgroundImageMutation, {
  props: ({ mutate }) => ({
    handleUpdateProfileBackgroundImage: async (id, values) => {
      const { defaultBackgroundMediaUrl: url } = values;

      values.defaultBackgroundMediaUrl = typeof url === 'object' ? url[0] : url;

      try {
        await mutate({
          mutation: updateAccountProfileBackgroundImageMutation,
          variables: { id, accountProfileBackgroundImageInputArguments: { ...values } },
        });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    },
  }),
});
