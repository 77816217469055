import pick from 'lodash/pick';
import * as Sentry from '@sentry/browser';
import { graphql } from '@apollo/react-hoc';
import { updateUserMutation } from 'lib/graphql/mutations';

export default graphql(updateUserMutation, {
  props: ({ mutate, ownProps }) => {
    return {
      submit: async (rawInput) => {
        try {
          await mutate({
            variables: {
              // These are the only two fields allowed as per the graphQL input type for user
              input: pick(rawInput, 'id', 'onboarding'),
              accountId: (ownProps.currentAccount || {}).id,
            },
            // Update the UI immediately so that the onboarding prompt disappears.
            // If for any reason the save failed, the (correct) returned user will
            // be cached in Apollo and the optimistic UI reverted.
            optimisticResponse: {
              __typename: 'Mutation',
              updateUser: {
                __typename: 'UserUpdatePayload',
                user: {
                  ...rawInput,
                  accounts: [], // Temp data (will be updated when the mutation completes)
                  createdAt: 1234, // Temp data (will be updated when the mutation completes)
                },
              },
            },
          });
        } catch (error) {
          Sentry.captureException(error);
        }
      },
    };
  },
});
