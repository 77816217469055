import { createGlobalStyle } from 'styled-components';
import { tweakAt, toEm } from 'lib/css';
import theme from 'css/theme';

const { colors, viewport } = theme;
const { w: width, h: height } = viewport.breakpoints;

const StyleSheet = createGlobalStyle`
  /**
  * ------------------------------------------------ app-like view
  * fixed position – removes bouncy scroll from iOS
  * full-width/height
  */
  body {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-image: radial-gradient(56% 100%, #ffffff 25%, ${colors.viewport.silver} 150%);

    // Apply these styles when the width is ABOVE the given width
    @media ${tweakAt({ height })} {
      flex-direction: column;
      justify-content: center;
      display: flex;
    }

    // Apply these styles when the width is ABOVE the given height
    @media ${tweakAt({ width })} {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    @media ${tweakAt({ width: width * 2 })} {
      background-image: radial-gradient(56% 100%, #ffffff 25%, ${colors.viewport.silver} 125%);
    }

    @media ${tweakAt({ width: width * 2 })} {
      background-image: radial-gradient(56% 100%, #ffffff 25%, ${colors.viewport.silver} 100%);
    }
  }

  /**
  * ------------------------------------------------ application root
  * creates the 'frame' shape
  */
  #application-root {
    flex: 0 1 100%;
    height: 100%;
    width: 100%;

    background-color: rgba(101, 119, 134, 1);
    box-shadow: 0 25px 60px rgba(25, 29, 33, 0.5);

    display: flex;
    flex-direction: column;

    position: relative;
    z-index: 1;

    @media ${tweakAt({ width })} {
      flex: 0 1 ${toEm(viewport.w)};
      max-width: ${toEm(viewport.w)};
    }

    @media ${tweakAt({ height })} {
      max-height: ${toEm(viewport.h)};
    }

    @media ${tweakAt({ width, height })} {
      border-radius: 20px;
      overflow: hidden;
    }

    @media ${tweakAt({ width: width * 2, height: height * 2 })} {
      transform: scale3d(2, 2, 2);
    }

    @media ${tweakAt({ width: width * 3, height: height * 3 })} {
      transform: scale3d(3, 3, 3);
    }
  }

  /**
  * ------------------------------------------------ 'outside' of frame
  * Things that go outside of the frame view can
  * be rendered here.
  */
  #application-aside {
    display: inline;
  }
`;

export { StyleSheet };
export default StyleSheet;
