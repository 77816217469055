import React from 'react';

function Hide(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M 3.40625 2 L 2 3.40625 L 20.59375 22 L 22 20.59375 L 18.25 16.84375 C 20.929688 15.046875 22.695313 12.75 22.8125 12.59375 C 23.078125 12.238281 23.078125 11.761719 22.8125 11.40625 C 22.617188 11.144531 17.957031 5 12 5 C 10.386719 5 8.886719 5.460938 7.53125 6.125 Z M 12 7 C 14.761719 7 17 9.238281 17 12 C 17 13.027344 16.6875 13.957031 16.15625 14.75 L 14.3125 12.90625 C 14.425781 12.621094 14.5 12.324219 14.5 12 C 14.5 10.621094 13.382813 9.5 12 9.5 C 11.675781 9.5 11.378906 9.574219 11.09375 9.6875 L 9.25 7.84375 C 10.042969 7.3125 10.976563 7 12 7 Z M 4.90625 7.75 C 2.707031 9.425781 1.292969 11.265625 1.1875 11.40625 C 0.921875 11.761719 0.921875 12.238281 1.1875 12.59375 C 1.382813 12.855469 6.042969 19 12 19 C 13.226563 19 14.410156 18.730469 15.5 18.3125 L 13.8125 16.65625 C 13.246094 16.878906 12.644531 17 12 17 C 9.238281 17 7 14.761719 7 12 C 7 11.355469 7.121094 10.753906 7.34375 10.1875 Z M 9.53125 12.375 C 9.691406 13.460938 10.539063 14.308594 11.625 14.46875 Z " />
    </svg>
  );
}

Hide.defaultProps = {
  fill: 'currentColor',
  width: 24,
  height: 24,
};

export default Hide;
