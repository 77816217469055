import React from 'react';
import styled from 'styled-components/macro';
import CodeInput from 'components/authentication/code-input';
import RegistrationLayout from 'components/authentication/registration/layout';
import SubmitButton from 'components/authentication/submit-button';
import Spinner from 'components/ui/spinner';
import routes from 'config/routes';

const Hint = styled.p``;

function VerifyForm(props) {
  return (
    <RegistrationLayout backTo={routes.registrationPath}>
      <Hint>
        Just one more step. Check your email and enter your sign-in code here to create your
        account.
      </Hint>
      <CodeInput {...props} />
      <SubmitButton
        disabled={props.formErrors.any || props.formIsPersisting}
        onClick={props.onSubmit}
      >
        {props.formIsPersisting ? (
          <Spinner fill="rgba(255,255,255,1)" height="50px" />
        ) : (
          'Create my account'
        )}
      </SubmitButton>
    </RegistrationLayout>
  );
}

export default VerifyForm;
