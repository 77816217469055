import { Heading as HeadingBase } from 'components/web-ui';
import styled from 'styled-components/macro';

const Heading = styled(HeadingBase)`
  font-size: 27px;
  line-height: 1.2;
  margin: 1em 0 0.75em 0;
  color: ${(p) => p.theme.colors.primaryPurple};
`;

export default Heading;
