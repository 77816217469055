import React from 'react';
import Deck from 'components/decks/deck';
import ErrorBoundary from 'components/errors/error-boundary';

function Account(props) {
  const { currentAccount, currentUser, data } = props;

  return (
    <ErrorBoundary>
      <Deck deck={data.defaultDeck} currentAccount={currentAccount} currentUser={currentUser} />
    </ErrorBoundary>
  );
}

export default Account;
