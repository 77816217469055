import React from 'react';
import { compose, pure } from 'recompose';
import styled from 'styled-components/macro';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import displayLoadingState from 'hocs/display-loading-state';
import withAuthenticationCheck from 'hocs/with-authentication-check';
import LayoutBase from './base';

const Layout = styled(LayoutBase)`
  background-color: white;
`;

function LayoutOnboarding(props) {
  const { component: Component, ...rest } = props;
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
}

export default compose(
  withAuthenticationCheck,
  withIdentityAccounts,
  displayLoadingState,
  pure,
)(LayoutOnboarding);
