import React from 'react';
import { compose, withHandlers, withProps, withState } from 'recompose';
import routes from 'config/routes';
import { withRouter } from 'react-router';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import styled from 'styled-components/macro';
import Button from './ig-auth-button';
import ViewHeader from '@tap-bio/ui/patterns/view-header';

const Layout = styled.div`
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.primaryPurple};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Main = styled.div`
  padding: ${20 / 16}rem 2rem;
`;

const Footer = styled.div`
  padding: ${20 / 16}rem 2rem;
`;

const BackButton = compose(
  withState('isPersisting', 'setIsPersisting', false),
  withRouter,
  withIdentityAccounts,
  withProps((props) => ({
    cancelUrl: routes.accountCardPath(props.currentAccount.username, props.match.params.cardId),
  })),
  withHandlers({
    onClick: (props) => () => {
      props.history.push(props.cancelUrl);
    },
  }),
)(ViewHeader.LeftButton);

function AddInstagramIdentity(props) {
  return (
    <Layout>
      <ViewHeader>
        <BackButton />
        <ViewHeader.Title children="Connect Instagram" />
        <ViewHeader.RightButton children="" />
      </ViewHeader>
      <Main>
        {props.errors && <p>Problem</p>}
        <h1>
          Adding an <br />
          Instagram Links card
        </h1>
        <p>
          By clicking the button below, and logging into Instagram, you'll be enhancing your Tap Bio
          with data from Instagram, including your recent photo uploads.
        </p>
        <p>
          We'll use this data to display a scrollable list of clickable Instagram photos, wherever
          we find a link in the caption.
        </p>
      </Main>
      <Footer>
        <Button gradient ig text="Add Instagram" />
      </Footer>
    </Layout>
  );
}

const instagramIdentityIsMissing = (props) =>
  props.currentAccount.identities.every((identity) => identity.provider !== 'instagram');

export { instagramIdentityIsMissing };
export default AddInstagramIdentity;
