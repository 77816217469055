import { graphql } from '@apollo/react-hoc';
import routes from 'config/routes';
import { addAccountToCurrentIdentityMutation } from 'lib/graphql/mutations';
import { usernameSearchQuery } from 'lib/graphql/queries';
import { errors, forbiddenCharacters } from 'lib/validations';
import debounce from 'lodash/debounce';
import React from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import {
  branch,
  compose,
  pure,
  renderComponent,
  withHandlers,
  withProps,
  withState,
} from 'recompose';

// We're also protecting against this via the API
const withAccountLimitRedirect = branch(
  ({ currentUser }) => currentUser.features.accountCount <= currentUser.accounts.length,
  renderComponent(({ currentAccount }) => <Redirect to={{ pathname: routes.accounts }} />),
);

export default compose(
  withAccountLimitRedirect,
  withRouter,
  withState('formData', 'setFormData', {}),
  withState('queryParam', 'setQueryParam', ''),
  withState('responseData', 'setResponseData', {}),
  withState('throttledSetQueryParam', '', (props) =>
    debounce((value) => props.setQueryParam(value), 500),
  ),

  graphql(usernameSearchQuery, {
    options: (props) => ({
      variables: { username: props.queryParam || '' },
      fetchPolicy: 'no-cache',
    }),
    props: ({ data }) => ({
      data,
      accountNameTaken: !!data.publicAccount,
    }),
  }),

  graphql(addAccountToCurrentIdentityMutation, {
    props: ({ mutate, ownProps }) => ({
      handleFormSubmit: () => {
        const accountName = ownProps.formData.accountName;
        const currentAccountId = ownProps.currentAccount.id;

        return mutate({
          variables: {
            accountName,
            currentAccountId,
          },
          update: (proxy, { data: { error, account } }) => {
            const pathname = routes.accountSwitch(ownProps.currentAccount.username);
            if (error) return console.error(error);
            ownProps.history.replace(pathname, { needsRefetch: true });
          },
        });
      },
    }),
  }),

  withHandlers({
    validateAccountName:
      ({ formData, ...props }) =>
      () => {
        if (!formData.accountName) return;

        if (formData.accountName.match(forbiddenCharacters)) {
          return errors.ACCOUNT_NAME_INVALID;
        }

        props.throttledSetQueryParam(formData.accountName);

        if (props.accountNameTaken) {
          return errors.ACCOUNT_NAME_NON_UNIQUE;
        }
      },

    onClickBack: (props) => props.history.goBack,
  }),

  withProps((props) => {
    const accountNameErrors = props.validateAccountName(props);

    return {
      formErrors: {
        accountName: accountNameErrors,
        any: !!accountNameErrors || !props.formData.accountName,
      },
    };
  }),

  withProps(({ formData, formErrors, ...props }) => {
    return {
      formHints: {
        accountName: formData.accountName ? (
          formErrors.accountName ? (
            ''
          ) : (
            <span>
              Your Tap Bio link will be <b>tap.bio/@{formData.accountName}</b>
            </span>
          )
        ) : (
          'Claim your Tap Bio link.'
        ),
      },
    };
  }),

  withHandlers({
    onFieldChange:
      ({ setFormData }) =>
      (name, value) =>
        setFormData((state) => ({ ...state, [name]: value })),

    onSubmit: (props) => (event) => {
      event.preventDefault();
      props.handleFormSubmit(props);
    },
  }),

  pure,
);
