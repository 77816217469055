import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

const BaseTurnstile = styled.div`
  iframe {
    width: 100% !important;
    margin-bottom: 14px;
  }
`;

const Component = ({ onChange }) => {
  const turnstile = useRef(null);

  useEffect(() => {
    if (!window.turnstile) return;
    if (!turnstile.current) return;

    window.turnstile.render(turnstile.current, {
      sitekey: '0x4AAAAAAABX5Z__EHqHaCv4',
      theme: 'light',
      callback: function (token) {
        if (typeof onChange !== 'function') return;
        onChange(token);
      },
    });
  }, [turnstile, onChange]);

  return <BaseTurnstile ref={turnstile} />;
};

export default Component;
