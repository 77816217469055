import React from 'react';
import styled from 'styled-components/macro';
import ctaOnClickHandler from '@tap-bio/core/event-handlers/cta-on-click-handler';
import {
  CopyWrapper,
  Crop,
  Description,
  ImageWrapper as BaseImageWrapper,
  Title,
} from 'components/cards/forms/image-gallery/styled';
import TextTruncate from 'react-text-truncate';

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ImageWrapper = styled(BaseImageWrapper)`
  cursor: ${(p) => (p.clickable ? 'pointer' : 'initial')};
  width: ${(props) => {
    switch (props.view) {
      case 'grid_1':
        return '100%';
      case 'grid_2':
        return 'calc(50% - 2.5px)';
      case 'grid_3':
        return 'calc(33.333333% - 3.333333px)';
      default:
        return '100%';
    }
  }};
`;

function GalleryImagesSquare({ images, view }) {
  if (!['grid_1', 'grid_2', 'grid_3'].includes(view)) return null;
  return (
    <ImagesContainer>
      {images.map((image) => (
        <ImageWrapper
          clickable={!!image.url}
          view={view}
          key={image.id}
          onClick={ctaOnClickHandler({ url: image.url })}
        >
          <a href={image.url}>
            <Crop style={{ backgroundImage: `url(${image.imageUrl})` }}>
              <CopyWrapper>
                <Title>
                  <TextTruncate line={1} truncateText="…" text={image.title} />
                </Title>
                <Description>
                  <TextTruncate line={2} truncateText="…" text={image.description} />
                </Description>
              </CopyWrapper>
            </Crop>
          </a>
        </ImageWrapper>
      ))}
    </ImagesContainer>
  );
}

export default GalleryImagesSquare;
