import { Input as BaseInput, Button } from 'components/web-ui';
import withCallToActionStateHandlers, {
  handleDeleteCta,
} from 'hocs/with-call-to-action-state-handlers';
import withDestroyCallToActionMutation from 'hocs/with-destroy-call-to-action-mutation';
import logo from 'images/icon-down-arrow.svg';
import { compose, omitProps, withHandlers, withProps } from 'lib/recompose';
import { SOCIAL_NETWORKS, urlToId, urlToUsername, usernameToUrl } from 'lib/social-network-utils';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

const Container = styled.div`
  margin-bottom: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const fieldBorderCSS = css`
  :active,
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primaryPurple};
    box-shadow: none;
  }
`;

const Input = styled.input`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
  ${fieldBorderCSS};
`.withComponent(omitProps('hasErrors')(BaseInput));

const FakeInput = styled(Input)``;

const Hidden = styled.div`
  display: none;
`;

const Select = styled.select`
  -webkit-appearance: none;
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  border-bottom: none;
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  background-image: url('${logo}');
  background-position: 7% 50%;
  background-size: 200% 50%;
  background-repeat: no-repeat;
  padding: 15px 10px;
  font-size: 16px;
  width: 100%;

  :active,
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.errorRed};
  color: ${({ theme }) => theme.colors.white};
  font-size: 15px;
  padding: 4px 12px 4px 12px;
  border-radius: 100px;
  margin-left: auto;
  margin-bottom: 7px;

  :hover,
  :focus,
  :active {
    border: 0;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.errorRed};
    -webkit-tap-highlight-color: ${({ theme }) => theme.colors.errorRed};
  }

  :active {
    opacity: 0.5;
  }
`;

function socialAccountPlaceholder(formState, field, fallback = 'Enter Username') {
  const network = SOCIAL_NETWORKS.find((network) => network.id === get(formState, field));
  if (network) return network.placeholder;
  return fallback;
}

const SocialAccountField = ({
  cta,
  FIELDS,
  ctaIsPersisted,
  formState,
  handleTitleChange,
  handleUsernameChange,
  handleDelete,
  renderField,
  renderSelect,
}) => {
  return (
    <Container>
      <DeleteButton
        children={ctaIsPersisted ? 'Delete' : 'Remove'}
        onClick={(event) => handleDelete(event, cta.id)}
      />

      {renderSelect({
        name: FIELDS.title,
        placeholder: 'Tap here',
        hint: 'Choose social service',
        options: [
          { value: '', label: 'Choose social service' },
          ...SOCIAL_NETWORKS.map((network) => ({
            value: network.id,
            label: network.title,
          })),
        ],
        styledSelect: Select,
        defaultValue: urlToId(get(formState, FIELDS.url)),
        onChange: handleTitleChange,
      })}

      <FakeInput
        autoCapitalize="off"
        autoCorrect="off"
        name={FIELDS.username}
        placeholder={socialAccountPlaceholder(formState, FIELDS.title)}
        defaultValue={urlToUsername(get(formState, FIELDS.url))}
        onChange={handleUsernameChange}
        type="text"
        onBlur={(event) => {
          // Webview bugfix for iOS 12
          // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
          // https://openradar.appspot.com/radar?id=5018321736957952
          window.scrollTo(0, 0);
        }}
      />

      <Hidden>
        {renderField({
          autoCapitalize: 'off',
          autoCorrect: 'off',
          name: FIELDS.url,
          selectAllOnFocus: true,
          styledInput: Input,
        })}
      </Hidden>
    </Container>
  );
};

export default compose(
  withProps(({ cta }) => ({ ctaIsPersisted: !String(cta.id).startsWith('new-') })),
  withProps(({ cta }) => ({
    FIELDS: {
      title: `entities.ctas.${cta.id}.title`,
      url: `entities.ctas.${cta.id}.url`,
      username: `entities.ctas.${cta.id}.username`,
    },
  })),
  withCallToActionStateHandlers,
  withDestroyCallToActionMutation,
  withHandlers({
    handleTitleChange:
      ({ formState, setFormState, FIELDS }) =>
      (name, value) => {
        const networkId = value;
        const username = urlToUsername(get(formState, FIELDS.url));
        const url = username ? usernameToUrl(networkId, username) : '';

        setFormState((state) => set({ ...state }, FIELDS.title, networkId));
        setFormState((state) => set({ ...state }, FIELDS.url, url));
      },

    handleUsernameChange:
      ({ formState, setFormState, FIELDS }) =>
      (event) => {
        const { value: username } = event.target;
        const networkId = get(formState, FIELDS.title);
        const url = usernameToUrl(networkId, username);
        setFormState((state) => set({ ...state }, FIELDS.url, url));
      },

    handleDelete: handleDeleteCta,
  }),
)(SocialAccountField);
