import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
`;

const AnimatedBackground = (props) => {
  const { isCardVisible } = props;

  const poster = `${props.backgroundMediaUrl}&fm=jpg`;
  const sources = {
    'video/webm': `${props.backgroundMediaUrl}&fm=webm`,
    'video/mp4': `${props.backgroundMediaUrl}&fm=mp4`,
  };

  const video = useRef(null);

  async function play() {
    if (video.current) await video.current.play();
  }

  async function pause() {
    if (video.current) await video.current.pause();
  }

  useEffect(() => {
    switch (isCardVisible) {
      case undefined:
      case true:
        play();
        break;
      default:
        pause();
        break;
    }
  }, [video, isCardVisible]);

  return (
    <Video autoPlay={isCardVisible} playsInline muted loop poster={poster} ref={video}>
      {Object.keys(sources).map((type, i) => (
        <source key={i} src={sources[type]} type={type} />
      ))}
    </Video>
  );
};

export default AnimatedBackground;
