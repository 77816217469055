import YoutubeCard from 'components/cards/preview/youtube';
import routes from 'config/routes';
import { compose, lifecycle, withProps } from 'lib/recompose';
import set from 'lodash/set';
import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';

const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* Not entirely sure why we need this but we can't scroll without it */
  height: 1px;
`;

// --- Component ---

function YoutubeCardForm(props) {
  return (
    <CardLayout>
      <YoutubeCard {...props} renderHeader={false} renderFormFields={true} />
    </CardLayout>
  );
}

// This form initialization is for the form that displays in the popover
const withYoutubeStateHandlers = withProps(({ setFormState, cta }) => ({
  initializeNewYoutube() {
    const id = `new-${Math.random().toString(36).substring(7)}`;
    setFormState((state) => {
      let newState = set({ ...state }, 'entities.youtubes', {
        id,
        username: '',
        alternatePlaylistId: '',
      });
      return set({ ...newState }, 'result.youtube', id);
    });
    return id;
  },
}));

export default compose(
  withRouter,
  withYoutubeStateHandlers,
  withProps(({ card }) => ({ buttonColor: card.buttonColor })),
  withProps(({ data: { loading, defaultDeck }, currentAccount: { username }, card }) => {
    if (loading) return;
    const { activeCards } = defaultDeck;
    const cardIndex = activeCards.findIndex((c) => c.id === card.id);
    const nextCard = activeCards[cardIndex + 1];
    const prevCard = activeCards[cardIndex - 1];
    const redirectCard = nextCard || prevCard;

    return {
      postDeleteRedirectUrl: routes.accountCardPath(username, redirectCard.id),
    };
  }),

  lifecycle({
    componentDidMount() {
      if (!this.props.formState.result.youtube) {
        this.props.initializeNewYoutube();
      }
    },
  }),
)(YoutubeCardForm);
