import React from 'react';
import { omitProps } from 'lib/recompose';
import { BackgroundImage, Heading, Text } from 'components/web-ui';
import styled from 'styled-components/macro';
import Spinner from 'components/ui/spinner';

const cardRadius = '5px';

const ImageContainer = styled.div`
  ${(p) => p.backgroundCSS};
  width: 47.5%;
  border-radius: ${cardRadius};
  position: relative;
  margin-bottom: 5%;
  opacity: ${(p) => (p.isEnabled ? 1 : 0.15)};

  &:active {
    opacity: ${(p) => (p.isEnabled ? 0.75 : 0.15)};
  }
`.withComponent(omitProps('backgroundCSS', 'isEnabled')(BackgroundImage));

const SpinnerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${cardRadius};
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

const BackgroundOverlay = styled.div`
  background: linear-gradient(to top right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  opacity: 0.4;
  mix-blend-mode: overlay;
  border-radius: ${cardRadius};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  color: white;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46%;
  width: 100%;
`;

const Icon = styled.div`
  background-color: #e6e6e6;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: #33114f;
  }
`;

const Title = styled(Heading)`
  font-size: 18px;
  margin-bottom: 7px;
  text-align: center;
`;

const Description = styled.div`
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  max-width: 80%;
  margin: 0 auto;

  > p {
    margin-bottom: 5px;
  }
`;

const Ribbon = styled.div`
  display: flex;
  height: 18px;
  font-size: 11px;
  line-height: 1;
  width: 100%;
  border-radius: ${cardRadius} ${cardRadius} 0 0;
  background-color: ${(p) => p.backgroundColor};
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
`.withComponent(({ backgroundColor, ...props }) => <div {...props} />);

const RibbonText = styled(Text)`
  color: white;
`;

function Card(props) {
  const { card, isCreating, isEnabled, handleClick } = props;
  return (
    <ImageContainer
      backgroundCSS={card.backgroundCSS}
      isEnabled={isEnabled}
      onClick={handleClick.bind(this, card.kind, isEnabled)}
      ratio={16 / 24}
    >
      {card.kind === 'instagram' && <BackgroundOverlay />}
      <Content column>
        <IconContainer>
          <Icon>
            <card.icon width={card.iconWidth || 28} height={card.iconHeight || 28} />
          </Icon>
        </IconContainer>
        <Title children={card.title} />
        <Description>
          {card.description.map((text) => (
            <Text key={text} children={text} />
          ))}
        </Description>
      </Content>
      {!card.isAvailable && (
        <Ribbon backgroundColor="#de2b56">
          <RibbonText>Coming Soon!</RibbonText>
        </Ribbon>
      )}
      {card.isBeta && (
        <Ribbon backgroundColor="#1485CC">
          <RibbonText>Beta</RibbonText>
        </Ribbon>
      )}
      {isCreating && (
        <SpinnerContainer>
          <Spinner fill="white" />
        </SpinnerContainer>
      )}
    </ImageContainer>
  );
}

export default Card;
