import React from 'react';
import sleep from 'lib/sleep';
import { branch, renderComponent, compose, defaultProps, withProps, lifecycle } from 'recompose';
import { withInstagramFeed } from 'hocs/instagram/with-instagram-feed';
import Spinner from 'components/ui/spinner';
import { Container, Grid } from './styles';
import FeaturedPhoto from './featured-photo';
import Medium from './medium';
import LoadingErrorMessage from './error';

function LoadingSpinner() {
  return (
    <Container>
      <Spinner fill="rgba(0,0,0,0.25)" />
    </Container>
  );
}

function Media(props) {
  const { card, photos, featuredPhoto, feed } = props;

  return (
    <Container style={card.ctas.length ? { paddingBottom: '73px' } : {}}>
      <Grid>
        <LoadingErrorMessage errorType={feed.errorType} />
        <FeaturedPhoto photo={featuredPhoto} />
        {photos.map((photo) => (
          <Medium key={photo.id} photo={photo} view={card.instagram.view} />
        ))}
      </Grid>
    </Container>
  );
}

export default compose(
  defaultProps({
    feed: {
      errorType: null,
      nextMaxId: null,
      photos: [],
    },
  }),
  withInstagramFeed,
  lifecycle({
    async componentDidUpdate(prevProps) {
      const prevMaxId = prevProps.feed.nextMaxId;
      const nextMaxId = this.props.feed.nextMaxId;
      const needsMore = prevMaxId !== nextMaxId && !!nextMaxId;
      if (needsMore) {
        await sleep(500);
        this.props.loadMoreEntries();
      }
    },
  }),

  withProps(({ card, feed, loading, ...rest }) => {
    if (feed.photos.length && card.instagram.hasFeaturedImage) {
      return {
        featuredPhoto: feed.photos[0],
        photos: feed.photos.slice(1),
      };
    }
    return { photos: feed.photos };
  }),

  branch((props) => props.loading, renderComponent(LoadingSpinner)),
)(Media);
