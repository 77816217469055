import { withRouter } from 'react-router';
import * as Recompose from 'recompose';
import routes from 'config/routes';
import OAuthAccessError from 'components/authentication/oauth-error';

const props = {
  provider: 'Instagram',
  message: 'Sorry. We were not able to authenticate with your Instagram account.',
};

export default Recompose.compose(
  withRouter,

  Recompose.withProps(props),
  Recompose.withHandlers({
    onBack: (props) => (evt) => {
      evt.preventDefault();
      props.history.push(routes.dashboardPath);
    },
  }),
)(OAuthAccessError);
