import { withProps } from 'recompose';
import cloneDeep from 'lodash/cloneDeep';

// Iterate through all the photos
// Find the photo that needs updating and either:
//   Replace it with the new photo (update/pin)
//   Delete it (destroy)
// If the photo doesn't match:
//   Set its view status to visible
//   Do nothing (all non-pin actions)

const withUpdatePhotos = withProps(({ photos, setPhotos }) => ({
  updatePhotos(updatedPhoto, options = { action: null }) {
    const newPhotos = [];
    photos.forEach((photo) => {
      const newPhoto = cloneDeep(photo);
      if (photo.instagram_photo.id === updatedPhoto.instagram_photo_id) {
        // Always update the state with the new photo
        newPhoto.lip_photo = options.action === 'destroy' ? null : updatedPhoto;
      } else if (options.action === 'pinned') {
        // Update all other pinned LIP photos to be visible
        // Don't touch any other statuses.
        if (newPhoto.lip_photo && newPhoto.lip_photo.view_status === 'pinned') {
          newPhoto.lip_photo.view_status = 'visible';
        }
      }
      newPhotos.push(newPhoto);
    });
    setPhotos(newPhotos);
  },
}));

export default withUpdatePhotos;
