import { validEmail, validPhoneNumber, validUrl } from 'lib/validations';
import { lifecycle, withHandlers } from 'recompose';
import get from 'lodash/get';

// For CTAs
function detectProtocol(value) {
  if (!value) return;

  if (value.match(validEmail)) return 'email';
  if (value.match(validPhoneNumber)) return 'tel';
  if (value.match(validUrl)) return 'url';
}

export const withDetectProtocol = withHandlers({ detectProtocol: () => detectProtocol });

// For login
export const withDetectProvider = withHandlers({
  detectProvider: () => (value) => {
    if (!value) return;
    if (value.match(validEmail)) return 'email';
    if (value.match(validPhoneNumber)) return 'sms';
  },
});

function removeTelPrefixDelimiter(str) {
  return str.replace(/\*/g, '');
}

export const withRenderProtocol = withHandlers({
  renderHrefWithProtocol: () => (str) => {
    if (!str) return '';

    if (/^https?:\/\//.test(str)) return str;
    if (str.startsWith('tel:')) return removeTelPrefixDelimiter(str);
    if (str.startsWith('mailto:')) return str;

    switch (detectProtocol(str)) {
      case 'tel':
        return removeTelPrefixDelimiter(str);
      case 'email':
        return `mailto:${str}`;
      default:
        return str;
    }
  },
});

export const withValidateEmail = withHandlers({
  validateEmail: (props) => (value) => {
    if (!value) return;
    return !!value.match(validEmail);
  },
});

export const withValidateTel = withHandlers({
  validateTel: (props) => (value) => {
    if (!value) return;
    return !!value.match(validPhoneNumber);
  },
});

export const withValidateUrl = withHandlers({
  validateUrl: (props) => (value) => {
    if (!value) return;
    return !!value.match(validUrl);
  },
});

// This function can be called from a link or image link popover so
// need to differentiate between these two
export function handleDetectProtocol(props) {
  let url;
  const cta = get(props.formState, `entities.ctas.${props.selectedCallToAction}`);
  const image = get(props.formState, `entities.images.${props.selectedImage}`);

  if (cta) url = cta.url;
  if (image) url = image.url;

  if (!url) return;

  // Set the hint type to blank if there's no value
  if (!url) {
    if (props.valueType !== 'text') props.setValueType('text');
    return;
  }

  // Set the hint type based on the value
  const protocol = props.detectProtocol(url);

  if (protocol) {
    if (props.valueType !== protocol) props.setValueType(protocol);
  } else {
    if (props.valueType !== 'text') props.setValueType('text');
  }
}

/*
 *  Historically, only a URL was allowed to be added to a CTA. This has been changed to allow
 *  a URL, email (mailto:) or phone number (tel:). This lifecycle HOC determines the type of
 *  input and sets it into state.
 */
export const withProtocolDetectionLifecycle = lifecycle({
  componentDidMount() {
    handleDetectProtocol(this.props);
  },

  componentWillReceiveProps(newProps) {
    handleDetectProtocol(newProps);
  },
});
