import React from 'react';

function LinkIcon(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <path
        d="M11.444 7.041a.937.937 0 1 0-1.012 1.578 3.113 3.113 0 0 1 1.443 2.631c0 .835-.325 1.62-.915 2.21l-3.75 3.75c-.59.59-1.376.915-2.21.915A3.129 3.129 0 0 1 1.875 15c0-.835.325-1.62.915-2.21l1.779-1.779a.938.938 0 1 0-1.325-1.325l-1.779 1.779A4.963 4.963 0 0 0 0 15c0 2.757 2.243 5 5 5 1.336 0 2.591-.52 3.536-1.465l3.75-3.75a4.964 4.964 0 0 0 1.464-3.535 4.98 4.98 0 0 0-2.306-4.209zM15 0c-1.336 0-2.591.52-3.536 1.465l-3.75 3.75A4.963 4.963 0 0 0 6.25 8.749c0 1.71.862 3.283 2.306 4.21a.935.935 0 0 0 1.296-.283.932.932 0 0 0 .126-.707.931.931 0 0 0-.41-.588 3.117 3.117 0 0 1-1.443-2.632c0-.835.325-1.619.915-2.209l3.75-3.75A3.107 3.107 0 0 1 15 1.875a3.128 3.128 0 0 1 3.125 3.124c0 .835-.325 1.62-.915 2.21l-1.779 1.779a.938.938 0 1 0 1.326 1.325l1.779-1.78A4.961 4.961 0 0 0 20 5C20 2.243 17.757 0 15 0z"
        fillRule="nonzero"
        fill="#ADADAD"
      />
    </svg>
  );
}

export default LinkIcon;
