import addLightenFilter from 'components/ui/add-lighten-filter';
import { Text as BaseText } from 'components/web-ui';
import withHasBlendMode from 'hocs/with-has-blend-mode';
import React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Photo = styled.div`
  /* For loading */
  background-color: #eeeeee;
  background-image: url('${({ url }) => url}');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  ${({ hasBlendMode, brandColor }) => hasBlendMode && addLightenFilter(brandColor)}
`.withComponent(({ url, brandColor, hasBlendMode, ...props }) => <div {...props} />);

const Label = styled(BaseText)`
  font-size: 24px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 20%;
  color: rgba(255, 255, 255, 0.5);
`;

function FilteredPhoto(props) {
  const { brandColor, hasBlendMode, backgroundMediaUrl, label } = props;
  return (
    <Container>
      <Photo brandColor={brandColor} hasBlendMode={hasBlendMode} url={backgroundMediaUrl} />
      <Label children={label} />
    </Container>
  );
}

export default compose(withHasBlendMode)(FilteredPhoto);
