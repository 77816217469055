import React from 'react';
import { compose, defaultProps, lifecycle, withHandlers } from 'recompose';
import { Container, Description, Select } from './styles';

function MailChimpMailingListSelector(props) {
  const { lists, selected, onChange } = props;
  return (
    <Container>
      <Select
        value={selected}
        onChange={onChange}
        onBlur={(event) => {
          // Webview bugfix for iOS 12
          // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
          // https://openradar.appspot.com/radar?id=5018321736957952
          window.scrollTo(0, 0);
        }}
      >
        {lists.map(({ node: { id, name } }, i) => (
          <option key={i} value={id}>
            {name}
          </option>
        ))}
      </Select>
      <Description>Choose a mailing list for email address collection.</Description>
    </Container>
  );
}

export default compose(
  defaultProps({
    lists: [],
    selected: '',
    onSet: () => null,
  }),

  // If there is no list set (e.g. the account has just been connected for the first time) then
  // we should ensure that the formState has a value set. If we don't do this then blank values
  // will be sent as the mutation's variables.
  lifecycle({
    componentDidMount() {
      const { selected, onSet, lists } = this.props;
      const selectedExists = lists.some((edge) => edge.node.id === selected);
      if (!selected || !selectedExists) {
        const defaultList = lists[0].node;
        onSet(defaultList.id, defaultList.url);
      }
    },
  }),

  withHandlers({
    onChange:
      ({ lists, onSet }) =>
      (evt) => {
        evt && evt.preventDefault();
        for (const {
          node: { id, url },
        } of lists) {
          if (id === evt.target.value) {
            onSet(evt.target.value, url);
          }
        }
      },
  }),
)(MailChimpMailingListSelector);
