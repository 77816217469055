import React from 'react';
import { compose } from 'recompose';
import {
  ImageGalleryPanel,
  ImageGalleryConfigPanel,
  LinkPanel,
  BackgroundImagePanel,
  PhotoCropperPanel,
  CustomStylesPanel,
  MailingListButtonPanel,
  YoutubeConfigPanel,
  TwitterConfigPanel,
  InstagramConfigPanel,
} from 'components/cards/forms/popovers';
import { connect } from 'react-redux';

function Popovers({ selectedPopover, ...props }) {
  switch (selectedPopover) {
    case 'image-gallery':
      return <ImageGalleryPanel {...props} />;
    case 'image-gallery-config':
      return <ImageGalleryConfigPanel {...props} />;
    case 'link':
      return <LinkPanel {...props} />;
    case 'background-image':
      return <BackgroundImagePanel {...props} />;
    case 'background-resize':
      return <PhotoCropperPanel {...props} />;
    case 'custom-styles':
      return <CustomStylesPanel {...props} />;
    case 'mailing-list-button':
      return <MailingListButtonPanel {...props} />;
    case 'youtube-config':
      return <YoutubeConfigPanel {...props} />;
    case 'twitter-config':
      return <TwitterConfigPanel {...props} />;
    case 'instagram-config':
      return <InstagramConfigPanel {...props} />;
    default:
      return null;
  }
}

export default compose(
  //
  connect(({ cardPopovers }) => ({ selectedPopover: cardPopovers.popover })),
)(Popovers);
