import Spinner from 'components/ui/spinner';
import { compose, defaultProps } from 'recompose';
import styled from 'styled-components/macro';

export const Panel = styled.div`
  display: flex;
  position: absolute;
  z-index: 200;
  bottom: 302px;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  flex-direction: column;
  height: 100%;
  max-height: calc(100% - 65px);
  min-height: 302px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  /* force hardware acceleration */
  transform: translate3d(0, 100%, 0);
  backface-visibility: hidden;
  perspective: 1000;
  overflow: hidden;
  transition: transform 0.35s ease;
  transition-delay: 0.25s;

  ${(props) => props.expanded && 'transform: translate3d(0, 302px, 0)'};
`;

export const SVGRules = styled.svg`
  height: 0;
  max-height: 0;
`;

export const Blur = styled.div`
  filter: blur(5px);
  pointer-events: none;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.54);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 1;
`;

export const NumbersContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 92px;
`;

export const ScrollableView = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: ${(props) => (props.scrollable ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
`;

export const ExpandedView = styled.div`
  flex: 1;
  box-sizing: content-box;
  background-color: #e6e3ea;
  background-image: linear-gradient(to bottom, #e6e3ea 0%, #fcf9ff 100%);
  background-attachment: scroll;
  padding: 20px;
`;

export const ChartPlaceholder = styled.div`
  display: flex;
  height: 150px;
  min-height: 150px;
  flex: 1;
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  height: 302px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  /* force hardware acceleration */
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
`;

export const ProgressBar = compose(
  defaultProps({
    fill: '#C5BDCC',
  }),
)(Spinner);

export const TrendsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0dee4 #e0dee4 #d3d0d7 #e0dee4;
  margin-top: 10px;
`;

export const TrendsItemContainer = styled.div`
  padding: 10px 12px;
`;

export const TrendsItemClickable = styled.a`
  display: block;
  color: black;
  text-decoration: none;
`;

export const TrendsItemHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  white-space: pre;

  & span {
    font-weight: normal;
  }
`;

export const TrendsBarChartContainer = styled.div`
  border-top: 1px solid #f0eef4;
`;

export const TrendsSummary = styled.div`
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  white-space: pre;
  border-top: 1px solid #e0dee4;
`;

export const DeletedTrendItemMessage = styled.div`
  padding: 18px 15px;
  border-bottom: ${(props) => (props.separator ? 1 : 0)}px solid #e0dee4;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 13px;
  font-weight: bold;
`;
