import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './select.css';

export default class Select extends Component {
  static propTypes = {
    errors: PropTypes.string,
    hint: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    this.state = { isFocused: false };
  }

  // === Start event handlers ===

  handleBlur = () => this.setState({ isFocused: false });
  handleClick = () => {
    this.setState({ isFocused: true });
    this.input.focus();
  };

  // === End event handlers ===

  renderOptions() {
    return this.props.options.map((o) => (
      <option key={o.value} value={o.value}>
        {o.label}
      </option>
    ));
  }

  render() {
    const { errors, hint, label, name, onChange, value } = this.props;
    const { isFocused } = this.state;

    const select = (
      <select
        onBlur={(event) => {
          // Webview bugfix for iOS 12
          // https://app.zenhub.com/workspaces/tap-bio-5aafe477976db06a5c298cc6/issues/revisionco/tap.bio-issues/740
          // https://openradar.appspot.com/radar?id=5018321736957952
          window.scrollTo(0, 0);
          this.handleBlur && this.handleBlur(event);
        }}
        onChange={(event) => onChange(name, event.target.value)}
        ref={(elem) => {
          this.input = elem;
        }}
        value={value}
      >
        {this.renderOptions()}
      </select>
    );
    if (label) {
      return (
        <label
          className={classNames('field select label', {
            'has-error': errors,
            '-is-focused': isFocused,
          })}
          htmlFor={name}
          onClick={this.handleClick}
        >
          <span className="label-text">
            {label} {errors}
          </span>
          {hint && <span className="hint">{hint}</span>}
          {select}
        </label>
      );
    }
    return select;
  }
}
