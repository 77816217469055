import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import routes from 'config/routes';
import { prefixPath } from 'utils';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import withCompleteOnboarding from 'hocs/with-complete-onboarding';
import withUpdateUserMutation from 'hocs/with-update-user-mutation';
import withAuthenticationCheck from 'hocs/with-authentication-check.js';
import withAuthorizationCheck from 'hocs/with-authorization-check.js';
import styled from 'styled-components/macro';
import AddLinkToInstagramProfileModal from './add-link-to-instagram-profile-modal';
import InstagramLinksModal from './instagram-links-modal';
import Modal from './modal';
import Tooltip from './tooltip';

const OnboardingContainer = styled.div``;

function Onboarding(props) {
  const {
    currentAccount: {
      username,
      profile: { defaultBackgroundMediaUrl },
    },
    currentUser: { onboarding },
    displayedCard,
    handleCompleteOnboarding,
    history,
    match,
    onboardingTargetElement,
  } = props;

  const isVirginAccount = !defaultBackgroundMediaUrl;
  const isEditingProfileCard = match.url.endsWith('edit') && displayedCard.kind === 'profile';

  // Use the onboarding target element to determine where we should display
  // the blocker and tooptips. Currently we're only using this for a single
  // element but this can easily be expanded if/when we update our onboarding.
  let top = 0;
  let bottom = 0;
  let left = 0;
  if (onboardingTargetElement) {
    ({ top, bottom } = onboardingTargetElement.getBoundingClientRect());
    left = onboardingTargetElement.offsetLeft;
  }

  return (
    <OnboardingContainer>
      {/*
          STEP 1 - Tooltip for + button
          Note that the + button does not initially display. There are three ways the user can get out
          of the initial screen:
  
            - Click 'Choose a profile photo' 
            - Click 'Skip'
            - Click either of the name or description inputs
         
          Any of these actions, ultimately, will cause `currentAccount.profile.defaultBackgroundMediaUrl`
          to have a non-blank value.
        */}

      {!isVirginAccount && !onboarding.hasClickedAddNewCard && !isEditingProfileCard && (
        <Tooltip
          children="Add another card"
          left="4%"
          onClickBlocker={handleCompleteOnboarding.bind(this, 'hasClickedAddNewCard')}
          top="65px"
          blockerPosition={{
            top: `${top}px`,
            width: '38px',
            bottom: `${bottom}px`,
            left: `4%`, // 4px is the margin on the button element
          }}
        />
      )}

      {/*
          STEP 2 - Display a modal when editing the instagram links card (which should be
          immediately after it's created)
          Requires step 1 to be complete
        */}
      {onboarding.hasClickedAddNewCard &&
        !onboarding.hasViewedInstagramLinksCard &&
        displayedCard.kind === 'instagram' &&
        match.url === routes.accountCardEditPath(username, displayedCard.id) && (
          <Modal onClickClose={handleCompleteOnboarding.bind(this, 'hasViewedInstagramLinksCard')}>
            <InstagramLinksModal
              onClickCta={(event) => {
                event.preventDefault();
                handleCompleteOnboarding('hasViewedInstagramLinksCard');
                history.push(routes.accountCardEditPath(username, displayedCard.id));
              }}
            />
          </Modal>
        )}

      {/*
          STEP 3 - Modal for education regarding the Instagram Profile link
          We'll only show this if:
  
           - We've not shown the 'add link' modal before
           - the onboarding for the add form has been completed
           - We're either viewing the deck root, or a card in the deck (in preview mode)
        */}
      {onboarding.hasClickedAddNewCard &&
        !onboarding.hasBeenPromptedToAddUrlToInstagramProfile &&
        (match.path === prefixPath('accounts/:accountUsername') ||
          match.path === prefixPath('accounts/:accountUsername/cards/:cardId')) && (
          <Modal
            onClickClose={handleCompleteOnboarding.bind(
              this,
              'hasBeenPromptedToAddUrlToInstagramProfile',
            )}
          >
            <AddLinkToInstagramProfileModal username={username} />
          </Modal>
        )}

      {/* STEP 4 - Tooltip for show/hide icon */}
      {onboarding.hasClickedAddNewCard &&
        onboarding.hasBeenPromptedToAddUrlToInstagramProfile &&
        !onboarding.hasClickedTogglePublish && (
          <Tooltip
            children="Tap to Publish"
            left={`${left}px + 4px`}
            onClickBlocker={handleCompleteOnboarding.bind(this, 'hasClickedTogglePublish')}
            top="65px"
            blockerPosition={{
              top: `${top}px`,
              width: '38px',
              bottom: `${bottom}px`,
              left: `${left}px`,
            }}
          />
        )}
    </OnboardingContainer>
  );
}

export default compose(
  connect((state, ownProps) => ({
    onboardingTargetElement: state.onboarding.element,
  })),
  withIdentityAccounts,
  withAuthenticationCheck,
  withAuthorizationCheck,
  connect((state, ownProps) => ({ displayedCard: state.onboarding.card })),
  withUpdateUserMutation,
  withCompleteOnboarding,
)(Onboarding);
