import React from 'react';
import CallToActionButton from 'components/cards/forms/call-to-action-button';
import styled from 'styled-components/macro';

const Ctas = styled.div`
  width: 100%;
  margin-top: 18px;
`;

function CallToActionButtons(props) {
  const { brandColor, ctas, handleDeleteCta, isPreview, onClick, mt } = props;

  return (
    <Ctas mt={mt}>
      {ctas.map((cta) => (
        <CallToActionButton
          brandColor={brandColor}
          cta={cta}
          key={cta.id}
          onClick={onClick && onClick.bind(this, cta.id)}
          isPreview={isPreview}
          handleDeleteCta={handleDeleteCta}
        />
      ))}
    </Ctas>
  );
}

CallToActionButtons.defaultProps = {
  isPreview: false,
  pt: 0,
};

export default CallToActionButtons;
