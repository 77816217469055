import React from 'react';
import NumberFormatter from 'components/cards/stats/elements/number-formatter';
import BarChart from 'components/cards/stats/elements/bar-chart/index';
import TwoLevelMetadata from 'components/cards/stats/elements/two-level-metadata';
import enhance from 'components/cards/stats/cards/shared/enhancer';
import DeletedTrendItem from 'components/cards/stats/elements/deleted-trend-item';
import {
  TrendsItemWrapper,
  TrendsItemContainer,
  TrendsItemHeader,
  TrendsBarChartContainer,
  TrendsSummary,
} from 'components/cards/stats/elements/shared';

const CallToAction = ({ cta, summary, graph, unit, total }) => (
  <TrendsItemWrapper>
    {cta ? (
      <TrendsItemContainer>
        <TrendsItemHeader>
          <TwoLevelMetadata title={cta.title} description={cta.url} href={cta.url} />
        </TrendsItemHeader>
      </TrendsItemContainer>
    ) : (
      <DeletedTrendItem />
    )}
    {!summary && (
      <TrendsBarChartContainer>
        <BarChart data={graph} unit={unit} />
      </TrendsBarChartContainer>
    )}
    <TrendsSummary>
      <NumberFormatter value={total} /> Clicks
    </TrendsSummary>
  </TrendsItemWrapper>
);

export default enhance(CallToAction);
