import set from 'lodash/set';
import { withHandlers } from 'recompose';

// A select element can't accept boolean values so we'll convert them
function coerceValue(value) {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
}

export default withHandlers({
  handleFieldChange:
    ({ setFormState }) =>
    (name, value) => {
      // Note that the key could be 'entities.name' (for normalized state) or just 'name' for
      // a regular state object.
      setFormState((state) => {
        return set({ ...state }, name, coerceValue(value));
      });
    },
});
