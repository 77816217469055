import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HeartIcon, ChatIcon } from 'components/icons';
import InstagramLogo from '../instagram-logo';
import './instagram-media-item-meta.css';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 's',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '4w',
    MM: (number) => `${Math.round(number * 4)}w`,
    y: '52w',
    yy: (number) => `${Math.round(number * 52)}w`,
  },
});

export default class InstagramMediaItemMeta extends Component {
  static propTypes = {
    className: PropTypes.string,
    commentCount: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    handleClick: PropTypes.func,
    instagramLink: PropTypes.string.isRequired,
    isPinned: PropTypes.bool.isRequired,
    likeCount: PropTypes.number.isRequired,
    title: PropTypes.string,
    url: PropTypes.string,
  };

  render() {
    const { commentCount, createdAt, instagramLink, likeCount } = this.props;
    return (
      <div className="instagram-media-item-meta">
        <a className="instagram-meta" href={instagramLink}>
          <div className="instagram-meta-element instagram-logo">
            <InstagramLogo width={19} height={19} />
            <span className="date">{moment.unix(parseInt(createdAt, 10)).fromNow()}</span>
          </div>
          <div className="instagram-meta-element">
            <HeartIcon width={22} height={22} />
            <span className="count">{likeCount}</span>
          </div>
          <div className="instagram-meta-element">
            <ChatIcon width={22} height={22} />
            <span className="count">{commentCount}</span>
          </div>
        </a>
      </div>
    );
  }
}
