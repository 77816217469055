import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

export const query = gql`
  query IsAllowRegistration {
    allowRegistration
  }
`;

export default graphql(query, {
  options: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  props: ({ ownProps, data }) => {
    const allowRegistration = !data || data.loading ? true : data.allowRegistration;
    return { allowRegistration };
  },
});
