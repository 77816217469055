import styled from 'styled-components/macro';

const Label = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.colors.veryDarkPurple};
  margin-bottom: 4px;
  display: block;
`;

export default Label;
