import { withProps } from 'recompose';

function toTwoDecimalPlaces(amount) {
  return amount.toFixed(2).replace('.00', '');
}

function formattedMonthlyAmount(interval, amount) {
  if (interval === 'year') {
    amount = amount / 100 / 12;
  } else {
    amount = amount / 100;
  }
  return toTwoDecimalPlaces(amount);
}

export default withProps((props) => {
  return {
    toTwoDecimalPlaces,
    formattedMonthlyAmount,
    priceClarification: (interval, amount, discount) => {
      if (discount && discount.description) {
        return discount.description;
      }
      return `$${toTwoDecimalPlaces(amount / 100)} charged each ${interval}`;
    },
  };
});
