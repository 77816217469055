import { cardAnalyticsQuery as query } from 'lib/graphql/queries';
import initStatType from 'components/cards/stats/dataloader';

const resolver = (props) => {
  return props.card && props.card.analytics && props.card.analytics.photos;
};

export default initStatType({
  name: 'Photo Clicks',
  query,
  resolver,
});
