import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import SelectBackgroundPhoto from 'components/cards/preview/profile/select-background-photo';
import { Camera as BaseCameraIcon } from 'components/icons';

const Button = styled.div`
  background-color: ${(props) => props.theme.colors.alphaBlack40};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.white};
  -webkit-tap-highlight-color: ${(props) => props.theme.colors.alphaBlack50};
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 10px 20px;

  @media (min-height: 32rem) {
    padding: 40px 20px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.alphaBlack60};
  }
`;

const CameraIcon = styled(BaseCameraIcon)`
  color: white;
`;

const Label = styled.p`
  max-width: 100px;
  text-align: center;
  margin: 0;
  font-size: 15px;

  @media (min-height: 32rem) {
    margin: 0.75em 0;
  }
`;

function UploadImageButton({ hidden, label, profile, onImageSelected }) {
  return (
    <Button hidden={hidden}>
      <CameraIcon width={42} />
      <Label>{label}</Label>
      <SelectBackgroundPhoto onImageSelected={onImageSelected} />
    </Button>
  );
}

UploadImageButton.propTypes = {
  hidden: PropTypes.bool,
  label: PropTypes.string,
  onImageSelected: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

UploadImageButton.defaultProps = {
  label: 'Choose a profile photo',
};

export default UploadImageButton;
