import React from 'react';
import styled from 'styled-components/macro';
import MediaType from './media-type';

const Image = styled.div`
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 1px);
  padding-bottom: 100%;
  background-size: cover;
`;

function Media(props) {
  let url = props.media_url;
  let typeDisplay = props.media_type;

  switch (props.media_type) {
    case 'VIDEO':
      url = props.thumbnail_url;
      break;
    case 'CAROUSEL_ALBUM':
      typeDisplay = 'gallery';
      break;
    default:
      break;
  }

  return (
    <Image style={{ backgroundImage: `url('${url}')` }}>
      <MediaType type={typeDisplay} />
    </Image>
  );
}

export default Media;
