import { loader } from 'graphql.macro';

const accountAnalyticsQuery = loader('./account-analytics.gql');
const accountAnalyticsVisitsQuery = loader('./account-analytics-visits.gql');
const billingProductSelectionQuery = loader('./billing-product-selection.gql');
const cardAnalyticsQuery = loader('./card-analytics.gql');
const cardQuery = loader('./card.gql');
const currentIdentityQuery = loader('./current-identity.gql');
const defaultDeckQuery = loader('./default-deck.gql');
const publicInstagramFeedWithCardIdQuery = loader('./public-instagram-feed-with-card-id.gql');
const signedUrlMultipleQuery = loader('./signed-url-multiple.gql');
const signedUrlQuery = loader('./signed-url.gql');
const tweetsPayloadQuery = loader('./tweets-payload.gql');
const usernameSearchQuery = loader('./username-search.gql');

export {
  accountAnalyticsQuery,
  accountAnalyticsVisitsQuery,
  billingProductSelectionQuery,
  cardAnalyticsQuery,
  cardQuery,
  currentIdentityQuery,
  defaultDeckQuery,
  publicInstagramFeedWithCardIdQuery,
  signedUrlMultipleQuery,
  signedUrlQuery,
  tweetsPayloadQuery,
  usernameSearchQuery
};

