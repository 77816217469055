import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const dateFormat = {
  HOURLY: 'ha',
  DAILY: 'd MMM',
};

function shouldHideTick(index, width) {
  if (index === 0) return false;
  if (width < 300) {
    if (index % 4 === 0) return false;
  } else if (width < 500) {
    if (index % 3 === 0) return false;
  } else if (index % 2 === 0) {
    return false;
  }
  return true;
}

export default ({ unit, width, index, x, y, payload: { value } }) => {
  if (shouldHideTick(index, width)) {
    return null;
  }

  const datetime = parseISO(value);
  const output = format(datetime, dateFormat[unit] || dateFormat.DAILY);

  return (
    <text dy={-4} x={x} y={y + 15} fontSize={10} fill="#c2bec8" textAnchor="middle">
      {output}
    </text>
  );
};
