import { YouTubeIcon } from 'components/icons';
import { BackgroundImage } from 'components/web-ui';
import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import styled from 'styled-components/macro';
import VideoMeta from './video-meta';

const VideoContainer = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 10px;
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const ThumbnailImage = styled(BackgroundImage)`
  border-radius: 4px;
`;

const Icon = styled.div`
  position: absolute;
  left: calc(50% - 36px);
  top: calc(50% - 24px);

  path {
    fill: rgba(0, 0, 0, 0.75);
  }

  #youtube-icon-chevron {
    fill: white;
  }
`;

const ratio = 9 / 16;

function Video(props) {
  const { video, handleClick } = props;
  return (
    <VideoContainer>
      <ThumbnailContainer onClick={handleClick}>
        <ThumbnailImage src={video.thumbnailUrl} ratio={ratio} />
        <Icon>
          <YouTubeIcon width={72} height={48} />
        </Icon>
      </ThumbnailContainer>
      <VideoMeta video={video} />
    </VideoContainer>
  );
}

const withHandlePlayVideo = withHandlers({
  handleClick:
    ({ setActiveVideo, video }) =>
    (event) => {
      event.preventDefault();
      setActiveVideo(video);
    },
});

export default compose(
  //
  withState('play', 'setPlay', false),
  withHandlePlayVideo,
)(Video);
