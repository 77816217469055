import * as Recompose from 'recompose';
import config from 'config';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import withDefaultDeck from 'hocs/with-default-deck';
import displayLoadingState from 'hocs/display-loading-state';
import { RedirectToFirstCard, RedirectToOnboarding } from './redirects';

export default Recompose.compose(
  withIdentityAccounts,
  withDefaultDeck,
  displayLoadingState,
  Recompose.withProps((props) => {
    const {
      profile: { name, title, tags },
    } = props.currentAccount;
    const hasCompletedOnboarding = !!name && !!title && !!tags;
    return { hasCompletedOnboarding };
  }),
  Recompose.branch(
    (props) => config.ENABLE_PROFILE_ONBOARDING && !props.hasCompletedOnboarding,
    Recompose.renderComponent(RedirectToOnboarding),
  ),
  Recompose.branch(
    (props) => !props.match.params.cardId,
    Recompose.renderComponent(RedirectToFirstCard),
  ),
  Recompose.pure,
);
