import routes from 'config/routes';
import { withHandlers } from 'recompose';

const withButtonClickHandler = withHandlers({
  handleShowCustomSettings:
    ({ cardId, history, setSelectedPopover, username }) =>
    (event) => {
      event.preventDefault();
      setSelectedPopover('youtube-config');
      history.push(routes.accountCardEditPath(username, cardId));
    },
});

export default withButtonClickHandler;
