// Find the discount that should be applied to the plan

export default function findDiscount(discounts, planStripeId) {
  let planDiscount = null;
  discounts.forEach((discount) => {
    discount.plans.forEach((plan) => {
      if (plan.stripeId === planStripeId) {
        planDiscount = discount;
      }
    });
  });
  return { discount: planDiscount };
}
