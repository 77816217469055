import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setPropTypes, withHandlers, withProps, withState } from 'recompose';
import Button from '../button';
import EditPhotoDetails from '../edit-photo-details';
import Photo from '../photo';
import PhotoChangeViewStatus from '../photo-change-view-status';
import './photo-card.css';

const propTypes = setPropTypes({
  activePanel: PropTypes.string.isRequired,
  instagramPhoto: PropTypes.object.isRequired,
  lipPhoto: PropTypes.object,
  updatePhotoState: PropTypes.func.isRequired,
  user: PropTypes.object,
});

const PhotoCard = ({
  activePanel,
  caption,
  cardId,
  description,
  globallyActivePanel,
  instagramPhoto,
  isHidden,
  isPinned,
  lipPhoto,
  onChangePanel,
  onClickAddLinkButton,
  photoMeta,
  shouldDisplayAddLink,
  shouldDisplayCard,
  shouldDisplayCardActions,
  shouldDisplayEditForm,
  title,
  updatePhotoState,
  url,
  user,
}) => (
  <div className={classNames('photo-card', { '-is-pinned': isPinned, '-is-hidden': isHidden })}>
    <Photo instagramPhoto={instagramPhoto} isPinned={isPinned} lipPhoto={lipPhoto}>
      {shouldDisplayCardActions() && (
        <div className="photo-actions">
          <PhotoChangeViewStatus
            defaultLink={user.default_link}
            instagramPhoto={instagramPhoto}
            lipPhoto={lipPhoto}
            updatePhotoState={updatePhotoState}
            user={user}
          />
        </div>
      )}
    </Photo>

    <div className="actions-panel">
      <div className="blocker" />

      {shouldDisplayEditForm() && (
        <div className="edit-panel">
          <EditPhotoDetails
            cardId={cardId}
            instagramPhoto={instagramPhoto}
            key={instagramPhoto.id}
            lipPhoto={lipPhoto}
            updatePhotoState={updatePhotoState}
            user={user}
          />
        </div>
      )}

      {shouldDisplayAddLink() && (
        <div className="stats-panel no-stats">
          <Button
            className="-is-no-stats"
            onClick={onClickAddLinkButton}
            text="Tap to add a link"
          />
        </div>
      )}
    </div>
  </div>
);

const handlers = withHandlers({
  onClickAddLinkButton:
    ({ setIsEditing }) =>
    () =>
      setIsEditing(true),
});

const props = withProps(({ activePanel, instagramPhoto, lipPhoto, url, user, isEditing }) => ({
  isHidden: (lipPhoto || {}).view_status === 'hidden',
  isPinned: (lipPhoto || {}).view_status === 'pinned',

  // Always display the actions if we're in stats view
  // Only display the actions in edit view if there's a URL
  shouldDisplayCardActions: () => {
    if (activePanel === 'stats') return true;
    return !!(activePanel === 'edit' && url);
  },

  shouldDisplayEditForm: () => {
    if (activePanel !== 'edit') return false;
    if (isEditing) return true;
    if (url) return true;
    return false;
  },

  shouldDisplayAddLink: () => {
    if (activePanel !== 'edit') return false;
    if (isEditing) return false;
    if (!url) return true;
    return false;
  },

  // A card's title can come from:
  //  1. A LIP photo record
  //  2. The default link (if any)
  // If a photo has a URL in an IG photo's caption there's no possible title
  title: () => {
    const hasExplicitUrl = (lipPhoto || {}).url || instagramPhoto.url;
    if ((lipPhoto || {}).title) return lipPhoto.title;
    if (hasExplicitUrl) return '';
    return (user.default_link || {}).title || '';
  },

  // A card's description can come from:
  //  1. A LIP photo record
  //  2. The default link (if any)
  // If a photo has a URL in an IG photo's caption there's no possible description
  description: () => {
    const hasExplicitUrl = !!(lipPhoto || {}).url || instagramPhoto.url;
    if ((lipPhoto || {}).description) return lipPhoto.description;
    if (hasExplicitUrl) return '';
    return (user.default_link || {}).description || '';
  },
}));

export default compose(
  withState('isEditing', 'setIsEditing', false),
  handlers,
  props,
  propTypes,
)(PhotoCard);
