import React from 'react';
import styled from 'styled-components/macro';
import { compose, withState, mapProps } from 'lib/recompose';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import { withList } from 'components/ui/base/list';
import EnvelopeIcon from 'components/icons/envelope';
import { updateAccountIdentityMutation } from 'lib/graphql/mutations';
import Select from './select';

const Icon = styled(EnvelopeIcon)`
  width: 1.25em;
  margin-right: 10px;
  color: black;
`;

const Layout = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `};
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
`;

const Controls = styled.div`
  flex: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 1em;
`;

const Username = styled.span`
  word-break: break-all;
`;

function IdentityListItem(props) {
  const { username, id: identityId } = props.identity;
  const { accountIdentities } = props.currentAccount;
  const accountIdentity = accountIdentities.find((x) => x.identityId === identityId);

  return (
    <Layout disabled={props.disabled}>
      <Content>
        <Icon />
        <Username>{username}</Username>
      </Content>
      <Controls>
        <Select
          onChange={props.updateAccountIdentity(identityId)}
          options={['never', 'daily', 'weekly', 'monthly']}
          defaultValue={accountIdentity.statsEmailFrequency}
        />
      </Controls>
    </Layout>
  );
}

export default compose(
  withState('needsRefresh', 'setNeedsRefresh', false),
  withRouter,
  withIdentityAccounts,
  mapProps((props) => {
    const { identities } = props.currentAccount;
    const emailIdentities = (x) => x.provider === 'email';

    return {
      ...props,
      currentAccount: {
        ...props.currentAccount,
        identities: identities.filter(emailIdentities),
      },
    };
  }),
  withList((props) => ({
    iterator: props.currentAccount.identities,
    propName: 'identity',
    key: 'id',
  })),
  graphql(updateAccountIdentityMutation, {
    props: ({ mutate, ownProps }) => ({
      updateAccountIdentity: (identityId) => (event) => {
        const { value: statsEmailFrequency } = event.target;
        const { id: accountId } = ownProps.currentAccount;
        const { refetch } = ownProps.data;

        return mutate({
          variables: { accountId, identityId, statsEmailFrequency },
          update: (proxy, { data: { updateAccountIdentity } }) => {
            const errors = updateAccountIdentity.errors;
            if (errors) return console.error(errors);
            refetch();
          },
        });
      },
    }),
  }),
)(IdentityListItem);
