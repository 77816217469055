import React from 'react';
import { Text } from 'components/web-ui';
import { Check as BaseCheck } from 'components/icons';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  line-height: 1.35;
  margin-bottom: 8px;
  color: ${({ isIncluded, theme: { colors } }) =>
    isIncluded ? colors.primaryPurple : colors.grey};
`.withComponent(({ isIncluded, ...rest }) => <div {...rest} />);

const CheckLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  margin-right: 8px;
  margin-top: 1px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.primaryPurple};
`.withComponent(({ isIncluded, ...rest }) => <div {...rest} />);

const Check = styled(BaseCheck)`
  path {
    fill: #ffffff;
  }
`;

const NoCheck = styled.div`
  margin-right: 8px;
  height: 16px;
  width: 16px;
  border: 2px solid #e5e5e5;
  border-radius: 50%;
`;

function Feature(props) {
  const { feature, isIncluded } = props;
  return (
    <Layout isIncluded={isIncluded}>
      {isIncluded ? (
        <CheckLayout>
          <Check width={10} height={10} />
        </CheckLayout>
      ) : (
        <NoCheck />
      )}
      <Text children={feature.description} />
    </Layout>
  );
}

export default Feature;
