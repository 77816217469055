import * as Sentry from '@sentry/browser';
import { graphql } from '@apollo/react-hoc';
import { updateCardMutation } from 'lib/graphql/mutations';
import { denormalizeCardMutationVariables, normalizeCardData } from 'lib/card-form-utils';

export default graphql(updateCardMutation, {
  props: ({
    mutate,
    ownProps: {
      card,
      loadInitialFormData,
      resetValidationErrors,
      setFormState,
      setIsPersisting,
      setValidationErrors,
    },
  }) => ({
    handleCardFormSubmit: async (formState, deckId) => {
      setIsPersisting(true);

      let saveSuccess = false;

      const formData = denormalizeCardMutationVariables(formState);
      const { cardInputArguments: input } = formData;
      const cardId = formData.id;

      try {
        await mutate({
          mutation: updateCardMutation,
          variables: {
            input,
            cardId,
            deckId,
          },
          update: (proxy, { data: updateCard }) => {
            if (updateCard.updateCard.validationErrors) {
              // Save failed. ONLY update the validation errors
              setValidationErrors(updateCard.updateCard.validationErrors);
            } else {
              // You MUST reset the validation errors before updating the formState. If you do not
              // do this the validation errors will be out of sync with the form's state and you'll
              // be in a world of pain, as indeed I was, and not for the first time.
              setValidationErrors(null);
              // Then reset the formState with the new card so that the IDs of any CTAs get set properly.
              setFormState(normalizeCardData(updateCard.updateCard.card));
              saveSuccess = true;
            }
          },
        });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      } finally {
        setIsPersisting(false);
      }
      return saveSuccess;
    },
  }),
});
