// A module…
//
// 1. MUST export default a function called reducer()
// 2. MUST export its action creators as functions
// 3. MUST have action types in the form npm-module-or-app/reducer/ACTION_TYPE
// 4. MAY export its action types as UPPER_SNAKE_CASE, if an external reducer needs to listen
//    for them, or if it is a published reusable library

const SET_CARD_KIND = 'tb.onboarding.setCardKind';
const SET_TARGET = 'tb.onboarding.setTarget';

const initialState = { card: {} };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CARD_KIND:
      return { ...state, card: action.attrs };
    case SET_TARGET:
      return { ...state, element: action.element };
    default:
      return state;
  }
}

export function setOnboardingCard(attrs) {
  return { type: SET_CARD_KIND, attrs };
}

export function setOnboardingTarget(element) {
  return { type: SET_TARGET, element };
}
