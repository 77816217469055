import React from 'react';
import { compose } from 'recompose';
import Tweet from 'components/cards/stats/cards/twitter/tweet';
import withLoader from 'components/cards/stats/elements/loading-trends';

function sortTweets(nodes) {
  return nodes.sort((a, b) => {
    if (a.total !== b.total) {
      return a.total > b.total ? -1 : 1;
    }
    if (a.tweet && b.tweet) {
      const t1 = new Date(a.tweet.created_at);
      const t2 = new Date(b.tweet.created_at);
      return t1 > t2 ? -1 : 1;
    }
    return 0;
  });
}

function Trends(props) {
  if (!props.data || !props.card || !props.card.twitter) return null;

  const { summary, unit, card, data, timeline } = props;
  const { username } = card.twitter;
  const { edges } = data;
  const nodes = edges.map((edge) => edge.node);
  const sortedNodes = sortTweets(nodes);

  return sortedNodes.map((node, i) => (
    <Tweet
      key={i}
      unit={unit}
      summary={summary}
      username={username}
      timeline={timeline}
      {...node}
    />
  ));
}

export default compose(withLoader)(Trends);
