import React from 'react';
import styled from 'styled-components/macro';
import InstagramMediaItemMeta from './instagram-media-item-meta';
import Media from './media';

const Layout = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
`;

const MediaContainer = styled.div`
  &:after {
    content: '';
    display: block;
    padding-top: 100%; /* Size the container so when the image is loading it's still the right height */
    background-color: #f2f3f3;
  }
`;

function Photo(props) {
  const {
    children,
    instagramPhoto: { created_time, url, likes, comments, link },
    isPinned,
  } = props;

  return (
    <Layout>
      <MediaContainer>
        <Media {...props.instagramPhoto} />
      </MediaContainer>
      {comments && likes && (
        <InstagramMediaItemMeta
          className="-is-dashboard"
          commentCount={comments.count}
          createdAt={created_time}
          instagramLink={link}
          isPinned={isPinned}
          likeCount={likes.count}
          url={url}
        />
      )}
      {children}
    </Layout>
  );
}

export default Photo;
