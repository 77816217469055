import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, withHandlers, renderNothing } from 'lib/recompose';
import styled from 'styled-components/macro';
import withInstagramLogin from 'hocs/instagram/with-instagram-login';
import { withRouter } from 'react-router';

const Button = styled.button`
  border-radius: 1em;
  font-size: 14px;
  width: 100%;
  height: 2em;
  padding: 0 10px 1px 10px;
  display: flex;
  align-items: center;
  color: white;
  background-color: ${(p) => p.theme.colors.alert};
  border: 0;
  text-align: center;
`;

function FixInstagramButton({ identity, handleClick }) {
  return <Button onClick={handleClick} children="Fix" />;
}

FixInstagramButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default compose(
  branch(
    //
    (props) => props.identity.provider !== 'instagram' || props.identity.tokenIsValid,
    renderNothing,
  ),
  withRouter,
  withInstagramLogin,
  withHandlers({
    handleClick: (props) => (event) => {
      event.preventDefault();
      props.handleInstagramLogin();
    },
  }),
)(FixInstagramButton);
