import React from 'react';
import * as Recompose from 'lib/recompose';
import PropTypes from 'prop-types';

const withConditionalRender = Recompose.compose(
  Recompose.setPropTypes({
    renderOnlyIf: PropTypes.bool.isRequired,
  }),
  Recompose.defaultProps({
    renderOnlyIf: false,
  }),
  Recompose.branch(
    (props) => !props.renderOnlyIf,
    Recompose.renderComponent(Recompose.omitProps('renderOnlyIf')(React.Fragment)),
  ),
);

export default withConditionalRender;
