import React from 'react';
import { Text } from 'components/web-ui';
import CardLayout from 'components/cards/preview/layout';
import CardPreviewHeader from 'components/headers/card-preview-header';
import * as Styled from './styled';
import SocialLinks from './social-links';
import UploadImageButton from './upload-image-button';
import { trimProtocol } from '@tap-bio/core/validators/url';

/**
 * --- The Component ---
 * Displays a preview version of the profile card.
 * If there's no profile (default) background image then we:
 *  - display UI to upload an image
 *  - Use opacity on the text
 */

const ProfilePreview = ({
  card,
  cardIndexInDeck,
  currentAccount,
  formState,
  handleClickBio,
  handleFieldChange,
  handleUpdateProfileBackgroundImage,
  hasDefaultBackgroundImage,
  isCardVisible,
  setFormState,
  onImageSelected,
  shouldTruncate,
  truncated,
  uploadedPhotoUrl,
  ...props
}) => {
  const { profile } = currentAccount;
  return (
    <CardLayout
      card={card}
      hasFooterOverlay={card.hasBackgroundGradient}
      isCardVisible={isCardVisible}
    >
      <CardPreviewHeader
        currentAccount={currentAccount}
        card={card}
        cardIndexInDeck={cardIndexInDeck}
        isVisible={card.editable && hasDefaultBackgroundImage}
      />

      <Styled.UploadContainer hidden={hasDefaultBackgroundImage}>
        <UploadImageButton profile={profile} onImageSelected={onImageSelected} />
        <Styled.SkipButton onClick={props.handleSkip} hidden={hasDefaultBackgroundImage}>
          Skip ➞
        </Styled.SkipButton>
      </Styled.UploadContainer>

      <Styled.Content scrollable={hasDefaultBackgroundImage}>
        {profile.name ? (
          <Styled.Name children={profile.name} />
        ) : (
          <Styled.OnboardingLink onClick={props.handleOnboardingLinkClick}>
            <Styled.Name children="Tap to Add your Name" />
          </Styled.OnboardingLink>
        )}

        {profile.title ? (
          <Styled.Title children={profile.title} />
        ) : (
          <Styled.OnboardingLink onClick={props.handleOnboardingLinkClick}>
            <Styled.Title children="Tap to Add a Description about Yourself" />
          </Styled.OnboardingLink>
        )}

        {profile.website && (
          <Styled.WebsiteLink href={profile.website}>
            <Text children={trimProtocol(profile.website)} />
          </Styled.WebsiteLink>
        )}

        <Styled.SocialIconsContainer>
          <SocialLinks ctas={card.ctas} />
        </Styled.SocialIconsContainer>

        <Styled.Bio children={profile.bio} />
      </Styled.Content>
    </CardLayout>
  );
};

export default ProfilePreview;
