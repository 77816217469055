const common = {
  environment: process.env.NODE_ENV,
  ENABLE_PROFILE_ONBOARDING: false,
  IG_AUTH_LS_KEY: 'tb_ig_auth_params',
  MAILCHIMP_AUTH_LS_KEY: 'tb_mailchimp_auth_params',
  CONSTANTCONTACT_AUTH_LS_KEY: 'tb_constantcontact_auth_params',
  // This must match the value on the server
  // We need this because we need to detect the presence of the default image and it's best to
  // compare with a constant
  DEFAULT_BACKGROUND_MEDIA_URL: 'https://tb.imgix.net/default-profile-background.png',

  // The string passed to the image uploader's `uploadProps` for the `accept` key.
  ALLOWED_IMAGE_EXTENSIONS: '.jpeg,.jpg,.gif,.png,.heic',

  profileColorChoices: {
    tapBioPrimary: [
      '#EF5777',
      '#575FCF',
      '#4BCFFA',
      '#34E7E4',
      '#0BE881',
      '#FFC048',
      '#FFD32A',
      '#FF5E57',
      '#1ABC9C',
      '#8E44AD',
      '#BD9391',
      '#2660A4',
    ],

    // Taken from the Apple colour picker as per https://cl.ly/e07f21353ba1/o

    tapBioAlt: [
      '#1BA4FC', // light blue
      '#0A4D7A', // dark blue
      '#2FE6CF', // aqua
      '#117B75', // dark aqua
      '#66D644', // light green
      '#0C700F', // dark green
      '#F9E047', // yellow
      '#FD9126', // orange
      '#FB6654', // orangy-red
      '#B31A11', // red
      '#ED62A8', // pink
      '#971D5E', // purple
    ],

    greyscale: [
      '#000000',
      '#141414',
      '#282828',
      '#3C3C3C',
      '#505050',
      '#646464',
      '#787878',
      '#8C8C8C',
      '#A0A0A0',
      '#B4B4B4',
      '#C8C8C8',
      '#DCDCDC',
    ],
  },
  profileTitleChoices: [
    'Entrepreneur',
    'Lifestyle Blogger',
    'Fashion Blogger',
    'Style Blogger',
    'Food Blogger',
    'Musician',
    'Artist',
    'Producer',
    'Chef',
    'Digital Photographer',
    'Stylist',
    'Makeup Artist',
    'Youtuber',
    'World Traveler',
    'Fitness Fanatic',
    'Beauty Guru',
    'Health Guru',
    'Designer',
    'Author',
    'Online Shop',
    'Coffee Shop',
    'Restaurant',
    'Bookstore',
    'Florist',
  ],
  profileTagChoices: [
    'Fashion',
    'Style',
    'Lifestyle',
    'Cooking',
    'Food',
    'Author',
    'Sports',
    'Music',
    'Photography',
    'Design',
    'Makeup',
    'Influencer',
    'Youtuber',
    'Recipe',
    'Outdoors',
    'Travel',
    'Adventure',
    'Nature',
    'Fitness',
    'Healthy',
    'Decorating',
    'Wedding',
  ],
};

export default common;
