import { graphql } from '@apollo/react-hoc';
import { cardQuery } from 'lib/graphql/queries';

export default graphql(cardQuery, {
  options: ({ match }) => ({ variables: { id: parseInt(match.params.cardId, 10) } }),
  props: ({ ownProps, data }) => {
    if (data.loading) return { data };
    return { card: data.card, data };
  },
});
