import { PlusIcon as BasePlusIcon } from 'components/icons';
import BaseButton from 'components/ui/button';
import { Text } from 'components/web-ui';
import withCallToActionStateHandlers from 'hocs/with-call-to-action-state-handlers';
import withImageStateHandlers from 'hocs/with-images-state-handlers';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { unsetPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';

const borderRadius = '10px';

const FormPopoverOuterContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2%;
  background-color: ${(props) => props.theme.colors.alphaBlack30};
  z-index: 10;
`;

const FormPopoverInnerContainer = styled.div`
  background-color: white;
  border-radius: ${borderRadius};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const PopoverHeader = styled.div`
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 50px;
  position: relative;
  font-size: 18px;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #eeeeee;
`;

const PopoverForm = styled.div`
  background-color: white;
  border-bottom: none;
  border-bottom-right-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 0 14px 15px 14px;
  flex-direction: column;
`;

const PopoverTitle = styled(Text)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  line-height: 1;
  margin-top: 2px;
`;
const PopoverDone = styled(Text)``;

const CancelIcon = styled(BasePlusIcon)`
  position: absolute;
  right: 12px;
  top: calc(50% - 10px);
  transform: rotate(-45deg);

  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const ClearButton = styled(BaseButton)`
  padding: 7px 5px;
  width: 60px;
  position: absolute;
  left: 15px;
`;

function FormBasePopover(props) {
  const { children, title, unsetPopover, clearImageForm, image } = props;
  return (
    <FormPopoverOuterContainer onClick={unsetPopover}>
      <FormPopoverInnerContainer onClick={(e) => e.stopPropagation()}>
        <PopoverHeader>
          {image && <ClearButton onClick={clearImageForm}>Clear</ClearButton>}
          <PopoverTitle children={title} />
          <PopoverDone onClick={unsetPopover}>
            <CancelIcon width={22} height={22} />
          </PopoverDone>
        </PopoverHeader>
        <PopoverForm children={children} />
      </FormPopoverInnerContainer>
    </FormPopoverOuterContainer>
  );
}

export default compose(
  withCallToActionStateHandlers,
  withImageStateHandlers,
  connect(null, (dispatch, ownProps) => ({
    clearImageForm: (event) => {
      event.preventDefault();
      const { card, image, clearImageState } = ownProps;
      const originalImage = card.images.find((i) => i.id === image.id);

      if (originalImage) clearImageState(originalImage);
    },

    unsetPopover: (event) => {
      const {
        card,
        cta,
        image,
        removeCallToActionFromState,
        resetCallToActionState,
        resetImageState,
      } = ownProps;

      // Always hide the popover
      dispatch(unsetPopover());

      // If there is no card (e.g. the popover was for the button colour) return early
      if (!card) return;

      // When closing a popover, we need to reset any formState that was in use because the
      // popover may have been closed without saving the form, in which care re-opening the
      // popover should display the data from the cache / query, NOT the edited formState.
      //
      // Additionally, we need to remove any CTAs that were added to the state
      if (image) {
        const originalImage = card.images.find((i) => i.id === image.id);
        if (originalImage) resetImageState(originalImage);
      } else {
        const originalCallToAction = card.ctas.find((c) => c.id === cta.id);
        if (originalCallToAction) {
          resetCallToActionState(originalCallToAction);
        } else {
          // Remove any unsaved CTAs from the formState
          removeCallToActionFromState(cta.id);
        }
      }
    },
  })),
)(FormBasePopover);
