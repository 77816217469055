import * as Sentry from '@sentry/browser';
import { Field, Select, ValidationError } from 'components/ui';
import { getFieldValue } from 'lib/card-form-utils';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { withHandlers } from 'recompose';

function getErrorMessage(validationErrors, index, key) {
  // There is always a key in validationError for the e.g. cta, but the array of errors
  // may be blank
  if (isEmpty(validationErrors[index].errors)) return null;

  // Get the first error message for the key
  const error = validationErrors[index].errors.find((e) => e.key === key);

  // Exit if there was no error message found for the key
  if (!error) return null;

  return error.message;
}

function getValidationError(formState, validationErrors, name) {
  /*
   * validationErrors look like this:
   *
   *   [
   *     {
   *       key: "cta-0",
   *       errors: {}
   *     },
   *     {
   *       key: "cta-1",
   *       errors: [
   *         {
   *           key: "title"
   *           message: "cannot be blank"
   *         },
   *         {
   *           key: "url"
   *           message: "is not a valid link"
   *         }
   *       ]
   *     }
   *   ]
   *
   *
   * We need to map them to form state keys that look like this:
   *
   *   entities.ctas.24.url
   *   entities.ctas.new-xoqddg.url
   *
   */

  // Initially (on load) we have no validation errors
  if (isEmpty(validationErrors)) return null;

  // Find the index of the cta
  let entityId = null;
  let entityIds = [];
  let entityType = null;
  let key;
  const parts = name.split('.');

  // Validating an entity / related record?
  if (parts.length === 4) {
    // e.g.
    // ['entities', 'ctas', '24', 'url']
    // ['entities', 'youtubes', '7', 'username']
    entityType = parts[1];
    entityId = parts[2];
    key = parts[3];
  } else if (parts.length === 1) {
    // ['email']
    key = parts[0];
  } else {
    Sentry.captureMessage("Couldn't determine an error's key", { parts });
    console.error("Couldn't determine an error's key", { parts });
    return null;
  }

  /*
   * Handle an error for an entity
   */

  // Set index for a top level error
  let index = 0;

  // Set index for a nested entity
  if (entityId) {
    // If the entity is an array type, find the index
    // Otherwise just use the first index
    const entities = get(formState, `result.${entityType}`);
    if (isArray(entities)) {
      entityIds = entities.map((id) => String(id));
      index = entityIds.indexOf(entityId);
    } else {
      index = 0;
    }
  }

  const errorMessage = getErrorMessage(validationErrors, index, key);

  if (!errorMessage) return null;

  // Return the error component for rendering
  return <ValidationError key={`${name}.error`} children={errorMessage} />;
}

export default withHandlers({
  renderField: (formProps) => (props) => {
    const { handleFieldChange, formState, validationErrors } = formProps;
    const { label, name, type = 'text', ...rest } = props;

    return (
      <Field
        label={label}
        name={name}
        type={type}
        onChange={handleFieldChange}
        validationError={getValidationError(formState, validationErrors, name)}
        value={getFieldValue(formState, name)}
        {...rest}
      />
    );
  },

  renderCheckbox:
    ({ handleFieldChange, formState, validationErrors }) =>
    ({ label, name, ...rest }) => (
      <Field
        label={label}
        name={name}
        type="checkbox"
        onChange={handleFieldChange}
        validationError={getValidationError(formState, validationErrors, name)}
        checked={getFieldValue(formState, name)}
        {...rest}
      />
    ),

  renderSelect:
    ({ handleFieldChange, formState, validationErrors }) =>
    ({ label, name, ...rest }) => (
      <Select
        label={label}
        name={name}
        onChange={handleFieldChange}
        validationError={getValidationError(formState, validationErrors, name)}
        value={getFieldValue(formState, name)}
        {...rest}
      />
    ),
});
