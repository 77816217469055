import React from 'react';
import {
  compose,
  pure,
  withProps,
  mapProps,
  withState,
  withHandlers,
  defaultProps,
} from 'recompose';
import { Text } from 'components/web-ui';
import TextInput from 'components/ui/base/text-input';
import styled from 'styled-components/macro';
import Label from './ui/label';
import Field from './ui/field';
import FormButton from './ui/form-button';

const FakeInput = styled.div`
  border-radius: 6px;
  line-height: 1.2;
  border: 1px solid #c2c2c2;
  margin-bottom: 5px;
  background-color: #fafafa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
`;

const FramelessInput = styled(TextInput)`
  border: none;
  font-size: 17px;
  flex: 1;
  color: ${(props) => props.theme.colors.veryDarkPurple};
  margin-bottom: 0;
  padding: 10px 10px;

  :focus {
    border: none;
  }
`;

const TagList = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;

  height: 150px;
  overflow: hidden;
  overflow-x: scroll;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
`;

const Tag = styled.span`
  background-color: white;
  margin: 4px 8px 4px 0;
  padding: 4px 10px;
  font-size: 15px;
  border: 2px solid currentColor;
  border-radius: 6px;
  text-align: center;

  color: ${(props) => (props.matched ? '#cacaca' : props.theme.colors.veryDarkPurple)};
  opacity: ${(props) => (props.matched ? 0.5 : 1)};

  :active,
  :focus {
    outline: none;
  }
`;

const SelectedTag = styled(Tag)`
  background-color: ${(props) => props.theme.colors.veryDarkPurple};
  color: white;
  margin: 2px;
  padding: 4px 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  border: none;
`;

const CloseIcon = styled.span`
  font-size: 24px;
  padding-left: 4px;
  -webkit-font-smoothing: antialiased;
  font-weight: 100;
  pointer-events: none;
`;

const TagCompleteField = ({
  hint,
  expanded,
  selectedTags,
  setSelectedTags,
  tagList,
  limit,
  setTagList,
  handleCustomTagInputChange,
  handleCustomTagInputKeyDown,
  removeTag,
  customTagInput,
  setCustomTagInput,
  addTag,
  onSubmit,
  onFocus,
  submitText,
  hidden,
  valid,
  displayFormButton,
  label,
  ...props
}) => {
  return (
    <Field hidden={hidden}>
      <Label children={label} />
      <input {...props} hidden />
      <FakeInput>
        {selectedTags.map((tag) => (
          <SelectedTag key={tag} onClick={removeTag} data-value={tag}>
            {tag}
            <CloseIcon>&times;</CloseIcon>
          </SelectedTag>
        ))}
        {selectedTags.length < limit && (
          <FramelessInput
            placeholder="Tags"
            onChange={handleCustomTagInputChange}
            onKeyDown={handleCustomTagInputKeyDown}
            value={customTagInput}
            onFocus={onFocus}
          />
        )}
      </FakeInput>
      {expanded && <Text />}
      {expanded && (
        <span>
          <TagList>
            {tagList.map((tag) => {
              return (
                <Tag
                  key={tag.text}
                  onClick={addTag}
                  matched={tag.matched}
                  data-value={tag.text}
                  children={tag.text}
                />
              );
            })}
          </TagList>
          {displayFormButton && (
            <FormButton disabled={!valid} children={submitText} onClick={onSubmit} />
          )}
        </span>
      )}
    </Field>
  );
};

export default compose(
  defaultProps({
    expanded: true,
    displayFormButton: true,
    tags: [],
  }),
  withState('customTagInput', 'setCustomTagInput', ''),
  withProps((props) => ({ selectedTags: props.value ? props.value.split(',') : [] })),
  mapProps((props) => {
    const { selectedTags, tags } = props;

    return {
      ...props,
      tagList:
        tags.map((text) => {
          const matched = selectedTags.map((tag) => tag.toLowerCase()).includes(text.toLowerCase());
          return { text, matched };
        }) || [],
    };
  }),
  withHandlers({
    removeTag: (props) => (event) => {
      const { selectedTags, onChange, name } = props;
      const { value: removedTag } = event.target.dataset;
      const filterRemovedTag = (tag) => {
        if (!tag) return;
        if (!removedTag) return;
        return tag.toLowerCase() !== removedTag.toLowerCase();
      };
      const tags = selectedTags.filter(filterRemovedTag);

      onChange(name, tags.join(','));
    },

    addTag: (props) => (event) => {
      const { selectedTags, onChange, name, limit } = props;
      const { value: addedTag } = event.target.dataset;

      if (selectedTags.length >= limit) return;
      if (selectedTags.includes(addedTag)) return;

      selectedTags.push(addedTag);
      onChange(name, selectedTags.join(','));
    },

    handleCustomTagInputChange: (props) => (event) => {
      const { setCustomTagInput } = props;
      const { value } = event.target;

      setCustomTagInput(value);
    },

    handleCustomTagInputKeyDown: (props) => (event) => {
      const { keyCode } = event;
      const { value } = event.target;
      const { selectedTags, setCustomTagInput, onChange, name } = props;
      const triggers = [9, 186, 188, 13];

      if (triggers.includes(keyCode)) {
        event.preventDefault();
        if (!selectedTags.map((tag) => tag && tag.toLowerCase()).includes(value.toLowerCase())) {
          selectedTags.push(value);
        }
        onChange(name, selectedTags.join(','));
        setCustomTagInput('');
      }
    },
  }),
  pure,
)(TagCompleteField);
