import querystring from 'querystring';
import { withProps } from 'recompose';

/**
 * @summary A HOC that adds a backgroundImageUrl prop, either
 *          the card's background image (if set), or the
 *          default background image from a profile.
 *
 * @returns {(String|void)} - The param's value, or undefined
 *          if no param is found
 */
export default withProps(
  ({
    card,
    currentAccount: {
      profile: { defaultBackgroundMediaUrl, brandColor },
    },
  }) => {
    const mediaUrl = card.backgroundMediaUrl || defaultBackgroundMediaUrl;
    const blendColor = card.backgroundColor || brandColor;

    if (!mediaUrl) return;

    const [url, params] = mediaUrl.split('?');
    const { w, ...qs } = querystring.parse(params);
    const ext = url.split('.').pop();
    const backgroundAnimated = ext === 'gif';

    const compression = {
      w: window.screen.width < 540 ? 375 : 540,
      cs: 'strip',
      dpr: window.devicePixelRatio || 1,
    };

    if (!backgroundAnimated) {
      compression.auto = 'format';
    }

    const dramaFilter = {
      blend: blendColor,
      bm: 'multiply',
    };

    const adjustments =
      qs.blend && qs.bm !== 'normal'
        ? { ...qs, ...compression, ...dramaFilter }
        : { ...qs, ...compression };
    const backgroundMediaUrl = url + '?' + querystring.stringify(adjustments);

    return { backgroundAnimated, backgroundMediaUrl };
  },
);
