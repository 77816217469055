import verticalSmoothScrolling from 'components/cards/shared/styles/vertical-smooth-scrolling-css';
import ScrollContainer from 'components/ui/scroll-container';
import pick from 'lodash/pick';
import React from 'react';
import { connect } from 'react-redux';
import { hidecardContentOverlay, showcardContentOverlay } from 'store/modules/card-content-overlay';
import styled from 'styled-components/macro';

function hasOverflowingChildren(element) {
  return element.offsetHeight < element.scrollHeight;
}

// Detects whether the card has sufficient content to show the content overlay
// and toggles redux state to show/hide the overlay.
function onMountOrUpdate(element, props) {
  const methodVerb = hasOverflowingChildren(element) ? 'show' : 'hide';
  props[`${methodVerb}FooterOverlay`](props.cardId);
}

export const ScrollableBase = styled(ScrollContainer)`
  display: flex;
  bottom: 0;
  flex-direction: column;
  flex: 1;
  padding: ${(p) => p.topPadding} 0 ${(p) => p.bottomPadding} 0;
  overflow-y: overlay;
  ${verticalSmoothScrolling};
`;

// This allows us to vertically center the content when it's below the scrolling threshold,
// but view all the content (i.e. justify: flex-start) when it's over the scroll threshold.
// https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
const ScrollableCentered = styled.div`
  padding: 0 ${(p) => p.xPadding};

  ${(p) => p.verticalAlign === 'center' && 'margin: auto 0;'};
`;

class Scrollable extends React.Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
  }

  componentDidMount() {
    onMountOrUpdate(this.element, this.props);
  }

  componentDidUpdate() {
    onMountOrUpdate(this.element, this.props);
  }

  render() {
    return (
      <ScrollableBase {...pick(this.props, ['id', 'verticalAlign', 'topPadding', 'bottomPadding'])}>
        <ScrollableCentered {...pick(this.props, ['xPadding', 'verticalAlign'])}>
          {this.props.children}
        </ScrollableCentered>
      </ScrollableBase>
    );
  }
}

Scrollable.defaultProps = {
  xPadding: '32px',
  topPadding: '85px',
  bottomPadding: '10px',
  id: 'scrollable-container',
  verticalAlign: 'center',
};

export default connect(null, (dispatch) => ({
  showFooterOverlay: (cardId) => dispatch(showcardContentOverlay(cardId)),
  hideFooterOverlay: (cardId) => dispatch(hidecardContentOverlay(cardId)),
}))(Scrollable);
