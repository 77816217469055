import routes from 'config/routes';
import displayLoadingState from 'hocs/display-loading-state';
import withDefaultDeck from 'hocs/with-default-deck';
import withUpdateDeckSortOrderMutation from 'hocs/with-update-deck-sort-order-mutation';
import isEqual from 'lodash/isEqual';
import { arrayMove } from 'react-sortable-hoc';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';

export default compose(
  withUpdateDeckSortOrderMutation,
  withDefaultDeck,
  displayLoadingState,
  withState(
    'sortedCards',
    'setSortedCards',
    ({
      data: {
        defaultDeck: { activeCards },
      },
    }) => activeCards,
  ),
  withProps(({ currentAccount: { username } }) => {
    const backURL = routes.account(username);
    return { backURL };
  }),
  withHandlers({
    onSortEnd:
      ({
        data: {
          defaultDeck: { id: deckId },
        },
        updateDeckSortOrder,
        setSortedCards,
      }) =>
      ({ oldIndex, newIndex }, event) => {
        setSortedCards((state) => {
          const newOrder = arrayMove(state, oldIndex, newIndex);
          updateDeckSortOrder(
            newOrder.map((i) => i.id),
            deckId,
          );
          return newOrder;
        });
      },
  }),

  // When we toggle a card's `hidden` attribute via the ToggleHiddenButton component, we need to
  // update THIS component's state cache so that the change is reflected immediately in the UI.
  lifecycle({
    componentDidUpdate(prevProps) {
      const shouldUpdateCardsState = !isEqual(
        prevProps.data.defaultDeck.activeCards.map((c) => c.hidden),
        this.props.data.defaultDeck.activeCards.map((c) => c.hidden),
      );
      if (shouldUpdateCardsState) {
        this.props.setSortedCards(this.props.data.defaultDeck.activeCards);
      }
    },
  }),
);
