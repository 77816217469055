import React from 'react';
import Transition from 'react-transition-group/Transition';
import styled from 'styled-components/macro';
import { Blocker } from 'components/ui';

const ModalContainer = styled.div`
  padding: 0 14px 8px 14px;
  border-radius: 8px;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  transform: translate(0%, -50%);
  font-size: 14px;
  transition: opacity 0.1s linear;
  opacity: 0;
  /* Always above the blocker (which is set to 100) */
  z-index: 101;
  pointer-events: auto;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ state }) => state === 'entered' && `opacity: 1;`};
`.withComponent(({ state, left, top, ...rest }) => <div {...rest} />);

const Close = styled.span`
  font-size: 50px;
  font-weight: 100;
  position: absolute;
  right: -3px;
  top: -59px;
  color: white;
`;

function Modal(props) {
  const { children, onClickClose } = props;
  return (
    <React.Fragment>
      <Blocker opacity={0.5} zIndex={100} onClick={onClickClose} />
      <Transition appear={true} in={true} timeout={0}>
        {(state) => (
          <ModalContainer state={state}>
            <Close onClick={onClickClose} children="&times;" />
            {children}
          </ModalContainer>
        )}
      </Transition>
    </React.Fragment>
  );
}

export default Modal;
