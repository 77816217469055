import { css } from 'styled-components';
import { GRADIENTS } from 'components/cards/preview/layout';

export default css`
  &::before {
    background: rgba(0, 0, 0, 0.35);
    background: ${GRADIENTS.backgroundImage};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
