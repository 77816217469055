import React from 'react';
import { Heading } from 'components/web-ui';
import styled from 'styled-components/macro';
import Feature from './feature';

const ProductFeaturesContainer = styled.div`
  margin: 10px 0 17px 20px;
`;

const LostFeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.billing.featuresBackground};
  padding: 4% 3% 1% 3%;
  border-radius: 4px;
  margin-bottom: 10%;
`;

const PlanFeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.billing.featuresBackground};
  padding: 4% 3% 1% 3%;
  border-radius: 4px;
  margin-bottom: 6%;
`;

const FeaturesHeading = styled(Heading)`
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.primaryPurple};
  margin-bottom: 1.5em;
`;

const Grid = styled.div`
  width: 48.5%;
  :nth-child(even) {
    margin-right: 1.5%;
  }
  :nth-child(odd) {
    margin-left: 1.5%;
  }
`;

function Features({ features, product, showOnlyEnabled }) {
  //
  // Displayed when downgrading a plan
  if (!product) {
    return (
      <LostFeaturesContainer>
        <FeaturesHeading children="You'll lose access to" />
        {features.map((feature) => (
          <Grid key={feature.id}>
            <Feature feature={feature} isIncluded />
          </Grid>
        ))}
      </LostFeaturesContainer>
    );
  } else {
    return showOnlyEnabled ? (
      // Displayed when selecting a plan
      // Displays all features for the plan
      <PlanFeaturesContainer>
        <FeaturesHeading children="Features" />
        {features.map(
          (feature) =>
            product.features.includes(feature.id) && (
              <Grid key={feature.id}>
                <Feature feature={feature} isIncluded />
              </Grid>
            ),
        )}
      </PlanFeaturesContainer>
    ) : (
      // Displayed when selecting a product
      // Dynamically enables / disables features based on plan level
      <ProductFeaturesContainer>
        {features.map((feature) => (
          <Feature
            feature={feature}
            isIncluded={product.features.includes(feature.id)}
            key={feature.id}
          />
        ))}
      </ProductFeaturesContainer>
    );
  }
}

export default Features;
