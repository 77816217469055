import React from 'react';
import { compose, withProps, withState, lifecycle, branch, renderComponent } from 'recompose';
import * as Sentry from '@sentry/browser';
import ErrorMessage from 'components/errors/error-message';

const ErrorComponent = (props) => {
  return props.customError ? <props.customError /> : <ErrorMessage />;
};

const ErrorBoundary = compose(
  withState('hasError', 'setHasError', false),
  lifecycle({
    componentDidCatch(error, info) {
      this.props.setHasError(true);
      Sentry.captureException(error, info);
    },
  }),
  branch((props) => props.hasError, renderComponent(ErrorComponent)),
)((props) => props.children);

const withErrorBoundary = (Component) => (props) => {
  const { customError, ...rest } = props;
  return (
    <ErrorBoundary customError={customError}>
      <Component {...rest} />
    </ErrorBoundary>
  );
};

const withErrorComponent = (customError) =>
  withProps({
    customError,
  });

export { withErrorBoundary, withErrorComponent, ErrorBoundary };
export default ErrorBoundary;
