import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withProps, setPropTypes, defaultProps, pickProps } from 'lib/recompose';
import { css } from 'styled-components';
import addLightenFilter from 'components/ui/add-lighten-filter';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import withDefaultDeck from 'hocs/with-default-deck';
import withBackgroundMediaUrl from 'components/cards/shared/hocs/with-background-media-url';
import withHasBlendMode from 'hocs/with-has-blend-mode';
import styled from 'styled-components/macro';
import AnimatedBackground from 'components/ui/animated-background';

const headerGradientContainer = css`
  content: '';
  position: absolute;
  top: 0;
  height: 15px;
  width: 100%;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
  z-index: 1;
`;

const footerGradientContainer = css`
  content: '';
  position: absolute;
  bottom: 0;
  height: 20%;
  width: 100%;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
  z-index: 1;
`;

export const GRADIENTS = {
  none: 'none',
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.9))`,
  cardPagination: `linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0))`,
  contentFade: `linear-gradient(to top, #000000 20%, rgba(0,0,0,0))`,
};

const addCardPaginationCSS = css`
  &::before {
    ${headerGradientContainer};
    background-image: ${GRADIENTS.cardPagination};
    z-index: 3;
  }
`;

export const addContentFadeCSS = css`
  &::after {
    ${footerGradientContainer};
    background-image: ${GRADIENTS.contentFade};
    z-index: 3;
    position: absolute;
    bottom: 0;
  }
`;

const getBackgroundColor = (props) => {
  if (props.backgroundColorSolid) return props.backgroundColorSolid;
  return props.backgroundAnimated || props.hasTransparentBackground
    ? 'transparent'
    : props.backgroundColor;
};

const getBackgroundImageGradient = (props) => {
  return props.hasFooterOverlay ? GRADIENTS.backgroundImage : GRADIENTS.none;
};

const getBackgroundImage = (props) => {
  if (props.backgroundAnimated || props.backgroundColorSolid || props.hasTransparentBackground)
    return 'none';
  return `${getBackgroundImageGradient(props)}, url(${props.backgroundMediaUrl})}`;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: ${getBackgroundColor};
  background-image: ${getBackgroundImage};
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  & > * {
    position: relative;
    z-index: 2;
  }

  ${(p) => p.hasBlendMode && addLightenFilter(p.backgroundColor, true)};
  ${(p) => p.hasHeaderOverlay && addCardPaginationCSS};
  ${(p) => false && p.cardOverlayIds.has(p.card.id) && addContentFadeCSS};
`;

function PreviewCardLayout(props) {
  return (
    <React.Fragment>
      {!props.backgroundColorSolid && props.backgroundAnimated && (
        <AnimatedBackground
          backgroundMediaUrl={props.backgroundMediaUrl}
          isCardVisible={props.isCardVisible}
        />
      )}
      <Wrapper {...props}>{props.children}</Wrapper>
    </React.Fragment>
  );
}

export default compose(
  connect((state) => ({ cardOverlayIds: state.cardContentOverlay.showOverlayCardIds })),
  setPropTypes({
    card: PropTypes.object.isRequired,
    hasHeaderOverlay: PropTypes.bool.isRequired,
    hasFooterOverlay: PropTypes.bool.isRequired,
  }),
  defaultProps({
    hasHeaderOverlay: true,
    hasFooterOverlay: false,
    hasTransparentBackground: false,
  }),
  withRouter,
  withIdentityAccounts,
  withDefaultDeck,
  withBackgroundMediaUrl,
  withHasBlendMode,
  withProps((props) => {
    return {
      backgroundColor: props.card.backgroundColor || props.currentAccount.profile.brandColor,
      backgroundColorSolid: props.card.backgroundColorSolid,
      isHidden: props.card.hidden,
    };
  }),
  pickProps(
    'card',
    'backgroundAnimated',
    'backgroundColor',
    'backgroundColorSolid',
    'backgroundMediaUrl',
    'brandColor',
    'children',
    'hasBackgroundOverlay',
    'hasBlendMode',
    'hasFooterOverlay',
    'hasHeaderOverlay',
    'hasTransparentBackground',
    'isHidden',
    'isCardVisible',
    'cardOverlayIds',
    'currentAccount',
  ),
)(PreviewCardLayout);
