import AddCallToActionButton from 'components/cards/forms/add-call-to-action-button';
import CallToActionButtons from 'components/cards/forms/call-to-action-buttons';
import Scrollable from 'components/cards/preview/scrollable';
import {
  DescriptionTextarea,
  StyledField,
  TitleTextarea,
} from 'components/cards/shared/styles/fields';
import withCallToActionStateHandlers, {
  handleDeleteCta,
  handleShowCtaForm,
} from 'hocs/with-call-to-action-state-handlers';
import withDestroyCallToActionMutation from 'hocs/with-destroy-call-to-action-mutation';
import { compose, withHandlers, withProps } from 'lib/recompose';
import React from 'react';
import { connect } from 'react-redux';
import { setSelectedCallToAction } from 'store/modules/card-call-to-action';
import { setSelectedPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';

const AddLinkButtonContainer = styled.div`
  position: absolute;
  bottom: 65px;
  left: 15px;
  right: 15px;
  color: rgba(255, 255, 255, 0.5);
`;

// --- Component ---

function SimpleCardForm(props) {
  const { buttonColor, card, handleDeleteCta, handleShowCtaForm, hasCtas, renderField } = props;
  const { brandColor } = props.currentAccount.profile;

  return (
    <Scrollable cardId={card.id} verticalAlign="top" xPadding="20px">
      {renderField({
        name: 'result.title',
        placeholder: 'Tap to add headline',
        selectAllOnFocus: true,
        styledField: StyledField,
        styledInput: TitleTextarea,
      })}
      {renderField({
        name: 'result.description',
        placeholder: 'Tap to add body',
        selectAllOnFocus: true,
        styledField: StyledField,
        styledInput: DescriptionTextarea,
      })}

      <CallToActionButtons
        brandColor={buttonColor || brandColor}
        ctas={card.ctas}
        onClick={handleShowCtaForm}
        handleDeleteCta={handleDeleteCta}
      />

      {!hasCtas && (
        <AddLinkButtonContainer>
          <AddCallToActionButton onClick={handleShowCtaForm.bind(this, null)} />
        </AddLinkButtonContainer>
      )}
    </Scrollable>
  );
}

export default compose(
  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setSelectedCallToAction: (id) => dispatch(setSelectedCallToAction(id)),
  })),
  withCallToActionStateHandlers,
  withDestroyCallToActionMutation,
  withProps(({ card }) => ({ hasCtas: card.ctas.length, buttonColor: card.buttonColor })),
  withHandlers({ handleShowCtaForm, handleDeleteCta }),
)(SimpleCardForm);
