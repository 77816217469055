import React from 'react';
import { Query } from '@apollo/react-components';
import { shouldUpdate } from 'lib/recompose';
import Spinner from 'components/ui/spinner';
import { tweetsPayloadQuery } from 'lib/graphql/queries';
import Error from './error';
import Tweet from './tweet';

function Tweets(props) {
  const { username, feed } = props;
  return (
    <Query query={tweetsPayloadQuery} variables={{ username, feed }}>
      {({ loading, error, data }) => {
        if (loading) return <Spinner />;

        // Handle a variety of cases relating to errors that can be returned by
        // the Twitter API.
        const statusCode = data.tweetsPayload.statusCode;
        if (error) return <Error statusCode={statusCode} />;
        if (data.tweetsPayload.statusCode !== 200)
          return <Error statusCode={statusCode} username={username} />;
        if (data.tweetsPayload.result.length === 0)
          return <Error statusCode={statusCode} username={username} isEmpty />;

        return data.tweetsPayload.result.map((tweet) => (
          <Tweet key={tweet.id} username={username} tweet={tweet} />
        ));
      }}
    </Query>
  );
}

// This component was continually re-rendering whilst entering each character of the
// username, thus also continually making the request to Twitter. To prevent this we'll
// only hit the API if the username prop changes which only happens after the save.
export default shouldUpdate((props, nextProps) => props.username !== nextProps.username)(Tweets);
