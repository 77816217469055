import React from 'react';
import * as Sentry from '@sentry/browser';
import theme from 'css/theme';
import { compose, branch, mapProps, renderNothing } from 'recompose';

const messages = {
  SessionChallengeExpired: 'Wups! This code has expired, please go back and request a new one!',

  SessionChallengeFailed:
    "That doesn't seem to be correct, double check the code we sent you and try again.",

  SessionChallengeLocked:
    "There's been too many failed attempts! Please go back and request a new login code!",

  default:
    "Hmm, an error occurred, but we're not sure what happened exactly. We've sent it to our team of developers so we can get it fixed ASAP. Sorry about that!",
};

const Error = compose(
  branch((props) => !(props.responseData || {}).error, renderNothing),
  mapProps(({ light, responseData }) => ({
    color: light ? '#ffe87f' : theme.colors.errorRed,
    responseData,
  })),
)(({ responseData, color }) => {
  if (!responseData.error.type) Sentry.captureException(responseData.error);

  return <p style={{ color: color }}>{messages[responseData.error.type || 'default']}</p>;
});

export default Error;
