import Button from 'components/ui/form-button';
import routes from 'config/routes';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import { branch, compose, renderNothing, withHandlers, withProps } from 'lib/recompose';
import React from 'react';
import { withRouter } from 'react-router';

const AccountsAddButton = ({ onClick }) => <Button onClick={onClick} text="Add Account" />;

const withCanAddAccounts = withProps(({ currentUser: { accounts, features } }) => ({
  canAddAccounts: features.accountCount > accounts.length,
}));

export default compose(
  withRouter,
  withIdentityAccounts,
  withCanAddAccounts,
  withHandlers({
    onClick:
      ({ currentAccount, history }) =>
      (event) => {
        history.push(routes.accountAdd(currentAccount.username));
      },
  }),
  branch(({ canAddAccounts }) => !canAddAccounts, renderNothing),
)(AccountsAddButton);
