import { gql } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

export const query = gql`
  query mailingListCampaigns(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $identityId: String
  ) {
    mailingListCampaigns(
      first: $first
      after: $after
      last: $last
      before: $before
      identityId: $identityId
    ) {
      edges {
        cursor
        node {
          id
          name
          url
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export default graphql(query, {
  options: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  props: ({ ownProps, data: withMailingListCampaigns }) => {
    return { withMailingListCampaigns };
  },
});
