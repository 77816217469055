import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { compute } from 'components/cards/stats/helpers/list-parser';
import Counter from 'components/cards/stats/elements/counter';
import { fetchParams } from 'components/cards/stats/params';
import {
  withTypeActiveState,
  withTypeDefaultProps,
  withTypeSelectHandler,
  withAnalyticsLoader,
} from 'components/cards/stats/enhancer';

const withQuery = (query, resolver) =>
  graphql(query, {
    options: ({ card, account, unit, dateTo, dateFrom }) => {
      const { id } = card;
      const { username } = account;
      const variables = {
        id,
        dateTo,
        dateFrom,
        username,
        frequency: unit,
      };
      return { ...fetchParams, variables };
    },
    props: ({ ownProps, data: { loading, ...props } }) => {
      try {
        if (!loading && resolver(props)) {
          // compute the chart data from the analytics connection object
          const connection = resolver(props);
          const { data, total } = compute(connection, ownProps.timeline);
          return { data, connection, total, loading };
        }
      } catch (e) {
        console.error(e);
      }
      return { loading };
    },
  });

const withStatType = ({ name, query, resolver }) =>
  compose(
    withTypeDefaultProps(name),
    withTypeActiveState,
    withQuery(query, resolver),
    withTypeSelectHandler,
    withAnalyticsLoader,
  )(Counter);

export default withStatType;
