import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'components/web-ui';
import { compose, pure } from 'recompose';

const CurrentPlanMessage = styled.div`
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  width: 100%;
  text-align: center;
  height: 34px;
  ${({ isVisible }) => !isVisible && 'visibility: hidden;'};
`.withComponent(({ isVisible, ...props }) => <Text {...props} />);

export default compose(
  //
  pure,
)(CurrentPlanMessage);
