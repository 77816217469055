import { contrastAdjust } from 'components/web-ui/color-utils';
import styled from 'styled-components/macro';

const Button = styled.button`
  font-weight: 400;
  padding-left: 32px;
  padding-right: 32px;
  background-color: ${(p) => p.bg};
  color: ${(p) => p.color};
  border: 0;
  cursor: pointer;

  &:hover {
    outline: none;
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.35);
  }

  &:active {
    outline: none;
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.35);
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${(p) => contrastAdjust(p.bg)};
  }
`;

export default Button;
