import React from 'react';
import HeaderEditSocialAccounts from 'components/cards/forms/profile/header-edit-social-accounts';
import SocialAccountFields from 'components/cards/forms/profile/social-account-fields';
import { compose, withState } from 'recompose';
import withUpdateCardMutation from 'hocs/with-update-card-mutation';
import withDefaultDeck from 'hocs/with-default-deck';
import renderField from 'hocs/render-field';
import handleLocalFieldChange from 'hocs/handle-local-field-change';
import { normalizeCardData } from 'lib/card-form-utils';
import styled from 'styled-components/macro';

const Form = styled.form`
  background-color: white;
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  flex-direction: column;
`;

function EditSocialAccounts(props) {
  const {
    data: { defaultDeck },
    formState,
    handleCardFormSubmit,
    setShowEditSocialAccounts,
  } = props;
  return (
    <Form
      onSubmit={async (event) => {
        event.preventDefault();
        const saveResult = await handleCardFormSubmit(formState, defaultDeck.id);
        setShowEditSocialAccounts(!saveResult);
      }}
    >
      <HeaderEditSocialAccounts {...props} />
      <SocialAccountFields {...props} />
    </Form>
  );
}

export default compose(
  withState('formState', 'setFormState', ({ card }) => normalizeCardData(card)),
  withState('validationErrors', 'setValidationErrors', null),
  handleLocalFieldChange,
  withDefaultDeck,
  renderField,
  withUpdateCardMutation,
)(EditSocialAccounts);
