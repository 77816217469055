import CardLayout from 'components/cards/preview/layout';
import {
  getButtonText,
  isMailingListReady,
  useEmailAddress,
  useSubmit,
} from 'components/cards/preview/mailing-list/enhancer';
import { Container, EmailInput, SubmitButton } from 'components/cards/preview/mailing-list/styles';
import Scrollable from 'components/cards/preview/scrollable';
import { Description, Title } from 'components/cards/preview/styles';
import Header from 'components/headers/card-preview-header';
import React from 'react';
import { fixLineBreakSpacers } from 'utils';

const MailingListCard = ({
  currentAccount,
  card,
  cardIndexInDeck,
  ctas,
  emailAddress,
  isCardVisible,
  setEmailAddress,
  onCallToActionClick,
  ...rest
}) => {
  const { mailingList } = card;
  const { url, mergeFields } = mailingList;
  const [email, valid, setEmail] = useEmailAddress();
  const [submitting, onSubmit] = useSubmit(card, currentAccount, email);
  const buttonText = getButtonText(card);
  const readyState = isMailingListReady(card);
  return (
    <CardLayout hasFooterOverlay={card.hasBackgroundGradient} card={card}>
      <Header currentAccount={currentAccount} card={card} isVisible={card.editable} />
      <Scrollable cardId={card.id} verticalAlign="top" xPadding="20px">
        <Container>
          {card.title && <Title children={fixLineBreakSpacers(card.title)} />}
          {card.description && <Description children={fixLineBreakSpacers(card.description)} />}
          <form action={url} method="POST">
            <EmailInput
              name="MERGE0"
              id="MERGE0"
              placeholder="📮 Enter an email address to test"
              onChange={setEmail}
              spellCheck={false}
              type="email"
              value={email}
              valid={!email || valid}
              disabled={!readyState}
            />
            <SubmitButton
              children={buttonText}
              color={card.buttonColor || currentAccount.profile.brandColor}
              disabled={!readyState || !valid}
              onClick={onSubmit}
              submitting={submitting}
            />
          </form>
        </Container>
      </Scrollable>
    </CardLayout>
  );
};

export default MailingListCard;
