import styled from 'styled-components/macro';

const Layout = styled.div`
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.primaryPurple};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Main = styled.div`
  padding: 20px;
`;

const Button = styled.a`
  display: block;
  text-align: right;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
`;

export { Layout, Main, Button };
