import React from 'react';
import * as Recompose from 'lib/recompose';
import CustomSettingsForm from 'components/cards/forms/custom-settings-form';
import * as Redux from 'react-redux';
import { setSelectedPopover } from 'store/modules/card-popovers';
import { withRouter } from 'react-router';
import * as Styled from './styled';

const POPOVERS = {
  'image-gallery': 'image-gallery-config',
  'mailing-list': 'mailing-card',
  instagram: 'instagram-config',
  twitter: 'twitter-config',
  youtube: 'youtube-config',
};

export default Recompose.compose(
  withRouter,

  Redux.connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
  })),

  Recompose.branch(
    (props) => !Object.keys(POPOVERS).includes(props.card.kind),
    Recompose.renderNothing,
  ),

  Recompose.withProps((props) => {
    const { kind } = props.card;
    const popover = POPOVERS[kind];
    const children = React.createElement(CustomSettingsForm, { ...props, popover });

    return {
      popover,
      children,
    };
  }),

  Recompose.withHandlers({
    onClick: (props) => (event) => {
      event.preventDefault();
      props.setSelectedPopover(props.popover);
    },
  }),
)(Styled.HeaderButton);
