import routes from 'config/routes';
import handleLocalFieldChange from 'hocs/handle-local-field-change';
import renderField from 'hocs/render-field';
import withHasBlendMode from 'hocs/with-has-blend-mode';
import withObjectToQueryParams from 'hocs/with-object-to-query-params';
import withUpdateProfileBackgroundImage from 'hocs/with-update-profile-background-image';
import withUpdateProfileBackgroundColor from 'hocs/with-update-profile-card-background';
import withUpdateProfileMutation from 'hocs/with-update-profile-mutation';
import * as Recompose from 'lib/recompose';
import omit from 'lodash/omit';
import querystring from 'querystring';
import React from 'react';
import * as Redux from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  setSelectedBackgroundImage,
  unsetSelectedBackgroundImage,
} from 'store/modules/card-select-background';
import { setUploadedBackgroundUrl } from 'store/modules/card-upload-background';

export default Recompose.compose(
  Redux.connect(
    (state, ownProps) => {
      const uploadedBackgroundPhotoUrl =
        typeof state.cardUploadBackground.url === 'string'
          ? [state.cardUploadBackground.url]
          : state.cardUploadBackground.url;

      const selectedBackgroundPhoto = Boolean(state.cardSelectedBackground.image);

      return {
        uploadedBackgroundPhotoUrl,
        selectedBackgroundPhoto,
      };
    },
    (dispatch) => ({
      setUploadedBackgroundUrl: (url) => dispatch(setUploadedBackgroundUrl(url)),
      setSelectedBackgroundImage: (image, file) =>
        dispatch(setSelectedBackgroundImage(image, file)),
      unsetSelectedBackgroundImage: () => dispatch(unsetSelectedBackgroundImage()),
    }),
  ),
  withObjectToQueryParams,
  Recompose.withProps(({ currentAccount }) => ({ profile: currentAccount.profile })),
  Recompose.withState('validationErrors', 'setValidationErrors', null),
  Recompose.withState('formState', 'setFormState', ({ profile }) =>
    // id - needs to be passed separately
    // __typename - is an Apollo thing and should not be sent with the mutation
    // defaultBackgroundMediaUrl - is always updated separately
    omit(profile, ['id', '__typename', 'defaultBackgroundMediaUrl']),
  ),
  Recompose.withState('isUploadingAvatar', 'setIsUploadingAvatar', false),
  Recompose.withState('isPersisting', 'setIsPersisting', false),
  Recompose.withState('saveComplete', 'setSaveComplete', false),
  Recompose.withState('titleAutocompleteIsExpanded', 'setTitleAutocompleteIsExpanded', false),
  Recompose.withState('showEditSocialAccounts', 'setShowEditSocialAccounts', false),
  handleLocalFieldChange,
  renderField,
  Recompose.withProps(({ formState, card, profile }) => {
    const { backgroundColor } = card;
    const { brandColor } = profile;
    const [url, params] = profile.defaultBackgroundMediaUrl.split('?');
    const qs = querystring.parse(params);

    if (!qs.blend || qs.bm === 'normal')
      return { defaultBackgroundMediaUrl: profile.defaultBackgroundMediaUrl };

    const filterColor = backgroundColor || brandColor;
    const query = { ...qs, blend: filterColor };
    const defaultBackgroundMediaUrl = [url, querystring.stringify(query)].join('?');

    return { defaultBackgroundMediaUrl, filterColor };
  }),
  withHasBlendMode,
  withUpdateProfileMutation,
  withUpdateProfileBackgroundImage,
  withUpdateProfileBackgroundColor,
  Recompose.withHandlers({
    handleAvatarUploadStarted:
      ({ setIsUploadingAvatar }) =>
      () => {
        setIsUploadingAvatar(true);
      },
    handleAvatarUploadCompleted:
      ({ setIsUploadingAvatar }) =>
      () => {
        setIsUploadingAvatar(false);
      },
    handleShowProfilePhotoFilter:
      ({ profile, setUploadedBackgroundUrl }) =>
      (event) => {
        event.preventDefault();
        const { defaultBackgroundMediaUrl } = profile;
        setUploadedBackgroundUrl(defaultBackgroundMediaUrl);
      },
    handleShowProfilePhotoCropper:
      ({ setSelectedBackgroundImage }) =>
      (image, file) => {
        setSelectedBackgroundImage(image, file);
      },
    handleCancelPhotoCropper:
      ({ unsetSelectedBackgroundImage }) =>
      () => {
        unsetSelectedBackgroundImage();
      },
    handleImageUploaded:
      ({ unsetSelectedBackgroundImage, setUploadedBackgroundUrl }) =>
      (name, url) => {
        setUploadedBackgroundUrl(url);
        unsetSelectedBackgroundImage();
      },
  }),
  Recompose.branch(
    ({ saveComplete }) => saveComplete,
    Recompose.renderComponent(({ card, currentAccount }) => (
      <Redirect to={routes.accountCardPath(currentAccount.username, card.id)} />
    )),
  ),
);
