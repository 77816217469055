import React from 'react';
import withToggleHiddenControl from 'components/cards/controls/toggle-hidden';
import styled from 'styled-components/macro';

const Button = styled.button`
  padding: 4px;
  border: none;
  cursor: pointer;
  border-radius: 6px;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

function ToggleHiddenButton(props) {
  return <Button {...props} />;
}

export default withToggleHiddenControl(ToggleHiddenButton);
