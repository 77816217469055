"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.ViewHeader = void 0;

var _layout = _interopRequireDefault(require("./components/layout"));

var _title = _interopRequireDefault(require("./components/title"));

var _leftButton = _interopRequireDefault(require("./components/left-button"));

var _rightButton = _interopRequireDefault(require("./components/right-button"));

var _spacer = _interopRequireDefault(require("./components/spacer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ViewHeader = _layout["default"];
exports.ViewHeader = ViewHeader;
ViewHeader.Title = _title["default"];
ViewHeader.LeftButton = _leftButton["default"];
ViewHeader.RightButton = _rightButton["default"];
ViewHeader.Spacer = _spacer["default"];
var _default = ViewHeader;
exports["default"] = _default;