import AddCallToActionButton from 'components/cards/forms/add-call-to-action-button';
import CallToActionButtons from 'components/cards/forms/call-to-action-buttons';
import {
  DescriptionSmallTextarea,
  StyledField,
  TitleSmallTextarea,
} from 'components/cards/shared/styles/fields';
import withCallToActionStateHandlers, {
  handleDeleteCta,
  handleShowCtaForm,
} from 'hocs/with-call-to-action-state-handlers';
import withDestroyCallToActionMutation from 'hocs/with-destroy-call-to-action-mutation';
import { branch, compose, renderComponent, withHandlers, withProps } from 'lib/recompose';
import React from 'react';
import { connect } from 'react-redux';
import { setSelectedCallToAction } from 'store/modules/card-call-to-action';
import { setSelectedPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';
import AddInstagramIdentity, { instagramIdentityIsMissing } from './add-instagram-identity';

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  padding-top: 68px;
`;

export const CallToActionButtonsContainer = styled.div`
  z-index: 12;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
`;

const AddCtaSection = styled.div`
  color: rgba(255, 255, 255, 0.5);
`;

function InstagramForm(props) {
  const {
    buttonColor,
    card,
    currentAccount: {
      profile: { brandColor },
    },
    handleDeleteCta,
    handleShowCtaForm,
    renderField,
  } = props;

  return (
    <CardContainer>
      {renderField({
        name: 'result.title',
        placeholder: 'Tap to add headline',
        selectAllOnFocus: true,
        styledInput: TitleSmallTextarea,
        styledField: StyledField,
      })}
      {renderField({
        name: 'result.description',
        placeholder: 'Tap to add body',
        selectAllOnFocus: true,
        styledInput: DescriptionSmallTextarea,
        styledField: StyledField,
      })}
      {/*
      Ideally we'd display the photos here, but unfortunately each photo is a form. Nested forms
      are not valid HTML (and throws an error in React) so the photos are rendered in a separate
      component that is outside the main card form.
    */}

      <CallToActionButtonsContainer>
        {card.ctas.length > 0 ? (
          <CallToActionButtons
            brandColor={buttonColor || brandColor}
            ctas={card.ctas}
            onClick={handleShowCtaForm}
            handleDeleteCta={handleDeleteCta}
          />
        ) : (
          <AddCtaSection>
            <AddCallToActionButton onClick={handleShowCtaForm.bind(this, null)} />
          </AddCtaSection>
        )}
      </CallToActionButtonsContainer>
    </CardContainer>
  );
}

export default compose(
  branch(instagramIdentityIsMissing, renderComponent(AddInstagramIdentity)),
  connect(null, (dispatch) => ({
    setSelectedPopover: (name) => dispatch(setSelectedPopover(name)),
    setSelectedCallToAction: (id) => dispatch(setSelectedCallToAction(id)),
  })),
  withCallToActionStateHandlers,
  withDestroyCallToActionMutation,
  withProps(({ card }) => ({ hasCtas: card.ctas.length, buttonColor: card.buttonColor })),
  withHandlers({ handleShowCtaForm, handleDeleteCta }),
)(InstagramForm);
