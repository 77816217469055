import React from 'react';
import styled from 'styled-components/macro';
import ctaOnClickHandler from '@tap-bio/core/event-handlers/cta-on-click-handler';
import Masonry from 'react-masonry-component';
import {
  CopyWrapper,
  Description,
  GutterSizer,
  Image,
  ImageFallback,
  ImageWrapper as BaseImageWrapper,
  Title,
} from 'components/cards/forms/image-gallery/styled';
import TextTruncate from 'react-text-truncate';

const ImageWrapper = styled(BaseImageWrapper)`
  cursor: ${(p) => (p.clickable ? 'pointer' : 'initial')};
  width: ${(props) => {
    switch (props.view) {
      case 'masonry_2':
        return 'calc(50% - 2.5px)'; // Gutter is 5px so each image should be 2.5px narrower.
      case 'masonry_3':
        return 'calc(33.3333333333% - 3.3333333333px)';
      default:
        return 'calc(50% - 2.5px)';
    }
  }};
`;

function GalleryImage(props) {
  const { view, url, imageUrl, aspectRatio, title, description } = props;

  return (
    <ImageWrapper clickable={!!url} view={view} onClick={ctaOnClickHandler({ url: url })}>
      {/*
        If the image's aspect ratio is null then the Image component will have a zero height.
        In this case we'll render the child ImageFallback component which provides the
        appropriate dimensions to 'expand' the Image component.
      */}
      <Image alt="A gallery image" imageUrl={imageUrl} aspectRatio={aspectRatio}>
        {!aspectRatio && <ImageFallback src={imageUrl} alt="" />}
      </Image>
      <GutterSizer className="gutter-sizer" />
      <CopyWrapper>
        <Title>
          <TextTruncate line={1} truncateText="…" text={title} />
        </Title>
        <Description>
          <TextTruncate line={2} truncateText="…" text={description} />
        </Description>
      </CopyWrapper>
    </ImageWrapper>
  );
}

function GalleryImagesMasonry(props) {
  const { images, hasCta, view } = props;

  if (!['masonry_2', 'masonry_3'].includes(view)) return null;

  return (
    <div style={hasCta ? { paddingBottom: '73px' } : {}}>
      <Masonry
        options={{
          gutter: '.gutter-sizer',
          horizontalOrder: true,
          transitionDuration: 0,
        }}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
      >
        {images.map((image) => (
          <GalleryImage key={image.id} view={view} {...image} />
        ))}
      </Masonry>
    </div>
  );
}

export default GalleryImagesMasonry;
