import { withRouter } from 'react-router';
import * as Recompose from 'lib/recompose';

export default Recompose.compose(
  withRouter,
  Recompose.withProps((props) => {
    const { cardId } = props.match.params;
    return { cardId: parseInt(cardId, 10) };
  }),
);
