import { withApollo } from '@apollo/react-hoc';
import ViewHeader from '@tap-bio/ui/patterns/view-header';
import { Layout as BaseLayout, Main as BaseMain } from 'components/cards/forms/shared-ui';
import { TwitterIcon as BaseTwitterIcon } from 'components/icons';
import { Input as BaseInput, Text } from 'components/web-ui';
import routes from 'config/routes';
import withDestroyCardMutation from 'hocs/with-destroy-card-mutation.jsx';
import logo from 'images/icon-down-arrow.svg';
import { compose, omitProps, withHandlers } from 'lib/recompose';
import set from 'lodash/set';
import React from 'react';
import { connect } from 'react-redux';
import { unsetPopover } from 'store/modules/card-popovers';
import styled from 'styled-components/macro';

const Layout = styled(BaseLayout)`
  padding: 0;
`;

const Main = styled(BaseMain)`
  padding: ${20 / 16}rem 2rem;
`;

const Input = styled.input`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  padding: 15px 10px;
  font-size: 16px;
`.withComponent(omitProps('hasErrors')(BaseInput));

const Select = styled.select`
  display: block;
  color: ${(props) => props.theme.colors.black};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.profileForm.inputBorder};
  background-color: ${(props) => props.theme.colors.profileForm.inputBackground};
  background-image: url('${logo}');
  background-position: 7% 50%;
  background-size: 200% 50%;
  background-repeat: no-repeat;
  padding: 15px 10px;
  font-size: 16px;
  width: 100%;

  :active,
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const Hint = styled(Text)`
  font-size: 13px;
  opacity: 0.4;
  margin-top: 5px;
  line-height: 1.35;
  color: #9d9894;
`;

const HintBox = styled(Text)`
  font-size: 13px;
  opacity: 0.4;
  background-color: #fefad4;
  height: 35px;
  line-height: 35px;
  border-radius: 6px;
  padding: 0px 10px;
  color: #9d9894;
  margin-top: -12px;
  margin-bottom: 15px;

  & span {
    color: #000;
    font-weight: bold;
  }
`;

const Field = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  flex-direction: column;
  align-items: stretch;
`;

const FormLayout = styled.div`
  padding: 10px 0 0 0;
`;

const TwitterIcon = styled(BaseTwitterIcon)`
  margin: 0 auto;
  path {
    fill: #059ff5;
  }
`;

function TwitterForm(props) {
  const { formState, isPersisting, renderField, renderSelect, handleUnset } = props;
  return (
    <Layout>
      <ViewHeader>
        <ViewHeader.LeftButton onClick={handleUnset} />
        <ViewHeader.Title children="Twitter Card" />
        <ViewHeader.RightButton type="Submit" children={isPersisting ? 'Saving...' : 'Save'} />
      </ViewHeader>
      <Main>
        <TwitterIcon width={70} height={100} />
        <FormLayout>
          {renderField({
            name: `entities.twitters.${formState.result.twitter}.username`,
            placeholder: 'Tap to add username',
            selectAllOnFocus: true,
            styledInput: Input,
            styledField: Field,
          })}
          <HintBox>
            https://twitter.com/<span>username</span>
          </HintBox>
          {renderSelect({
            name: `entities.twitters.${formState.result.twitter}.feed`,
            placeholder: 'Choose one',
            options: [
              { value: '', label: 'Choose one' },
              { value: 'Recent Tweets', label: 'Recent Tweets' },
              { value: 'Popular Tweets', label: 'Popular Tweets' },
            ],
            selectAllOnFocus: true,
            styledSelect: Select,
            styledField: Field,
          })}
          <Hint>
            Choose whether recent or popular tweets are displayed from this twitter account
          </Hint>
        </FormLayout>
      </Main>
    </Layout>
  );
}

const withRedux = connect(null, (dispatch) => ({ unsetPopover: () => dispatch(unsetPopover()) }));

const withHeaderButtonHandlers = withHandlers({
  handleUnset: (props) => async (event) => {
    event.preventDefault();

    // Hide the settings popover
    props.unsetPopover();

    // Delete card if it's not fully configured
    if (!props.card.twitter) {
      const accountUsername = props.currentAccount.username;
      const cardId = props.card.id;
      const cancelUrl = routes.account(accountUsername);
      await props.handleCardDestroy(cardId, accountUsername);
      props.history.push(cancelUrl);
      return;
    }

    // Clear out the form if we're hitting the back button
    if (props.card.twitter) {
      const { username, feed } = props.card.twitter;
      props.setFormState((state) =>
        set({ ...state }, `entities.twitters.${props.formState.result.twitter}.username`, username),
      );
      props.setFormState((state) =>
        set({ ...state }, `entities.twitters.${props.formState.result.twitter}.feed`, feed),
      );
    }
  },
});

export default compose(
  withRedux,
  withApollo,
  withDestroyCardMutation,
  withHeaderButtonHandlers,
)(TwitterForm);
