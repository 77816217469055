import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { withHandlers } from 'recompose';

/*
 *   Handle when an onboarding item (the action we're encouraging the user to take) has
 *   been completed.
 *
 *   If the onboarding item is already complete, do nothing.
 *
 *   We copy the user (because Apollo data is immutable) and update the appropriate key
 *   in the onboarding JSON object, then send it to be persisted.
 */

export default withHandlers({
  handleCompleteOnboarding:
    ({ submit, currentUser, currentAccount }) =>
    (key) => {
      const user = currentUser || currentAccount.owner;

      // Don't persist if the onboarding action is already complete
      if (user.onboarding[key]) return;

      // Update the user and persist
      const updatedUser = cloneDeep(user);
      submit(set(updatedUser, `onboarding.${key}`, true));
    },
});
