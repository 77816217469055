import React from 'react';
import routes from 'config/routes';
import { compose, withProps, withState, lifecycle } from 'lib/recompose';
import { withRouter } from 'react-router';
import YouTube from 'react-youtube';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import CardLayout from 'components/cards/preview/layout';
import Header from 'components/headers/card-preview-header';
import spinner from 'images/spinner.svg';
import { fixLineBreakSpacers } from 'utils';
import {
  TitleSmallTextarea,
  DescriptionSmallTextarea,
  StyledField,
} from 'components/cards/shared/styles/fields';
import { ContentSpacer, DescriptionSmall, TitleSmall } from 'components/cards/preview/styles';
import { connect } from 'react-redux';
import { setSelectedPopover } from 'store/modules/card-popovers';
import NotConfigured from './not-configured';
import VideoMeta from './video-meta';
import Video from './video';
import OpenInAppButton from './open-in-app-button';
import SubscribeButton from './subscribe-button';

// --- Redux ---

// Reference to the video player, set via the player's onReady event
// This is so we can automatically enable audio for fulscreen playback
let player = null;

// --- Styled Components ---

const videoLoadingStyles = css`
  .youtube-video-container {
    background-color: black;
    background-image: url('${spinner}');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 4px;
    overflow: hidden;
    display: block;
  }
`;

const FieldsContainer = styled.div`
  padding: 68px 10px 0 10px;
  display: flex;
  bottom: 0;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  ${videoLoadingStyles};
`;

const MainVideoContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background-color: black;

  > .youtube-video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Buttons = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
`;

const Videos = styled.div`
  margin: 15px -10px 0 -10px;
  padding: 0 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  ${videoLoadingStyles};
`;

// --- The Component ---

class YoutubeCard extends React.PureComponent {
  render() {
    const {
      activeVideo,
      card,
      cardIndexInDeck,
      currentAccount,
      isCardVisible,
      hasVideos,
      renderField,
      renderFormFields,
      renderHeader,
      setActiveVideo,
    } = this.props;
    const titleWithFallback = card.title || 'Tap a video to watch';

    return (
      <CardLayout
        card={card}
        hasFooterOverlay={card.hasBackgroundGradient}
        hasHeaderOverlay={false}
        hasTransparentBackground={!renderHeader}
        hasBackgroundOverlay={false}
        isCardVisible={isCardVisible}
      >
        <div style={{ height: '100%' }}>
          {renderHeader && (
            <Header
              currentAccount={currentAccount}
              card={card}
              cardIndexInDeck={cardIndexInDeck}
              isVisible={card.editable}
            />
          )}
          <FieldsContainer>
            {renderFormFields ? (
              <React.Fragment>
                {renderField({
                  name: 'result.title',
                  placeholder: 'Tap to add headline',
                  selectAllOnFocus: true,
                  styledInput: TitleSmallTextarea,
                  styledField: StyledField,
                })}
                {renderField({
                  name: 'result.description',
                  placeholder: 'Tap to add body',
                  selectAllOnFocus: true,
                  styledInput: DescriptionSmallTextarea,
                  styledField: StyledField,
                })}
              </React.Fragment>
            ) : (
              <ContentSpacer title={titleWithFallback} description={card.description}>
                <TitleSmall children={fixLineBreakSpacers(titleWithFallback)} />
                {card.description && (
                  <DescriptionSmall children={fixLineBreakSpacers(card.description)} />
                )}
              </ContentSpacer>
            )}
            {hasVideos ? (
              <React.Fragment>
                <MainVideoContainer>
                  <YouTube
                    videoId={(activeVideo || card.youtube.videos[0]).id}
                    containerClassName="youtube-video-container"
                    onReady={(event) => {
                      player = event.target;
                    }}
                    // https://developers.google.com/youtube/player_parameters
                    opts={{
                      width: '100%',
                      height: '100%',
                      playerVars: {
                        // Don't autoplay initially. We'll use state to determine whether we need to
                        // autoplay but we can't do that until the player's been initialized.
                        autoplay: 0,

                        // Don't display information like the video title and uploader before the video starts playing.
                        showinfo: 0,

                        // Use a YouTube player that does not show a YouTube logo
                        modestbranding: 1,

                        // Don't show related videos after playback has finished
                        rel: 0,

                        // Play in fullscreen
                        playsinline: 0,
                      },
                    }}
                  />
                </MainVideoContainer>
                <VideoMeta video={activeVideo || card.youtube.videos[0]} />
                <Buttons>
                  <SubscribeButton
                    backgroundColor={card.buttonColor || 'red'}
                    cardId={card.id}
                    username={currentAccount.username}
                  />
                  <OpenInAppButton
                    backgroundColor={card.buttonColor || 'red'}
                    cardId={card.id}
                    username={currentAccount.username}
                  />
                </Buttons>
                <Videos>
                  {/* The key is the index because playlists can have duplicate videos */}
                  {card.youtube.videos.slice(1).map((video, index) => (
                    <Video
                      activeVideo={activeVideo || card.youtube.videos[0]}
                      key={index}
                      setActiveVideo={setActiveVideo}
                      video={video}
                    />
                  ))}
                </Videos>
              </React.Fragment>
            ) : (
              <NotConfigured card={card} />
            )}
          </FieldsContainer>
        </div>
      </CardLayout>
    );
  }
}

YoutubeCard.defaultProps = {
  renderFormFields: false,
};

const withHasVideos = withProps(({ card: { youtube } }) => ({
  hasVideos: youtube && youtube.videos.length > 0,
}));

const withRedux = connect(
  (state) => ({ deckViewstate: state.deckViewstate }),
  (dispatch) => ({ setSelectedPopover: (popover) => dispatch(setSelectedPopover(popover)) }),
);

const withLifecycle = lifecycle({
  componentDidMount() {
    const { card, history, setSelectedPopover, currentAccount } = this.props;
    if (!card.youtube) {
      setSelectedPopover('youtube-config');
      history.push(routes.accountCardEditPath(currentAccount.username, card.id));
    }
  },

  componentDidUpdate() {
    if (!player) return;
    if (this.props.deckViewstate.activeIndex !== this.props.cardIndexInDeck) {
      // Occasionally we're getting a 'The YouTube player is not attached to the DOM' error which
      // I belive can be caught here
      try {
        player.pauseVideo();
      } catch (error) {
        console.log('Could not pause video');
      }
    }
  },
});

export default compose(
  withRedux,
  withRouter,
  withLifecycle,
  withHasVideos,
  withState('activeVideo', 'setActiveVideo', ({ card, hasVideos }) =>
    hasVideos ? card.youtube.videos[0] : null,
  ),
)(YoutubeCard);
