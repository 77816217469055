import debounce from 'lib/debounce-with-promise';
import * as Recompose from 'recompose';
import { graphql } from '@apollo/react-hoc';
import { usernameSearchQuery } from 'lib/graphql/queries';
import { forbiddenCharacters } from 'lib/validations';

export default Recompose.compose(
  Recompose.defaultProps({ accountNameUnique: false }),
  Recompose.withState('accountNameChecked', 'setAccountNameChecked', false),
  Recompose.withState('usernameSearchQuery', 'setUsernameSearchQuery', ''),
  Recompose.withState('throttledSetUsernameSearchQuery', '', (props) => {
    return debounce((value) => props.setUsernameSearchQuery(value), 500);
  }),

  graphql(usernameSearchQuery, {
    skip: (props) => !props.usernameSearchQuery,
    options: (props) => ({
      variables: { username: props.usernameSearchQuery || '' },
    }),
    props: ({ data }) => {
      if (!data) return { accountNameUnique: false };

      return {
        data,
        accountNameUnique: !data.publicAccount,
      };
    },
  }),

  Recompose.withHandlers({
    validateAccountNameUniqueness: (props) => async (value) => {
      if (!value) return;
      if (value.match(forbiddenCharacters)) return;

      props.setAccountNameChecked(false);
      await props.throttledSetUsernameSearchQuery(value);
      props.setAccountNameChecked(true);
    },
  }),
);
