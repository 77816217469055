import { Link as BaseLink, Text as BaseText } from 'components/web-ui';
import styled from 'styled-components/macro';

const Layout = styled.div`
  padding: 1.5em 20px;
  color: ${(props) => props.theme.colors.veryDarkPurple};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  flex: 1 0 auto;

  display: flex;
  align-items: center;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
`;

const Content = styled.header`
  flex: 3 0 auto;
`;

const Text = styled(BaseText)`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
`;

const Link = styled(BaseLink)`
  color: ${(props) => props.theme.colors.veryDarkPurple};
  text-decoration: underline;
`;

const Separator = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 14px;
  margin-left: 10px;
  margin-right: 10px;
`;

export { Content, Footer, Header, Layout, Link, Separator, Text };
