import React from 'react';
import Panel from 'components/cards/stats/component';
import CardClickType from 'components/cards/stats/types/card-click-type';
import CardClickTrends from 'components/cards/stats/cards/shared/ctas/trends';
import CardViewType from 'components/cards/stats/types/card-view-type';

export default (props) => (
  <Panel {...props}>
    <CardClickType>
      <CardClickTrends />
    </CardClickType>
    <CardViewType />
  </Panel>
);
