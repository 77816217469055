import React from 'react';
import Cards from 'components/cards';
import MoreNavigation from 'components/account-menu';

function Deck(props) {
  const { deck, currentAccount, currentUser } = props;
  return (
    <React.Fragment>
      <MoreNavigation currentAccount={currentAccount} deck={deck} />
      <Cards cards={deck.activeCards} currentAccount={currentAccount} currentUser={currentUser} />
    </React.Fragment>
  );
}

export default Deck;
