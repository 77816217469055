import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash/isEmpty';
import { graphql } from '@apollo/react-hoc';
import { billingAddSourceMutation } from 'lib/graphql/mutations';

export default graphql(billingAddSourceMutation, {
  props: ({ mutate, ownProps: { formState, setIsPersisting, setPaymentError } }) => ({
    addSource: async (stripeSource, newPlanStripeId, userId, turnstileToken) => {
      let success = true;
      setPaymentError(null);
      try {
        const response = await mutate({
          mutation: billingAddSourceMutation,
          variables: { input: { stripeSource, newPlanStripeId, userId, turnstileToken } },
        });
        if (!isEmpty(response.data.addSource.errors)) {
          success = false;
          setPaymentError(response.data.addSource.errors.error_message);
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      } finally {
        // This mutation is always executed at the same time as the request to Stripe for the token.
        // The 'persisting' state is set before that first (of two) requests, and unset here.
        setIsPersisting(false);
        return success;
      }
    },
  }),
});
