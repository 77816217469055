import * as Sentry from '@sentry/browser';
import omit from 'lodash/omit';
import { graphql } from '@apollo/react-hoc';
import { updateCardMutation } from 'lib/graphql/mutations';

export default graphql(updateCardMutation, {
  props: ({ mutate, ownProps: { card, cards, currentAccount } }) => ({
    handleUpdateProfileBackgroundColor: async (backgroundColor) => {
      const targetCard = card ? card : cards.reduce((acc, o) => (o.kind === 'profile' ? o : acc));
      const targetDeck = currentAccount.decks[0];
      const { id: cardId, ctas } = targetCard;
      const { id: deckId } = targetDeck;
      const input = { backgroundColor, ctas };
      let saveSuccess = false;

      input.ctas = input.ctas.map((cta) => omit(cta, ['__typename']));
      input.images = [];

      const variables = { cardId, deckId, input };
      try {
        await mutate({ variables });
        saveSuccess = true;
      } catch (error) {
        Sentry.captureException(error, { extra: variables });
      }
      return saveSuccess;
    },
  }),
});
