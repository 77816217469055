import React from 'react';
import routes from 'config/routes';
import * as Recompose from 'recompose';
import { Redirect } from 'react-router-dom';
import withAllowRegistrationChecker from 'hocs/with-allow-registration-checker';

export default Recompose.compose(
  Recompose.branch((props) => !props.whitelisted, withAllowRegistrationChecker),
  Recompose.branch(
    (props) => !props.whitelisted && !props.allowRegistration,
    Recompose.renderComponent(() => (
      <Redirect
        push
        to={{
          pathname: routes.loginPath,
        }}
      />
    )),
  ),
);
