import React from 'react';
import { compose, withProps } from 'recompose';
import { Redirect } from 'react-router-dom';
import routes from 'config/routes';
import Account from 'lib/current-account';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import withIdentityAccountsAuthorizationCheck from 'hocs/with-identity-accounts-authorization-check';
import displayLoadingState from 'hocs/display-loading-state';

function Accounts({ username }) {
  return <Redirect to={`${routes.account(username)}`} />;
}

export default compose(
  withIdentityAccounts,
  withIdentityAccountsAuthorizationCheck,
  displayLoadingState,
  withProps(({ accounts }) => {
    let username;
    let exists = false;
    if (Account.hasCurrentAccount()) {
      username = Account.getCurrentAccount();
      for (const account of accounts) {
        if (username === account.username) {
          exists = true;
        }
      }
      if (!exists) {
        username = accounts[0].username;
      }
    } else {
      username = accounts[0].username;
    }
    return { username };
  }),
)(Accounts);
