import { graphql, withApollo } from '@apollo/react-hoc';
import routes from 'config/routes';
import fbq from 'lib/fbq';
import { createSessionChallengeMutation } from 'lib/graphql/mutations';
import { errors, forbiddenCharacters, validEmail } from 'lib/validations';
import * as Recompose from 'recompose';
import redirectDisabledRegistration from './redirect-disabled-registraion';
import redirectWithSignupParams from './redirect-with-signup-params';
import validateAccountNameUniqueness from './validate-account-name-uniqueness';

export default Recompose.compose(
  withApollo,
  Recompose.withState('turnstileToken', 'setTurnstileToken', null),
  Recompose.withState('formData', 'setFormData', {}),
  Recompose.withState('formErrors', 'setFormErrors', {}),
  Recompose.withState('usernameSearchQuery', 'setUsernameSearchQuery', ''),
  Recompose.withState('responseData', 'setResponseData', {}),
  Recompose.withState('formIsPersisting', 'setformIsPersisting', false),
  redirectDisabledRegistration,
  redirectWithSignupParams,
  graphql(createSessionChallengeMutation, {
    props: ({ mutate, ownProps }) => ({
      handleFormSubmit: () =>
        mutate({
          variables: {
            turnstileToken: ownProps.turnstileToken,
            accountName: ownProps.formData.accountName,
            username: ownProps.formData.email,
            provider: 'email',
            action: 'register',
          },
          update: (proxy, { data: { error, createSessionChallenge } }) => {
            if (error) {
              ownProps.setResponseData({ errorType: error.type });
              return console.error(error);
            }
            fbq('track', 'StartTrial');
            ownProps.setResponseData(createSessionChallenge);
          },
        }),
    }),
  }),
  validateAccountNameUniqueness,
  Recompose.withProps((props) => {
    const { formData } = props;

    const accountNameErrors = () => {
      if (!formData.accountName) {
        return null;
      }

      if (formData.accountName.match(forbiddenCharacters)) {
        return errors.ACCOUNT_NAME_INVALID;
      }

      if (props.accountNameChecked && !props.accountNameUnique) {
        return errors.ACCOUNT_NAME_NON_UNIQUE;
      }

      return null;
    };

    const emailErrors = () => {
      if (props.responseData.errorType === 'EmailInactiveRecipientError') {
        return errors.EMAIL_INACTIVE;
      }

      if (!formData.email) return;
      if (!formData.email.match(validEmail)) return errors.EMAIL_INVALID;
    };

    return {
      accountNameValid:
        props.accountNameChecked && props.formData.accountName && props.accountNameUnique,
      formErrors: {
        accountName: accountNameErrors(),
        email: emailErrors(),
        any:
          !!accountNameErrors() ||
          !!emailErrors() ||
          !props.formData.accountName ||
          !props.formData.email,
      },
    };
  }),
  Recompose.withHandlers({
    onFieldChange: (props) => async (name, value) => {
      if (name === 'accountName') props.validateAccountNameUniqueness(value);
      return props.setFormData((state) => ({ ...state, [name]: value }));
    },

    onSubmit: (props) => () => {
      props.setformIsPersisting(true);
      return props.handleFormSubmit(props);
    },

    handleRedirectToLogin: (props) => () => {
      return props.history.push({
        pathname: routes.loginPath,
        search: window.location.search,
      });
    },
  }),

  Recompose.pure,
);
