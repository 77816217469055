import React from 'react';
import { Heading, Image as BaseImage, ButtonCircle } from 'components/web-ui';
import image from 'images/onboarding-instagram.svg';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Image = styled(BaseImage)`
  width: 70%;
  margin: 0 auto;
  min-height: 30%;
`;

const ModalHeading = styled(Heading)`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 1.5rem 0;
`;

const Text = styled.p`
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
`;

const TryItButton = styled(ButtonCircle)`
  background-color: ${({ theme }) => theme.colors.primaryPurple};
  font-weight: bold;
  color: white;
  font-size: 1rem;
  height: 4rem;
`;

function InstagramLinksModal(props) {
  const { onClickCta } = props;
  return (
    <Layout>
      <ModalHeading children="This card displays all of your Instagram posts that have links associated with them." />
      <Image src={image} alt="Logo" />
      <div>
        <Text
          children={`Make this your first card and your followers will always find the right post when you tell them “tap my bio link”.`}
        />
        <Text
          children={`Simply paste your links in the captions of your posts on Instagram and they’ll automatically
        appear. Or add links by tapping “Edit card” above.`}
        />
      </div>

      <TryItButton children="Try it" onClick={onClickCta} />
    </Layout>
  );
}

export default InstagramLinksModal;
