import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ProfileCard from 'components/cards/preview/profile/index';
import SimpleCard from 'components/cards/preview/simple';
import DiscoverCard from 'components/cards/preview/discover';
import InstagramCard from 'components/cards/preview/instagram/index';
import MailingListCard from 'components/cards/preview/mailing-list/index';
import ImageGalleryCard from 'components/cards/preview/image-gallery/index';
import LinksCard from 'components/cards/preview/links';
import EventCard from 'components/cards/preview/event';
import YoutubeCard from 'components/cards/preview/youtube';
import TwitterCard from 'components/cards/preview/twitter';
import ErrorCard from 'components/cards/all/error';
import Stats from 'components/cards/stats/base';
import withBackgroundMediaUrl from 'components/cards/shared/hocs/with-background-media-url';
import withHasBackgroundOverlay from 'components/cards/shared/hocs/with-has-background-overlay';
import withHasBlendMode from 'hocs/with-has-blend-mode';
import { withErrorBoundary, withErrorComponent } from 'components/errors/error-boundary';

function CardType(props) {
  props = { ...props, renderHeader: true };
  switch (props.card.kind) {
    case 'twitter':
      return <TwitterCard {...props} />;
    case 'profile':
      return <ProfileCard {...props} />;
    case 'simple':
      return <SimpleCard {...props} />;
    case 'instagram':
      return <InstagramCard {...props} />;
    case 'event':
      return <EventCard {...props} />;
    case 'links':
      return <LinksCard {...props} />;
    case 'discover':
      return <DiscoverCard {...props} />;
    case 'mailing-list':
      return <MailingListCard {...props} />;
    case 'image-gallery':
      return <ImageGalleryCard {...props} />;
    case 'youtube':
      return <YoutubeCard {...props} />;
    case 'tap.bio':
      throw new Error('tap.bio card has been removed');
    default:
      break;
  }
}

function Card(props) {
  return (
    <React.Fragment>
      <CardType {...props} />
      <Stats {...props} isShowing={props.statsAreShowing} />
    </React.Fragment>
  );
}

export default compose(
  connect((state, ownProps) => ({ statsAreShowing: state.stats.isShowing })),
  withBackgroundMediaUrl,
  withHasBackgroundOverlay,
  withHasBlendMode,
  withErrorComponent(ErrorCard),
  withErrorBoundary,
)(Card);
