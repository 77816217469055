import * as Sentry from '@sentry/browser';
import { graphql } from '@apollo/react-hoc';
import { updateAccountProfileMutation } from 'lib/graphql/mutations';

export default graphql(updateAccountProfileMutation, {
  props: ({
    mutate,
    ownProps: { currentAccount, formState, setIsPersisting, setValidationErrors },
  }) => ({
    handleProfileFormSubmit: async (event) => {
      event.preventDefault();
      setIsPersisting(true);
      let saveSuccess = false;

      formState.defaultBackgroundMediaUrl =
        typeof formState.defaultBackgroundMediaUrl === 'object'
          ? formState.defaultBackgroundMediaUrl[0]
          : formState.defaultBackgroundMediaUrl;

      try {
        await mutate({
          mutation: updateAccountProfileMutation,
          variables: {
            accountId: currentAccount.id,
            accountProfileInputArguments: formState,
          },
          update: (proxy, { data: { updateAccountProfile } }) => {
            if (updateAccountProfile.validationErrors) {
              // Save failed. ONLY update the validation errors
              setValidationErrors(updateAccountProfile.validationErrors);
            } else {
              // Save was successful. Profile data is automatically updated.
              saveSuccess = true;
            }
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      } finally {
        setIsPersisting(false);
      }
      return saveSuccess;
    },
  }),
});
