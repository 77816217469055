import styled from 'styled-components/macro';
import { Small } from 'components/web-ui';

const ValidationError = styled(Small)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.errorRed};
  display: block;
  font-size: 13px;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 10;
  padding: 4px 8px;
  border-radius: 2px;

  &:first-letter {
    text-transform: capitalize;
  }

  :after {
    bottom: 100%;
    left: 83%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${({ theme }) => theme.colors.errorRed};
    border-width: 5px;
    margin-left: -5px;
  }
`;

export default ValidationError;
