import { Heading, Text } from 'components/web-ui';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import { compose, pure, withProps } from 'recompose';
import styled from 'styled-components/macro';
import ProductRibbon from './product-ribbon';

const cardRadius = '4px';

const Container = styled.div`
  display: flex;
  border-radius: ${cardRadius};
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  line-height: 1;
  margin-bottom: 5%;
  padding: 3% 0;
  position: relative;
  text-align: center;
  width: 47.5%;
  cursor: pointer;
  background-color: ${({ isSelected, theme: { colors } }) =>
    isSelected ? colors.primaryPurple : '#F5F3F6'};
  color: ${({ isSelected, theme: { colors } }) =>
    isSelected ? colors.white : colors.primaryPurple};
`.withComponent(({ isSelected, ...rest }) => <div {...rest} />);

const Title = styled(Heading)`
  font-size: 14px;
  margin-bottom: 7px;
  text-transform: uppercase;
`;

const Trial = styled(Heading)`
  font-size: 13px;
  margin-top: -7px;
  margin-bottom: 6px;
  color: #f56920;
  font-weight: normal;
  font-style: italic;
`;

const Description = styled(Text)`
  font-size: 18px;
  margin: 0 auto 10px auto;
`;

const Amount = styled.p`
  font-size: 14px;
  color: ${({ isSelected, theme: { colors } }) =>
    isSelected ? colors.alphaWhite50 : 'rgba(51, 21, 78, 0.5)'};
  ${({ isDiscounted }) => isDiscounted && 'text-decoration: line-through;'};
`.withComponent(({ isSelected, isDiscounted, ...rest }) => <Text {...rest} />);

function Product(props) {
  const {
    formattedAmount,
    isDiscounted,
    isOnTrial,
    isSelected,
    onClick,
    trialDaysLeft,
    product: { name, description, metadata },
  } = props;

  return (
    <Container isSelected={isSelected} onClick={onClick}>
      <Title>{name}</Title>
      {isOnTrial && <Trial>{trialDaysLeft} left on trial</Trial>}

      <ProductRibbon text="discount" isDiscounted={isDiscounted} />
      <Description children={description} />
      <Amount
        isSelected={isSelected}
        isDiscounted={isDiscounted}
        children={(metadata || {}).customPricingDisplay || formattedAmount}
      />
    </Container>
  );
}

const withHasDiscount = withProps(({ currentUser, product: { plans } }) => {
  if (!plans) return { isDiscounted: false };
  const discountedPlanIds = [];
  currentUser.discounts.forEach((d) => d.plans.forEach((p) => discountedPlanIds.push(p.stripeId)));
  const planIds = plans.map((p) => p.stripeId);
  return {
    isDiscounted: !isEmpty(intersection(discountedPlanIds, planIds)),
  };
});

const withHasTrial = withProps((props) => {
  const {
    currentUser: {
      stripeSubscription: { status, productStripeId, trialEndsAt },
    },
  } = props;
  const isTrialing = status === 'trialing';
  const trialProductStripeId = productStripeId;
  const thisProductStripeId = props.product.stripeId;

  if (!isTrialing) {
    return {
      isOnTrial: false,
      trialDaysLeft: null,
    };
  }

  return {
    isOnTrial: trialProductStripeId === thisProductStripeId,
    trialDaysLeft: moment.unix(trialEndsAt, 'YYMMDD').fromNow(true),
  };
});

const withFormattedAmount = withProps(({ product: { plans } }) => {
  if (!plans) return; // Platinum product doesn't have plans
  let plan = plans.find((plan) => plan.interval === 'year');
  if (!plan) plan = plans[0];
  return {
    formattedAmount: plan.amountDisplay || `$${plan.amount / 100 / 12} / month`,
  };
});

export default compose(withHasDiscount, withHasTrial, withFormattedAmount, pure)(Product);
