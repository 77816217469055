import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import Routes from 'routes';
import StyleSheet from './layout';

function TrackPageViews() {
  const location = useLocation();
  useEffect(
    function sendGaPageView() {
      window.gtag('event', 'page_view', {
        path: location.pathname,
      });
    },
    [location],
  );

  return null;
}

function Application(props) {
  return (
    <BrowserRouter>
      <StyleSheet />
      <TrackPageViews />
      <Routes />
    </BrowserRouter>
  );
}

export default Application;
