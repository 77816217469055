import removeScrollbarCSS from 'components/cards/shared/styles/remove-scrollbar-css';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  padding: 0 5px;
  overflow-y: auto;
  flex: 1;
  ${removeScrollbarCSS};
`;

export const Crop = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.09);
  background-size: cover;
  background-position: center center;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &:not([data-src]) ::before {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    content: 'This Photo Is Temporarily Unavailable';
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const Frame = styled.div`
  padding-bottom: 5px;
  width: ${(props) => {
    switch (props.view) {
      case 'grid_1':
        return '100%';
      case 'grid_2':
        return 'calc(50% - 2.5px)';
      case 'grid_3':
        return 'calc(33.33333% - 3px)';
      default:
        return 'calc(50% - 2.5px)';
    }
  }};
`;

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
