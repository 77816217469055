import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import AddButton from './buttons/show-card-new';
import AccountMenuButton from './buttons/show-account-menu';

function GlobalHeader(props) {
  const { currentAccount, currentUser } = props;

  return (
    <React.Fragment>
      <AddButton currentAccount={currentAccount} currentUser={currentUser} title="Add New Card" />
      <AccountMenuButton currentAccount={currentAccount} />
    </React.Fragment>
  );
}

export default compose(
  branch(({ currentAccount }) => !currentAccount.profile.defaultBackgroundMediaUrl, renderNothing),
)(GlobalHeader);
