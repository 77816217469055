import { useMutation } from '@apollo/react-hooks';
import { isEmailValid } from '@tap-bio/core/validators/email';
import { subscribeMailingListMutation } from 'lib/graphql/mutations';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToast } from 'store/modules/toast';

const SUBSCRIBED = 'Subscribed';

function useEmailAddress() {
  const [email, setEmail] = useState('');
  const valid = isEmailValid(email);

  function setHandler(evt) {
    evt.preventDefault();
    setEmail(evt.target.value);
  }

  return [email, valid, setHandler];
}

function useSubmit(card, account, email) {
  const dispatch = useDispatch();
  const [lock, setLock] = useState(false);
  const [subscribe, { loading, data }] = useMutation(subscribeMailingListMutation);
  const { id, mailingList } = card;
  const { mergeFields } = mailingList;
  const useBrowserAction =
    Array.isArray(mergeFields) && mergeFields.filter((f) => f.required).length > 0;

  useEffect(() => {
    if (!loading) return;
    if (!!data) setLock(true);
  }, [data, loading]);

  useEffect(() => {
    if (lock) window.setTimeout(() => setLock(false), 2 * 1000);
  }, [lock]);

  async function onSubmit(evt) {
    if (useBrowserAction) {
      return;
    }
    if (evt) evt.preventDefault();

    const variables = {
      cardId: card.id,
      email,
      username: account.username,
    };
    setLock(true);
    try {
      await subscribe({ variables });
      dispatch(setToast(SUBSCRIBED));
    } catch (err) {
      setLock(false);
      dispatch(setToast(String(err)));
    }
  }

  return [lock, onSubmit];
}

function getButtonText(card) {
  const { ctas } = card;
  if (Array.isArray(ctas) && ctas.length > 0 && !!ctas[0]) {
    return ctas[0].title;
  }
  return 'Sign up';
}

function isMailingListReady(card) {
  if (card && card.mailingList) {
    return !!card.mailingList.kind && !!card.mailingList.url;
  }
  return false;
}

export { getButtonText, isMailingListReady, useEmailAddress, useSubmit };
