/* global tap */

import Onboarding from 'components/onboarding';
import displayLoadingState from 'hocs/display-loading-state';
import withAuthenticationCheck from 'hocs/with-authentication-check';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import withIdentityAccountsAuthorizationCheck from 'hocs/with-identity-accounts-authorization-check';
import React from 'react';
import { compose, lifecycle, pure } from 'recompose';
import styled from 'styled-components/macro';
import LayoutBase from './base';

const Layout = styled(LayoutBase)`
  background-color: #333333;
`;

function LayoutDashboard(props) {
  const { component: Component, ...rest } = props;
  return (
    <Layout>
      <Component {...rest} />
      {/*
        The onboarding code assumes that a current account exists. However, we grab the current
        account based on the username in the URL. For a route like /dashboard/billing there is no
        username, so as a workaround we'll only render the onboarding if we have a username
    */}
      {rest.currentAccount && <Onboarding {...rest} />}
    </Layout>
  );
}

const withLifecycle = lifecycle({
  componentDidMount() {
    const { accounts, currentUser, currentAccount } = this.props;
    const account = currentAccount || accounts[0];

    // Delay loading Intercom for 15 seconds so any users created via the Stripe integration
    // are available to be queried at Intercom (hopefully preventing duplicates)
    setTimeout(function () {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: account.profile.email,
        user_id: currentUser.id,
        created_at: currentUser.createdAt,
        custom_launcher_selector: '#launch-intercom',
        hide_default_launcher: true,
        primary_username: account.username,
      });
    }, 15 * 1000);

    // --- Execute any referral conversions via Tapfiliate ---
    //
    // It's okay to execute this more than once (which does seem to happen) as Tapfiliate will
    // de-duplicate the conversions.
    //
    if (!currentUser.onboarding.hasBeenPromptedToAddUrlToInstagramProfile) {
      tap(
        'conversion',
        currentUser.stripeCustomer.id,
        0,
        {
          meta_data: {
            userId: currentUser.id,
            username: currentAccount.username,
          },
        },
        'launch',
      );
    }
  },
});

export default compose(
  withAuthenticationCheck,
  withIdentityAccounts,
  withIdentityAccountsAuthorizationCheck,
  displayLoadingState,
  withLifecycle,
  pure,
)(LayoutDashboard);
