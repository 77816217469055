import styled from 'styled-components/macro';
import ScrollContainer from 'components/ui/scroll-container';
import { Heading } from 'components/web-ui';

const Scrollable = styled(ScrollContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 70px;
`;

const Header = styled.header`
  margin-bottom: 5px;
`;

const CardHeading = styled(Heading)`
  font-size: 20px;
  text-align: center;
  color: white;
  margin-top: 5px;
`;

const CardSubHeading = styled(Heading)`
  text-align: center;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 5px;
  margin-left: 60px;
  margin-right: 60px;
`;

const Content = styled.div`
  display: flex;
  padding: 5px;
  padding-bottom: 76px;
  flex-shrink: 0;
`;

const CallToActionButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
`;

export { Scrollable, Header, CardHeading, CardSubHeading, Content, CallToActionButtonContainer };
