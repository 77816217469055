import React from 'react';
import routes from 'config/routes';
import { Link } from 'react-router-dom';
import withIdentityAccounts from 'hocs/with-identity-accounts';
import * as Styled from './styled';
import AddIdentityForm from './add-identity-form';
import IdentitiesList from './identities-list';
import ViewHeader from '@tap-bio/ui/patterns/view-header';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMoreMenu } from 'store/modules/more-menu';

function AccountAccessScene(props) {
  const moreMenuIsShowing = useSelector((state) => state.moreMenu.isShowing);
  const dispatch = useDispatch();

  function onClickBack() {
    if (moreMenuIsShowing) return;
    dispatch(toggleMoreMenu());
  }

  return (
    <Styled.Layout>
      <ViewHeader>
        {/*
          Previously when rendering this component we can use the history API to return from whence
          we came but since we added a direct link to the Account Access screen to the Instagram
          invalid token email we cannot use `history.goBack` as it'll take us back into Instagram's
          OAuth flow.

          So we're going to return to the current account e.g. /accounts/freddieking and update the
          Redux state to display the 'Your Account' menu instead.
        */}
        <ViewHeader.LeftButton
          as={Link}
          children="Back"
          onClick={onClickBack}
          to={routes.account(props.currentAccount.username)}
        />
        <ViewHeader.Title children="Account Access" />
        <ViewHeader.RightButton children="" />
      </ViewHeader>
      <Styled.Main>
        <p>Here’s who has access to your account, you can add or remove access below.</p>
        <IdentitiesList />
        <br />
        <AddIdentityForm onAdd={props.data.refetch} />
      </Styled.Main>
    </Styled.Layout>
  );
}

export default withIdentityAccounts(AccountAccessScene);
